import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptimizationProblemListComponent } from './optimization-problem-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';



@NgModule({
  declarations: [OptimizationProblemListComponent],
  exports: [OptimizationProblemListComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatProgressBarModule
  ]
})
export class VkOptimizationProblemListModule { }
