import { FormSchema } from '@nrg/components';

export interface MaloFormData {
  protocolId: string;
  maloId: string;
  anlagenNrEinspeisemenge?: number | null;
  anlagenNrGebuehren?: number | null;
  bemessungsleistung?: number | null;
  abschaltungExportEnabled: boolean;
  accountingGrid?: string | null;
  datenzaehlpunkt?: string | null;
  controlArea?: null | 'TransnetBW' | 'Tennet' | 'Amprion' | '50Hertz' | 'Flensburg';
  voltageLevel?: null | 'Hochspannung' | 'Höchstspannung' | 'Mittelspannung' | 'Niederspannung';
  measurementLocationIds: string[];
}

export const maloSchema = (isCustomer: boolean): FormSchema => ({
  type: 'object',
  title: 'Marktlokation',
  properties: {
    maloId: {
      title: 'Marktlokation-ID',
      type: 'string',
      readonly: isCustomer,
      minLength: 11,
      maxLength: 11
    },
    abschaltungExportEnabled: {
      title: 'Abschaltungsexport aktiviert (nur DV)',
      readonly: isCustomer,
      hidden: isCustomer,
      type: 'boolean'
    },
    anlagenNrEinspeisemenge: {
      title: 'Anlagen-Nr. Einspeisemenge',
      type: 'number',
      readonly: isCustomer,
      hidden: isCustomer
    },
    anlagenNrGebuehren: {
      title: 'Anlagen-Nr. Gebühren',
      readonly: isCustomer,
      type: 'number',
      hidden: isCustomer
    },
    bemessungsleistung: {
      title: 'Bemessungsleistung',
      readonly: isCustomer,
      type: 'number',
    },
    accountingGrid: {
      title: 'Bilanzkreis',
      type: 'string',
      readonly: isCustomer,
      warnIfEmpty: 'wird für RD2.0 benötigt.',
      minLength: 16,
      maxLength: 16
    },
    datenzaehlpunkt: {
      title: 'Datenzählpunkt',
      type: 'string',
      hidden: isCustomer
    },
    controlArea: {
      title: 'Regelzone',
      readonly: isCustomer,
      enum: [
        'TransnetBW', 'Tennet', 'Amprion', '50Hertz', 'Flensburg'
      ],
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    },
    measurementLocationIds: {
      title: 'Messlokations-IDs',
      description: 'Die Messlokation-ID setzt sich aus Buchstaben und Ziffern zusammen. Die Nummer besteht aus insgesamt 33 Stellen. Beispiel: DE10000090420200000000000000XXXXX. Weitere Informationen finden Sie unter anderem auf der Abrechnung Ihres Netzbetreibers.',
      type: 'array',
      items: {
        type: 'string',
        minimum: 0,
        pattern: '[A-Z\\d]{33}'
      },
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    },
    voltageLevel: {
      title: 'Spannungsebene',
      readonly: isCustomer,
      enum: [
        'Hochspannung',
        'Höchstspannung',
        'Mittelspannung',
        'Niederspannung'
      ],
      description: 'Informationen dazu finden Sie unter anderem auf der Abrechnung Ihres Netzbetreibers',
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    }
  },
  required: [
    'maloId',
    'abschaltungExportEnabled'
  ]
} as FormSchema);
