import { AxisRange } from '../models/axis';
import { Dimensions } from '../models/dimensions';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { isArrayEqual } from '../utils/array-utils';
import { Color, colorToString } from '../models/color';

export type ChartSeriesType = 'Line' | 'Schedule' | 'PlotBand'; // TODO: rename Schedule to Block

export interface ChangeDetection {
  detectChanges(): void;
}

export interface IChartSeries<TData> {
  readonly type: ChartSeriesType;
  readonly id: string;
  readonly name: string;
  readonly color: string;
  readonly yAxisId: string;
  readonly isEditable: boolean;

  isVisible: boolean;

  onInit(changeDetection: ChangeDetection): void;

  onDestroy(): void;

  detectChanges(): void;

  updateLayerDimensions(dimensions: Dimensions): void;

  updateXAxisRange(range: AxisRange): void;

  updateYAxisRange(range: AxisRange): void;

  notifyNeedsRedraw(needsRedraw: boolean): void;

  setData(data: TData[]): void;

  getData(index: number): TData | undefined;

  cloneData<TIn extends TData>(data: TIn): TData;

  updateData(index: number, data: TData): void;

  onSelectStart(index: number): void;

  onSelectEnd(index: number): TData | undefined;

  deselectData(): void;

  isDataSelected(index: number): boolean;
}

type CompareFn<TData> = (lhs: TData, rhs: TData) => boolean;

export type SelectableData<TData> = TData & { isSelected?: boolean };

function createIsSelectedFn<TData>(data: TData[], compareFn: CompareFn<TData>, selectedData?: TData): (rhs: TData) => boolean {
  if (selectedData == null) {
    return (_) => false;
  }

  return (value) => {
    return compareFn(selectedData, value);
  };
}

export class SelectableCollection<TData> {
  private selectedValue?: TData;

  constructor(private data: TData[],
              private readonly isSelectedFn: CompareFn<TData>) {
  }

  public setData(data: TData[]): void {
    this.data = data;
  }

  public select(index: number): boolean {
    const currentSelection = this.data[index];

    if (!this.isSelected(index)) {
      this.selectedValue = currentSelection;
      return true;
    }

    return false;
  }

  public deselect(): boolean {
    if (this.selectedValue == null) {
      return false;
    }

    this.selectedValue = undefined;
    return true;
  }

  public isSelected(index: number): boolean {
    const previousSelection = this.selectedValue;
    const value = this.data[index];

    if (value == null) {
      return false;
    }

    return previousSelection != null && this.isSelectedFn(previousSelection, value);
  }

  * [Symbol.iterator](): IterableIterator<SelectableData<TData>> {
    const isSelected = createIsSelectedFn(this.data, this.isSelectedFn, this.selectedValue);

    for (const value of this.data) {
      yield { ...value, isSelected: isSelected(value) };
    }
  }
}

export abstract class ChartSeries<TDataIn, TDataOut> implements IChartSeries<TDataIn> {
  protected readonly seriesWillBeDestroyed$$ = new Subject();
  abstract readonly type: ChartSeriesType;

  private needsRedraw = true;
  private selectedData?: TDataIn;

  protected readonly layerDimensions$$ = new ReplaySubject<Dimensions>(1);
  protected readonly xAxisRange$$ = new ReplaySubject<AxisRange>(1);
  protected readonly yAxisRange$$ = new ReplaySubject<AxisRange>(1);

  private readonly data$$ = new BehaviorSubject<TDataIn[]>([]);
  public readonly data = new SelectableCollection<TDataOut>([], this.isSelected);

  public readonly color: string;

  private changeDetection?: ChangeDetection;

  private _isVisible = true;
  public get isVisible(): boolean {
    return this._isVisible;
  }

  public set isVisible(value: boolean) {
    this._isVisible = value;
    this.changeDetection?.detectChanges();
  }

  public get fillOpacity(): number {
    return this.isEditable ? 1 : 0.65;
  }

  constructor(public readonly id: string,
              public readonly name: string,
              color: Color,
              public readonly yAxisId: string,
              isVisible: boolean = true,
              public readonly isEditable: boolean = false) {
    this.isVisible = isVisible;
    this.color = colorToString(color);
  }

  public onInit(changeDetection: ChangeDetection): void {
    this.changeDetection = changeDetection;

    const dimensions$ = combineLatest([
      this.layerDimensions$$,
      this.xAxisRange$$,
      this.yAxisRange$$,
    ]).pipe(
      debounceTime(100),
    );

    combineLatest([
      dimensions$,
      this.data$$.pipe(distinctUntilChanged(
        (lhs, rhs) => isArrayEqual(lhs, rhs, this.isDataEqual)
      )),
    ]).pipe(
      takeUntil(this.seriesWillBeDestroyed$$),
    ).subscribe(([[layerDimensions, xAxisRange, yAxisRange], data]) => {
      const data2 = this.render(layerDimensions, xAxisRange, yAxisRange, data, this.needsRedraw);
      this.data.setData(data2);
      this.detectChanges();
    });
  }

  public onDestroy(): void {
    this.seriesWillBeDestroyed$$.next();
    this.seriesWillBeDestroyed$$.complete();
    this.changeDetection = undefined;
  }

  public detectChanges(): void {
    if (this.changeDetection == null) {
      throw new Error('change detection not initialized');
    }

    this.changeDetection.detectChanges();
  }

  public abstract isDataEqual(lhs: TDataIn, rhs: TDataIn): boolean;

  public abstract onDragStart(selectionIndex: number, fromPos: number, axisWidth: number, axisFrom: number, axisTo: number): void;

  public abstract onDrag(toPos: number): TDataIn | undefined;

  public abstract onDragEnd(): void;

  public abstract render(layerDimensions: Dimensions, xAxisRange: AxisRange, yAxisRange: AxisRange, data: TDataIn[], needsRedraw: boolean): TDataOut[];

  public notifyNeedsRedraw(needsRedraw: boolean = true): void {
    this.needsRedraw = needsRedraw;
  }

  public updateLayerDimensions(dimensions: Dimensions): void {
    this.layerDimensions$$.next(dimensions);
    this.notifyNeedsRedraw();
  }

  public updateXAxisRange(range: AxisRange): void {
    this.xAxisRange$$.next(range);
    this.notifyNeedsRedraw();
  }

  public updateYAxisRange(range: AxisRange): void {
    this.yAxisRange$$.next(range);
    this.notifyNeedsRedraw();
  }

  public setData(data: TDataIn[]): void {
    this.data$$.next(data);
  }

  public updateData(index: number, data: TDataIn): void {
    const previousData = this.data$$.value.slice();

    previousData[index] = data;

    this.setData(previousData);
  }

  public abstract cloneData<TIn extends TDataIn>(data: TIn): TDataIn;

  public getData(index: number): TDataIn | undefined {
    const data = this.data$$.value[index];
    return data != null ? this.cloneData(data) : undefined;
  }

  // TODO: workaround used to simulate click event
  public onSelectStart(index: number): void {
    this.selectedData = this.getData(index);
  }

  // TODO: workaround used to simulate click event
  public onSelectEnd(index: number): TDataIn | undefined {
    const data = this.getData(index);

    if (this.selectedData != null && data != null &&
      this.isDataEqual(this.selectedData, data) &&
      this.data.select(index)) {
      this.detectChanges();
    }

    return data;
  }

  public deselectData(): void {
    if (this.data.deselect()) {
      this.detectChanges();
    }
  }

  public isDataSelected(index: number): boolean {
    return this.data.isSelected(index);
  }

  protected abstract isSelected(lhs: TDataOut, rhs: TDataOut): boolean;
}
