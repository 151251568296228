import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionRequiredDirective } from './permission-required.directive';

@NgModule({
  declarations: [PermissionRequiredDirective],
  imports: [
    CommonModule
  ],
  exports: [PermissionRequiredDirective]
})
export class VkPermissionRequiredModule { }
