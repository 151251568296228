<mat-card>
  <h3><span class="tag">{{tag}}</span>{{name}}</h3>
  <form [formGroup]="form" (ngSubmit)="onUpdate($event)">
    <mat-form-field>
      <mat-label>Unit Type</mat-label>
      <input required formControlName="unitType" matInput type="number">
      <mat-error *ngIf="unitTypeInput.invalid">{{fieldErrorMessage}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Unit Number</mat-label>
      <input required formControlName="unitNumber" matInput type="number">
      <mat-error *ngIf="unitNumberInput.invalid">{{fieldErrorMessage}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Data Point</mat-label>
      <mat-select [(value)]="selectedDataPoint" id="select-datapoint">
        <mat-option value={{option}} *ngFor="let option of dataPointOptions">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="buttons">
      <button [disabled]="readonly$ | async" mat-button color="warn" type="button" (click)="onDetach($event)">Zuweisung
        auflösen
      </button>
      <button [disabled]="form.invalid || (readonly$ | async)" mat-stroked-button type="submit">Aktualisieren</button>
    </div>
  </form>
</mat-card>
