import { Directive, ElementRef, Input } from '@angular/core';
import { filterResultByQuery, Result } from '../search-sheet/models/result.model';

@Directive({
  selector: '[nrgHighlightedTitle]'
})
export class HighlightedTitleDirective {
  private _node?: Result;
  private _query?: string;

  @Input() set node(node: Result) {
    this._node = node;
    this.setTitle(this.listItem, this._query ?? '', node);
  }

  @Input() set query(query: string) {
    this._query = query;
    if (this._node != null) {
      this.setTitle(this.listItem, query ?? '', this._node);
    }
  }

  constructor(private readonly listItem: ElementRef) {
  }

  setTitle(listItem: ElementRef, query: string, result: Result) {
    const queryMatch = filterResultByQuery(query, result);

    if (queryMatch.hasMatch) {
      const prefix = result.title.slice(0, queryMatch.matchStart);
      const suffix = result.title.slice(queryMatch.matchEnd, result.title.length);
      listItem.nativeElement.innerHTML = `${prefix}<span class="query">${queryMatch.rawMatch}</span>${suffix}`;
    } else {
      listItem.nativeElement.innerHTML = this._node!.title;
    }
  }
}
