<div class="wrapper" fxLayout="column" fxLayoutAlign="space-between start">
  <div>
    <div class="logo">
      <img id="companyLogo" src="./assets/img/nergie_weiss.png" [routerLink]="['/']">
    </div>
    <mat-list>
      <mat-list-item>
        <mat-icon>account_box</mat-icon>
        <div mat-line>{{username}}</div>
      </mat-list-item>
    </mat-list>
    <div class="spacer"></div>
    <mat-nav-list>
      <a mat-list-item *ngFor="let menuItem of menuItems" [routerLink]="menuItem.url" routerLinkActive="active">
        <mat-icon>{{menuItem.icon}}</mat-icon>
        {{ menuItem.title }}
        <mat-icon class="notification-icon" *ngIf="menuItem.notificationIcon">{{menuItem.notificationIcon}}</mat-icon>
      </a>
    </mat-nav-list>
  </div>
  <div class="version mat-small">{{version}}</div>
</div>
