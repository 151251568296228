import { Observable, of } from 'rxjs';

export class QueryMock<TSubscription, TVariables> {

  private mock: (args: TVariables) => TSubscription;

  calls: TVariables[] = [];

  fetch(args: TVariables): Observable<any> {
    if (!this.mock) {
      throw new Error('QueryMock was not set up');
    } else {
      this.calls.push(args);
      return of({
        data: this.mock(args)
      });
    }
  }

  setup(mock: (args: TVariables) => TSubscription) {
    this.mock = mock;
  }

  reset() {
    this.mock = undefined;
    this.calls = [];
  }

}
