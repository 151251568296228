import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptimizationResultChartComponent } from './optimization-result-chart.component';
import { NrgChartModule } from '@nrg/components';



@NgModule({
  declarations: [OptimizationResultChartComponent],
  exports: [OptimizationResultChartComponent],
  imports: [
    CommonModule,
    NrgChartModule
  ]
})
export class VkOptimizationResultChartModule { }
