import { calculateStepSize } from './coordinates';
import { AxisRange } from './axis';

const defaultXAxisHeight = 80;
const defaultYAxisLeftWidth = 65;
const defaultYAxisRightWidth = 45;
const defaultPaddingTop = 10;

export interface Size {
  width: number;
  height: number;
}

export function isSizeEqual(lhs: Size, rhs: Size): boolean {
  return lhs.width === rhs.width &&
    lhs.height === rhs.height;
}

export interface XDimensions {
  x: number;
  width: number;
}

export interface YDimensions {
  y: number;
  height: number;
}

export interface Dimensions extends XDimensions, YDimensions {
}

export interface ChartDimensions {
  xAxis: Dimensions;
  yAxisLeft: Dimensions;
  yAxisRight: Dimensions;
  series: Dimensions;
}

export function getChartDimensions(svgWidth: number,
                                   svgHeight: number,
                                   xAxisHeight: number = defaultXAxisHeight,
                                   yAxisLeftWidth: number = defaultYAxisLeftWidth,
                                   yAxisRightWidth: number = defaultYAxisRightWidth,
                                   paddingTop: number = defaultPaddingTop): ChartDimensions {
  const chartHeight = svgHeight - xAxisHeight - paddingTop;
  const chartWidth = svgWidth - (yAxisLeftWidth + yAxisRightWidth);

  return {
    yAxisLeft: {
      x: 0,
      y: paddingTop,
      width: yAxisLeftWidth,
      height: chartHeight
    },
    series: {
      x: yAxisLeftWidth,
      y: paddingTop,
      width: chartWidth,
      height: chartHeight
    },
    yAxisRight: {
      x: chartWidth + yAxisLeftWidth,
      y: paddingTop,
      width: yAxisRightWidth,
      height: chartHeight
    },
    xAxis: {
      x: yAxisLeftWidth,
      y: chartHeight + paddingTop,
      width: chartWidth,
      height: xAxisHeight
    },
  };
}

export function translateXDimensionsToWorldCoordinateSystem(dimensions: XDimensions, axisWidth: number, axisRange: AxisRange): AxisRange {
  const stepSizeX = calculateStepSize(axisWidth, axisRange.from, axisRange.to);
  const from = dimensions.x / stepSizeX + axisRange.from;
  const to = dimensions.width / stepSizeX + from;

  return {
    from: Math.trunc(from),
    to: Math.trunc(to)
  };
}
