import { MonoEntityFormDataSource } from '../entity-form-data-source';
import { FormSchema } from '@nrg/components';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { salesHierarchyDefaultValuesSchema } from '../../schema/sales-hierarchy-default-values.schema';
import { Permission } from '@vk/authentication';
import {
  SalesHierarchyDefaults,
  SalesHierarchyDefaultValuesGQL,
  SetSalesHierarchyDefaultValuesGQL
} from './sales-hierarchy-default-values-data-source.graphql.generated';
import { Injectable } from '@angular/core';
import { EntityType, VkCommonState, VkLoadingHandler } from '@vk/common';

@Injectable({
  providedIn: 'any'
})
export class SalesHierarchyDefaultValuesFormDataSource extends MonoEntityFormDataSource<SalesHierarchyDefaults> {
  readonly writePermission = Permission.CONTRACTS_WRITE_SALES;
  readonly entityType = EntityType.SalesHierarchyDefaultValues;
  readonly schema: FormSchema = salesHierarchyDefaultValuesSchema;

  readonly state = new ReplaySubject<SalesHierarchyDefaults>(1);

  constructor(
    private readonly salesHierarchyDefaultValues: SalesHierarchyDefaultValuesGQL,
    private readonly setSalesHierarchyDefaultValues: SetSalesHierarchyDefaultValuesGQL,
    private readonly loadingHandler: VkLoadingHandler
  ) {
    super('SalesHierarchyDefaultValuesFormDataSource');
  }

  private tenantId = '';

  public saveEntityIfNeeded(updatedEntity: SalesHierarchyDefaults, store: Store<VkCommonState>): void {
    let updatedDvfpDefaults = updatedEntity.dvfpDefaults;
    let updatedDvDefaults = updatedEntity.dvDefaults;

    if (updatedEntity.dvfpDefaults.grundgebuehrDiscount === 0) {
      updatedDvfpDefaults = { ...updatedEntity.dvfpDefaults, grundgebuehrDiscount: null, grundgebuehrDiscountEnde: null };
    }

    if (updatedEntity.dvDefaults.grundgebuehrDiscount === 0) {
      updatedDvDefaults = { ...updatedEntity.dvDefaults, grundgebuehrDiscount: null, grundgebuehrDiscountEnde: null };
    }
    const updatedSalesHierarchy = { ...updatedEntity, dvDefaults: updatedDvDefaults, dvfpDefaults: updatedDvfpDefaults };

    this.saveEntity(updatedSalesHierarchy, store);
  }

  loadEntity(id: string, store: Store<VkCommonState>): void {
    this.state.next({} as any);
    this.tenantId = id;
    this.salesHierarchyDefaultValues.subscribe({ tenantId: id }).pipe(
      first(),
      this.loadingHandler.untilFirst('SalesHierarchyDefaultValuesGQL', this.correlationId)
    ).subscribe(result => {
      this.state.next(result.data!.salesHierarchyDefaults);
    });
  }

  selectEntityById(id: string, store: Store<VkCommonState>): Observable<SalesHierarchyDefaults> {
    return this.state.asObservable();
  }

  saveEntity(entity: SalesHierarchyDefaults, store: Store<VkCommonState>): void {
    if (confirm('Sie sind dabei, die Standardwerte für Angebote zu ändern. Hierbei werden alle bestehenden Vertragsangebote geschlossen und neue Angebote basierend auf den neue Standardwerten erzeugt. Eventuell hinterlegte Sonder-Vertragskonditionen bei Kunden werden dabei zurückgesetzt. Sind Sie sicher?')) {
      this.setSalesHierarchyDefaultValues.mutate({
        dvValues: entity.dvDefaults,
        dvfpValues: entity.dvfpDefaults,
        dvfpidValues: entity.dvfpidDefaults,
        tenantId: this.tenantId
      }).pipe(
        this.loadingHandler.untilFirst('SetSalesHierarchyDefaultValuesGQL', this.correlationId)
      ).subscribe();
    }
  }

  createEntity(entity: SalesHierarchyDefaults, customerId: string, store: Store<VkCommonState>): void {
    throw new Error('Not implented!');
  }

  deleteEntity(id: string, store: Store<VkCommonState>): void {
    throw new Error('Not implented!');
  }

}

