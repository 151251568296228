import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HeatLoadExcelImportService, OffsetMode } from './heat-load-excel-import.service';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Permission, selectPermissionPresent } from '@vk/authentication';
import { ToolbarItem, ToolBarItemType } from '@nrg/components';
import {
  HeatStorageOptimizationPreferencesGQL, HeatStoragePreferences, HistoricalHeatLoad,
  SaveHeatStorageOptimizationPreferencesGQL
} from './heat-storage-preferences.graphql.generated';

@Component({
  selector: 'vk-heat-storage-preferences',
  templateUrl: './heat-storage-preferences.component.html',
  styleUrls: ['./heat-storage-preferences.component.css']
})
export class HeatStoragePreferencesComponent implements OnInit, OnDestroy {

  private componentWillUnmount$$ = new Subject();

  public isReadonly$$ = new BehaviorSubject(true);

  public heatStorageId$ = new ReplaySubject<string>(1);


  heatStoragePreferences$ = new ReplaySubject<HeatStoragePreferences>(1);

  selectedDate$$ = new ReplaySubject<Date>(1);

  offsetModeControl = new FormControl();
  dateControl = new FormControl();

  minimalHeat = new FormControl();
  maximalHeat = new FormControl();

  displayedColumns: string[] = ['time', 'value'];
  private loadSubscription: Subscription;

  constructor(
    private readonly heatLoadExcelImportService: HeatLoadExcelImportService,
    private readonly store: Store<unknown>,
    private readonly load: HeatStorageOptimizationPreferencesGQL,
    private readonly save: SaveHeatStorageOptimizationPreferencesGQL,
  ) {
  }

  hasPermission$ = this.store.pipe(select(selectPermissionPresent, { permission: Permission.ASSETS_WRITE }));

  actions$ = combineLatest([
    this.isReadonly$$,
    this.hasPermission$
  ]).pipe(
    map(([isReadonly, hasPermission]) => {
      if (!hasPermission) {
        return [{
          disabled: true,
          icon: 'create',
          itemType: ToolBarItemType.LABEL,
        }] as ToolbarItem[];
      }
      if (isReadonly) {
        return [{
          disabled: false,
          icon: 'create',
          itemType: ToolBarItemType.ACTION,
          execute: () => {
            this.isReadonly$$.next(false);
          }
        }];
      } else {
        return [{
          disabled: false,
          icon: 'check',
          itemType: ToolBarItemType.ACTION,
          execute: () => {
            this.handleSubmit();
            this.isReadonly$$.next(true);
          }
        }, {
          disabled: false,
          icon: 'cancel',
          itemType: ToolBarItemType.ACTION,
          execute: () => {
            this.handleRevert();
            this.isReadonly$$.next(true);
          }
        }];
      }
    })
  );

  tableData$ = combineLatest([
    this.heatStoragePreferences$,
    this.selectedDate$$
  ]).pipe(
    map(([heatStoragePreferences, date]) => {

      if (!heatStoragePreferences || !heatStoragePreferences.historicalHeatLoad || !date) {
        return [];
      }

      const startTimestamp = new Date(heatStoragePreferences.historicalHeatLoad.startDate).getTime();

      return Array.from({ length: 24 }, (v, i) => i).map(hour => {
        const { dateForHour, index } = this.calculateDatetimeAndIndex(date, hour, startTimestamp);
        return {
          time: `${dateForHour.toLocaleDateString()} ${dateForHour.toLocaleTimeString()}`,
          value: heatStoragePreferences.historicalHeatLoad.values[index] ?? null
        };
      });
    })
  );

  private calculateDatetimeAndIndex(date: Date, hour: number, startTimestamp: number) {
    const dateForHour = new Date(date);
    dateForHour.setHours(hour);
    const timestamp = dateForHour.getTime();
    const index = Math.floor((timestamp - startTimestamp) / (1000 * 60 * 60));
    return { dateForHour, index };
  }

  @Input() set heatStorageId(value: string) {
    this.heatStorageId$.next(value);
  }

  ngOnInit(): void {

    this.loadSubscription = this.heatStorageId$.pipe(
      switchMap(id => this.load.subscribe({ id })),
      map(it => it.data.heatStorageOptimizationPreferences)
    ).subscribe(this.heatStoragePreferences$);

    this.offsetModeControl.setValue(OffsetMode.Auto);

    this.dateControl.valueChanges.pipe(
      takeUntil(this.componentWillUnmount$$)
    ).subscribe(this.selectedDate$$);

    this.minimalHeat.valueChanges.pipe(
      takeUntil(this.componentWillUnmount$$)
    ).subscribe(value => this.updateMinimalHeat(value));

    this.maximalHeat.valueChanges.pipe(
      takeUntil(this.componentWillUnmount$$)
    ).subscribe(value => this.updateMaximalHeat(value));

    this.heatStoragePreferences$.pipe(
      filter(it => !!it),
      first()
    ).subscribe(preferences => {
      if (preferences.historicalHeatLoad) {
        this.updateDatePickerFromHeatLoad(preferences.historicalHeatLoad);
      }
    });

    this.heatStoragePreferences$.pipe(
      filter(it => !!it),
      takeUntil(this.componentWillUnmount$$)
    ).subscribe(preferences => {
      const min = preferences.minimalHeatLoad ?? '';
      if (this.minimalHeat.value !== min) {
        this.minimalHeat.setValue(min);
      }
      const max = preferences.maximalHeatLoad ?? '';
      if (this.maximalHeat.value !== max) {
        this.maximalHeat.setValue(max);
      }
    });
  }

  ngOnDestroy(): void {
    this.loadSubscription.unsubscribe();
    this.componentWillUnmount$$.next();
    this.componentWillUnmount$$.complete();
    this.selectedDate$$.complete();
  }

  handleNewFile(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.item(0);
    if (file) {
      const reader = new FileReader();
      reader.onload = async (evt) => {
        const content = evt.target.result as ArrayBuffer;

        const heatLoad = await this.heatLoadExcelImportService.importExcelFile(content, OffsetMode[this.offsetModeControl.value]);

        const id = await this.heatStorageId$.pipe(first()).toPromise();
        this.updateDatePickerFromHeatLoad(heatLoad);

        this.heatStoragePreferences$.pipe(first()).subscribe(preferences => {
          const newPreferences = {
            ...preferences,
            historicalHeatLoad: heatLoad
          };
          this.heatStoragePreferences$.next(newPreferences);
        });
      };
      reader.readAsArrayBuffer(file);
    }
  }

  private updateDatePickerFromHeatLoad(heatLoad: HistoricalHeatLoad) {
    if (heatLoad) {
      const dateForPicker = new Date(heatLoad.startDate);
      dateForPicker.setHours(dateForPicker.getHours() + 12);
      this.dateControl.setValue(dateForPicker);
    }
  }

  deleteHistoricalHeatLoad() {
    this.heatStoragePreferences$.pipe(first()).subscribe(preferences => {
      const newPreferences = {
        ...preferences,
        historicalHeatLoad: null
      };
      this.heatStoragePreferences$.next(newPreferences);
    });
  }

  updateMinimalHeat(value: number) {
    this.heatStoragePreferences$.pipe(first()).subscribe(preferences => {
      const newPreferences = {
        ...preferences,
        minimalHeatLoad: this.coerceNumber(value)
      };
      this.heatStoragePreferences$.next(newPreferences);
    });
  }

  updateMaximalHeat(value: number) {
    this.heatStoragePreferences$.pipe(first()).subscribe(preferences => {
      const newPreferences = {
        ...preferences,
        maximalHeatLoad: this.coerceNumber(value)
      };
      this.heatStoragePreferences$.next(newPreferences);
    });
  }

  private coerceNumber(value: any): number | null {
    if (typeof value === 'number') {
      return value;
    } else {
      return null;
    }
  }

  async handleSubmit() {
    this.heatStoragePreferences$.pipe(
      first(),
      switchMap(preferences => {
        return this.save.mutate({
          preferences
        });
      })
    ).subscribe();
  }

  async handleRevert() {

    this.loadSubscription.unsubscribe();

    this.loadSubscription = this.heatStorageId$.pipe(
      switchMap(id => this.load.subscribe({ id })),
      map(it => it.data.heatStorageOptimizationPreferences)
    ).subscribe(this.heatStoragePreferences$);

  }
}
