import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NrgToolbarComponent } from './toolbar.component';
import { ToolbarService } from './toolbar.service';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    NrgToolbarComponent
  ],
  exports: [
    NrgToolbarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class NrgToolbarModule {

  static forRoot(): ModuleWithProviders<NrgToolbarModule> {
    return {
      ngModule: NrgToolbarModule,
      providers: [
        ToolbarService
      ]
    };
  }

}
