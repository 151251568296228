<div>
  <mat-progress-bar *ngIf="loading$ | async" mode="query"></mat-progress-bar>
  <mat-form-field>
    <input matInput #searchBox (input)="search(searchBox.value)" placeholder="Suchen"/>
  </mat-form-field>
  <table>
    <tr>
      <th class="listElement">Name</th>
      <th class="listElement">Host</th>
      <th class="listElement">Status</th>
    </tr>
    <tr *ngFor="let cpe of cpes$ | async">
      <td>{{cpe.name}}</td>
      <td>{{cpe.ipAddress}}</td>
      <td>
        <span [ngClass]="connectionStateClass(cpe.state)">
          {{connectionStateDisplayString(cpe.state)}}</span>
      </td>
      <td>
        <nrg-button type="negative" (click)="toggleConnection(cpe.state, cpe.protocolId)">
          {{toggleConnectionDisplayString(cpe.state)}}</nrg-button>
      </td>
    </tr>
  </table>
</div>
