import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import {NrgMenuComponent} from './menu.component';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  declarations: [
    NrgMenuComponent
  ],
  exports: [
    NrgMenuComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
    RouterModule,
    MatCardModule,
    MatToolbarModule,
    FlexLayoutModule
  ]
})
export class NrgMenuModule {
}
