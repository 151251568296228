import { FormObjectProperty, FormSchema } from '@nrg/components';
import { ControlAreaEnum } from './customer-import-export.graphql.generated';

export interface CustomerTemplate {
  customerId: string;
  kundennummer?: number | null;
  firmierung?: string | null;
  firmierungZusatz1?: string | null;
  firmierungZusatz2?: string | null;
  email?: string | null;
  strasse?: string | null;
  hausnummer?: string | null;
  zusatz?: string | null;
  plz?: string | null;
  stadt?: string | null;
  land?: string | null;
  aussendienstMitarbeiter?: string | null;
  innendienstMitarbeiter?: string | null;
}

export const customerTemplateSchema: FormObjectProperty = {
  title: 'Kunde/Geschäftspartner',
  type: 'object',
  properties: {
    customerId: {
      type: 'string',
      title: 'Kunden-ID'
    },
    kundennummer: {
      type: 'number',
      title: 'Kundennummer'
    },
    firmierung: {
      type: 'string',
      title: 'Firmierung'
    },
    firmierungZusatz1: {
      type: 'string',
      title: 'Firmierung Zusatz 1'
    },
    firmierungZusatz2: {
      type: 'string',
      title: 'Firmierung Zusatz 2'
    },
    email: {
      title: 'E-Mail',
      type: 'string'
    },
    strasse: {
      title: 'Straße',
      type: 'string'
    },
    hausnummer: {
      title: 'Hausnummer',
      type: 'string'
    },
    zusatz: {
      title: 'Zusatz',
      type: 'string'
    },
    plz: {
      title: 'Postleitzahl',
      type: 'string'
    },
    stadt: {
      title: 'Stadt',
      type: 'string'
    },
    land: {
      title: 'Land',
      type: 'string'
    },
    aussendienstMitarbeiter: {
      title: 'Außendienstmitarbeiter',
      type: 'string'
    },
    innendienstMitarbeiter: {
      title: 'Innendienstmitarbeiter',
      type: 'string'
    },
  },
  required: ['customerId', 'firmierung']
};

export interface MaloTemplate {
  maloId: string;
  controlArea?: ControlAreaEnum | null;
  accountingGrid?: string | null;
}

export const maloTemplateSchema: FormObjectProperty = {
  title: 'Marktlokation',
  type: 'object',
  properties: {
    maloId: {
      title: 'Marktlokation-ID',
      type: 'string',
    },
    accountingGrid: {
      title: 'Bilanzkreis',
      type: 'string',
    },
    controlArea: {
      title: 'Regelzone',
      enum: [
        '10YDE-ENBW-----N',
        '10YDE-EON------1',
        '10YDE-RWENET---I',
        '10YDE-VE-------2',
        '10YFLENSBURG---3'
      ],
    },
  },
  required: [
    'maloId'
  ]
};

export interface TechnicalUnitTemplate {
  eegAnlagenschluessel?: string | null;
  obereNennleistung: number;
  maxLeistung: number;
  minLeistung: number;
  anlageDarfStehen: boolean;
  startbarProTag?: number | null;
  wirkungsgradElektrisch?: number | null;
  wirkungsgradThermisch?: number | null;
  maxJahresmengeStrom?: number | null;
  strasse?: string | null;
  hausnummer?: string | null;
  zusatz?: string | null;
  plz?: string | null;
  stadt?: string | null;
  land?: string | null;
  datumDerNetzanbindung?: string | null;
  inbetriebnahmeDatum?: string | null;
  vertragsrelevant?: boolean | null;
  energySource?: 'Biomasse' | 'Photovoltaik';
  curtailmentOffset: number;
  anzulegenderWert?: number | null;
  flexMotor: boolean;
}

export const technicalUnitTemplateSchema: FormObjectProperty = {
  title: 'Technische Einheit',
  type: 'object',
  properties: {
    obereNennleistung: {
      title: 'Obere Nennleistung elektrisch (kW)',
      description: 'Auf welche maximale Leistung ist die Maschine ausgelegt?',
      type: 'number',
      minimum: 0,
    },
    maxLeistung: {
      title: 'Maximale Fahrplanleistung (kW)',
      description: 'Welche Leistung soll die Maschine maximal fahren?',
      type: 'number',
      minimum: 0
    },
    minLeistung: {
      title: 'Minimale Fahrplanleistung (kW)',
      description: 'Welche Leistung soll die Maschine minimal fahren?',
      type: 'number',
      minimum: 1
    },
    curtailmentOffset: {
      title: 'Offset (Abschaltung DV)',
      type: 'number',
      description: 'Durch die Hinterlegung eines Offsets kann Einfluss auf den Trigger zur Abschaltung DV genommen werden',
      default: 0
    },
    energySource: {
      title: 'Erzeugungsart',
      enum: ['Biomasse', 'Photovoltaik'],
      default: 'Biomasse'
    },
    anlageDarfStehen: {
      title: 'Abschaltung auf 0 kW erlaubt',
      description: 'Darf die Maschine bis auf Null herunter gefahren werden?',
      type: 'boolean',
    },
    eegAnlagenschluessel: {
      title: 'EEG-Anlagenschlüssel',
      type: 'string'
    },
    flexMotor: {
      title: 'Flexmotor',
      type: 'boolean'
    },
    anzulegenderWert: {
      title: 'Anzulegender Wert (ct/kWh)',
      description: 'Die Pflege des Feldes ist abhängig von Feld Flex-Motor.',
      type: 'number',
    },
    startbarProTag: {
      title: 'Anzahl Starts pro Tag',
      description: 'Es werden maximal diese Anzahl an Starts pro Tag in der Fahrplanerstellung berücksichtigt.',
      type: 'number',
      minimum: 0,
      maximum: 24
    },
    wirkungsgradElektrisch: {
      title: 'Wirkungsgrad elektrisch (Prozent)',
      description: 'Wie ist das Verhältnis von aufgewandter Energieinput zu nutzbarem Strom? (Wirkungsgrad el. Herstellerangabe BHKW)',
      type: 'number',
      minimum: 25,
      maximum: 55
    },
    wirkungsgradThermisch: {
      title: 'Wirkungsgrad thermisch (Prozent)',
      description: 'Wie ist das Verhältnis von aufgewandter Energieinput zu nutzbarer Wärme? (Wirkungsgrad th. Herstellerangabe BHKW)',
      type: 'number',
      minimum: 0,
      maximum: 55
    },
    maxJahresmengeStrom: {
      title: 'Maximale Jahresmenge Strom (kWh)',
      description: 'Wieviel Strom soll maximal pro Jahr erzeugt werden?',
      type: 'number',
      minimum: 0
    },
    strasse: {
      title: 'Straße',
      type: 'string'
    },
    hausnummer: {
      title: 'Hausnummer',
      type: 'string'
    },
    zusatz: {
      title: 'Zusatz',
      type: 'string'
    },
    plz: {
      title: 'Postleitzahl',
      type: 'string'
    },
    stadt: {
      title: 'Stadt',
      type: 'string'
    },
    land: {
      title: 'Land',
      type: 'string'
    },
    datumDerNetzanbindung: {
      title: 'Datum der Netzanbindung',
      type: 'dateString'
    },
    inbetriebnahmeDatum: {
      title: 'Datum der Inbetriebnahme',
      type: 'dateString'
    },
    vertragsrelevant: {
      title: 'Vertragsrelevant',
      type: 'boolean'
    },
  },
  required: [
    'name',
    'maxLeistung',
    'minLeistung',
    'obereNennleistung',
    'curtailmentOffset',
    'anlageDarfStehen'

  ]
};

export interface GasStorageTemplate {
  producedBiogas: number;
  capacity: number;
  fillingLevel?: number | null;
  methanConcentration?: number | null;
  minimalFillingLevel?: number | null;
  maximalFillingLevel?: number | null;
}

export const gasStorageTemplateSchema: FormObjectProperty = {
  title: 'Gasspeicher',
  type: 'object',
  properties: {
    producedBiogas: {
      type: 'number',
      title: 'Erzeugtes Biogas (m³N/h)',
      minimum: 0,
      maximum: 1000
    },
    capacity: {
      type: 'number',
      title: 'Speicherkapazität Biogas (m³)',
      minimum: 0,
      maximum: 25000
    },
    methanConcentration: {
      type: 'number',
      title: 'Methangehalt Biogas (Prozent)',
      minimum: 45,
      maximum: 80
    },
    minimalFillingLevel: {
      type: 'number',
      title: 'Untere Gasspeicherfüllstandsgrenze (Prozent)',
      minimum: 0,
      maximum: 40
    },
    maximalFillingLevel: {
      type: 'number',
      title: 'Obere Gasspeicherfüllstandsgrenze (Prozent)',
      minimum: 60,
      maximum: 100
    }
  },
  required: [
    'name',
    'producedBiogas',
    'capacity'
  ]
};

export interface CustomerWithAssets {
  customer: CustomerTemplate;
  malo: MaloTemplate[];
  gasStorage: GasStorageTemplate[];
  technicalUnits: TechnicalUnitTemplate[];
}

export const customerWithAssetsTemplateSchema: FormSchema = {
  type: 'object',
  properties: {
    customer: customerTemplateSchema,
    malo: {
      title: 'Marktlokation',
      type: 'array',
      items: maloTemplateSchema,
      maxItems: 1,
    },
    gasStorage: {
      title: 'Gasspeicher',
      type: 'array',
      items: gasStorageTemplateSchema,
      maxItems: 1
    },
    technicalUnits: {
      title: 'Technische Einheiten',
      type: 'array',
      items: technicalUnitTemplateSchema
    }
  },
  required: [
    'customer',
    'malo',
    'gasStorage',
    'technicalUnits',
  ]
};
