import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VkCpeStatusListComponent } from './cpe-status-list.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NrgButtonModule } from '@nrg/components';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [VkCpeStatusListComponent],
  imports: [
    CommonModule, MatInputModule, MatFormFieldModule, NrgButtonModule, MatProgressBarModule
  ],
  exports: [VkCpeStatusListComponent]
})

export class VkCpeStatusListModule { }
