import { FormSchema } from '@nrg/components';

export const priceCalculationDVFPSchema: FormSchema = {
  type: 'object',
  required: [],
  properties: {
    dienstleistungsentgeltSatz: {
      type: 'number',
      title: 'Dienstleistungsentgelt Satz'
    },
    grundgebuehr: {
      type: 'number',
      title: 'Grundgebühr'
    },
    grundgebuehrDiscount: {
      type: 'number',
      title: 'Rabatt auf Grundgebühr (in Prozent)',
      minimum: 0,
      maximum: 100
    },
    grundgebuehrDiscountEnde: {
      type: 'monthYearString',
      title: 'Rabatt begrenzt bis einschließlich...',
    },
    zuteilungsfaktorFpMehrerloes: {
      type: 'number',
      title: 'Zuteilungsfaktor FP Mehrerlös'
    },
    zuteilungsfaktorFpMindererloes: {
      type: 'number',
      title: 'Zuteilungsfaktor FP Mindererlös'
    },
  },
  title: 'Preiskalkulation'
};
