import { select, Store } from '@ngrx/store';
import { AllocationEntity, EntityAllocations } from '@nrg/components';
import { Observable, of, ReplaySubject } from 'rxjs';
import { Permission } from '@vk/authentication';
import { selectPermissionPresent } from '@vk/authentication';
import { first, map, takeUntil } from 'rxjs/operators';
import {
  AllocateEntityGQL,
  AssetAllocations,
  AssetAllocationType,
  DeallocateEntityGQL,
  EntityAllocationsGQL
} from './entity-allocations.graphql.generated';
import { VkCommonState } from '@vk/common';

export class EntityAllocationDataSource {

  private readonly state$$ = new ReplaySubject<AssetAllocations>(1);
  private readonly dataSourceWillBeDestroyed$$ = new ReplaySubject<void>(1);

  constructor(
    private readonly store: Store<VkCommonState>,
    private readonly load: EntityAllocationsGQL,
    private readonly allocate: AllocateEntityGQL,
    private readonly deallocate: DeallocateEntityGQL,
    private type: AssetAllocationType
  ) {
  }

  selectIsReadonly(): Observable<boolean> {
    return this.store.pipe(
      select(selectPermissionPresent, { permission: Permission.ASSETS_WRITE }),
      map(it => !it)
    );
  }

  loadEntities(parentId: string) {

    let parkId: string | undefined;
    let customerId: string | undefined;

    switch (this.type) {
      case AssetAllocationType.TechnicalUnitToPark:
      case AssetAllocationType.HeatStorageToPark:
      case AssetAllocationType.GasStorageToPark:
        customerId = parentId;
        break;
      case AssetAllocationType.TechnicalUnitToMalo:
      case AssetAllocationType.TechnicalUnitToGasStorage:
      case AssetAllocationType.TechnicalUnitToHeatStorage:
        parkId = parentId;
        break;
    }

    this.load.subscribe({
      type: this.type,
      customerId,
      parkId
    }).pipe(
      takeUntil(this.dataSourceWillBeDestroyed$$)
    ).subscribe(result => {
      this.state$$.next(result.data!.assetAllocationsByType);
    });

  }

  unload() {
    this.dataSourceWillBeDestroyed$$.next();
  }

  selectRowEntities(): Observable<AllocationEntity[]> {
    return this.state$$.pipe(
      map(state => state.rowEntities.map(entity => {
        return {
          id: entity.protocolId,
          type: entity.type.toString(),
          name: entity.name
        };
      }))
    );
  }

  selectColumnEntities(): Observable<AllocationEntity[]> {
    return this.state$$.pipe(
      map(state => state.columnEntities.map(entity => {
        return {
          id: entity.protocolId,
          type: entity.type.toString(),
          name: entity.name
        };
      }))
    );
  }

  selectEntityAllocations(): Observable<EntityAllocations> {
    return this.state$$.pipe(
      map(state => {
        const result: EntityAllocations = {};
        for (const allocation of state.allocations) {
          result[allocation.rowEntityProtocolId] = result[allocation.rowEntityProtocolId] ?? [];
          result[allocation.rowEntityProtocolId].push(allocation.columnEntityProtocolId);
        }
        return result;
      })
    );
  }

  updateEntityAllocation(checked: boolean, rowEntity: AllocationEntity, columnEntity: AllocationEntity): void {
    if (checked) {
      this.allocate.mutate({
        type: this.type,
        rowId: rowEntity.id,
        columnId: columnEntity.id
      }).subscribe(
        () => {
        },
        error => this.resetForm()
      );
    } else {
      this.deallocate.mutate({
        type: this.type,
        rowId: rowEntity.id,
        columnId: columnEntity.id
      }).subscribe(
        () => {
        },
        error => this.resetForm()
      );
    }
  }

  private resetForm() {
    this.state$$.pipe(first()).subscribe(state => this.state$$.next(state));
  }
}
