import { IAdminLayoutRemote } from './admin-layout.remote.interface';
import { empty, Observable, throwError } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

export class AdminLayoutRemote implements IAdminLayoutRemote {

    constructor(private component: {username: Observable<string>}) { }

    assertUsername(nameToAssert: string): Promise<void> {
        return this.component.username.pipe(
            filter(name => name !== ''),
            take(1),
            switchMap(name => {
                if (name === nameToAssert) {
                    return empty();
                } else {
                    return throwError(new Error(`Username of logged in user expected to be '${nameToAssert}' but found '${name}'`));
                }
            }),
        ).toPromise();
    }

    getUsername(): Promise<string> {
        return this.component.username.pipe(
            filter(name => name !== ''),
            take(1),
        ).toPromise();
    }
}
