interface Range {
  from: number;
  to: number;
}

export type DragAndDropHandler = (toPos: number) => number;

export function handleDragAndDrop(fromPos: number,
                                  axisWidth: number,
                                  axisFrom: number,
                                  axisTo: number): DragAndDropHandler {
  const stepSize = (axisTo - axisFrom) / axisWidth;

  return toPos => {
    const distance = (toPos - fromPos) * stepSize;
    // tslint:disable-next-line:no-bitwise
    return ~~distance;
  };
}

export class DragAndDrop<T extends Range> {
  private handler?: DragAndDropHandler;
  private block?: T;

  start(fromPos: number,
        axisWidth: number,
        axisFrom: number,
        axisTo: number,
        block: T): void {
    this.handler = handleDragAndDrop(fromPos, axisWidth, axisFrom, axisTo);
    this.block = block;
  }

  drag(toPos: number): T | undefined {
    if (this.handler != null && this.block != null) {
      const distance = this.handler(toPos);
      const block = this.block;

      return {
        ...block,
        from: block.from + distance,
        to: block.to + distance,
      };
    }

    return undefined;
  }

  stop(): void {
    this.handler = undefined;
    this.block = undefined;
  }
}
