import { MonoEntityFormDataSource } from '../entity-form-data-source';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { FormSchema } from '@nrg/components';
import { offerSchema } from '../../schema/offer.schema';
import { Permission } from '@vk/authentication';
import { OfferFromDataGQL, OfferInput, SaveOfferFormDataGQL } from './offer-data-source.graphql.generated';
import { EntityType, VkCommonState } from '@vk/common';

export class OfferFormDataSource extends MonoEntityFormDataSource<OfferInput> {
  readonly writePermission = Permission.CONTRACTS_WRITE_SALES;
  readonly entityType = EntityType.Customer;
  readonly schema: FormSchema = offerSchema;

  private readonly state = new ReplaySubject<OfferInput>(1);
  private id: string | undefined;

  constructor(
    correlationId: string,
    private readonly offerFromData: OfferFromDataGQL,
    private readonly saveOfferFormData: SaveOfferFormDataGQL
  ) {
    super(correlationId);
  }

  loadEntity(id: string, store: Store<unknown>): void {
    this.id = id;
    this.offerFromData.subscribe({ id }).subscribe(result => this.state.next(result.data!.offerById));
  }

  saveEntity(entity: OfferInput, store: Store<unknown>): void {
    this.saveOfferFormData.mutate({ id: this.id!, data: entity }).subscribe();
  }

  selectEntityById(id: string, store: Store<VkCommonState>): Observable<OfferInput | undefined> {
    return this.state.asObservable();
  }

  createEntity(entity: OfferInput, customerId: string, store: Store<VkCommonState>): void {
    throw new Error('Method not implemented.');
  }

  deleteEntity(id: string, store: Store<VkCommonState>): void {
    throw new Error('Method not implemented.');
  }

  makeTemplate(parentId: string): OfferInput {
    throw new Error('Method not implemented.');
  }

}
