<svg #chart width="100%" height="500" xmlns="http://www.w3.org/2000/svg"
     (mousedown)="onZoomBegin($event)"
     (mousemove)="onZoomRangeChange($event)">

  <!-- X Axis -->
  <g class="axis-x" nrgTransform [translateBy]="xAxisDimensions$ | async">
    <line x1="0" y1="0" [attr.x2]="xAxisWidth$ | async" y2="0"></line>
    <ng-container *ngFor="let tick of xAxisTicks$ | async">
      <line [attr.x1]="tick.x1"
            [attr.y1]="tick.y1"
            [attr.x2]="tick.x2"
            [attr.y2]="tick.y2">
      </line>
      <text nrgTransform [translateBy]="tick.label" rotateBy="-45"
            text-anchor="end">{{tick.label.text}}</text>
    </ng-container>
  </g>

  <!-- Y Axis Left -->
  <g class="axis-y left" nrgTransform [translateBy]="yAxisLeftDimensions$ | async">
    <line [attr.x1]="yAxisLeftWidth$ | async" y1="0" [attr.x2]="yAxisLeftWidth$ | async"
          [attr.y2]="yAxisLeftHeight$ | async"></line>
    <ng-container *ngFor="let tick of yAxisLeftTicks$ | async">
      <line [attr.x1]="tick.x1"
            [attr.y1]="tick.y1"
            [attr.x2]="tick.x2"
            [attr.y2]="tick.y2">
      </line>
      <text nrgTransform [translateBy]="tick.label"
            text-anchor="end">{{tick.label.text}}</text>
    </ng-container>
  </g>

  <!-- Y Axis Right -->
  <g class="axis-y right" nrgTransform [translateBy]="yAxisRightDimensions$ | async">
    <line [attr.x1]="0" y1="0" [attr.x2]="0" [attr.y2]="yAxisRightHeight$ | async"></line>
    <ng-container *ngFor="let tick of yAxisRightTicks$ | async">
      <line [attr.x1]="tick.x1"
            [attr.y1]="tick.y1"
            [attr.x2]="tick.x2"
            [attr.y2]="tick.y2">
      </line>
      <text nrgTransform [translateBy]="tick.label">{{tick.label.text}}</text>
    </ng-container>
  </g>

  <g class="chart-series" nrgTransform [translateBy]="seriesDimensions$ | async">
    <ng-container *ngFor="let series of chartSeries$ | async">
      <ng-container *ngIf="series.isVisible" [ngSwitch]="series.type">

        <!-- Line Chart Series -->
        <ng-container *ngSwitchCase="'Line'">
          <g class="line-chart" [id]="series.id">
            <path [attr.d]="series.path$$ | async"
                  [attr.stroke]="series.color"
                  [attr.stroke-width]="2"
                  [attr.stroke-opacity]="0.9"
                  fill="transparent"></path>
            <circle *ngFor="let point of series.points$$ | async"
                    class="data-point" r="4"
                    [attr.cx]="point[0]"
                    [attr.cy]="point[1]"
                    [attr.data-x]="point[2]"
                    [attr.data-y]="point[3]"
                    (click)="onPointSelected($event.target, series, point[2], point[3])">
            </circle>
          </g>
        </ng-container>

        <!-- Block Chart Series -->
        <ng-container *ngSwitchCase="'Schedule'">
          <g class="block-chart" [id]="series.id">
            <rect *ngFor="let block of series.data; let i = index"
                  (mousedown)="series.isEditable && onBlockMouseDown($event, series, i)"
                  (mouseup)="series.isEditable && onBlockSelectEnd($event, series, i)"
                  [class.editable]="series.isEditable"
                  [class.selected]="series.isEditable && block.isSelected"
                  [id]="block.id"
                  [attr.data-value]="block.value"
                  [attr.data-from]="block.from"
                  [attr.data-to]="block.to"
                  [attr.fill]="series.color"
                  [attr.fill-opacity]="series.fillOpacity"
                  [attr.stroke]="series.color"
                  [attr.stroke-width]="2"
                  [attr.stroke-opacity]="0.8"
                  [attr.rx]="2"
                  [attr.ry]="2"
                  [attr.x]="block.x"
                  [attr.y]="block.y"
                  [attr.width]="block.width"
                  [attr.height]="block.height"/>
          </g>
        </ng-container>
      </ng-container>
    </ng-container>
    <rect *ngIf="isZoomAreaVisible"
          fill="#00000050"
          [attr.x]="zoomAreaX$ | async"
          [attr.y]="zoomAreaY$ | async"
          [attr.width]="zoomAreaWidth$ | async"
          [attr.height]="zoomAreaHeight$ | async"></rect>
  </g>
</svg>


