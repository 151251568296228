import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { selectIsLoadingByContext, VkCommonState, VkLoadingHandler } from '@vk/common';
import {
  AttachableDevice,
  AttachableDeviceType,
  AttachedDevice,
  AttacheEntityToCpeGQL,
  CpeAllocationsGQL,
  DetacheEntityToCpeGQL,
  ReattacheEntityToCpeGQL
} from './attach-entity-to-cpe-data-source.graphql.generated';
import { Injectable } from '@angular/core';

let instanceCounter = 0;

@Injectable({
  providedIn: 'any'
})
export class AttachEntityToCpeDataSource {
  private readonly dataSourceWillBeDestroyed = new Subject();
  public readonly cpeId$$ = new ReplaySubject<string>(1);
  public readonly type$$ = new ReplaySubject<AttachableDeviceType>(1);

  private data$ = combineLatest([
    this.cpeId$$,
    this.type$$
  ]).pipe(
    switchMap(([protocolId, type]) => {
      return this.cpeAllocationsGQL.subscribe({
        protocolId,
        type
      }).pipe(
        this.vkLoadingHandler.untilFirst('AttachEntityToCpeDataSource', this.correlationId)
      );
    }),
    map(it => it.data!.cpeAllocationsByType),
    takeUntil(this.dataSourceWillBeDestroyed)
  );

  public correlationId = `AttachEntityToCpeDataSource_${instanceCounter++}`;

  public readonly isLoading$ = this.store.pipe(select(selectIsLoadingByContext, { correlationId: this.correlationId }));

  public readonly attachableEntities$: Observable<AttachableDevice[]> = this.data$.pipe(
    map(data => data.attachableDevices),
  );

  public readonly attachedEntities$: Observable<AttachedDevice[]> = this.data$.pipe(
    map(data => data.attachedDevices),
  );

  public load(cpeId: string, type: 'GasStorage' | 'HeatStorage' | 'TechnicalUnit') {
    this.cpeId$$.next(cpeId);
    switch (type) {
      case 'GasStorage':
        this.type$$.next(AttachableDeviceType.GasStorage);
        break;
      case 'TechnicalUnit':
        this.type$$.next(AttachableDeviceType.TechnicalUnit);
        break;
      case 'HeatStorage':
        this.type$$.next(AttachableDeviceType.HeatStorage);
        break;
    }
  }

  constructor(
    protected readonly store: Store<VkCommonState>,
    private readonly cpeAllocationsGQL: CpeAllocationsGQL,
    private readonly attacheEntityToCpeGQL: AttacheEntityToCpeGQL,
    private readonly detacheEntityToCpeGQL: DetacheEntityToCpeGQL,
    private readonly reattacheEntityToCpeGQL: ReattacheEntityToCpeGQL,
    private readonly vkLoadingHandler: VkLoadingHandler
  ) {
  }

  public attachEntity(entityId: string, unitType: number, unitNumber: number, dataPoint: string) {
    combineLatest([
      this.cpeId$$,
      this.type$$
    ]).pipe(
      first()
    ).subscribe(([cpeId, type]) => {
      this.attacheEntityToCpeGQL.mutate({
        type,
        protocolId: entityId,
        unitType,
        unitNumber,
        dataPoint,
        cpeProtocolId: cpeId
      }).pipe(
        this.vkLoadingHandler.untilComplete('AttachEntityToCpeDataSource', this.correlationId)
      ).subscribe();
    });
  }

  public detachEntity(entityId: string) {
    combineLatest([
      this.cpeId$$,
      this.type$$
    ]).pipe(
      first()
    ).subscribe(([cpeId, type]) => {
      this.detacheEntityToCpeGQL.mutate({
        type,
        protocolId: entityId,
        cpeProtocolId: cpeId
      }).pipe(
        this.vkLoadingHandler.untilComplete('AttachEntityToCpeDataSource', this.correlationId)
      ).subscribe();
    });
  }

  public reattachEntity(entityId: string, unitType: number, unitNumber: number, dataPoint: string) {
    combineLatest([
      this.cpeId$$,
      this.type$$
    ]).pipe(
      first()
    ).subscribe(([cpeId, type]) => {
      this.reattacheEntityToCpeGQL.mutate({
        type,
        protocolId: entityId,
        unitType,
        unitNumber,
        dataPoint,
        cpeProtocolId: cpeId
      }).pipe(
        this.vkLoadingHandler.untilComplete('AttachEntityToCpeDataSource', this.correlationId)
      ).subscribe();
    });
  }

  public onDestroy() {
    this.dataSourceWillBeDestroyed.next();
    this.dataSourceWillBeDestroyed.complete();
  }

}
