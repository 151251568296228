export enum EntityType {
  SafetySchedule = 'Sicherheitsfahrplan',
  ProductionForecast = 'Leistungsprognose',
  CurtailmentSchedule = 'Abschaltfahrplan',
  TemperatureForwardCurve = 'TemperatureForwardCurve',
  HeatFillingLevelForecast = 'HeatFillingLevelForecast',
  HeatLoadForecast = 'HeatLoadForecast',
  Malo = 'Malo',
  Park = 'Park',
  TechnicalUnit = 'TechnicalUnit',
  PowerReading = 'PowerReading',
  Prescription = 'Prescription',
  Proposal = 'Proposal',
  Contract = 'Contract',
  ActiveSchedule = 'ActiveSchedule',
  OperationsSchedule = 'OperationsSchedule',
  CustomerPremisesEquipment = 'CustomerPremisesEquipment',
  HeatStorage = 'HeatStorage',
  GasStorage = 'GasStorage',
  GasReading = 'GasReading',
  HeatReading = 'HeatReading',
  GasFillingLevelForecast = 'GasFillingLevelForecast',
  Customer = 'Customer',
  SalesHierarchy = 'SalesHierarchy',
  PriceCalculation = 'PriceCalculation',
  Offer = 'Offer',
  OfferRevenuePrediction = 'OfferRevenuePrediction',
  SellingOpportunity = 'SellingOpportunity',
  NonAvailability = 'NonAvailability',
  ParkRevenueForecast = 'ParkRevenueForecast',
  PriceForwardCurve = 'PriceForwardCurve',
  Optimization = 'Optimization',
  SalesHierarchyDefaultValues = 'SalesHierarchyDefaultValues',
  AllowedProduct = 'AllowedProduct',
  NetworkConnection = 'NetworkConnection',
  ContractRelevance = 'ContractRelevance',
  Pool = 'Pool'
}
