import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParkPreferencesComponent } from './park-preferences.component';
import { NrgSchemaFormModule, NrgToolbarModule } from '@nrg/components';

@NgModule({
  declarations: [ParkPreferencesComponent],
  exports: [ParkPreferencesComponent],
  imports: [
    CommonModule,
    NrgToolbarModule,
    NrgSchemaFormModule
  ]
})
export class VkParkPreferencesModule { }
