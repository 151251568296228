import { FormObjectProperty, SchemaFormDataSource } from '@nrg/components';
import { CustomerWithAssets, customerWithAssetsTemplateSchema } from '../customer-import-export.schema';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

export class CustomerImportFormDataSource extends SchemaFormDataSource<CustomerWithAssets> {

  public customerId$$ = new ReplaySubject<string>(1);
  public anyTeNeedIsInvalid$$ = new ReplaySubject<boolean>(1);

  constructor(private readonly allCustomerIds$: Observable<string[]>) {
    super();
  }

  public customerIdAlreadyInUse$ = combineLatest([
    this.allCustomerIds$,
    this.customerId$$
  ]).pipe(
    map(([allCustomerIds, customerId]) => {
      if (!customerId) {
        return false;
      } else {
        return allCustomerIds.includes(customerId);
      }
    }),
    startWith(false)
  );

  schema: FormObjectProperty = customerWithAssetsTemplateSchema;

  onFormValueChanges(data: CustomerWithAssets) {
    super.onFormValueChanges(data);
    this.customerId$$.next(data.customer.customerId);
    this.anyTeNeedIsInvalid$$.next(data.technicalUnits.some(te => !te.flexMotor && !te.anzulegenderWert));
  }

  loadImport(data: CustomerWithAssets) {
    this.patchValue(data);
  }

  setUpDerivedFields(formGroup: FormGroup) {

  }
}
