import { FormSchema } from '@nrg/components';

export const offerSchema: FormSchema = {
    type: 'object',
    required: [
      'vertragsversion'
    ],
    properties: {
      kuendigungsfrist: {
        type: 'string',
        // example: 'P3M',
        description: 'Beispiele: 1 Monat: P1M; 3 Monate: P3M; 1 Jahr: P1Y; 1 Jahr und 3 Monate: P1Y3M',
        title: 'Kündigungsfrist',
        readonly: true
      },
      verlaengerungsdauer: {
        type: 'string',
        // example: 'P1Y',
        description: 'Beispiele: 1 Monat: P1M; 3 Monate: P3M; 1 Jahr: P1Y; 1 Jahr und 3 Monate: P1Y3M',
        title: 'Verlängerungsdauer',
        readonly: true
      },
      vertragsversion: {
        title: 'Version des Vertragstextes',
        enum: ['v1.0', 'v1.1', 'v2.0'],
        required: [
          'v1.0',
          'v1.1',
          'v2.0',
        ],
      }
    },
    title: 'Angebot'
  };
