import { FormSchema } from '@nrg/components';

export interface CustomerPremisesEquipmentFormData {
  protocolId: string;
  name: string;
  commonAddress?: number | null;
  ipAddress?: string | null;
  port?: number | null;
  steuerbox?: string | null;
  simCardId?: string | null;
  phoneNumber?: string | null;
}

export const customerPremisesEquipmentSchema: FormSchema = {
  type: 'object',
  properties: {
    name: {
      title: 'Name',
      type: 'string'
    },
    commonAddress: {
      title: 'Common Address',
      type: 'number'
    },
    ipAddress: {
      title: 'IP Adresse',
      type: 'string'
    },
    port: {
      title: 'Port (IP Adresse)',
      type: 'number'
    },
    steuerbox: {
      title: 'Steuerbox',
      type: 'string'
    },
    simCardId: {
      title: 'SIM Kartennummer',
      type: 'string'
    },
    phoneNumber: {
      title: 'SIM Rufnummer',
      type: 'string'
    },
  },
  required: []
};
