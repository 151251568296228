import { FormSchema, SchemaFormDataSource } from '@nrg/components';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, switchMap, tap } from 'rxjs/operators';
import {
  ParkOptimizationPreferencesGQL,
  ParkPreferencesInput,
  SaveParkOptimizationPreferencesGQL
} from './park-preferences.graphql.generated';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParkPreferencesDataSource extends SchemaFormDataSource<ParkPreferencesInput> {
  readonly schema: FormSchema = {
    type: 'object',
    title: 'Einstellungen für Fahrplanoptimierung',
    properties: {
      accuracy: {
        type: 'number',
        title: 'Optimierungsgenauigkeit',
        description: 'Bestimmt das Abbruchkriterium der Optimierung. Höhere Werte beschleunigen die Optimierung indem diese früher abgebrochen wird. Die errzielten Erlöse werden dabei jedoch veringert.',
        default: 10,
      },
      targetMarginPercentage: {
        type: 'number',
        title: 'Zielmengengenauigkeit (Prozent)',
        description: 'Gibt an um wieviel die Zielmenge der Lösung von der Vorgabe nach unten abweichen darf.',
        default: 5,
      },
      optimizationTimeFrame: {
        enum: ['Daily', 'Weekly'],
        title: 'Zeithorizont der Optimierung',
        default: 'Weekly',
      }
    },
    required: []
  };

  private currentValue: ParkPreferencesInput;
  public parkId$: Observable<string>;

  constructor(
    private readonly load: ParkOptimizationPreferencesGQL,
    private readonly save: SaveParkOptimizationPreferencesGQL,
  ) {
    super();
  }

  onInit() {

    if (!this.parkId$) {
      throw new Error('parkId$ must be set before init');
    }

    this.parkId$.pipe(
      distinctUntilChanged(),
      switchMap(parkId => {
        return this.load.subscribe({id: parkId});
      }),
      filter(it => !!it)
    ).subscribe(result => {
      this.patchValue(result.data.parkOptimizationPreferences);
    });
  }

  onFormValueChanges(values: any): void {
    this.currentValue = values;
    super.onFormValueChanges(values);
  }

  submit() {
    this.parkId$.pipe(
      first()
    ).subscribe(id => {
      const preferences = {
        id,
        ...this.currentValue
      };
      this.patchValue(preferences);
      this.save.mutate({ preferences }).subscribe();
    });
    super.submit();
  }
}
