import { FormSchema } from '@nrg/components';

export const items: FormSchema = {
  type: 'object',
  required: [
    'grundgebuehr',
    'numberOfTechnischeEinheiten'
  ],
  properties: {
    grundgebuehr: {
      type: 'number'
    },
    numberOfTechnischeEinheiten: {
      type: 'number',
    }
  },
  title: 'Grundgebühr je Anzahl TEs'
};

export const salesHierarchyDefaultValuesDV: FormSchema = {
  type: 'object',
  required: [
    'dienstleistungsentgeltSatz',
    'verfahrenAusfallarbeit',
    'vertragsversion'
  ],
  properties: {
    vertragsversion: {
      title: 'Version des Vertragstextes',
      enum: ['v1.0', 'v1.1', 'v2.0'],
      required: [
        'v1.0',
        'v1.1',
        'v2.0',
      ],
    },
    dienstleistungsentgeltSatz: {
      type: 'number'
    },
    grundgebuehrDiscount: {
      type: 'number',
      title: 'Rabatt auf Grundgebühr (in Prozent)',
      minimum: 0,
      maximum: 100,
    },
    grundgebuehrDiscountEnde: {
      type: 'monthYearString',
      title: 'Rabatt begrenzt bis einschließlich...'
    },
    grundgebuehrenPerNummberOfTechnischeEinheiten: {
      title: 'Grundgebühr je Anzahl TEs',
      type: 'array',
      description: 'The list should not be empty and it should cover the whole range of number of TEs from 1 to the maximum number of TEs that can be attached to a malo without gaps.',
      items
    },
    kuendigungsfrist: {
      type: 'string',
      description: 'A string representation of java.lang.Period represented in ISO-8601 of the form PnYnMnD or by the regex \'([-+]?)P(?:([-+]?[0-9]+)Y)?(?:([-+]?[0-9]+)M)?(?:([-+]?[0-9]+)W)?(?:([-+]?[0-9]+)D)?\'',
      readonly: true
    },
    verlaengerungsdauer: {
      type: 'string',
      description: 'A string representation of java.lang.Period represented in ISO-8601 of the form PnYnMnD or by the regex \'([-+]?)P(?:([-+]?[0-9]+)Y)?(?:([-+]?[0-9]+)M)?(?:([-+]?[0-9]+)W)?(?:([-+]?[0-9]+)D)?\'',
      readonly: true
    },
    par51EntschaedigungFactor: {
      type: 'number',
      title: 'Faktor §51 (in Prozent)'
    },
    ausfallVerguetungFactor: {
      type: 'number',
      title: 'Faktor Ausfallvergütung (in Prozent)',
      readonly: true
    },
    ausfallVerguetungMalo: {
      enum: ['ANZULENGENDERWERT'],
      title: 'Art der Ausfallvergütung bei marktbedingter Steuerung',
      readonly: true
    },
    verfahrenAusfallarbeit: {
      enum: ['PAUSCHAL'],
      title: 'Berechnungsverfahren der Ausfallarbeit',
      readonly: true
    },
    par51EntschaedigungMalo: {
      enum: ['MONATSMARKTWERT'],
      title: 'Grundlage Entschädigung bei Steuerung aufgrund §51',
      readonly: true
    },
  },
  title: 'Default-Werte für DV-Angebote'
};

export const salesHierarchyDefaultValuesDVFP: FormSchema = {
  type: 'object',
  required: [
    'dienstleistungsentgeltSatz',
    'zuteilungsfaktorMehrerloes',
    'zuteilungsfaktorMindererloes',
    'vertragsversion'
  ],
  properties: {
    vertragsversion: {
      title: 'Version des Vertragstextes',
      enum: ['v1.0', 'v1.1', 'v2.0'],
      required: [
        'v1.0',
        'v1.1',
        'v2.0',
      ],
    },
    dienstleistungsentgeltSatz: {
      type: 'number'
    },
    grundgebuehrDiscount: {
      type: 'number',
      title: 'Rabatt auf Grundgebühr (in Prozent)'
    },
    grundgebuehrDiscountEnde: {
      type: 'monthYearString',
      title: 'Rabatt begrenzt bis einschließlich...'
    },
    grundgebuehrenPerNummberOfTechnischeEinheiten: {
      title: 'Grundgebühr je Anzahl TEs',
      type: 'array',
      description: 'The list should not be empty and it should cover the whole range of number of TEs from 1 to the maximum number of TEs that can be attached to a malo without gaps.',
      items
    },
    kuendigungsfrist: {
      type: 'string',
      description: 'A string representation of java.lang.Period represented in ISO-8601 of the form PnYnMnD or by the regex \'([-+]?)P(?:([-+]?[0-9]+)Y)?(?:([-+]?[0-9]+)M)?(?:([-+]?[0-9]+)W)?(?:([-+]?[0-9]+)D)?\'',
      readonly: true
    },
    verlaengerungsdauer: {
      type: 'string',
      description: 'A string representation of java.lang.Period represented in ISO-8601 of the form PnYnMnD or by the regex \'([-+]?)P(?:([-+]?[0-9]+)Y)?(?:([-+]?[0-9]+)M)?(?:([-+]?[0-9]+)W)?(?:([-+]?[0-9]+)D)?\'',
      readonly: true
    },
    zuteilungsfaktorMehrerloes: {
      type: 'number'
    },
    zuteilungsfaktorMindererloes: {
      type: 'number'
    }
  },
  title: 'Default-Werte für DV+FP-Angebote'
};

export const salesHierarchyDefaultValuesDVFPID: FormSchema = {
  type: 'object',
  required: [
    'dienstleistungsentgeltSatz',
    'zuteilungsfaktorMehrerloes',
    'zuteilungsfaktorMindererloes',
    'zuteilungsfaktorFpMehrerloesId',
    'zuteilungsfaktorFpMindererloesId',
    'vertragsversion'
  ],
  properties: {
    vertragsversion: {
      title: 'Version des Vertragstextes',
      enum: ['v2.0'],
      required: [
        'v2.0'
      ],
    },
    dienstleistungsentgeltSatz: {
      type: 'number'
    },
    grundgebuehrDiscount: {
      type: 'number',
      title: 'Rabatt auf Grundgebühr (in Prozent)'
    },
    grundgebuehrDiscountEnde: {
      type: 'monthYearString',
      title: 'Rabatt begrenzt bis einschließlich...'
    },
    grundgebuehrenPerNummberOfTechnischeEinheiten: {
      title: 'Grundgebühr je Anzahl TEs',
      type: 'array',
      description: 'The list should not be empty and it should cover the whole range of number of TEs from 1 to the maximum number of TEs that can be attached to a malo without gaps.',
      items
    },
    kuendigungsfrist: {
      type: 'string',
      description: 'A string representation of java.lang.Period represented in ISO-8601 of the form PnYnMnD or by the regex \'([-+]?)P(?:([-+]?[0-9]+)Y)?(?:([-+]?[0-9]+)M)?(?:([-+]?[0-9]+)W)?(?:([-+]?[0-9]+)D)?\'',
      readonly: true
    },
    verlaengerungsdauer: {
      type: 'string',
      description: 'A string representation of java.lang.Period represented in ISO-8601 of the form PnYnMnD or by the regex \'([-+]?)P(?:([-+]?[0-9]+)Y)?(?:([-+]?[0-9]+)M)?(?:([-+]?[0-9]+)W)?(?:([-+]?[0-9]+)D)?\'',
      readonly: true
    },
    zuteilungsfaktorMehrerloes: {
      type: 'number',
      title: 'Zuteilungsfaktor FP Mehrerlös'
    },
    zuteilungsfaktorMindererloes: {
      type: 'number',
      title: 'Zuteilungsfaktor FP Mindererlös'
    },
    zuteilungsfaktorMehrerloesId: {
      type: 'number',
      title: 'Zuteilungsfaktor Intraday Mehrerlös'
    },
    zuteilungsfaktorMindererloesId: {
      type: 'number',
      title: 'Zuteilungsfaktor Intraday Mindererlös'
    },
  },
  title: 'Default-Werte für DV+FP+ID-Angebote'
};

export const salesHierarchyDefaultValuesSchema: FormSchema = {
  type: 'object',
  required: ['dvDefaults', 'dvfpDefaults', 'dvfpDefaults'],
  properties: {
    dvDefaults: salesHierarchyDefaultValuesDV,
    dvfpDefaults: salesHierarchyDefaultValuesDVFP,
    dvfpidDefaults: salesHierarchyDefaultValuesDVFPID
  },
  title: 'Default-Werte für Angebote'
};
