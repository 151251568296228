import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { convertDateToUTCIsoString, FormGroupBuilder, setDateToLastDayOfMonth } from './form-group-builder/form-group-builder';
import { FormListProperty, FormObjectProperty, FormProperty, FormSchema } from './form-group-builder/form-schema';
import { SchemaFormDataSource } from './schema-form-data-source';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'nrg-schema-form[dataSource]',
  templateUrl: './schema-form.component.html',
  styleUrls: ['./schema-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchemaFormComponent implements OnInit, OnDestroy {
  private readonly componentWillBeDestroyed = new Subject();

  public readonly _disabled = new BehaviorSubject<boolean>(false);
  private readonly _schemaForm = new ReplaySubject<FormGroupDirective>(1);

  public formGroup!: FormGroup;

  @ViewChild('schemaForm')
  public set schemaForm(form: FormGroupDirective) {
    this._schemaForm.next(form);
  }

  @Input()
  public dataSource!: SchemaFormDataSource<any>;

  public get schema(): FormSchema {
    return this.dataSource.schema;
  }

  @Input()
  public submitButtonTitle = 'Absenden';

  @Input()
  public hideSubmitButton = false;

  @Input()
  public set disabled(disabled: boolean) {
    this._disabled.next(disabled);
  }

  public get disabled(): boolean {
    return this._disabled.value;
  }

  @Output()
  public valueSubmit = new EventEmitter<any>();

  @Output()
  public hasValidationErrors = new EventEmitter<boolean>();

  @Output()
  public valueChanges = new EventEmitter<any>();

  public get canSubmit(): boolean {
    return !this.disabled && !this.formGroup.invalid;
  }

  public propertyKeys(formGroup: FormGroup) {
    return Object.getOwnPropertyNames(formGroup.controls);
  }

  constructor(private readonly formGroupBuilder: FormGroupBuilder, private ref: ChangeDetectorRef) {
  }

  public detectChanges() {
    // workaround to allow change detection if form is disabled
    const needsEnabling = this._disabled.value;

    if (needsEnabling) {
      this._disabled.next(false);
    }

    this.ref.markForCheck();

    if (needsEnabling) {
      this._disabled.next(true);
    }
  }

  ngOnInit() {
    this.createFormGroup();

    this.dataSource.onInit();

    this.dataSource.setUpDerivedFields(this.formGroup);

    this.dataSource.submit$.pipe(
      withLatestFrom(this._schemaForm),
      takeUntil(this.componentWillBeDestroyed),
    ).subscribe(([_, schemaForm]) => {
      schemaForm.ngSubmit.emit();
    });

    this.dataSource.values$.pipe(
      takeUntil(this.componentWillBeDestroyed)
    ).subscribe(values => {
      this.updateFormGroup(this.schema, this.formGroup, values);
    });

    this._disabled.pipe(
      takeUntil(this.componentWillBeDestroyed)
    ).subscribe(isDisabled => {
      this.updateIsDisabled(isDisabled);
    });

    this.formGroup.valueChanges.pipe(
      takeUntil(this.componentWillBeDestroyed),
    ).subscribe(values => {
      this.dataSource.onFormValueChanges(values);
      this.hasValidationErrors.emit(!this.canSubmit);
    });
  }

  ngOnDestroy(): void {
    this.dataSource.onDestroy();
    this.componentWillBeDestroyed.next();
    this.componentWillBeDestroyed.complete();
  }

  private createFormGroup() {
    this.formGroup = this.formGroupBuilder.makeGroup({}, this.schema, this.disabled);
  }

  private updateFormGroup(schema: FormSchema, formGroup: FormGroup, values: any): void {
    const mappedValues = this.formGroupBuilder.mapFormData(values, schema, true);
    formGroup.reset();
    formGroup.patchValue(mappedValues);

    this.updateContainingArraysRecursively(mappedValues, formGroup, schema);
    this.dataSource.onOriginalFormValueChanges(formGroup.value);
    validateAllFormFields(formGroup);
    this.detectChanges();
  }

  private updateContainingArraysRecursively(mappedValues: any, formGroup: FormGroup, schema: FormSchema, path: string[] = []) {
    for (const key of Object.getOwnPropertyNames(mappedValues)) {
      const subGroup = mappedValues[key];
      if (Array.isArray(subGroup)) {
        const formArray = formGroup.get([...path, key]) as FormArray;
        formArray.clear();
        subGroup.forEach((item: any) => {
          const groupSchema = (schema.properties[key] as FormListProperty).items as FormSchema;
          const arrayFormGroup = this.formGroupBuilder.makeGroup(item, groupSchema, this.disabled);
          this.updateFormGroup(groupSchema, arrayFormGroup, item);
          formArray.push(arrayFormGroup);
        });
      } else if (schema.properties[key].type === 'object') {
        this.updateContainingArraysRecursively(subGroup, formGroup, schema.properties[key] as FormObjectProperty, [...path, key]);
      }
    }
  }

  private updateIsDisabled(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }

  public schemaForProperty(schema: FormSchema, propertyKey: string): FormProperty {
    return schema.properties[propertyKey];
  }

  public formGroupForProperty(formGroup: FormGroup, propertyKey: string): FormGroup {
    return formGroup.controls[propertyKey] as FormGroup;
  }

  public titleForProperty(schema: FormSchema, propertyKey: string): string {
    if (!schema || !schema.properties || !schema.properties[propertyKey] || !schema.properties[propertyKey].title) {
      return propertyKey;
    }
    const property = schema.properties[propertyKey];
    const title = property.title ?? propertyKey;

    if (property.readonly && schema.required && schema.required.includes(propertyKey)) {
      return `${title} [schreibgeschützt] [Pflichtfeld]`;
    }

    if (property.readonly) {
      return `${title} [schreibgeschützt]`;
    }

    if (schema.required && schema.required.includes(propertyKey)) {
      return `${title} [Pflichtfeld]`;
    }

    return title;
  }

  public isRequired(schema: FormSchema, propertyKey: string): boolean {
    return schema.required && schema.required.includes(propertyKey);
  }

  public isReadonly(schema: FormSchema, propertyKey: string): boolean {
    const readOnly = schema.properties[propertyKey].readonly;
    return readOnly === true;
  }

  public isFormArray(schema: FormSchema, propertyKey: string): boolean {
    const subSchema = schema.properties[propertyKey];
    return subSchema.type === 'array' && subSchema.items.type === 'object';
  }

  public defaultValue(schema: FormSchema, propertyKey: string): string | number | undefined {
    const subSchema = schema.properties[propertyKey];
    if (subSchema.type === 'number') {
      return subSchema.default;
    } else if (subSchema.type === 'string' || 'enum' in subSchema) {
      return subSchema.default;
    }
  }

  public getFormArray(formGroup: FormGroup, propertyKey: string): FormArray {
    return formGroup.controls[propertyKey] as FormArray;
  }

  public getArraySchema(schema: FormSchema, propertyKey: string): FormSchema {
    return (schema.properties[propertyKey] as FormListProperty).items as FormSchema;
  }

  public addNewFormGroup(formArray: FormArray, itemSchema: FormSchema, event: Event) {
    formArray.push(this.formGroupBuilder.makeGroup({}, itemSchema, this.disabled));
    event.preventDefault();
  }

  public removeFormGroup(formArray: FormArray, index: number) {
    formArray.removeAt(index);
  }

  public handleSubmit() {
    const value = this.formGroupBuilder.mapFormDataBack(this.formGroup, this.schema);
    this.valueSubmit.next(value);
  }

  public hasError(formGroup: FormGroup, propertyKey: string): boolean {
    return formGroup.controls[propertyKey].errors != null;
  }

  public hasWarnings(formGroup: FormGroup, propertyKey: string): boolean {
    return (formGroup.controls[propertyKey] as any).warnings != null;
  }

  public getError(formGroup: FormGroup, schema: FormSchema, propertyKey: string): string {
    const errors = formGroup.controls[propertyKey].errors;
    return this.errorDisplayString(errors, schema, propertyKey);
  }

  public getWarning(formGroup: FormGroup, schema: FormSchema, propertyKey: string): string {
    const warnings = (formGroup.controls[propertyKey] as any).warnings;
    return this.errorDisplayString(warnings, schema, propertyKey);
  }

  private errorDisplayString(errors: ValidationErrors | null, schema: FormObjectProperty, propertyKey: string) {
    if (errors == null) {
      return '';
    }

    const schemaForProperty = schema.properties[propertyKey];

    if (errors.required != null) {
      return typeof schemaForProperty.warnIfEmpty === 'string' ? schemaForProperty.warnIfEmpty : 'wird benötigt.';
    }

    if (errors.min != null) {
      return `muss mindestes ${errors.min.min} sein.`;
    }

    if (errors.max != null) {
      return `darf höchstens ${errors.max.max} sein.`;
    }

    if (errors.maxlength != null) {
      return `darf höchstens ${errors.maxlength.requiredLength} Zeichen haben.`;
    }

    if (errors.minlength != null) {
      return `muss mindestens ${errors.minlength.requiredLength} Zeichen haben.`;
    }

    if (errors.pattern != null) {
      return `ist ungültig.`;
    }

    return '';
  }

  public isEnum(schema: FormSchema, propertyKey: string): boolean {
    const property = this.schemaForProperty(schema, propertyKey);
    return 'enum' in property;
  }

  // this closes the mat-date-picker after selection of month and sets date to the last day of the month.
  // it is a small workaround as i dont find a way to set the format of the control value to MM-YYYY without overwriting the global
  // dateAdapter which would change the format for all date pickers in this component. It would be better
  // to use normalized values here (01.03.2020) and display only MM-YYYY and map to the 31.03.2020 in
  // the mapping method (i implemented this already there already).
  public chosenMonthHandler(normalizedMonth: Date, datePicker: MatDatepicker<any>, formGroup: FormGroup, propertyKey: string) {
    const newDate = setDateToLastDayOfMonth(normalizedMonth);
    const normalizedDate = convertDateToUTCIsoString(newDate);
    formGroup.controls[propertyKey].setValue(normalizedDate);
    datePicker.close();
  }

  notAtMaxItems(schema: FormSchema, formGroup: FormGroup, propertyKey: string) {
    const controls = this.getFormArray(formGroup, propertyKey).controls;
    const subSchema = this.schemaForProperty(schema, propertyKey) as FormListProperty;
    return !subSchema.maxItems || controls.length < subSchema.maxItems;
  }
}

function validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);

    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    }
    else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    }
  });
}
