import { Component, OnInit } from '@angular/core';
import { VkLoggingService } from '@vk/common';
import de from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

  ngOnInit() {
    registerLocaleData(de);
    this.loggingService.init('portal');
  }

  constructor(
    private readonly loggingService: VkLoggingService
  ) {
  }
}
