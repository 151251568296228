import { NgModule, ModuleWithProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { optimizerBaseUrl, autoMonitorLoggedInCustomer } from './vk-optimizer.tokens';
import { HttpClientModule } from '@angular/common/http';
import { VkAuthenticationModule } from '@vk/authentication';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { VkOptimizerReportLinkModule } from './report-link/report-link.module';


@NgModule({
  imports: [
    HttpClientModule,
    VkAuthenticationModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    VkOptimizerReportLinkModule,
  ]
})
export class VkOptimizerModule {
  static forRoot(baseUrl: string = '', autoMonitorCustomer: boolean = false): ModuleWithProviders<VkOptimizerModule> {
    return {
      ngModule: VkOptimizerModule,
      providers: [
        {
          provide: optimizerBaseUrl,
          useValue: baseUrl
        },
        {
          provide: autoMonitorLoggedInCustomer,
          useValue: autoMonitorCustomer
        }
      ]
    };
  }
}
