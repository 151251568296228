import { FormSchema } from '@nrg/components';
import { CodingEnum } from '../data-source/park.graphql.generated';

export interface ParkFormData {
  id: string;
  parkName: string;
  distributionSystemOperatorId?: string | null;
  distributionSystemOperatorCoding?: CodingEnum | null;
  parkGroesse: number;
  sortenreinerPark: boolean;
  underPar51: boolean;
  safetySchedulesEnabled: boolean;
  produktfreigabeDerived: string;
  activateOverride: boolean;
  produktfreigabeDV: boolean;
  produktfreigabeDVFP: boolean;
  produktfreigabeDVFPID: boolean;
  redispatchTransmissionOverride?: boolean | null;
  abschaltungEnabled: boolean;
}

export const parkSchema: ((isCustomer: boolean, isCreate: boolean) => FormSchema) = (isCustomer: boolean, isCreate: boolean) => ({
  type: 'object',
  properties: {
    parkName: {
      title: 'Name',
      type: 'string',
      readonly: isCustomer
    },
    abschaltungEnabled: {
      title: 'Abschaltung aktiviert (nur DV)',
      type: 'boolean',
      readonly: true,
      description: 'Feature noch nicht freigegeben, Implementierung fertig aber noch nicht getestet.',
      hidden: isCustomer
    },
    safetySchedulesEnabled: {
      title: 'Notfall-Fahrplan aktiviert',
      type: 'boolean',
      readonly: isCustomer || isCreate
    },
    parkGroesse: {
      title: 'Parkgröße (Leistung in kW)',
      type: 'number',
      readonly: true,
      hidden: isCustomer || isCreate
    },
    sortenreinerPark: {
      title: 'Sortenreiner Park',
      type: 'boolean',
      readonly: true,
      hidden: isCustomer || isCreate
    },
    underPar51: {
      title: 'Relevanz für §51 (Park)',
      type: 'boolean',
      readonly: true,
      hidden: isCustomer || isCreate
    },
    produktfreigabeDerived: {
      title: 'Errechnete Produktfreigabe',
      type: 'string',
      readonly: true,
      hidden: isCustomer || isCreate
    },
    activateOverride: {
      title: 'Manuelles Überschreiben aktivieren',
      type: 'boolean',
      hidden: isCustomer || isCreate
    },
    produktfreigabeDV: {
      title: 'Produktfreigabe DV',
      type: 'boolean',
      hidden: isCustomer || isCreate
    },
    produktfreigabeDVFP: {
      title: 'Produktfreigabe DVFP',
      type: 'boolean',
      hidden: isCustomer || isCreate
    },
    produktfreigabeDVFPID: {
      title: 'Produktfreigabe DVFPID',
      type: 'boolean',
      hidden: isCustomer || isCreate
    },
    redispatchTransmissionOverride: {
      title: 'Redispatch-Datenübertragung (überschreibt reguläre Logik falls gesetzt)',
      description: 'JA: Die Daten für diesen Park werden immer gesendet. NEIN: Dieser Park wird aus den zu sendenden Daten herausgefiltert. Leer: Es gilt die reguläre Logik gemäß der Verträge.',
      type: 'boolean',
      hidden: isCustomer
    },
    distributionSystemOperatorId: {
      title: 'Anschlussnetzbetreiber (MP-ID) (nur für RD2.0 relevant)',
      description: 'Bei MP-ID des Anschlussnetzbetreibers handelt es sich um eine 13-stellige Identifikationsnummer. Sie besteht ausschließlich aus Ziffern. Beispiel: 9900000000000 Weitere Informationen finden Sie unter anderem in den Unterlagen zum Thema Redispatch Ihres Netzbetreibers bzw. auf der Website des BDEW (Stichwort: bdew-codes).',
      type: 'string',
      warnIfEmpty: 'wird für RD2.0 benötigt.',
      minLength: 13,
      maxLength: 13
    },
    distributionSystemOperatorCoding: {
      title: 'Codierung der Anschlussnetzbetreiber-ID (nur für RD2.0 relevant)',
      description: 'Informationen dazu finden Sie unter anderem in den Unterlagen zum Thema Redispatch Ihres Netzbetreibers. Beispiel: NDE. Standardmäßig kann bei BDEW-Codes von NDE ausgegangen werden',
      enum: ['A10', 'NDE'],
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    }
  },
  required: [
    'parkName',
    'safetySchedulesEnabled',
    'activateOverride',
    'produktfreigabeDV',
    'produktfreigabeDVFP',
    'produktfreigabeDVFPID',
    'abschaltungEnabled'
  ]
});
