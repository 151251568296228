import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultTreeComponent } from './search-result-tree.component';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { SimpleTreeItemComponent } from './simple-tree-item/simple-tree-item.component';
import { HighlightedTitleDirective } from './highlighted-title.directive';


@NgModule({
  declarations: [
    SearchResultTreeComponent,
    SimpleTreeItemComponent,
    HighlightedTitleDirective
  ],
  exports: [
    SearchResultTreeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule
  ]
})
export class NrgSearchResultTreeModule { }
