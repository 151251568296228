<h3>
  <a href="https://www.n-ergie.de">
    N-ERGIE AKTIENGESELLSCHAFT
  </a>
</h3>
<div>
  &copy;
  {{now | date: 'yyyy'}}, made with <i class="material-icons">favorite</i>
  <a href="https://n-ergie.com" target="_blank"> by N-ERGIE Aktiengesellschaft</a> for a better web.
</div>
