import { MonoEntityFormDataSource } from './entity-form-data-source';
import { select, Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { FormSchema } from '@nrg/components';
import { customerSchema } from '../schema/customer.schema';
import { Permission } from '@vk/authentication';
import { CustomerDataInput, CustomerFormDataGQL, UpdateCustomerDataGQL } from './customer.graphql.generated';
import { first, map } from 'rxjs/operators';
import { VkLoadingHandler } from '@vk/common';
import { Injectable } from '@angular/core';

const correlationId = 'CustomerFormDataSource';

@Injectable({
  providedIn: 'any'
})
export class CustomerFormDataSource extends MonoEntityFormDataSource<CustomerDataInput> {
  readonly writePermission = Permission.ASSETS_WRITE;
  readonly entityType = 'CustomerFormDataSource' as any;
  readonly schema: FormSchema = customerSchema;

  constructor(
    private readonly load: CustomerFormDataGQL,
    private readonly update: UpdateCustomerDataGQL,
    private readonly loadingHandler: VkLoadingHandler
  ) {
    super(correlationId);
  }

  private readonly state = new ReplaySubject<CustomerDataInput>(1);

  loadEntity(id: string, store: Store<unknown>): void {
    this.state.next({} as any);
    this.load.subscribe({ customerId: id }).pipe(
      first(),
      this.loadingHandler.untilFirst('ParkGQL', this.correlationId),
      map(result => result.data!.customerDataById)
    ).subscribe(result => this.state.next(result));
  }

  selectEntityById(id: string, store: Store<unknown>): Observable<CustomerDataInput | undefined> {
    return this.state.asObservable();
  }

  saveEntity(entity: CustomerDataInput, store: Store<unknown>): void {
    this.update.mutate({ data: entity }).subscribe();
  }

  createEntity(entity: CustomerDataInput, customerId: string, store: Store<unknown>): void {
    throw new Error('Method not implemented.');
  }

  deleteEntity(id: string, store: Store<unknown>): void {
    throw new Error('Method not implemented.');
  }

}
