import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AssetType, JobStateFragment, JobType, OptimizationStateGQL } from './optimization-state.graphql.generated';

@Component({
  selector: 'vk-optimization-state',
  templateUrl: './optimization-state.component.html',
  styleUrls: ['./optimization-state.component.css']
})
export class OptimizationStateComponent implements OnInit {

  private readonly showChart$$ = new BehaviorSubject<boolean>(false);
  public showChart$ = this.showChart$$.asObservable();

  public assetId$$ = new ReplaySubject<string>(1);

  @Input()
  public showExportButtons: boolean;

  @Input()
  public showRequestedAt: boolean;

  @Input()
  public showFatalMessageInClear: boolean;

  @Input()
  public set assetId(value: string | undefined) {
    this.assetId$$.next(value);
  }

  @Input()
  public jobType: JobType;

  public optimization$: Observable<JobStateFragment>;

  constructor(private readonly state: OptimizationStateGQL) {
  }

  ngOnInit(): void {
    this.optimization$ = this.assetId$$.pipe(
      switchMap(( assetId) => {

        return this.state.subscribe({
          assetId,
          assetType: this.assetType,
          jobType: this.jobType
        }).pipe(
          map(result => {
            return result.data.optimizationJobState[0];
          })
        );
      })
    );
  }

  get assetType(): AssetType {
    switch (this.jobType) {
      case JobType.DayAhead:
      case JobType.DryRun:
      case JobType.RevenueHistory:
      case JobType.RevenuePrediction:
      case JobType.IntraDay:
        return AssetType.Park;
      case JobType.HeatLoadForecast:
        return AssetType.HeatStorage;
      case JobType.ProductionForecast:
        return AssetType.TechnicalUnit;
    }
  }

  displayDate(value: Date) {
    if (value) {
      return value.toLocaleString();
    } else {
      return '--';
    }
  }

  handleToggleShowChart() {
    this.showChart$$.next(!this.showChart$$.value);
  }

  showChartAndReportButton() {
    return this.jobType !== JobType.HeatLoadForecast;
  }
}
