import {AfterViewInit, Directive, Input, OnDestroy} from '@angular/core';
import {Permission} from '../user.model';
import {Store} from '@ngrx/store';
import {VkAuthenticationState} from '../authentication.module';
import {selectAnyPermissionPresent, selectPermissionPresent} from '../store/authentication.reducer';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {MatButton} from '@angular/material/button';

@Directive({
    selector: '[vkPermissionRequired]'
})
export class PermissionRequiredDirective implements AfterViewInit, OnDestroy {

    private readonly componentWillBeDestroyed$$ = new Subject();
    private readonly isButtonDisabled$$ = new BehaviorSubject<boolean>(false);

    @Input('vkPermissionRequired') permission!: Permission;

    // tslint:disable-next-line:no-input-rename
    @Input('vkAlternativePermission') alternativePermission: Permission | undefined;

    @Input() set disabled(isDisabled: boolean) {
        this.isButtonDisabled$$.next(isDisabled);
    }

    constructor(
        private readonly store: Store<VkAuthenticationState>,
        private matButton: MatButton
    ) {
    }

    ngAfterViewInit() {

        const permissions = this.alternativePermission ? [this.permission, this.alternativePermission] : [this.permission];

        combineLatest([
            this.isButtonDisabled$$,
            this.store.select(selectAnyPermissionPresent, {permissions})
        ]).pipe(
            takeUntil(this.componentWillBeDestroyed$$),
            map(([isButtonDisabled, permissionGranted]) => {
                return !isButtonDisabled && permissionGranted;
            })
        ).subscribe(isEnabled => {
            this.matButton.disabled = !isEnabled;
        });
    }

    ngOnDestroy() {
        this.componentWillBeDestroyed$$.next();
        this.componentWillBeDestroyed$$.complete();
    }

}
