<ng-container *ngIf="(dataSource.loading$ | async) === false">
  <nrg-chart [dateRange]="dataSource.dateRange$ | async"
             [series]="dataSource.series$ | async"
             [yAxisLeft]="dataSource.yAxisLeft$ | async"
             [yAxisRight]="dataSource.yAxisRight"
  ></nrg-chart>
  <div>
    <p *ngIf="dataSource.displayedTechnicalUnits$ | async as technicalUnits">
      <span class="chart-label" *ngFor="let asset of technicalUnits"><span [style.color]="asset.color">◼</span>{{asset.name}}</span>
    </p>
  </div>
</ng-container>
