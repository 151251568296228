<ng-container *ngIf="node != null">
  <div routerLinkActive="active" matRipple [matRippleTrigger]="link" class="nrg-tree-item mat-tree-node">
    <button *ngIf="icon" mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.title">
      <mat-icon class="mat-icon-rtl-mirror">
        {{icon}}
      </mat-icon>
    </button>
    <button *ngIf="!icon" mat-icon-button disabled></button>
    <div class="nrg-tree-item-label" *ngIf="node.label">{{node.label}}</div>
    <a #link [routerLink]="node.url">
      <div nrgHighlightedTitle [node]="node" [query]="query"></div>
    </a>
  </div>
</ng-container>
