import { FormSchema } from '@nrg/components';
import { addressSchema } from '../address.schema';
import { geographicCoordinatesSchema } from '../geographic-coordinates.schema';

export const technicalUnitGeneralSettingsSchema = (isCustomer: boolean): FormSchema => ({
  type: 'object',
  properties: {
    name: {
      title: 'Name',
      type: 'string',
      readonly: isCustomer
    },
    eegAnlagenschluessel: {
      title: 'EEG-Anlagenschlüssel',
      description: 'Der EEG-Anlagenschlüssel beginnt immer mit dem Buchstaben "E" und wird anschließend mit 32 weiteren Zeichen vervollständigt. In Summe besteht die Nummer aus 33 Zeichen. Beispiel: E3030303BIXX000000030303030303030. Weitere Informationen finden Sie unter anderem auf der Abrechnung Ihres Netzbetreibers.',
      type: 'string',
      warnIfEmpty: 'wird für RD2.0 benötigt.',
      pattern: 'E[A-Z\\d]{32}'
    },
    billingMethod: {
      title: 'Abrechnungsmodell (nur für RD2.0 relevant)',
      enum: ['PAUSCHAL', 'SPITZ', 'SPITZ_LIGHT'],
      readonly: isCustomer
    },
    accountingModel: {
      title: 'Bilanzierungsmodell (nur für RD2.0 relevant)',
      enum: ['PROGNOSEMODELL'],
      readonly: true
    },
    callType: {
      title: 'Abrufmodell (nur für RD2.0 relevant)',
      enum: ['DULDUNGSFALL'],
      readonly: true
    },
    mastrNumber: {
      title: 'Marktstammdatenreg. Nr.',
      type: 'string',
      description: 'Die Marktstammdatenreg. Nr. beginnt immer mit drei Buchstaben und wird anschließend mit zwölf Ziffern vervollständigt. Insgesamt besteht die Nummer aus 15 Stellen. Beispiel: SEE990000000000. Der Wert wurde von der Bundernetzagentur bzw. vom Netzbetreiber mitgeteilt. Weitere Informationen finden Sie unter anderem in den Unterlagen zum Thema Marktstammdatenregister Ihres Netzbetreibers',
      warnIfEmpty: 'wird für RD2.0 benötigt.',
      minLength: 15,
      maxLength: 15
    },
    controllableResourceId: {
      title: 'Steuerbare Ressourcen-ID (nur für RD2.0 relevant)',
      type: 'string',
      description: 'Bei der Steuerbaren Ressourcen.-ID handelt es sich um eine 11-stellige Identifikationsnummer. Sie beginnt immer mit dem Buchstaben "C". Beispiel: C1010101010. Weitere Informationen finden Sie unter anderem in den Unterlagen zum Thema Redispatch Ihres Netzbetreibers.',
      warnIfEmpty: 'wird für RD2.0 benötigt.',
      pattern: 'C[A-Z\\d]{9}\\d'
    },
    technicalResourceId: {
      title: 'Technische Ressourcen-ID (nur für RD2.0 relevant)',
      type: 'string',
      description: 'Bei der Technische Ressourcen-ID handelt es sich um eine 11-stellige Identifikationsnummer. Sie beginnt immer mit dem Buchstaben "D". Beispiel: D2020202020. Weitere Informationen finden Sie unter anderem in den Unterlagen zum Thema Redispatch Ihres Netzbetreibers.',
      warnIfEmpty: 'wird für RD2.0 benötigt.',
      pattern: 'D[A-Z\\d]{9}\\d'
    },
    anzulegenderWert: {
      title: 'Anzulegender Wert (ct/kWh)',
      description: 'Der anzulegende Wert repräsentiert den gesetzlichen Förderanspruch während der Förderperiode. Der Wert wurde von der Bundernetzagentur bzw. vom Netzbetreiber im Rahmen der Einspeisezusage mitgeteilt. Hinweis: Ein anzulegender Wert ist zwingend notwendig wenn es sich nicht um einen Flex-Motor handelt. Handelt es sich um einen Flex-Motor, ist kein anzulegender Wert notwendig.',
      type: 'number',
      precision: 2,
      warnIfEmpty: 'wird für RD2.0 benötigt.',
    },
    energySource: {
      title: 'Erzeugungsart',
      enum: ['Biomasse', 'Photovoltaik'],
      warnIfEmpty: 'wird für RD2.0 benötigt.',
      readonly: isCustomer
    },
    curtailmentOffset: {
      title: 'Offset (Abschaltung DV)',
      type: 'number',
      default: 0,
      readonly: isCustomer,
      hidden: isCustomer,
    },
    flexMotor: {
      title: 'Flex-Motor',
      description: 'Wird eine Flexibilitätsprämie (Flex-Zuschlag) in Anspruch genommen, so handelt es sich um einen Flex-Motor. Andernfalls nicht.',
      type: 'boolean'
    },
    bemerkung: {
      title: 'Bemerkung',
      type: 'string',
      hidden: isCustomer,
      readonly: isCustomer
    },
    adresse: {
      readonly: isCustomer,
      ...addressSchema
    },
    koordinatenWgs84: {
      ...geographicCoordinatesSchema
    }
  },
  title: 'Allgemein',
  required: [
    'name',
    'energySource',
    'flexMotor',
    'accountingModel',
    'callType'
  ],
  order: [
    'name',
    'mastrNumber',
    'eegAnlagenschluessel',
    'anzulegenderWert',
    'flexMotor',
    'energySource',
    'curtailmentOffset',
    'bemerkung',
    'technicalResourceId',
    'controllableResourceId',
    'billingMethod',
    'accountingModel',
    'callType',
    'koordinatenWgs84',
    'adresse',
  ]
} as FormSchema);
