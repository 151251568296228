import { createAction, props } from '@ngrx/store';
import { VkError } from '../../entities/loading-error.entity';
import { EntityErrorStatus } from '../../entities/status';

export const notifyWillBeginLoading = createAction(
  '[Status] Notify Will Begin Loading',
  props<{ ids: string[], correlationId: string, entity: string }>()
);

export const notifyDidEndLoading = createAction(
  '[Status] Notify Did End Loading',
  props<{ ids: string[], correlationId: string, entity: string, error?: VkError }>()
);

export const acknowledgeAllErrors = createAction(
  '[Status] Acknowledge All Errors'
);

export const addError = createAction(
  '[Status] Add Error',
  props<{ error: EntityErrorStatus }>()
);
