import { NgModule } from '@angular/core';
import { ContractLinkMockComponent } from './contract-link.component.mock';

@NgModule({
    declarations: [ContractLinkMockComponent],
    imports: [
    ],
    exports: [
        ContractLinkMockComponent
    ]
  })
  export class ContractLinkMockModule {}
