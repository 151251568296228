<table mat-table [dataSource]="products" class="mat-elevation-z0">

  <ng-container matColumnDef="checkbox">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox [disabled]="element.contractId == null"
                    (change)="$event ? onContractSelected(element.contractId, $event.checked, element.contractAmendmentId) : null"></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Technische Einheit</th>
    <td mat-cell *matCellDef="let element" [ngClass]="{'italic' : isTechnicalUnitDeleted(element)}">
      {{element.technicalUnitName}}
    </td>
  </ng-container>

  <ng-container matColumnDef="malo">
    <th mat-header-cell *matHeaderCellDef> Marktlokation</th>
    <td mat-cell *matCellDef="let element"> {{element.maloName}} </td>
  </ng-container>

  <ng-container matColumnDef="product">
    <th mat-header-cell *matHeaderCellDef> Produktpaket</th>
    <td mat-cell *matCellDef="let element">  {{getProductName(element.productType)}} </td>
  </ng-container>

  <ng-container matColumnDef="marketingStart">
    <th mat-header-cell *matHeaderCellDef> Vermarktungsbeginn</th>
    <td mat-cell *matCellDef="let element">
      <div
          [class.strike-out]="isInStateStorniert(element.state)">{{ (element.commercializationStart | date)}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="marketingEnd">
    <th mat-header-cell *matHeaderCellDef> Vermarktungsende</th>
    <td mat-cell *matCellDef="let element">
      <div [class.strike-out]="isInStateStorniert(element.state)">{{ (element.commercializationEnd | date)}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="sellingOpportunityId">
    <th mat-header-cell *matHeaderCellDef> Verkaufschance</th>
    <td mat-cell *matCellDef="let element">
      <button mat-stroked-button *ngIf="!isNew(element) && !isAmendment(element)"
              (click)="onOpenVerkaufschanceForOffer(element.offerId)">
        Verkaufschance
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef> Status</th>
    <td mat-cell *matCellDef="let element">
      <div
          [class.green]="isInActiveProductSection(element.state)"
          [class.highlight]="isNewOrDefect(element)"
      > {{ getStateName(element) }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="contractPdf">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <div class="contract-action-btns" fxLayout="row">
        <div fxLayout="column" fxLayoutAlign="start center">
          <vk-contract-link *ngIf="!isAmendment(element)" [buttonLabel]="'Aufhebungsvertrag herunterladen'"
                            [customerId]="customerId" type="termination-agreement"
                            [technicalUnitId]="element.technicalUnitId">

          </vk-contract-link>

          <vk-contract-link [buttonLabel]="'Vertrag herunterladen'" *ngIf="element.contractId && !isAmendment(element)"
                            [customerId]="customerId" type="contract"
                            [contractId]="element.contractId">

          </vk-contract-link>

          <vk-contract-link [buttonLabel]="'Nachtrag herunterladen'" *ngIf="isAmendment(element)"
                            [customerId]="customerId" type="contract-amendment"
                            [contractAmendmentId]="element.contractAmendmentId">

          </vk-contract-link>

        </div>
      </div>
    </td>
  </ng-container>


  <ng-container matColumnDef="commercializationType">
    <th mat-header-cell *matHeaderCellDef> Vermarktungsart</th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field *ngIf="!isAmendment(element) && element.possibleCommercializationTypes?.length !== 0">
        <mat-select #marketingMethodInput class="select-marketing-method"
                    (selectionChange)="onChangeMarketingMethod($event, element, marketingMethodInput)"
                    [value]="element.currentCommercializationType"
        >
          <mat-option *ngFor="let marketingMethod of element.possibleCommercializationTypes"
                      [value]="marketingMethod">
            {{nameForCommercializationType(marketingMethod)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="isAmendment(element)">
        Festpreis: {{element.fixedPrice | number: '1.2-2'}}&thinsp;€/MWh
      </div>
      <div *ngIf="element.possibleCommercializationTypes?.length == 0">nicht vermarktet</div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


