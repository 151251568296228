<ng-container *ngIf="optimization$ | async as optimization else noJob" [ngSwitch]="optimization.state" ]>
  <ng-container *ngSwitchDefault>
    <vk-progress-indicator [currentLoadingStep]="optimization.state"
                           [showStepDescriptions]="true"></vk-progress-indicator>
  </ng-container>
  <ng-container *ngSwitchCase="'Inactive'">
    <p *ngIf="showRequestedAt">Letzte Optimierung: {{displayDate(optimization.requestedAt)}}</p>
    <vk-optimization-problem-list
        [jobType]="jobType"
        [assetType]="assetType"
        [assetId]="optimization.assetId"
        [showFatalMessageInClear]="showFatalMessageInClear"
    ></vk-optimization-problem-list>
  </ng-container>
  <div *ngIf="showExportButtons">
    <div>
      <vk-optimization-input-link [parkId]="optimization.parkId" [jobyType]="jobType" [assetId]="optimization.assetId"></vk-optimization-input-link>
      <vk-optimization-output-link [parkId]="optimization.parkId" [jobyType]="jobType" [assetId]="optimization.assetId"></vk-optimization-output-link>
      <vk-optimization-report-link *ngIf="showChartAndReportButton()" [parkId]="optimization.parkId" [jobyType]="jobType"></vk-optimization-report-link>
      <button *ngIf="showChartAndReportButton()" mat-button (click)="handleToggleShowChart()">
        <mat-icon aria-hidden="false">insert_chart</mat-icon>
        {{(showChart$ | async) ? "Chart ausblenden" : "Chart einblenden"}}
      </button>
    </div>
    <vk-optimization-result-chart *ngIf="showChart$ | async"
                                  [parkId]="optimization.parkId"
                                  [jobType]="jobType"
    ></vk-optimization-result-chart>
  </div>
</ng-container>
<ng-template #noJob>
  <p><i>nicht aktiv</i></p>
</ng-template>
