import { NgModule } from '@angular/core';
import { InputLinkComponent } from './input-link.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [InputLinkComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        InputLinkComponent
    ]
})
export class VkOptimizerInputLinkModule { }
