import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AttachEntityToCpeComponent } from './attach-entity-to-cpe.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { VkEntityConfigCardModule } from './entity-config-card/entity-config-card.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    AttachEntityToCpeComponent
  ],
  exports: [
    AttachEntityToCpeComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    VkEntityConfigCardModule,
    MatButtonModule,
    MatProgressBarModule
  ]
})
export class VkAttachEntityToCpeModule {
}
