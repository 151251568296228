import { Injectable } from '@angular/core';
import { LogErrorGQL, LogNavigationEventGQL, LogSessionStartGQL } from './logging.graphql.generated';
import { NavigationEnd, Router } from '@angular/router';
import { filter, first, switchMap, timeout } from 'rxjs/operators';
import { selectAuthenticationFeatureState, User, VkAuthenticationState } from '@vk/authentication';
import { select, Store } from '@ngrx/store';
import { selectAllNotAckownledgedErrors } from '../store/status/status.reducer';
import { VkLoadingHandler } from '../vk-loading-handler.service';
import { ErrorCode } from '../entities/loading-error.entity';
import { BehaviorSubject, ObservableInput } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VkLoggingService {

  private loggedErrorIds = new Set<string>();

  constructor(
    private readonly navigationEvent: LogNavigationEventGQL,
    private readonly error: LogErrorGQL,
    private readonly sessionStart: LogSessionStartGQL,
    private readonly router: Router,
    private readonly store: Store<VkAuthenticationState>,
    private readonly loadingHandler: VkLoadingHandler
  ) {
  }

  private errorLoggingWorks$ = new BehaviorSubject(true);

  init(app: string) {

    this.logMutation(user => {
      return this.sessionStart.mutate({
        app,
        impersonatedUser: user.impersonatedUserId,
        sessionId: user.sessionId,
        user: user.name,
        userAgent: navigator?.userAgent,
        screenWidth: window?.screen?.availWidth,
        screenHeight: window?.screen?.availHeight
      });
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.logMutation(user => {
          return this.navigationEvent.mutate({
            app,
            url: event.url,
            impersonatedUser: user.impersonatedUserId,
            sessionId: user.sessionId,
            user: user.name
          });
        });
      }
    });

    this.store.pipe(
      select(selectAllNotAckownledgedErrors)
    ).subscribe(allErrors => {
      const newErrors = allErrors.filter(error => !this.loggedErrorIds.has(error.errorId));
      for (const error of newErrors) {
        this.logMutation(user => {
          return this.error.mutate({
            app,
            message: JSON.stringify(error),
            impersonatedUser: user.impersonatedUserId,
            sessionId: user.sessionId,
            user: user.name
          });
        });
        this.loggedErrorIds.add(error.errorId);
      }
    });

  }

  private logMutation(fn: (user: User) => ObservableInput<any>) {
    if (this.errorLoggingWorks$.value) {
      this.store.pipe(
        select(selectAuthenticationFeatureState),
        filter(state => !!state.currentUser.name),
        first(),
        timeout(5000),
        this.loadingHandler.untilComplete('FrontendLogging', 'FrontendLogging'),
        switchMap(state => {
          return fn(state.currentUser);
        })
      ).subscribe(
        () => {
        }, err => {
          this.errorLoggingWorks$.next(false);
        });
    }
  }

}
