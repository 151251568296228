import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Permission, selectPermissionPresent } from '@vk/authentication';
import { map, tap } from 'rxjs/operators';
import { ToolbarItem, ToolBarItemType } from '@nrg/components';
import { ParkPreferencesDataSource } from './park-preferences.data-source';

@Component({
  selector: 'vk-park-preferences',
  templateUrl: './park-preferences.component.html',
  styleUrls: ['./park-preferences.component.css']
})
export class ParkPreferencesComponent implements OnInit, OnDestroy {

  private componentWillUnmount$$ = new Subject();

  @Input()
  public set parkId(value: string) {
    this.parkId$.next(value);
  }

  public parkId$ = new ReplaySubject<string>(1);

  public isReadonly$$ = new BehaviorSubject(true);

  constructor(
    private readonly store: Store<unknown>,
    public readonly dataSource: ParkPreferencesDataSource
  ) {
  }

  hasPermission$ = this.store.pipe(select(selectPermissionPresent, { permission: Permission.ASSETS_WRITE }));

  actions$ = combineLatest([
    this.isReadonly$$,
    this.hasPermission$
  ]).pipe(
    map(([isReadonly, hasPermission]) => {
      if (!hasPermission) {
        return [{
          disabled: true,
          icon: 'create',
          itemType: ToolBarItemType.LABEL
        }] as ToolbarItem[];
      }
      if (isReadonly) {
        return [{
          disabled: false,
          icon: 'create',
          itemType: ToolBarItemType.ACTION,
          execute: () => {
            this.isReadonly$$.next(false);
          }
        }];
      } else {
        return [{
          disabled: false,
          icon: 'check',
          itemType: ToolBarItemType.ACTION,
          execute: () => {
            this.dataSource.submit();
            this.isReadonly$$.next(true);
          }
        }, {
          disabled: false,
          icon: 'cancel',
          itemType: ToolBarItemType.ACTION,
          execute: () => {
            this.dataSource.reset();
            this.isReadonly$$.next(true);
          }
        }];
      }
    }),
    tap(console.log)
  );

  ngOnInit(): void {
    this.dataSource.parkId$ = this.parkId$;
    this.dataSource.onInit();
  }

  ngOnDestroy(): void {
    this.componentWillUnmount$$.next(true);
    this.componentWillUnmount$$.complete();
  }

}
