import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NrgSheetContainerComponent } from './sheet-container.component';
import { SheetComponent } from './sheet/sheet.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    NrgSheetContainerComponent,
    SheetComponent
  ],
  exports: [
    NrgSheetContainerComponent,
    SheetComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class NrgSheetModule {
}
