import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReplaySubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export interface AllocationEntity {
  type: string;
  id: string;
  name: string;
}

export interface EntityAllocations {
  [rowEntitiyId: string]: string[];
}

@Component({
  selector: 'nrg-allocation-grid',
  templateUrl: './allocation-grid.component.html',
  styleUrls: ['./allocation-grid.component.css']
})
export class AllocationGridComponent {

  public isLoading = false;

  public readonly rowEntities$$ = new ReplaySubject<AllocationEntity[]>(1);
  public readonly columnEntities$$ = new ReplaySubject<AllocationEntity[]>(1);
  public readonly allocations$$ = new ReplaySubject<EntityAllocations>(1);

  public readonly dataSource$ = combineLatest([
    this.rowEntities$$,
    this.columnEntities$$,
    this.allocations$$
  ]).pipe(
    map(([rowEntities, columnEntities, connections]) => {
      return rowEntities.map((row: any) => {
        const item = row as any;
        columnEntities.forEach((column: any) => {
          const connectedIds = connections[row.id] || [];
          if (connectedIds.indexOf(column.id) !== -1) {
            item[column.id] = true;
          } else {
            item[column.id] = false;
          }
        });
        return row;
      });
    })
  );

  columns = this.columnEntities$$.pipe(
    map((columns) => ['rowName'].concat(columns.map(column => column.id)))
  );

  @Input() set rowEntities(values: AllocationEntity[]) {
    if (values) {
      this.rowEntities$$.next(values);
    }
  }

  @Input() set columnEntities(values: AllocationEntity[]) {
    if (values) {
      this.columnEntities$$.next(values);
    }
  }

  @Input() set allocations(values: EntityAllocations) {
    if (values) {
      this.allocations$$.next(values);
    }
  }

  @Input() singleSelectionPerRow = false;

  @Input() readonly = false;

  @Output() entityAllocationsChanged = new EventEmitter<{ checked: boolean, rowEntity: AllocationEntity, columnEntity: AllocationEntity }>();

  constructor() {
  }

  public handleAllocationChange(event: any, rowEntity: AllocationEntity, columnEntity: AllocationEntity) {

    if (this.readonly) {
      return;
    }

    this.entityAllocationsChanged.next({
      checked: event.checked,
      columnEntity,
      rowEntity
    });
  }

}
