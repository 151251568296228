import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatSidenavContent } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { MenuItem } from '../menu/models/menu-item.model';
import { ToolbarItem } from '../toolbar/models/toolbar-item';

@Component({
  selector: 'nrg-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NrgLayoutComponent implements OnInit, OnDestroy {

  public readonly isMenuOpen = new BehaviorSubject<boolean>(true);
  public readonly isWindowSmall = new BehaviorSubject<boolean>(false);

  public readonly componentWillBeDestroyed = new Subject();

  @Input() public showSidebar = true;

  @Input() public coloredToolbar = false;

  @Input() public showToolbarLogo = false;

  @Input()
  public isLoading = false;

  @Input()
  public version?: string;

  @Input()
  public username?: string;

  @Input()
  public menuItems: MenuItem[] = [];

  @Input()
  public actions: ToolbarItem[] = [];

  @Output()
  public logout = new EventEmitter<void>();

  @ViewChild('content')
  public content?: MatSidenavContent;

  public title: Observable<string>;

  private titleUrl = '';

  constructor(private router: Router) {
    this.title = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => event as NavigationEnd),
      map(event => {
        const menuItem = this.menuItems.find(item => event.url.startsWith(item.url));
        if (menuItem != null) {
          this.titleUrl = menuItem.url;
        }
        return menuItem != null ? menuItem.title : '';
      }),
      startWith(''),
      takeUntil(this.componentWillBeDestroyed)
    );
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.componentWillBeDestroyed)
    ).subscribe(() => {
      if (this.content != null) {
        this.content.scrollTo({
          top: 0
        });
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.componentWillBeDestroyed)
    ).subscribe(() => {
      if (this.content != null) {
        this.content.scrollTo({
          top: 0
        });
      }
    });

    this.positionSidebar();
  }

  ngOnDestroy() {
    this.componentWillBeDestroyed.next();
    this.componentWillBeDestroyed.complete();
  }

  onLogout() {
    this.logout.emit();
  }

  onToggleMenu() {
    this.isMenuOpen.next(!this.isMenuOpen.value);
  }

  onMenuOpenedChange(isOpen: boolean) {
    this.isMenuOpen.next(isOpen);
  }

  shouldShowSidebar(): boolean {
    return this.showSidebar;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.positionSidebar();
  }

  private positionSidebar() {
    if (this.shouldShowSidebar()) {
      const isWindowSmall = window.innerWidth < 1000;
      this.isWindowSmall.next(isWindowSmall);
      this.isMenuOpen.next(!isWindowSmall);
    }
  }

  handleTitleClick() {
    this.router.navigateByUrl(this.titleUrl);
  }
}
