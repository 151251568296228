<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<mat-sidenav-container>
    <mat-sidenav *ngIf="shouldShowSidebar()" class="nrg-layout-sidebar"
                 [position]="(isWindowSmall | async) ? 'end' : 'start'"
                 [mode]="(isWindowSmall | async) ? 'over' : 'side'"
                 [opened]="isMenuOpen | async"
                 (openedChange)="onMenuOpenedChange($event)">
      <nrg-menu [username]="username"
                [menuItems]="menuItems"
                [version]="version"></nrg-menu>
    </mat-sidenav>
  <mat-sidenav-content #content fxLayout="column" class="nrg-layout-content mat-typography">
    <nrg-toolbar [isWindowSmall]="isWindowSmall | async"
                 [title]="title | async"
                 [items]="actions"
                 [showLogo]="showToolbarLogo"
                 [colorToolbar]="coloredToolbar"
                 (toggleMenu)="onToggleMenu()"
                 (logout)="onLogout()"
                 (titleClick)="handleTitleClick()"
                 [showMenuButton]="showSidebar"
                 [showLogoutButton]="true">
      <nrg-breadcrumb></nrg-breadcrumb>
    </nrg-toolbar>
    <main role="main">
      <ng-content></ng-content>
    </main>
    <ng-content select="footer"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
