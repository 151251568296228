import { onError } from '@apollo/client/link/error';

import { VkCommonState } from '../vk-common.state';
import { Store } from '@ngrx/store';
import { VkError, readError } from '../entities/loading-error.entity';
import { notifyDidEndLoading } from '../store/status/status.actions';


let counter = 0;
let graphQlIsReachable = true;

const noConnectionErrorMessages = ['502 Bad Gateway', '503 Service Unavailable', '504 Gateway Timeout'];

export function createErrorLink(store: Store<VkCommonState>) {

  return onError(({ graphQLErrors, networkError }) => {

    if (graphQlIsReachable) {

      const context: { ids: string[], correlationId: string, entity: string, error?: VkError } = {
        ids: [],
        entity: 'GraphQL',
        correlationId: `GraphQL_${counter++}`
      };

      if (graphQLErrors) {
        graphQLErrors.map(({ message, extensions }) => {
          const error = extensions?.messageForUser ?? 'Es ist ein unerwarteter Fehler aufgetreten.';
          store.dispatch(notifyDidEndLoading({ ...context, error: { ...readError, rawError: message, error } }));
        });
      }

      if (networkError) {
        const { message, extensions } = networkError as any;
        const error = extensions?.messageForUser ?? 'Es ist ein unerwarteter Fehler aufgetreten.';
        store.dispatch(notifyDidEndLoading({ ...context, error: { ...readError, rawError: message, error } }));
        if (noConnectionErrorMessages.some(errorMessage => networkError.message.includes(errorMessage))) {
          graphQlIsReachable = false;
        }
      }

    }
  });

}
