import { DataPoint } from './data-point';

export function translatePointToWorldCoordinateSystem([x, y]: DataPoint, fromX: number, toX: number, fromY: number, toY: number, axisWidth: number, axisHeight: number): DataPoint {
  const x0 = x * ((toX - fromX) / axisWidth);

  // invert y-axis
  const yInverted = (y - axisHeight) * -1;
  const y0 = yInverted * ((toY - fromY) / axisHeight);

  return [
    x0 + fromX,
    y0 + fromY
  ];
}

export function calculateStepSize(axisWidthOrHeight: number, from: number, to: number) {
  return axisWidthOrHeight / (to - from);
}

export function translateXToChartCoordinateSystem(fromX: number, stepSizeX: number): (x: number) => number {
  return x => (x - fromX) * stepSizeX;
}

export function translateYToChartCoordinateSystem(y: number, fromY: number, stepSizeY: number, axisHeight: number) {
  return axisHeight - (y - fromY) * stepSizeY;
}
