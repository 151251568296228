<div [class.full-width]="fullWidth">
  <mat-toolbar [style.flex-direction]="isWindowSmall ? 'row-reverse' : 'row'"
               [ngClass]="{'mat-backoffice-toolbar': colorToolbar === true, 'white-background': inMatCard === true}"
  >
    <div class="logo" *ngIf="showLogo">
      <img id="companyLogo" src="./assets/img/nergie_weiss.png" [routerLink]="['/']"/>
    </div>
    <section class="sidebar-toggle">
      <button *ngIf="showMenuButton" mat-icon-button (click)="onToggleMenu()">
        <mat-icon>menu</mat-icon>
      </button>
    </section>
    <h1 *ngIf="title" [class.pointer]="!disableTitleClick" (click)="handleTitleClick()">{{title}}</h1>
    <ng-content></ng-content>
    <span class="spacer"></span>
    <div *ngFor="let item of items">
      <div *ngIf="isLabel(item.itemType)" class="action-label">
        <div>
          <mat-icon>{{item.icon}}</mat-icon>
        </div>
        <div> {{item.text}}</div>
      </div>
      <button *ngIf="!isLabel(item.itemType)" [disabled]="item.disabled" mat-button color="secondary"
              (click)="item.execute()">
        <mat-icon>{{item.icon}}</mat-icon>
        {{item.text}}
      </button>
    </div>

  </mat-toolbar>
</div>
