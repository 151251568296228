export enum ErrorCode {
  CreatingEntityFailed = 'CreatingEntityFailed',
  ReadingEntityFailed = 'ReadingEntityFailed',
  UpdatingEntityFailed = 'UpdatingEntityFailed',
  DeletingEntityFailed = 'DeletingEntityFailed',
  TimeOutError = 'TimeOutError',
  GeneralError = 'GeneralError'
}

export const timeOutError: VkError = {
  error: 'Ein Timeout beim Laden ist aufgetreten.',
  code: ErrorCode.TimeOutError
};

export const updateError: VkError = {
  error: 'Es ist ein Fehler beim Aktualisieren aufgetreten.',
  code: ErrorCode.UpdatingEntityFailed
};

export const createError: VkError = {
  error: 'Es ist ein Fehler beim Anlegen aufgetreten.',
  code: ErrorCode.CreatingEntityFailed
};

export const deleteError: VkError = {
  error: 'Es ist ein Fehler beim Löschen aufgetreten.',
  code: ErrorCode.DeletingEntityFailed
};

export const readError: VkError = {
  error: 'Es ist ein Fehler beim Holen der Daten aufgetreten.',
  code: ErrorCode.ReadingEntityFailed
};

export function isUpdateError(error: any): error is VkError {
  const errorCode = (error as VkError).code;
  return errorCode === ErrorCode.UpdatingEntityFailed;
}

export function isReadingError(error: any): error is VkError {
  const errorCode = (error as VkError).code;
  return errorCode === ErrorCode.ReadingEntityFailed;
}

export function isDeleteError(error: any): error is VkError {
  const errorCode = (error as VkError).code;
  return errorCode === ErrorCode.DeletingEntityFailed;
}

export function isCreateError(error: any): error is VkError {
  const errorCode = (error as VkError).code;
  return errorCode === ErrorCode.CreatingEntityFailed;
}



export function isTimeOutError(error: any): boolean {
  if (typeof (error) !== 'string' && 'name' in error) {
    return error.name === 'TimeoutError';
  }
  return false;
}

export interface VkError {
  readonly code: ErrorCode;
  readonly error?: any;
  readonly rawError?: any;
}

