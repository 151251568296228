import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpersonateLinkComponent } from './impersonate-link.component';



@NgModule({
  declarations: [ImpersonateLinkComponent],
  imports: [
    CommonModule
  ],
  exports: [
    ImpersonateLinkComponent
  ]
})
export class VkImpersonateLinkModule { }
