import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContractState, ProductType } from '../contracts-table/contracts.graphql.generated';

@Component({
  selector: 'vk-contracts-table-header',
  templateUrl: './vk-contracts-table-header.component.html',
  styleUrls: ['./vk-contracts-table-header.component.scss']
})
export class VkContractsTableHeaderComponent {
  @Input() parkName?: string;
  @Input() productType: ProductType = ProductType.None;
  @Input() contractStart?: Date;
  @Input() contractEnd?: Date;
  @Input() canceledFor?: Date;
  @Input() signatureDate?: Date;
  @Input() contractState?: ContractState;
  @Input() contractVersion?: string;
  @Input() hasAmendment?: boolean;

  @Input() showContractConditionsButton = false;
  @Input() showOfferButton = true;
  @Input() disableOfferButton = false;
  @Input() showOfferButtonName = 'Produkt auswählen';
  @Input() shouldHighlightProductSwitchButton = true;

  @Output() showContractConditions = new EventEmitter<boolean>();
  @Output() showOffers = new EventEmitter();

  constructor() {
  }

  onShowContractConditions() {
    this.showContractConditions.emit(true);
  }

  onShowOffers() {
    this.showOffers.emit();
  }

  returnProductString(productType: ProductType): string {
    switch (productType) {
      case ProductType.Dv:
        return 'DV';
      case ProductType.Dvfp:
        return 'DVFP';
      case ProductType.Id:
        return 'DVFPID';
      default:
        return '---';
    }
  }

  shouldHighlightButton(): string {
    return this.shouldHighlightProductSwitchButton ? 'primary' : 'secondary';
  }

  hasCancellationDate(): boolean {
    return this.canceledFor != null;
  }

  isInStateStorniert(): boolean {
    return this.contractState === ContractState.Storniert;
  }
}

