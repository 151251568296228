import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomerImportExportService } from '../customer-import-export.service';

@Component({
  selector: 'vk-customer-export-button[customerId]',
  templateUrl: './customer-export-button.component.html',
  styleUrls: ['./customer-export-button.component.scss']
})
export class CustomerExportButtonComponent implements OnInit {

  @Input() public customerId!: string;

  constructor(private readonly customerImportExportService: CustomerImportExportService) {
  }

  ngOnInit(): void {
  }

  async handleClick() {

    const result = await this.customerImportExportService.exportCustomer(this.customerId);

    const json = JSON.stringify(result);

    const blob = new Blob([json], { type: 'text/plain' });

    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);

    link.download = this.customerId + '.json';

    link.click();
  }
}
