import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { FormSchema } from '@nrg/components';
import { MonoEntityFormDataSource } from '../entity-form-data-source';
import { technicalUnitGeneralSettingsSchema } from '../../schema/technical-unit/technical-unit-general-settings.schema';
import { Permission } from '@vk/authentication';
import {
  Address,
  BillingMethodEnum,
  EnergySourceEnum,
  GeneralSettingsGQL,
  GeneralSettingsSubscription,
  GeoCoordinates,
  SaveGeneralSettingsGQL
} from './technicalUnit.graphql.generated';
import { IS_PORTAL } from '../../entity-form.injection-tokens';
import { Inject, Injectable } from '@angular/core';
import { VkLoadingHandler } from '@vk/common';

export interface TechnicalUnitGeneralSettings {
  technicalUnitId: string;
  name: string;
  eegAnlagenschluessel?: string | null;
  bemerkung?: string | null;
  anzulegenderWert?: number | null;
  adresse?: Address | null;
  koordinatenWgs84?: GeoCoordinates | null;
  curtailmentOffset: number;
  energySource: 'Biomasse' | 'Photovoltaik';
  flexMotor: boolean;
  billingMethod: BillingMethodEnum;
  accountingModel: 'PROGNOSEMODELL';
  callType: 'DULDUNGSFALL';
  controllableResourceId?: string | null;
  technicalResourceId?: string | null;
  mastrNumber?: string | null;
}

export type gqlType = GeneralSettingsSubscription['technicalUnitById']['generalSettings'];

const correlationId = 'TechnicalUnitGeneralSettingsFormDataSource';

@Injectable({
  providedIn: 'any'
})
export class TechnicalUnitGeneralSettingsFormDataSource extends MonoEntityFormDataSource<TechnicalUnitGeneralSettings> {

  readonly state = new ReplaySubject<TechnicalUnitGeneralSettings>(1);

  constructor(
    @Inject(IS_PORTAL) private isCustomer: boolean,
    private load: GeneralSettingsGQL,
    private save: SaveGeneralSettingsGQL,
    private readonly loadingHandler: VkLoadingHandler
  ) {
    super(correlationId);
  }

  readonly writePermission = Permission.ASSETS_WRITE;
  readonly alternativeWritePermission = Permission.ASSETS_WRITE_CUSTOMER;
  readonly entityType = 'TechnicalUnitGeneralSettings' as any;
  readonly schema: FormSchema = technicalUnitGeneralSettingsSchema(this.isCustomer);

  selectEntityById(id: string, store: Store<unknown>): Observable<TechnicalUnitGeneralSettings> {
    return this.state.asObservable();
  }

  loadEntity(id: string, store: Store<unknown>): void {
    this.state.next({} as any);
    this.load.subscribe({ technicalUnitId: id }).pipe(
      first(),
      this.loadingHandler.untilFirst('GeneralSettingsGQL', this.correlationId)
    ).subscribe(result => {
      this.state.next(this.mapToGeneralSettings(result.data!.technicalUnitById.generalSettings));
    });
  }

  saveEntity(generalSettings: TechnicalUnitGeneralSettings, store: Store<unknown>, customerId: string): void {
    const id = generalSettings.technicalUnitId;

    const data = this.setGeneralSettingsOnTechnicalUnit(generalSettings);

    this.save.mutate({
      technicalUnitId: id,
      data
    }).pipe(
      this.loadingHandler.untilFirst('SaveGeneralSettingsGQL', this.correlationId)
    ).subscribe();
  }

  createEntity(generalSettings: TechnicalUnitGeneralSettings, customerId: string, store: Store<unknown>): void {
    // not implemented
    throw new Error('Not Implemented: createEntity');
  }

  deleteEntity(id: string, store: Store<unknown>, customerId: string): void {
    // not implemented
    throw new Error('Not Implemented: deleteEntity');
  }

  makeTemplate(parentId: string): TechnicalUnitGeneralSettings | any {
    return {
      flexMotor: true
    } as any;
  }


  private mapToGeneralSettings(technicalUnit: gqlType): TechnicalUnitGeneralSettings {
    return {
      technicalUnitId: technicalUnit.technicalUnitId,
      name: technicalUnit.name,
      eegAnlagenschluessel: technicalUnit.eegAnlagenschluessel,
      anzulegenderWert: technicalUnit.anzulegenderWert,
      bemerkung: technicalUnit.bemerkung,
      adresse: technicalUnit.adresse,
      koordinatenWgs84: technicalUnit.koordinatenWgs84,
      energySource: translateEnergySource(technicalUnit.energySource),
      curtailmentOffset: technicalUnit.curtailmentOffset,
      flexMotor: technicalUnit.flexMotor,
      billingMethod: technicalUnit.billingMethod,
      controllableResourceId: technicalUnit.controllableResourceId,
      technicalResourceId: technicalUnit.technicalResourceId,
      mastrNumber: technicalUnit.mastrNumber,
      accountingModel: 'PROGNOSEMODELL',
      callType: 'DULDUNGSFALL'
    };
  }

  private setGeneralSettingsOnTechnicalUnit(technicalUnitGeneralSettings: TechnicalUnitGeneralSettings): gqlType {
    return {
      name: technicalUnitGeneralSettings.name,
      eegAnlagenschluessel: technicalUnitGeneralSettings.eegAnlagenschluessel,
      anzulegenderWert: technicalUnitGeneralSettings.anzulegenderWert,
      bemerkung: technicalUnitGeneralSettings.bemerkung,
      adresse: technicalUnitGeneralSettings.adresse,
      koordinatenWgs84: technicalUnitGeneralSettings.koordinatenWgs84,
      curtailmentOffset: technicalUnitGeneralSettings.curtailmentOffset ?? 0,
      energySource: translateEnergySourceBack(technicalUnitGeneralSettings.energySource),
      flexMotor: technicalUnitGeneralSettings.flexMotor,
      technicalUnitId: technicalUnitGeneralSettings.technicalUnitId,
      billingMethod: technicalUnitGeneralSettings.billingMethod,
      controllableResourceId: technicalUnitGeneralSettings.controllableResourceId,
      technicalResourceId: technicalUnitGeneralSettings.technicalResourceId,
      mastrNumber: technicalUnitGeneralSettings.mastrNumber
    };
  }


}

export function translateEnergySource(source: EnergySourceEnum): 'Biomasse' | 'Photovoltaik' {
  switch (source) {
    case EnergySourceEnum.Biomass:
      return 'Biomasse';
    case EnergySourceEnum.Solar:
      return 'Photovoltaik';
  }
}

export function translateEnergySourceBack(source: 'Biomasse' | 'Photovoltaik'): EnergySourceEnum {
  switch (source) {
    case 'Biomasse':
      return EnergySourceEnum.Biomass;
    case 'Photovoltaik':
      return EnergySourceEnum.Solar;
  }
}
