<ng-container [ngSwitch]="state$$ | async">
  <ng-container *ngSwitchCase="'Input'">
    <div class="form-top">
      Vorlage laden: <input type="file" (change)="handleNewFile($event)">
      <div class="warning" *ngIf="dataSource.customerIdAlreadyInUse$ | async">
        <mat-icon>warning</mat-icon> Kunden-Id wird bereits verwendet!
      </div>
      <div class="warning" *ngIf="dataSource.anyTeNeedIsInvalid$$ | async">
        <mat-icon>warning</mat-icon> Die Angaben für mindestens eine der technischen Einheiten sind inkonsitent. Bitte prüfen sie inbesondere den anzulegenden Wert und das Feld Flex-Motor.
      </div>
    </div>
    <nrg-schema-form
        [dataSource]="dataSource"
        (valueSubmit)="handleSubmit($event)"
    ></nrg-schema-form>
  </ng-container>
  <ng-container *ngSwitchCase="'Loading'">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <p>Kundendaten werden angelegt.</p>
  </ng-container>
  <ng-container *ngSwitchCase="'Done'">
    <p>Der Kunde {{lastCreatedCustomerId$$ | async}} wurde erfolgreich angelegt.</p>
    <button mat-raised-button (click)="handleCreateNextCustomer()">Weiteren Kunden anlegen</button>
  </ng-container>
</ng-container>
