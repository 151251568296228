import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppDialogConfig } from './config/dialog-config';

@Component({
  selector: 'nrg-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent implements OnInit, OnDestroy {
  private readonly componentWillBeDestroyed = new Subject();

  public get hasActions(): boolean {
    return this.config.cancelButtonLabel != null || this.config.acceptButtonLabel != null;
  }

  public get hasMessage(): boolean {
    return this.config.message != null;
  }

  public get titleDisplay() {
    if (this.config.title) {
      return this.config.title;
    } else {
      switch (this.config.type) {
        case 'fail': return 'Fehler';
        case 'warning': return 'Warnung';
        default: return null;
      }
    }
  }

  public get hasTitle(): boolean {
    return this.titleDisplay != null;
  }

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
              @Inject(MAT_DIALOG_DATA) public config: AppDialogConfig) {
  }

  ngOnInit() {
    if (this.config.timer) {
      timer(this.config.timer).pipe(
        takeUntil(this.componentWillBeDestroyed),
      ).subscribe(
        () => this.closeDialog()
      );
    }
  }

  closeDialog(dialogResult?: any): void {
    if (this.config.onClose) {
      this.config.onClose();
    }
    this.dialogRef.close(dialogResult);
  }

  onNoClick(): void {
    this.closeDialog();
  }

  onAcceptClick() {
    this.closeDialog(true);
  }

  onCancelClick() {
    this.closeDialog(false);
  }

  ngOnDestroy() {
    this.componentWillBeDestroyed.next();
    this.componentWillBeDestroyed.complete();
  }
}

export function showMessage(dialog: MatDialog, config: AppDialogConfig) {
  return dialog.open(DialogComponent, {
    data: config,
  });
}
