import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { pageNotFoundImg } from '../../resources/img/page-not-found';

@Component({
  selector: 'nrg-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNotFoundComponent  {

  public imgageEncoded = pageNotFoundImg;

  constructor(private router: Router) { }

  backToRoot() {
    this.router.navigateByUrl('');
  }
}
