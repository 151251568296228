import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { DetachEvent, UpdateEvent } from './entity-config-card/entity-config-card.component';
import { Permission, selectPermissionPresent, VkAuthenticationState } from '@vk/authentication';
import { select, Store } from '@ngrx/store';
import { AttachEntityToCpeDataSource } from './attach-entity-to-cpe-data-source';
import { AllDataPointOptionsGQL } from './attach-entity-to-cpe-data-source.graphql.generated';

interface AddFormValues {
  entityId: string;
  unitType: number;
  unitNumber: number;
  dataPoint: string;
}

@Component({
  selector: 'vk-attach-entity-to-cpe[dataSource]',
  templateUrl: './attach-entity-to-cpe.component.html',
  styleUrls: ['./attach-entity-to-cpe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachEntityToCpeComponent implements OnInit, OnDestroy {

  constructor(private readonly store: Store<VkAuthenticationState>,
              readonly allDataPointOptionsGQL: AllDataPointOptionsGQL) {
  }

  private readonly componentWillBeDestroyed = new Subject();
  public teDataPointOptions: Array<string> = [];
  public gsDataPointOptions: Array<string> = [];
  public wsDataPointOptions: Array<string> = [];

  public readonly form: FormGroup = new FormGroup({
    entityId: new FormControl(),
    unitType: new FormControl(),
    unitNumber: new FormControl(),
    dataPoint: new FormControl()
  });

  public readonly isReadonly$ = this.store.pipe(
    select(selectPermissionPresent, { permission: Permission.ASSETS_WRITE }),
    map(it => !it)
  );

  public readonly fieldErrorMessage = 'Der angegebene Wert ist ungültig.';

  public get isEntityIdInputInvalid(): boolean {
    const control = this.form.get('entityId')!;
    return control.invalid && (control.dirty || control.touched);
  }

  public get isUnitTypeInputInvalid(): boolean {
    const control = this.form.get('unitType')!;
    return control.invalid && (control.dirty || control.touched);
  }

  public get isUnitNumberInputInvalid(): boolean {
    const control = this.form.get('unitNumber')!;
    return control.invalid && (control.dirty || control.touched);
  }

  public get isDataPointInputInvalid(): boolean {
    const control = this.form.get('dataPoint')!;
    return control.invalid && (control.dirty || control.touched);
  }

  @Input()
  public dataSource!: AttachEntityToCpeDataSource;

  @Input()
  public title?: string;

  @Input()
  public entityType?: string;

  @Input()
  public tag?: string;

  public readonly isFormDisabled$ = new ReplaySubject<boolean>(1);

  ngOnDestroy() {
    this.componentWillBeDestroyed.next();
    this.componentWillBeDestroyed.complete();
  }

  allDataPointOptionsFor(type: string | undefined): Array<string> {
    switch (type) {
      case 'TE': {
        return this.teDataPointOptions;
      }
      case 'WS': {
        return this.wsDataPointOptions;
      }
      case 'GS': {
        return this.gsDataPointOptions;
      }
      default: {
        return [];
      }
    }
  }

  onEntityAttach(submitEvent: Event) {
    submitEvent.preventDefault();
    submitEvent.stopPropagation();

    const { entityId, unitType, unitNumber, dataPoint }: AddFormValues = this.form.value;

    this.dataSource.attachEntity(entityId, unitType, unitNumber, dataPoint);

    this.resetForm(submitEvent);
  }

  private resetForm(submitEvent: Event) {
    (submitEvent.currentTarget as any)?.reset();
    this.form.reset();
  }

  onEntityDetach(event: DetachEvent) {
    this.dataSource.detachEntity(event.entityId);
  }

  onEntityUpdate(event: UpdateEvent) {
    this.dataSource.reattachEntity(event.entityId, event.unitType, event.unitNumber, event.dataPoint);
  }

  ngOnInit(): void {
    this.allDataPointOptionsGQL.subscribe().pipe(
      filter(response => response.data != null),
      map(response => response.data!!.allDataPointOptions),
      takeUntil(this.componentWillBeDestroyed)
    ).subscribe(options => {
      this.teDataPointOptions = options.technicalUnit;
      this.wsDataPointOptions = options.heatStorage;
      this.gsDataPointOptions = options.gasStorage;
    });

    combineLatest([
      this.dataSource.attachableEntities$.pipe(
        map(entities => entities.length === 0)
      ),
      this.isReadonly$
    ]).pipe(
      map(([formDisabled, readonly]) => formDisabled || readonly)
    ).subscribe(this.isFormDisabled$);
  }
}
