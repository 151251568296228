import { MonoEntityFormDataSource } from './entity-form-data-source';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { gasStorageSchema } from '../schema/gas-storage-form.schema';
import { FormSchema } from '@nrg/components';
import { FormGroup } from '@angular/forms';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Permission } from '@vk/authentication';
import { Injectable } from '@angular/core';
import {
  CalculateEnergyContentGQL,
  CreateGasStorageGQL,
  GasStorageByIdGQL, GasStorageCreateInput,
  GasStorageInput,
  UpdateGasStorageGQL
} from './gas-storage.graphql.generated';
import { VkLoadingHandler } from '@vk/common';

const correlationId = 'GasStorageFormDataSource';

@Injectable({
  providedIn: 'any'
})
export class GasStorageFormDataSource extends MonoEntityFormDataSource<GasStorageInput> {
  readonly writePermission = Permission.ASSETS_WRITE_CUSTOMER;
  readonly alternativeWritePermission = Permission.ASSETS_WRITE;
  readonly entityType = 'GasStorage' as any;
  readonly schema: FormSchema = gasStorageSchema;

  readonly state = new ReplaySubject<GasStorageInput>(1);

  constructor(
    private readonly calculateEnergyContent: CalculateEnergyContentGQL,
    private readonly load: GasStorageByIdGQL,
    private readonly update: UpdateGasStorageGQL,
    private readonly create: CreateGasStorageGQL,
    private readonly loadingHandler: VkLoadingHandler
  ) {
    super(correlationId);
  }

  onInit() {
  }

  setUpDerivedFields(formGroup: FormGroup) {
    this.setUpCalculationOfEnergyConcentration(formGroup);
  }

  loadEntity(id: string, store: Store<unknown>): void {
    this.state.next({} as any);
    this.load.subscribe({ gasStorageId: id }).pipe(
      first(),
      this.loadingHandler.untilFirst('ParkGQL', this.correlationId),
      map(result => result.data!.gasStorageById)
    ).subscribe(result => this.state.next(result));
  }

  selectEntityById(id: string, store: Store<unknown>): Observable<GasStorageInput | undefined> {
    return this.state.asObservable();
  }

  saveEntity(entity: GasStorageInput, store: Store<unknown>): void {
    this.update.mutate({ data: entity }).subscribe();
  }

  createEntity(entity: GasStorageInput, customerId: string, store: Store<unknown>): void {
    const createInput: GasStorageCreateInput = {
      customerId,
      energyConcentration: entity.energyConcentration,
      capacity: entity.capacity,
      methanConcentration: entity.methanConcentration,
      maximalFillingLevel: entity.maximalFillingLevel,
      name: entity.name,
      minimalFillingLevel: entity.minimalFillingLevel,
      producedBiogas: entity.producedBiogas,
    };

    this.create.mutate({ data: createInput }).subscribe();
  }

  deleteEntity(id: string, store: Store<unknown>): void {
  }

  setUpCalculationOfEnergyConcentration(formGroup: FormGroup) {
    formGroup.get('methanConcentration')!.valueChanges.pipe(
      switchMap(methanConcentration => {
        return this.calculateEnergyContent.subscribe({ methanConcentration });
      }),
      map(data => data?.data?.calculateEnergyConcentration?.result),
      takeUntil(this.dataSourceWillBeDestroyed)
    ).subscribe(
      energyConcentration => {
        formGroup.get('energyConcentration')!.setValue(energyConcentration);
      }
    );
  }
}

