export function createMock(type: any, base: any = {}) {
  for (const prop of Object.getOwnPropertyNames(type.prototype)) {
    if (!(prop in base)) {
      base[prop] = (...args: any[]) => {
        throw new Error('Not Implemented: ' + prop);
      };
    }
  }
  return base;
}
