import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { buildAssetTree, MasterDataUrlMappingStrategy } from './models/master-data.converter';
import { Result } from '@nrg/components';
import { AssetTreeForOpsGQL, AssetTreeForPortalGQL, CustomerTree, Pool } from './master-data.graphql.generated';
import { IS_PORTAL } from '../entity-form/entity-form.injection-tokens';
import { selectIsLoadingByContext, VkCommonState, VkLoadingHandler } from '@vk/common';
import { select, Store } from '@ngrx/store';


const correlationId = 'VkMasterDataSheet';

@Component({
  selector: 'vk-master-data-sheet',
  templateUrl: './master-data-sheet.component.html',
  styleUrls: ['./master-data-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterDataSheetComponent {
  private _customer = new ReplaySubject<string>(1);
  private readonly _urlMappingStrategy = new ReplaySubject<MasterDataUrlMappingStrategy>(1);
  public readonly isLoading$ = this.store.pipe(
    select(selectIsLoadingByContext, { correlationId })
  );
  public query = new BehaviorSubject<string>('');
  private poolId$$ = new BehaviorSubject<Pool | undefined >(undefined);

  public results$: Observable<Result[]> =
    combineLatest([
        this.query.pipe(
          debounceTime(500),
          startWith('')),
        this._customer,
        this.poolId$$
      ]
    ).pipe(
      switchMap(([query, party, pool]) => this.isPortal ? this.assetTreeForPortal$(party, query) : this.assetTreeForOps$(party, query, pool)),
      map(([assetTree, mappingStrategy]) => buildAssetTree(assetTree.data!.assetTree as Array<CustomerTree>, mappingStrategy, this.isPortal),
        this.loadingHandler.untilFirst('AssetTreeGQL', correlationId)
      )
);


  @Input() set urlMappingStrategy(strategy: MasterDataUrlMappingStrategy) {
    this._urlMappingStrategy.next(strategy);
  }

  @Input() set customerId(customerId: string) {
    this._customer.next(customerId);
  }

  @Input() withPoolFilter = false;

  constructor(
    private readonly assetTreeForPortal: AssetTreeForPortalGQL,
    private readonly assetTreeForOps: AssetTreeForOpsGQL,
    private readonly loadingHandler: VkLoadingHandler,
    private readonly store: Store<VkCommonState>,
    @Inject(IS_PORTAL) private isPortal: boolean
  ) {
  }

  assetTreeForPortal$(customerId: string, query: string) {
    return combineLatest([
      this.assetTreeForPortal.subscribe({ customerId, query }),
      this._urlMappingStrategy
    ]);
  }

  assetTreeForOps$(tenantId: string, query: string, pool?: Pool) {
    return combineLatest([
      this.assetTreeForOps.subscribe({ tenantId, query, pool }),
      this._urlMappingStrategy
    ]);
  }

  onQueryChange(query: string) {
    this.query.next(query);
  }

  onPoolSelected(pool: Pool) {
    this.poolId$$.next(pool);
  }
}
