import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FilteredResult } from '../../search-sheet/models/result.model';

@Component({
  selector: 'nrg-simple-tree-item',
  templateUrl: './simple-tree-item.component.html',
  styleUrls: ['./simple-tree-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTreeItemComponent {

  @Input() icon?: string;

  @Input() node?: FilteredResult;

  @Input() query?: string;

}
