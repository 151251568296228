import { FormSchema } from '@nrg/components';

export const createTechnicalUnitSchema: FormSchema = {
  title: 'Technische Einheit anlegen',
  type: 'object',
  properties: {
    obereNennleistung: {
      title: 'Obere Nennleistung elektrisch (kW)',
      description: 'Auf welche maximale Leistung ist die Maschine ausgelegt?',
      type: 'number',
      minimum: 0,
    },
    minLeistung: {
      title: 'Minimale Fahrplanleistung (kW)',
      description: 'Welche Leistung soll die Maschine minimal fahren?',
      type: 'number',
      minimum: 1
    },
    anzulegenderWert: {
      title: 'Anzulegender Wert (ct/kWh)',
      description: 'Die Pflege des Feldes ist abhängig von Feld Flex-Motor.',
      type: 'number'
    },
    flexMotor: {
      title: 'Flexmotor',
      type: 'boolean'
    }
  },
  required: [
    'name',
    'obereNennleistung',
    'minLeistung',
  ]
};
