import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, pairwise, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { ChartDimensions, Dimensions, getChartDimensions, isSizeEqual, Size } from './models/dimensions';
import { Axis, AxisConfig, AxisRange, AxisSide, generateAxisLabelsFromConfig, generateXAxis, generateYAxis } from './models/axis';
import { createTimeline, minuteInMs } from './models/timeline';
import { createResizeObserver } from '../utils/resize-observer';
import { ChartSeriesTypes } from './series/chart-series-types';
import { Block } from './series/block/block';
import { BlockChartSeries } from './series/block/block-chart-series';
import { LineChartSeries } from './series/line/line-chart-series';
import { DataPoint } from './models/data-point';
import { ZoomHandler } from './zoom';

const minimumZoomDistance = minuteInMs;

@Component({
  selector: 'nrg-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  private readonly componentWillBeDestroyed$$ = new Subject<void>();
  private readonly _onSizeUpdate$$ = new BehaviorSubject<Size>({ width: 0, height: 0 });

  private dragAndDropSeries?: BlockChartSeries;
  private zoomHandler?: ZoomHandler;

  private readonly dateRange$$ = new ReplaySubject<AxisRange>(1);
  private readonly yAxisRightAxisConfig$$ = new ReplaySubject<AxisConfig>(1);
  private readonly yAxisRightLabels$ = this.yAxisRightAxisConfig$$.pipe(
    map(axisConfig => generateAxisLabelsFromConfig(axisConfig)),
  );
  private readonly yAxisLeftAxisConfig$$ = new ReplaySubject<AxisConfig>(1);
  private readonly yAxisLeftLabels$ = this.yAxisLeftAxisConfig$$.pipe(
    map(axisConfig => generateAxisLabelsFromConfig(axisConfig)),
  );

  private readonly _chartSeries$$ = new ReplaySubject<ChartSeriesTypes[]>(1);
  public readonly chartSeries$: Observable<ChartSeriesTypes[]> = this._chartSeries$$.pipe(
    startWith([]),
    pairwise(),
    map(([previousSeries, currentSeries]) => {
      // clean up previous displayed series
      previousSeries.forEach(series => series.onDestroy());

      // setup new series
      currentSeries.forEach(series => series.onInit({
        detectChanges: () => {
          this.changeDetectorRef.detectChanges();
        }
      }));

      return currentSeries;
    }),
    shareReplay(1),
  );

  private readonly chartElementSize$ = this._onSizeUpdate$$.pipe(
    debounceTime(100),
    distinctUntilChanged((x, y) => isSizeEqual(x, y)),
    shareReplay(1),
  );

  private readonly dimensions$ = this.chartElementSize$.pipe(
    map(svgSize => getChartDimensions(svgSize.width, svgSize.height)),
    shareReplay(1),
  );

  @ViewChild('chart') chartElement?: ElementRef;

  private readonly zoomAreaDimensions$ = new ReplaySubject<Dimensions>(1);
  public readonly zoomAreaX$ = this.zoomAreaDimensions$.pipe(map(zoom => zoom.x));
  public readonly zoomAreaY$ = this.zoomAreaDimensions$.pipe(map(zoom => zoom.y));
  public readonly zoomAreaWidth$ = this.zoomAreaDimensions$.pipe(map(zoom => zoom.width));
  public readonly zoomAreaHeight$ = this.zoomAreaDimensions$.pipe(map(zoom => zoom.height));

  public readonly xAxisDimensions$: Observable<Dimensions> = this.dimensions$.pipe(
    map(dimensions => dimensions.xAxis),
  );

  public readonly xAxisWidth$: Observable<number> = this.xAxisDimensions$.pipe(
    map(axis => axis.width),
  );

  public xAxisTicks$: Observable<Axis> = combineLatest([this.xAxisWidth$, this.dateRange$$]).pipe(
    map(([axisWidth, { from, to }]) => {
      if (axisWidth <= 0) {
        return [];
      }

      const labels = [...createTimeline(axisWidth, from, to)];

      return generateXAxis(labels, axisWidth);
    })
  );

  public get isZoomAreaVisible(): boolean {
    return this.zoomHandler != null;
  }

  @Input()
  public set yAxisLeft(config: AxisConfig) {
    if (config == null) {
      return;
    }

    this.yAxisLeftAxisConfig$$.next(config);
  }

  public readonly yAxisLeftDimensions$: Observable<Dimensions> = this.dimensions$.pipe(
    map(dimensions => dimensions.yAxisLeft),
  );

  public readonly yAxisLeftWidth$: Observable<number> = this.yAxisLeftDimensions$.pipe(
    map(axis => axis.width),
  );

  public readonly yAxisLeftHeight$: Observable<number> = this.yAxisLeftDimensions$.pipe(
    map(axis => axis.height),
  );

  public readonly yAxisLeftTicks$: Observable<Axis> = combineLatest([
    this.yAxisLeftDimensions$,
    this.yAxisLeftLabels$
  ]).pipe(
    map(([axis, labels]) => {
      return generateYAxis(labels, axis.width, axis.height, AxisSide.Left);
    })
  );

  @Input()
  public set yAxisRight(config: AxisConfig) {
    if (config == null) {
      return;
    }

    this.yAxisRightAxisConfig$$.next(config);
  }

  public readonly yAxisRightDimensions$: Observable<Dimensions> = this.dimensions$.pipe(
    map(dimensions => dimensions.yAxisRight),
  );

  public readonly yAxisRightHeight$: Observable<number> = this.yAxisRightDimensions$.pipe(
    map(axis => axis.height),
  );

  public readonly yAxisRightTicks$: Observable<Axis> = combineLatest([
    this.yAxisRightDimensions$,
    this.yAxisRightLabels$,
  ]).pipe(
    map(([axis, labels]) => {
      return generateYAxis(labels, axis.width, axis.height, AxisSide.Right);
    })
  );

  public readonly seriesDimensions$: Observable<Dimensions> = this.dimensions$.pipe(
    map(dimensions => dimensions.series),
  );

  @Input()
  public set series(series: ChartSeriesTypes[]) {
    if (series == null) {
      return;
    }

    this._chartSeries$$.next(series);
  }

  @Input()
  public set dateRange(range: AxisRange) {
    if (range == null) {
      return;
    }
    this.dateRange$$.next(range);
  }

  @Output()
  public readonly pointSelect = new EventEmitter<{ seriesId: string, point: DataPoint }>();

  @Output()
  public readonly blockUpdate = new EventEmitter<{ seriesId: string, block: Block }>();

  @Output()
  public readonly blockSelect = new EventEmitter<{ seriesId: string, block: Block }>();

  @Output()
  public readonly zoom = new EventEmitter<{ from: number, to: number }>();

  constructor(private hostElement: ElementRef,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    createResizeObserver(this.hostElement.nativeElement)
      .pipe(takeUntil(this.componentWillBeDestroyed$$))
      .subscribe(() => {
        this.notifySvgSizeUpdate();
      });
  }

  ngAfterViewChecked(): void {
    this.notifySvgSizeUpdate();
  }

  ngOnInit(): void {
    combineLatest([
      this.chartSeries$,
      this.dimensions$,
      this.dateRange$$,
      this.yAxisLeftAxisConfig$$,
      this.yAxisRightAxisConfig$$,
    ]).pipe(
      debounceTime(100),
      takeUntil(this.componentWillBeDestroyed$$),
    ).subscribe(([chartSeries, dimensions, dateRange, yAxisLeft, yAxisRight]) => {
      chartSeries.forEach(series => {
        updateSeries(series, dimensions, dateRange, yAxisRight, yAxisLeft);
      });
    });
  }

  ngOnDestroy() {
    this.componentWillBeDestroyed$$.next();
    this.componentWillBeDestroyed$$.complete();

    this._chartSeries$$.pipe(
      first()
    ).subscribe(value => {
      value.forEach(series => series.onDestroy());
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.notifySvgSizeUpdate();
  }

  private notifySvgSizeUpdate() {
    if (this.chartElement == null) {
      return;
    }

    const previousSize = this._onSizeUpdate$$.value;
    const size = getElementSize(this.chartElement.nativeElement);

    if (!isSizeEqual(previousSize, size)) {
      this._onSizeUpdate$$.next(size);
    }
  }

  async onPointSelected(eventTarget: EventTarget, series: LineChartSeries, x: number, y: number) {
    series.onPointSelected(eventTarget);
    this.pointSelect.emit({ seriesId: series.id, point: [x, y] });
  }

  async onDragStart(event: MouseEvent, series: BlockChartSeries, selectionIndex: number): Promise<void> {
    if (!series.isDataSelected(selectionIndex)) {
      return;
    }

    this.dragAndDropSeries = series;
    const fromPos = event.clientX;

    const [dimensions, dateRange] = await combineLatest([this.dimensions$, this.dateRange$$])
      .pipe(first())
      .toPromise();

    series.onDragStart(selectionIndex, fromPos, dimensions.series.width, dateRange.from, dateRange.to);
  }

  @HostListener('window:mousemove', ['$event'])
  onDrag(event: MouseEvent): void {
    if (this.dragAndDropSeries == null) {
      return;
    }

    this.dragAndDropSeries.onDrag(event.clientX);
  }

  onDragEnd(event: MouseEvent): void {
    if (this.dragAndDropSeries == null) {
      return;
    }

    const series = this.dragAndDropSeries;
    const block = series.onDrag(event.clientX);

    if (block != null) {
      this.blockUpdate.emit({ seriesId: series.id, block });
    }

    this.cleanUpDragHandler();
  }

  @HostListener('mouseleave')
  onDragCancelled(): void {
    this.cleanUpDragHandler();
  }

  private cleanUpDragHandler(): void {
    if (this.dragAndDropSeries != null) {
      this.dragAndDropSeries.onDragEnd();
    }
    this.dragAndDropSeries = undefined;
  }

  async onZoomBegin(mouseEvent: MouseEvent): Promise<void> {
    // do not allow zooming if user is dragging a block
    if (this.dragAndDropSeries != null || this.chartElement == null) {
      return;
    }

    const [{ series }, dateRange] = await combineLatest([this.dimensions$, this.dateRange$$])
      .pipe(first())
      .toPromise();

    const elementX = getBoundingClientRect(this.chartElement.nativeElement).left;

    this.zoomHandler = new ZoomHandler(elementX, mouseEvent.pageX, series.x, series.width, series.height, dateRange, minimumZoomDistance);
    this.zoomAreaDimensions$.next({ x: 0, y: 0, width: 0, height: 0 });
  }

  onZoomRangeChange(mouseEvent: MouseEvent) {
    if (this.zoomHandler == null) {
      return;
    }

    const { dimensions, isZoomAllowed } = this.zoomHandler.update(mouseEvent.pageX);

    if (isZoomAllowed) {
      this.zoomAreaDimensions$.next(dimensions);
    }
  }

  onZoomEnd(mouseEvent: MouseEvent) {
    if (this.zoomHandler == null) {
      return;
    }

    const { range, isZoomAllowed  } = this.zoomHandler.end(mouseEvent.pageX);
    this.zoomHandler = undefined;

    // avoid zooming if range is less than the allowed minimum
    if (isZoomAllowed) {
      this.zoom.emit(range);
    }
  }

  async onBlockMouseDown(event: MouseEvent, series: BlockChartSeries, selectionIndex: number): Promise<void> {
    this.onBlockSelectStart(event, series, selectionIndex);
    await this.onDragStart(event, series, selectionIndex);
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(mouseEvent: MouseEvent): void {
    this.onDragEnd(mouseEvent);
    this.onZoomEnd(mouseEvent);
  }

  onBlockSelectStart(event: MouseEvent, series: BlockChartSeries, selectionIndex: number): void {
    if (this.dragAndDropSeries != null) {
      return;
    }

    series.onSelectStart(selectionIndex);
  }

  onBlockSelectEnd(event: MouseEvent, series: BlockChartSeries, selectionIndex: number): void {
    if (this.dragAndDropSeries != null) {
      return;
    }

    const block = series.onSelectEnd(selectionIndex);

    if (block == null) {
      return;
    }

    this.blockSelect.emit({
      seriesId: series.id,
      block
    });
  }
}

function getBoundingClientRect(element: Element): DOMRect {
  return element.getBoundingClientRect();
}

function getElementSize(element: Element): Size {
  return {
    width: element.clientWidth,
    height: element.clientHeight
  };
}

function updateSeries<TSeriesData>(series: ChartSeriesTypes, dimensions: ChartDimensions, dateRange: AxisRange, yAxisRight: AxisConfig, yAxisLeft: AxisConfig) {
  series.updateLayerDimensions(dimensions.series);
  series.updateXAxisRange(dateRange);

  if (series.yAxisId === yAxisRight.id) {
    series.updateYAxisRange(yAxisRight);
  } else if (series.yAxisId === yAxisLeft.id) {
    series.updateYAxisRange(yAxisLeft);
  } else {
    console.warn(`Unable to assign series to y axis with id "${series.yAxisId}", cause axis with this id does not exist.`);
  }
}
