import { BehaviorSubject, Subject } from 'rxjs';
import { FormSchema } from './form-group-builder/form-schema';
import { isEqualWith } from 'lodash-es';
import { FormGroup } from '@angular/forms';


export abstract class SchemaFormDataSource<TValues> {
  private readonly submit$$ = new Subject<void>();
  private readonly values$$ = new BehaviorSubject<any>({});

  private originalFormValues?: any;
  private currentFormValues?: any;

  public readonly submit$ = this.submit$$.asObservable();
  public readonly values$ = this.values$$.asObservable();

  public abstract readonly schema: FormSchema;

  public patchValue(values: any): void {
    this.originalFormValues = null;
    this.values$$.next(values);
  }

  public get hasChanges(): boolean {
    return !isEqualWith(this.originalFormValues, this.currentFormValues, isNullOrUndefined);
  }

  public onOriginalFormValueChanges(values: any): void {
    this.originalFormValues = values;
  }

  public onFormValueChanges(values: any): void {
    this.currentFormValues = values;
  }

  public onInit(): void {
  }

  public onDestroy(): void {
  }

  public submit(): void {
    this.submit$$.next();
  }

  public reset(): void {
    this.values$$.next(this.values$$.value || {});
  }

  public setUpDerivedFields(formGroup: FormGroup) {
  }

}

function isNullOrUndefined(lhs: any, rhs: any): boolean | undefined {
  if (lhs == null && rhs == null) {
    return true;
  }

  return undefined;
}
