import { Routes } from '@angular/router';
import { LogoutModule, VkAuthenticationGuard, VkLogoutGuard } from '@vk/authentication';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';


export const AppRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [VkAuthenticationGuard],
    canActivateChild: [VkAuthenticationGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/monitor'
      },
      {
        path: 'logout',
        canActivate: [VkLogoutGuard],
        loadChildren: () => LogoutModule
      },

      // Top-Level menu entry points
      {
        path: 'monitor',
        loadChildren: () => import('./sub-apps/monitor/monitor.module').then(m => m.MonitorModule)
      },
      {
        path: 'vermarktungsmanager',
        loadChildren: () => import('./fahrplan-manager/fahrplan-manager.module').then(m => m.FahrplanManagerModule)
      },
      {
        path: 'verfuegbarkeit',
        loadChildren: () => import('./sub-apps/non-availability/non-availability.module').then(m => m.NonAvailabilityModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./sub-apps/products/products-overview/products-overview.module').then(m => m.ProductsOverviewModule)
      },
      {
        path: 'masterdata',
        loadChildren: () => import('./sub-apps/master-data/master-data.module').then(m => m.MasterDataModule)
      },
      {
        path: 'allocations',
        loadChildren: () => import('./sub-apps/allocation/allocation.module').then(m => m.AllocationModule)
      },
      {
        path: 'fahrplan',
        loadChildren: () => import('./fahrplan-manager/fahrplan-manager.module').then(m => m.FahrplanManagerModule)
      },
      {
        path: 'imprint',
        loadChildren: () => import('./sub-apps/imprint/imprint.module').then(m => m.ImprintModule)
      },
      {
        path: 'privacyprotection',
        loadChildren: () => import('./sub-apps/privacy-protection/privacy-protection.module').then(m => m.PrivacyProtectionModule)
      },
      {
        path: 'terms-of-use',
        loadChildren: () => import('./sub-apps/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule)
      },
      {
        path: 'disclaimer',
        loadChildren: () => import('./sub-apps/disclaimer/disclaimer.module').then(m => m.DisclaimerModule)
      },
      {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'documentation',
        loadChildren: () => import('./sub-apps/documentation/documentation.module').then(m => m.DocumentationModule)
      },
      {
        path: '**',
        canActivate: [VkAuthenticationGuard],
        canActivateChild: [VkAuthenticationGuard],
        loadChildren: () => import('./sub-apps/page-not-found/page-not-found.module').then(m => m.LazyLoadPageNotFoundModule)
      },
    ]
  }
];

