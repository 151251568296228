import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { select, Store } from '@ngrx/store';
import { VkAuthenticationState } from './authentication.module';
import * as AuthActions from './store/authentication.actions';
import { selectCustomerId } from './store/authentication.reducer';
import { filter, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VkAuthenticationGuard extends KeycloakAuthGuard implements CanActivateChild {

  constructor(protected router: Router,
              protected keycloakAngular: KeycloakService,
              private readonly store: Store<VkAuthenticationState>) {
    super(router, keycloakAngular);
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.authenticated) {
      this.keycloakAngular.login();
      return false;
    }

    this.store.dispatch(AuthActions.getLoggedInUser());
    await this.store.pipe(select(selectCustomerId), filter(it => !!it), first()).toPromise();
    await this.keycloakAngular.isLoggedIn();
    await this.keycloakAngular.getToken();
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

}
