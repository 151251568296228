import { Dimensions, translateXDimensionsToWorldCoordinateSystem } from './models/dimensions';
import { AxisRange } from './models/axis';
import { calculateStepSize, translateXToChartCoordinateSystem } from './models/coordinates';

export interface ZoomUpdateEvent {
  dimensions: Dimensions;
  isZoomAllowed: boolean;
}

export interface ZoomEndEvent {
  range: AxisRange;
  isZoomAllowed: boolean;
}

export class ZoomHandler {
  private readonly minimumZoomDistance: number;
  private readonly offsetX;
  private readonly fromX;

  constructor(elementX: number,
              mouseStartX: number,
              seriesX: number,
              private readonly seriesWidth: number,
              private readonly seriesHeight: number,
              private readonly seriesRange: AxisRange,
              minimumZoomDistance: number) {
    this.offsetX = elementX + seriesX;
    this.fromX = mouseStartX - this.offsetX;

    const stepSize = calculateStepSize(this.seriesWidth, seriesRange.from, seriesRange.to);
    this.minimumZoomDistance = translateXToChartCoordinateSystem(0, stepSize)(minimumZoomDistance);
  }

  update(mouseEndX: number): ZoomUpdateEvent {
    // check if mouse x pos is in range
    const toX = Math.max(0, Math.min(mouseEndX - this.offsetX, this.seriesWidth));

    const from = Math.min(this.fromX, toX);
    const to = Math.max(this.fromX, toX);
    const width = to - from;

    return {
      dimensions: {
        width,
        height: this.seriesHeight,
        x: from,
        y: 0,
      },
      isZoomAllowed: width >= this.minimumZoomDistance
    };
  }

  end(mouseEndX: number): ZoomEndEvent {
    const { dimensions, isZoomAllowed } = this.update(mouseEndX);
    const range = translateXDimensionsToWorldCoordinateSystem(
      dimensions,
      this.seriesWidth,
      this.seriesRange
    );

    return {
      range,
      isZoomAllowed
    };
  }
}
