import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { dvfpProductAdvertisement, dvfpidProductAdvertisement, dvProductAdvertisement, ProductAdvertisement } from './product-cards.model';
import { GetOffersWithPredictionsForParkGQL, Produkt } from './products-overview.graphql.generated';


@Injectable({
  providedIn: 'any',
})
export class SelectNewProductDataSource implements OnDestroy {

  private readonly dataSourceWillBeDestroyed$ = new Subject<boolean>();

  constructor(
    private getOffersWithPredictionsForPark: GetOffersWithPredictionsForParkGQL
  ) {
  }

  openOffers(parkId: string): Observable<Array<ProductAdvertisement>> {
    return this.getOffersWithPredictionsForPark.fetch({ parkId }).pipe(
      map(response => response.data.offersWithPredictionsForPark),
      takeUntil(this.dataSourceWillBeDestroyed$),
      map(productAdvertisements => {
        return productAdvertisements.map(revenue => {
          switch (revenue.productType) {
            case Produkt.Direktvermarktung: {
              return { ...dvProductAdvertisement(revenue.hasRedispatch), revenue };
            }
            case Produkt.DirektvermarktungFahrplanmanagement: {
              return { ...dvfpProductAdvertisement(revenue.hasRedispatch), revenue };
            }
            case Produkt.FahrplanmanagementIntraday: {
              return { ...dvfpidProductAdvertisement(revenue.hasRedispatch), revenue };
            }
          }
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.dataSourceWillBeDestroyed$.next();
    this.dataSourceWillBeDestroyed$.complete();
  }

}
