import { ApolloLink } from '@apollo/client/core';

import { distinctUntilChanged, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { initialUserState, selectSessionId, VkAuthenticationService } from '@vk/authentication';
import * as ZenObservable from 'zen-observable';
import { concat, interval, of } from 'rxjs';
import { getMainDefinition } from '@apollo/client/utilities';

export function createAuthLink(authenticationService: VkAuthenticationService) {

  const sessionId = initialUserState.sessionId;

  const token$ = concat(of(1), interval(10000)).pipe(
    switchMap(() => {
      return authenticationService.loadUser();
    }),
    map(it => {
      return it.token;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  // @ts-ignore
  return new ApolloLink((operation, forward) => {
    return new ZenObservable(observer => {

      const definition = getMainDefinition(operation.query);
      const isSubscription = (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );

      let subscription: any;
      let tokenSwapCount = 0;

      const tokenSubscription = token$.subscribe(token => {

        // only renew subscriptions. do not renew queries and mutations
        if (!isSubscription && tokenSwapCount > 0) {
          return;
        }

        tokenSwapCount++;

        if (subscription) {
          subscription.unsubscribe();
        }

        operation.extensions = { token, sessionId };

        subscription = forward(operation).subscribe(
          value => observer.next(value),
          error => observer.error(error),
          () => {
            tokenSubscription.unsubscribe();
            observer.complete();
          }
        );

      });

      return () => {
        subscription?.unsubscribe();
        tokenSubscription?.unsubscribe();
      };

    });
  });
}
