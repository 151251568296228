import { ChangeDetection, ChartSeries, ChartSeriesType } from '../chart-series';
import { AxisRange } from '../../models/axis';
import { Dimensions } from '../../models/dimensions';
import { DataPoint } from '../../models/data-point';
import { createLinePath, TranslatedDataPoint, translatePointsToChartCoordinateSystem } from './line-path';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { translatePointToWorldCoordinateSystem } from '../../models/coordinates';

export class LineChartSeries extends ChartSeries<DataPoint, TranslatedDataPoint> {
  public readonly type: ChartSeriesType = 'Line';

  private dataShift = 0;

  private readonly pointSelected$$ = new ReplaySubject<DataPoint>(1);
  public readonly points$$ = new BehaviorSubject<TranslatedDataPoint[]>([]);
  public readonly path$$ = new BehaviorSubject<string>('');

  onInit(changeDetection: ChangeDetection): void {
    super.onInit(changeDetection);

    this.pointSelected$$.pipe(
      withLatestFrom(
        this.layerDimensions$$,
        this.xAxisRange$$,
        this.yAxisRange$$,
      ),
      map(([point, { width, height }, xAxisRange, yAxisRange]) => {
        return translatePointToWorldCoordinateSystem(point, xAxisRange.from, xAxisRange.to, yAxisRange.from, yAxisRange.to, width, height);
      }),
      takeUntil(this.seriesWillBeDestroyed$$),
    ).subscribe(([x, y]) => {
      console.warn(`point selected: ${new Date(x)} - ${y}`);
    });
  }

  render(layerDimensions: Dimensions, xAxisRange: AxisRange, yAxisRange: AxisRange, currentPoints: DataPoint[], needsRedraw: boolean): TranslatedDataPoint[] {
    needsRedraw = needsRedraw || this.points$$.value.length >= currentPoints.length;

    const previousPoints = needsRedraw ? [] : this.points$$.value;
    const previousPointCount = previousPoints.length;
    const previousPath = needsRedraw ? '' : this.path$$.value;
    const dataShift = needsRedraw ? 0 : this.dataShift;

    const { points, startIndex } = translatePointsToChartCoordinateSystem(
      currentPoints.slice(previousPointCount + dataShift), // just compute new points
      xAxisRange.from,
      xAxisRange.to,
      yAxisRange.from,
      yAxisRange.to,
      layerDimensions.width,
      layerDimensions.height
    );

    // all points to display translated to the chart coordinates
    const translatedPoints = previousPoints.concat(points);
    const path = createLinePath(
      translatedPoints,
      previousPointCount,
      previousPath
    );

    this.points$$.next(translatedPoints);
    this.path$$.next(path);

    if (needsRedraw) {
      this.dataShift = startIndex;
    }

    this.notifyNeedsRedraw(false);

    return points;
  }

  public isDataEqual(lhs: DataPoint, rhs: DataPoint): boolean {
    return false;
  }

  protected isSelected(lhs: TranslatedDataPoint, rhs: TranslatedDataPoint): boolean {
    return false;
  }

  public cloneData(point: DataPoint): DataPoint {
    return [...point];
  }

  onPointSelected(target: EventTarget) {
    if (target instanceof SVGCircleElement) {
      this.pointSelected$$.next([target.cx.baseVal.value, target.cy.baseVal.value]);
    }
  }

  public onDragStart(selectionIndex: number, fromPos: number, axisWidth: number, axisFrom: number, axisTo: number): void {
    // does nothing
  }

  public onDrag(toPos: number): DataPoint | undefined {
    return undefined;
  }

  public onDragEnd(): void {
    // does nothing
  }

}
