import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Handle, makeHandle } from './models/handle';
import { BehaviorSubject, Subject } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { NrgSheetContainerService } from './sheet-container.service';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'nrg-sheet-container',
  templateUrl: './sheet-container.component.html',
  styleUrls: ['./sheet-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('sheetVisibility', [
      transition(':enter', [
        style({
          width: '0px',
          opacity: 0
        }),
        animate('150ms ease-in', style({
          width: '300px',
          opacity: 1
        })),
      ]),
      transition(':leave', [
        style({
          width: '*',
          opacity: 1
        }),
        animate('150ms ease-out', style({
          width: '0px',
          opacity: 0
        })),
      ]),
    ])
  ]
})
export class NrgSheetContainerComponent implements OnInit, OnDestroy {
  private readonly componentWillBeDestroyed$$ = new Subject<void>();
  public readonly sheetWidth$$ = new BehaviorSubject<number>(300);
  private handle?: Handle;

  public readonly title$ = this.sheetContainerService.title$;
  public readonly isSheetOpen$ = this.sheetContainerService.isOpen$;
  public readonly isSheetHeaderVisible$ = this.sheetContainerService.isHeaderVisible$;

  @Input()
  public set sheetWidth(width: number) {
    this.sheetWidth$$.next(width);
  }

  public get sheetWidth(): number {
    return this.sheetWidth$$.value;
  }

  @Output() public readonly sheetClosed = new EventEmitter();
  @Output() public readonly contentChange = new EventEmitter<string>();

  @HostBinding('class.disable-selection') disableUserSelection = true;

  constructor(private readonly sheetContainerService: NrgSheetContainerService) {
  }

  onMouseDown(event: MouseEvent) {
    this.disableUserSelection = true;
    this.handle = makeHandle(this.sheetWidth, event.clientX);
  }

  @HostListener('mouseup')
  onMouseUp() {
    this.handle = undefined;
    this.disableUserSelection = false;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.handle != null) {
      this.sheetWidth = this.handle(event.clientX);
    }
  }

  onClose(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.sheetContainerService.close();
    this.sheetClosed.emit();
  }

  ngOnInit() {
    this.sheetContainerService.contextType$
      .pipe(takeUntil(this.componentWillBeDestroyed$$))
      .subscribe(contextType => {
        this.contentChange.emit(contextType);
      });
  }

  ngOnDestroy() {
    this.componentWillBeDestroyed$$.next();
    this.componentWillBeDestroyed$$.complete();
  }
}
