import { WebSocketLink } from '@apollo/client/link/ws';
import { split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { HttpLink } from 'apollo-angular/http';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { initialUserState } from '@vk/authentication';
// import { HttpHeaders } from '@angular/common/http';

export function createSplitLink(httpLinkCreator: HttpLink) {
  // const headers = new HttpHeaders();
  // headers.append();

  const httpLink = httpLinkCreator.create({
    uri: '/graphql',
    headers: { 'Vk-Session-Id': initialUserState.sessionId } as any
  });
  const { host, protocol } = window.location;
  const wsProtocol = protocol.startsWith('https') ? 'wss' : 'ws';
  const path = host.includes('localhost') ? 'subscriptions' : 'graphqlws';
  const client = new SubscriptionClient(
    `${wsProtocol}://${host}/${path}`,
    {
      reconnect: true,
      lazy: true,
    });

  const wsLink = new WebSocketLink(client);

  // The websocket starts hanging on the server side after ~1000 messages or ~260 subscriptions.
  // Therefore we renew it every 200 subscriptions.
  const clientAsAny = client as any;
  let reconnectedOperationId = clientAsAny.nextOperationId;
  setInterval(() => {
    if ((clientAsAny.nextOperationId - reconnectedOperationId) > 200) {
      reconnectedOperationId = clientAsAny.nextOperationId;
      clientAsAny.tryReconnect();
    }
  }, 1000);

  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );
}
