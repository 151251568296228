import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityAllocationGridComponent } from './entity-allocation-grid.component';
import { NrgAllocationGridModule } from '@nrg/components';



@NgModule({
  declarations: [EntityAllocationGridComponent],
  exports: [EntityAllocationGridComponent],
  imports: [
    CommonModule,
    NrgAllocationGridModule
  ]
})
export class VkEntityAllocationGridModule { }
