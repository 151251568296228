import { Component, Input } from '@angular/core';
import {
  OptimizationLoadingStateToMessageMap,
  optimizationLoadingStateToMessageMap
} from './models/optimization-loading-state-to-message-map.model';
import { KeyValue } from '@angular/common';
import { JobState } from '../optimization-state.graphql.generated';

@Component({
  selector: 'vk-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
})
export class ProgressIndicatorComponent {

  @Input() currentLoadingStep: JobState;
  @Input() showStepDescriptions: boolean;

  public loadingSteps: OptimizationLoadingStateToMessageMap = optimizationLoadingStateToMessageMap;


  public isStepDone(loadingStep: string) {
    let loadingIsDone = false;
    this.loadingSteps.forEach((description, aLoadingStep) => {

      if (aLoadingStep === loadingStep) {
        loadingIsDone = false;
      } else if (aLoadingStep === this.currentLoadingStep) {
        loadingIsDone = true;
      }

    });
    return loadingIsDone;
  }

  public orderAsIs(a: KeyValue<JobState, string>, b: KeyValue<JobState, string>): number {
    return 0;
  }
}

