import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AllocateEntityGQL, AssetAllocationType, DeallocateEntityGQL, EntityAllocationsGQL } from './entity-allocations.graphql.generated';
import { VkCommonState } from '@vk/common';
import { EntityAllocationDataSource } from './entity-allocation-data-source';

export type AllocationType =
  'GasStorageToPark'
  | 'HeatStorageToPark'
  | 'TechnicalUnitToGasStorage'
  | 'TechnicalUnitToHeatStorage'
  | 'TechnicalUnitToMalo'
  | 'TechnicalUnitToPark';

@Injectable({
  providedIn: 'any'
})
export class EntityAllocationDataSourceFactory {
  constructor(
    private readonly store: Store<VkCommonState>,
    private readonly load: EntityAllocationsGQL,
    private readonly allocate: AllocateEntityGQL,
    private readonly deallocate: DeallocateEntityGQL
  ) {
  }

  createEntityAllocationDataSource(type: AllocationType) {
    const allocationType = this.mapAllocationType(type);

    return new EntityAllocationDataSource(
      this.store,
      this.load,
      this.allocate,
      this.deallocate,
      allocationType
    );
  }

  private mapAllocationType(type: AllocationType): AssetAllocationType {
    switch (type) {
      case 'GasStorageToPark':
        return AssetAllocationType.GasStorageToPark;
      case 'HeatStorageToPark':
        return AssetAllocationType.HeatStorageToPark;
      case 'TechnicalUnitToGasStorage':
        return AssetAllocationType.TechnicalUnitToGasStorage;
      case 'TechnicalUnitToHeatStorage':
        return AssetAllocationType.TechnicalUnitToHeatStorage;
      case 'TechnicalUnitToMalo':
        return AssetAllocationType.TechnicalUnitToMalo;
      case 'TechnicalUnitToPark':
        return AssetAllocationType.TechnicalUnitToPark;
    }
  }
}
