import { FormSchema } from '@nrg/components';

export const technicalUnitConnectionSettingsSchema = (isCustomer: boolean) => {
  return {
    type: 'object',
    properties: {
      hundertProzentWert: {
        title: '100%-Wert',
        type: 'number',
        minimum: 0,
        readonly: isCustomer
      },
      anbindungVK: {
        title: 'Anbindung (Virtuelles Kraftwerk)',
        type: 'string',
        readonly: isCustomer
      },
      anbindungTE: {
        title: 'Anbindung (Technische Einheit)',
        type: 'string',
        readonly: isCustomer
      },
      steuerungsfreigabeDV: {
        title: 'Steuerungsfreigabe DV',
        type: 'boolean',
        readonly: isCustomer
      },
      steuerungsfreigabeDVFP: {
        title: 'Steuerungsfreigabe DVFP',
        type: 'boolean',
        readonly: isCustomer
      },
      steuerungsfreigabeDVFPID: {
        title: 'Steuerungsfreigabe DVFPID',
        type: 'boolean',
        readonly: isCustomer
      },
      steuerungsmodus: {
        title: 'Steuerungsmodus',
        type: 'string',
        readonly: isCustomer
      },
      steuerbarkeit: {
        title: 'Steuerbarkeit',
        enum: ['kontinuierlich', 'stufig', 'Ein / Aus'],
        readonly: isCustomer
      },
      motorhersteller: {
        title: 'Motorenhersteller',
        type: 'string',
        readonly: isCustomer
      },
      steuerungstyp: {
        title: 'Steuerungstyp',
        type: 'string',
        readonly: isCustomer
      },
      obereNennleistung: {
        title: 'Obere Nennleistung elektrisch (kW)',
        description: 'Auf welche maximale Leistung ist die Maschine ausgelegt?',
        type: 'number',
        minimum: 0,
        readonly: isCustomer
      },
      untereNennleistung: {
        title: 'Untere Nennleistung elektrisch (kW)',
        description: 'Auf welche minimale Leistung ist die Maschine ausgelegt?',
        type: 'number',
        readonly: isCustomer
      },
      technischeMinDauerleistung: {
        title: 'Technische Minimalleistung elektrisch (kW)',
        type: 'number',
        readonly: isCustomer,
        warnIfEmpty: 'wird für RD2.0 benötigt.'
      },
      leistungsstufen: {
        title: 'Schaltstufen (kW)',
        description: 'Auf welchen Schaltstufen kann die Anlage gefahren werden?',
        type: 'array',
        items: {
          type: 'number',
          minimum: 0
        },
        readonly: isCustomer
      },
      dateOfNetworkConnection: {
        type: 'dateString',
        title: 'Datum der Netzanbindung',
        readonly: isCustomer
      },
      dateOfCommission: {
        type: 'dateString',
        title: 'Datum der Inbetriebnahme',
        readonly: isCustomer
      },
      planungsrelevant: {
        type: 'boolean',
        title: 'Planungsrelevant',
        readonly: true
      },
      planungsrelevantOverride: {
        type: 'boolean',
        title: 'Planungsrelevant (manuell überschrieben durch)',
        readonly: isCustomer
      },
    },
    title: 'Anbindung',
    required: [
      'datenblatt',
      'name',
      'teSteuerung',
      'obereNennleistung'
    ],
    order: [
      'hundertProzentWert',
      'anbindungVK',
      'anbindungTE',
      'steuerungstyp',
      'steuerungsmodus',
      'steuerbarkeit',
      'motorhersteller',
      'leistungsstufen',
      'obereNennleistung',
      'untereNennleistung',
      'technischeMinDauerleistung',
      'steuerungsfreigabeDV',
      'steuerungsfreigabeDVFP',
      'steuerungsfreigabeDVFPID',
      'dateOfNetworkConnection',
      'dateOfCommission',
      'planungsrelevant',
      'planungsrelevantOverride'
    ]
  } as FormSchema;
};
