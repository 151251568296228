import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export enum AvailableProductTypes {
  DV = 'DV',
  DVFP = 'DVFP',
}

export interface ProductData {
  defaultSelection: boolean;
  textColor: string;
  backgroundColor: string;
  icon: string;
  title: string;
  subtitle?: string;
  description: string[];
  type: AvailableProductTypes;
}

export interface ProductsDataSource {
  bhkwProducts: ProductData[];
  kraftwerkeProducts: ProductData[];
}


export const productCards: ProductsDataSource = {
  bhkwProducts: [
    {
      defaultSelection: true,
      title: 'Direktvermarktung (DV)',
      subtitle: 'Grundlage zur Vermarktung Ihrer Anlage am Strommarkt',
      description: [
        'An- und Abmeldung Ihrer Anlage zur Direktvermarktung',
        'Abwicklung des Bilanzkreismanagements',
        'Erstellung von Einspeiseprognosen',
        'Monitoring der IST-Werte',
        'Verwaltung der Anlagendaten Ihrer Parks',
        'Planung von Nicht-Verfügbarkeiten'
      ],
      textColor: 'white',
      backgroundColor: '#f00000',
      icon: 'local_offer',
      type: AvailableProductTypes.DV
    },
    {
      defaultSelection: true,
      title: 'Direktvermarktung und Fahrplanoptimierung (DVFP)',
      subtitle: 'Ihr Weg zur Erwirtschaftung attraktiver Mehrerlöse durch flexible Fahrweise',
      description: [
        'An- und Abmeldung Ihrer Anlage zur Direktvermarktung',
        'Abwicklung des Bilanzkreismanagements',
        'Erstellung von Einspeiseprognosen',
        'Monitoring der IST-Werte',
        'Verwaltung der Anlagendaten Ihrer Parks',
        'Planung von Nicht-Verfügbarkeiten',
        'Zugang zum Spotmarkt',
        'Vermarktungsmanager',
        'Tägliche Optimierung durch die N-ERGIE',
        'Verwaltung Ihrer Nebenbedingungen'
      ],
      textColor: 'white',
      backgroundColor: '#e94e35',
      icon: 'local_airport',
      type: AvailableProductTypes.DVFP
    }
  ],
  kraftwerkeProducts: [{
    defaultSelection: true,
    title: 'Basis-Paket',
    description: [
      'Monitor Leistungswerte',
      'Ansicht Nebenbedingungen',
      'Stammdaten-Verwaltung',
      'Vermarktungsplanung',
      'Management Fernwirktechnik',
      'Management Anlagenspezifika',
      'Abrechnungsdetails',
      'Reports',
      'Hilfe / FAQ'
    ],
    textColor: 'white',
    backgroundColor: '#89384d',
    icon: 'local_offer',
    type: AvailableProductTypes.DV
  }, {
    defaultSelection: false,
    title: 'Intraday-Paket',
    description: [
      'Zugang zum Intraday-Markt',
      'Nutzung automatisierter Handelssysteme',
      'Konfiguration Nebenbedingungen',
      'Erweiterte Vermarktungsplanung'
    ],
    textColor: 'white',
    backgroundColor: '#710c2f',
    icon: 'local_airport',
    type: AvailableProductTypes.DVFP
  }
  ]
};


@Injectable({
  providedIn: 'root'
})
export class ProductsDataService {

  constructor() {
  }
  getBhkwProducts$: Observable<ProductData[]> = of(productCards.bhkwProducts);
  getKraftwerkeProducts$ = of(productCards.kraftwerkeProducts);

  getBhkwProducts(): ProductData[] {
    return productCards.bhkwProducts;
  }

  getKraftwerkeProducts(): ProductData[] {
    return productCards.kraftwerkeProducts;
  }
}
