import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

const maxRetryAttempts = 3;
const scalingDuration = 1000;
const includedStatusCodes = [0, 504];
const includedHttpMethods = ['GET'];

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (includedHttpMethods.includes(req.method.toUpperCase())) {
      return next.handle(req).pipe(
        retryWhen((attempts: Observable<any>) => {
            return attempts.pipe(
              mergeMap((error, i) => {

                const retryAttempt = i + 1;

                if (
                  retryAttempt > maxRetryAttempts || !includedStatusCodes.find(e => e === error.status)
                ) {
                  return throwError(error);
                }

                return timer(retryAttempt * scalingDuration);

              })
            );
          }
        )
      );
    } else {
      return next.handle(req);
    }
  }
}
