<div *ngIf="shouldDisplayButton()">
  <div *ngIf="buttonLabel != null; else downloadIcon">
    <button mat-stroked-button color="tertiary" aria-label="Vertragsdokument herunterladen" (click)="onDownloadContract()">{{buttonLabel}}</button>
  </div>

  <ng-template #downloadIcon>
    <mat-icon aria-hidden="false" aria-label="Vertragsdokument herunterladen" (click)="onDownloadContract()">
      file_download
    </mat-icon>
  </ng-template>
</div>

