import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptimizationStateComponent } from './optimization-state.component';
import { VkOptimizationProblemListModule } from '../optimization-problem-list/optimization-problem-list.module';
import { MatIconModule } from '@angular/material/icon';
import { ProgressIndicatorModule } from './progress-indicator/progress-indicator.module';
import { VkOptimizerInputLinkModule } from '../input-link/input-link.module';
import { VkOptimizerOutputLinkModule } from '../output-link/output-link.module';
import { VkOptimizerReportLinkModule } from '../report-link/report-link.module';
import { VkOptimizationResultChartModule } from '../optimization-result-chart/optimization-result-chart.module';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [OptimizationStateComponent],
  exports: [OptimizationStateComponent],
  imports: [
    CommonModule,
    MatIconModule,
    VkOptimizationProblemListModule,
    ProgressIndicatorModule,
    VkOptimizerInputLinkModule,
    VkOptimizerOutputLinkModule,
    VkOptimizerReportLinkModule,
    VkOptimizationResultChartModule,
    MatButtonModule
  ]
})
export class VkOptimizationStateModule { }
