export interface QueryParam {
  readonly name: string;
  readonly value: string;
}

export class UrlBuilder {
  private readonly queryParams: QueryParam[] = [];

  constructor(private readonly baseUrl: string) {
  }

  public appendQueryParam(name: string, value: string): void {
    this.queryParams.push({name, value});
  }

  public build(): string {
    return urlByAppendingQueryParams(this.baseUrl, this.queryParams);
  }

}

export function urlByAppendingQueryParams(baseUrl: string, queryParams: QueryParam[]): string {
  return queryParams.reduce((url, param, index) => {
    if (index === 0) {
      url += '?';
    } else {
      url += '&';
    }

    return `${url}${param.name}=${param.value}`;
  }, baseUrl);
}

/**
 * @deprecated should not be used after backend api is fixed
 */
export function getLastPartFromUrl(url: string): string {
  return url.replace(/"/g, '').split('/').pop() || '';
}
