import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { AppDialogConfig, showMessage } from '@nrg/components';
import {
  CommercializationType,
  ContractState,
  ProductSet,
  ProductType,
  TechnicalUnitProduct,
  TechnicalUnitProductType
} from './contracts.graphql.generated';
import { isEqual } from 'lodash-es';

export interface CommercializationChangeForContract {
  contractId: string | undefined | null;
  productType: ProductType;
  commercializationType: CommercializationType;
}

export interface SelectedProduct {
  contractId: string;
  contractAmendmentId?: string;
}

@Component({
  selector: 'vk-contracts-table',
  templateUrl: './vk-contracts-table.component.html',
  styleUrls: ['./vk-contracts-table.component.scss']
})
export class VkContractsTableComponent implements OnInit {

  public displayedColumns: string[] = ['name', 'state', 'malo', 'product', 'commercializationType', 'marketingStart', 'contractPdf'];
  public products = new ReplaySubject<Array<TechnicalUnitProduct>>(1);
  private _selectedContracts: Array<SelectedProduct> = [];

  @Input() displayCancellationContract = false;
  @Input() customerId?: string;

  @Input() set backOfficeMode(enabled: boolean) {
    if (enabled) {
      this.displayedColumns = ['name', 'state', 'malo', 'product', 'commercializationType', 'marketingStart', 'marketingEnd', 'sellingOpportunityId', 'contractPdf'];
      return;
    }
    this.displayedColumns = ['name', 'state', 'malo', 'product', 'commercializationType', 'marketingStart', 'contractPdf'];
  }

  @Input() showCheckBoxes = false;

  @Input() set teProducts(products: Array<TechnicalUnitProduct>) {
    this.products.next(products);
    this.selectedContracts.emit([]);
  }

  @Output() marketingMethodChanged = new EventEmitter<CommercializationChangeForContract>();
  @Output() openSellingOpportunityForOffer = new EventEmitter<string>();
  @Output() selectedContracts = new EventEmitter<SelectedProduct[]>();

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.showCheckBoxes) {
      this.displayedColumns = ['checkbox'].concat(this.displayedColumns);
    }
  }

  onChangeMarketingMethod(selectionChange: MatSelectChange, te: TechnicalUnitProduct, marketingMethodInput: MatSelect) {
    const commercializationType = selectionChange.value;
    const dialogConfig = commercializationType === CommercializationType.Fahrplanmanagement ? switchToMarkethingMethodDVFPRequest : switchToMarkethingMethodDvRequest;
    const dialog = showMessage(this.dialog, dialogConfig);

    dialog.afterClosed().subscribe(
      (confirmation => {
        if (confirmation) {
          this.marketingMethodChanged.emit({
            contractId: te.contractId,
            productType: te.productType,
            commercializationType: selectionChange.value
          });
        } else {
          marketingMethodInput.value = te.currentCommercializationType;
        }
      })
    );
  }

  isInActiveProductSection(contractState: ContractState): boolean {
    return contractState === ContractState.Aktiv || contractState === ContractState.Neu;
  }

  isNewOrDefect(technicalUnitProduct: TechnicalUnitProduct): boolean {
    return technicalUnitProduct.state === ContractState.Neu || technicalUnitProduct.technicalUnitHasDefect === true;
  }

  isNew(technicalUnitProduct: TechnicalUnitProduct): boolean {
    return technicalUnitProduct.state === ContractState.Neu;
  }

  getStateName(technicalUnitProduct: TechnicalUnitProduct): string {
    return technicalUnitProduct.technicalUnitHasDefect ? 'Defekt' : technicalUnitProduct.state;
  }

  isTechnicalUnitDeleted(technicalUnitProduct: TechnicalUnitProduct): boolean {
    return technicalUnitProduct.technicalUnitId == null;
  }

  nameForCommercializationType(commercializationType: CommercializationType): string {
    switch (commercializationType) {
      case CommercializationType.DirektvermarktungUngeplant: {
        return 'DV (ungeplant)';
      }
      case CommercializationType.DirektvermarktungGeplant: {
        return 'DV (geplant)';
      }
      case CommercializationType.Fahrplanmanagement: {
        return 'FP';
      }
      case CommercializationType.Intraday: {
        return 'DVFPID';
      }
      case CommercializationType.None: {
        return 'keine Vermarktung';
      }
    }
    return '---';
  }

  onContractSelected(contractId: string, checked: boolean, contractAmendmentId?: string) {
    const selectionChange = { contractId, contractAmendmentId };
    this._selectedContracts = checked ? this._selectedContracts.concat(selectionChange) : this._selectedContracts.filter(selection => !isEqual(selection, selectionChange));
    this.selectedContracts.emit(this._selectedContracts);
  }

  onOpenVerkaufschanceForOffer(offerId: string) {
    this.openSellingOpportunityForOffer.emit(offerId);
  }

  getProductName(productType: ProductType) {
    switch (productType) {
      case ProductType.Dv: {
        return 'DV';
      }
      case ProductType.Dvfp: {
        return 'DVFP';
      }
      case ProductType.Id: {
        return 'ID';
      }
      default:
        return '---';
    }
  }

  isInStateStorniert(contractState: ContractState): boolean {
    return contractState === ContractState.Storniert;
  }

  hasCancelationDate(technicalUnitProduct: TechnicalUnitProduct): boolean {
    return technicalUnitProduct.canceldFor != null;
  }

  isAmendment(technicalUnitProduct: TechnicalUnitProduct): boolean {
    return technicalUnitProduct.type === TechnicalUnitProductType.FixedPriceAmendment;
  }
}

const switchToMarkethingMethodDVFPRequest: AppDialogConfig = {
  message: 'In der Vermarktungsart "FP" erhalten Sie für diese Technische Einheit (TE) täglich eine automatische Fahrplanoptimierung. ' +
    'Diese basiert auf der Eingabe in Ihren Anlagedaten und auf unserer täglich neu zur Verfügung gestellten Preiserwartung für die kommenden Tage. Durch Anpassungen ' +
    'in Ihren Anlagedaten und mittels des Vermarktungsmanagers können Sie im Bedarfsfall aktiv Einfluss auf die automatisierte Fahrplanoptimierung nehmen',
  acceptButtonLabel: 'wechseln',
  cancelButtonLabel: 'abbrechen',
};

const switchToMarkethingMethodDvRequest: AppDialogConfig = {
  message: 'In der Vermarktungsart "DV (geplant)" muss die Fahrweise der Technischen Einheit (TE) eigenständig im Vermarktungsmanager vorgenommen werden.',
  acceptButtonLabel: 'wechseln',
  cancelButtonLabel: 'abbrechen',
};


export function shouldStrikeOutContractDates(productSet: ProductSet): boolean {
  return productSet.contractDates.canceledFor != null;
}
