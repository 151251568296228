import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { commonFeatureName, VkCommonState } from './vk-common.state';
import { statusReducer } from './store/status/status.reducer';
import { CommonModule } from '@angular/common';

const COMMON_FEATURE_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<VkCommonState>>(`${commonFeatureName} reducer`);

export const commonFeatureReducers: ActionReducerMap<VkCommonState> = {
  status: statusReducer
};

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(commonFeatureName, COMMON_FEATURE_REDUCER_TOKEN)
  ],
  providers: [{
    provide: COMMON_FEATURE_REDUCER_TOKEN,
    useValue: commonFeatureReducers,
  }]
})
export class VkCommonModule { }
