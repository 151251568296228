import { Component } from '@angular/core';

@Component({
  selector: 'nrg-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.scss']
})
export class SheetComponent {

}
