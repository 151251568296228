export interface Result {
  title: string;
  label?: string;
  url: string;
  children: Result[];
}

export interface FilteredResult extends Result {
  matchStart: number;
  matchEnd: number;
  match: string | null;
  children: FilteredResult[];
  level: number;
}

export interface QueryMatch {
  hasMatch: boolean;
  rawMatch?: string;
  matchStart: number;
  matchEnd: number;
}

export function filterResultByQuery(query: string, result: Result): QueryMatch {
  const normalizedQuery = query.toLowerCase();
  const resultTitle = result.title.toLowerCase();
  const matchStart = resultTitle.indexOf(normalizedQuery);
  const hasMatch = matchStart >= 0;

  if (hasMatch || query.length === 0) {
    // search in title
    const matchEnd = hasMatch ? matchStart + query.length : -1;
    const rawMatch = hasMatch ? result.title.substring(matchStart, matchStart + query.length) : undefined;

    return {
      hasMatch,
      matchStart,
      rawMatch,
      matchEnd
    };
  }

  return {
    hasMatch: false,
    matchStart: -1,
    matchEnd: -1
  };
}
