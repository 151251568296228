export enum AuthenticationErrorType {
  SessionTimeout = 'SessionTimeout',
  MissingKundenId = 'MissingKundenId'
}

export type AuthenticationError = SessionTimeoutError | MissingKundenIdError;

export interface SessionTimeoutError {
  readonly type: AuthenticationErrorType.SessionTimeout;
  readonly kundenId: string;
}

export interface MissingKundenIdError {
  readonly type: AuthenticationErrorType.MissingKundenId;
}
