import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MasterDataSheetComponent } from './master-data-sheet.component';
import { NrgSearchSheetModule } from '@nrg/components';
import { PoolSelectionModule } from '../pool-selection/pool-selection.module';

@NgModule({
  declarations: [
    MasterDataSheetComponent
  ],
  exports: [
    MasterDataSheetComponent
  ],
  imports: [
    CommonModule,
    NrgSearchSheetModule,
    PoolSelectionModule
  ]
})
export class VkMasterDataSheetModule { }
