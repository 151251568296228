import { OfferWithRevenuePrediction, Produkt } from './products-overview.graphql.generated';

export interface ProductAdvertisement {
  textColor: string;
  backgroundColor: string;
  title: string;
  titleShort: string;
  subtitle?: string;
  description: string[];
  product: Produkt;
  revenue?: OfferWithRevenuePrediction;
}

export function dvProductAdvertisement(withRedispatch: boolean): ProductAdvertisement {
  return {
    textColor: 'white',
    backgroundColor: '#f00000',
    product: Produkt.Direktvermarktung,
    title: 'Direktvermarktung (DV)' + (withRedispatch ? ' inkl. Redispatch 2.0' : ''),
    titleShort: 'Direktvermarktung',
    subtitle: 'Grundlage zur Vermarktung Ihrer Anlage am Strommarkt',
    description: [
      'An- und Abmeldung Ihrer Anlage zur Direktvermarktung',
      'Abwicklung des Bilanzkreismanagements',
      'Erstellung von Einspeiseprognosen',
      'Monitoring der IST-Werte',
      'Verwaltung der Anlagendaten Ihrer Parks',
      'Planung von Nicht-Verfügbarkeiten',
      ...(withRedispatch ? ['Redipsatch 2.0: Übernahme Pflichten BTR und EIV, etc.'] : [])
    ],
  };
}

export function dvfpProductAdvertisement(withRedispatch: boolean): ProductAdvertisement {
  return {
    title: 'Direktvermarktung und Fahrplanoptimierung (DVFP)' + (withRedispatch ? ' inkl. Redispatch 2.0' : ''),
    titleShort: 'Direktvermarktung und Fahrplanoptimierung',
    subtitle: 'Ihr Weg zur Erwirtschaftung attraktiver Mehrerlöse durch flexible Fahrweise',
    description: [
      'An- und Abmeldung Ihrer Anlage zur Direktvermarktung',
      'Abwicklung des Bilanzkreismanagements',
      'Erstellung von Einspeiseprognosen',
      'Monitoring der IST-Werte',
      'Verwaltung der Anlagendaten Ihrer Parks',
      'Planung von Nicht-Verfügbarkeiten',
      'Zugang zum Spotmarkt',
      'Vermarktungsmanager',
      'Tägliche Optimierung durch die N-ERGIE',
      'Verwaltung Ihrer Nebenbedingungen',
      ...(withRedispatch ? ['Redipsatch 2.0: Übernahme Pflichten BTR und EIV, etc.'] : [])
    ],
    textColor: 'white',
    backgroundColor: '#e94e35',
    product: Produkt.DirektvermarktungFahrplanmanagement
  };
}

export function dvfpidProductAdvertisement(withRedispatch: boolean): ProductAdvertisement {
  return {
    title: 'Direktvermarktung, Fahrplanoptimierung und Intraday-Vermarktung (DVFPID)' + (withRedispatch ? ' inkl. Redispatch 2.0' : ''),
    titleShort: 'Direktvermarktung, Fahrplanoptimierung und Intraday-Vermarktung',
    subtitle: 'Lorem ipsum sine amet ...',
    description: [
      'Lorem ipsum',
      'Sine amet',
      '...',
      ...(withRedispatch ? ['Redipsatch 2.0: Übernahme Pflichten BTR und EIV, etc.'] : [])
    ],
    textColor: 'white',
    backgroundColor: '#f08161',
    product: Produkt.FahrplanmanagementIntraday
  };
}

