import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { showMessage } from '@nrg/components';
import { MatDialog } from '@angular/material/dialog';
import { bufferTime, distinctUntilChanged, filter, mergeAll, takeUntil, withLatestFrom } from 'rxjs/operators';
import { acknowledgeAllErrors, VkError, selectAllNotAckownledgedErrors, VkCommonState } from '@vk/common';
import { selectSessionId } from '@vk/authentication';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'vk-error-notification',
  templateUrl: './error-notification.component.html',
  styleUrls: ['./error-notification.component.scss']
})
export class ErrorNotificationComponent implements OnInit, OnDestroy {
  private readonly componentWillBeDestroyed = new Subject();
  private readonly dialogIsShown$$ = new BehaviorSubject(false);

  public showOriginalError = false;

  private readonly sessionId$: Observable<string>;

  constructor(
    private readonly store: Store<VkCommonState>,
    private readonly dialog: MatDialog
  ) {

    this.sessionId$ = this.store.pipe(
      select(selectSessionId)
    );
  }

  ngOnInit(): void {

    const hostnamesToShowErrors = [
      'ops-uat.vk.n-ergie.de',
      'uat.vk.n-ergie.de',
      'ops-upshift.vk.n-ergie.de',
      'upshift.vk.n-ergie.de',
      'ops-staging.vk.n-ergie.de',
      'staging.vk.n-ergie.de',
      'localhost'
    ];

    if (hostnamesToShowErrors.includes(window.location.hostname)) {
      this.showOriginalError = true;
    }

    // to prevent multiple popups buffer all incoming errors in a second and display them together
    this.store.pipe(
      select(selectAllNotAckownledgedErrors),
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      bufferTime(200),
      mergeAll(),
      mergeAll(),
      bufferTime(200),
      filter(error => error.length > 0),
      takeUntil(this.componentWillBeDestroyed),
      withLatestFrom(this.sessionId$)
    ).subscribe(([errors, sessionId]) => {
      const isShown = this.dialogIsShown$$.value;

      if (errors.length && !isShown) {

        const errorMessage = getUniqueErrorMessages(errors, this.showOriginalError);

        const message = `${errorMessage}\n\nSitzungsschlüssel: ${sessionId}`;

        console.error(errors);

        showMessage(this.dialog, {
          message,
          acceptButtonLabel: 'Schließen',
          type: 'fail',
          onClose: () => {
            this.store.dispatch(acknowledgeAllErrors());
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.componentWillBeDestroyed.next();
    this.componentWillBeDestroyed.complete();
  }

}


export function getUniqueErrorMessages(allErrors: VkError[] | any, showOriginalError: boolean): string {

  const allMessages: string[] = allErrors.map((error: any) => {
    if ('error' in error) {

      if (typeof (error.error) === 'string') {
        return showOriginalError ? (error.rawError ?? error.error) : error.error;
      }
      if (!Array.isArray(error.error)) {
        return getUniqueErrorMessages([error.error], showOriginalError);
      } else {
        return getUniqueErrorMessages(error.error, showOriginalError);
      }
    } else {
      return 'Es ist ein unerwarteter Fehler aufgetreten.';
    }
  });

  return [...new Set(allMessages)].join(' ');
}



