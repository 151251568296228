import { Component, Input, OnInit } from '@angular/core';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { JobType, LoadOutputGQL } from './output-link.graphql.generated';

@Component({
  selector: 'vk-optimization-output-link',
  templateUrl: './output-link.component.html',
  styleUrls: ['./output-link.component.css']
})
export class OutputLinkComponent implements OnInit {

  @Input() parkId: string;
  @Input() parkName: string;
  @Input() jobyType: JobType;
  @Input() assetId?: string;

  private loadingFile$$ = new BehaviorSubject(false);

  public fileIsPresent$ = new ReplaySubject<boolean>(1);

  private fileName = 'output.json';

  public label$ = this.loadingFile$$.pipe(map(it => it ? 'Lädt...' : 'output.json'));

  constructor(private readonly load: LoadOutputGQL) {
  }

  ngOnInit() {
    if (this.parkName) {
      this.fileName = this.parkName + '_output.json';
    }
  }

  handleClick() {
    if (this.loadingFile$$.getValue()) {
      return;
    }
    this.loadingFile$$.next(true);

    this.load.fetch({
      assetId: this.assetId ?? this.parkId,
      jobType: this.jobyType
    }).subscribe(result => {
      this.loadingFile$$.next(false);
      this.openFile(new Blob([result.data.optimizationOutput]));
    });
  }

  private openFile(blob: Blob) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = this.fileName;
    link.click();
  }
}
