import { Directive, ElementRef, Input } from '@angular/core';

export type Matches = (TextToken | TextMatch)[];

export interface TextToken {
  type: 'Token';
  value: string;
}

export interface TextMatch {
  type: 'Match';
  value: string;
}

@Directive({
  selector: '[nrgQueryHighlight]'
})
export class QueryHighlightDirective {
  private _text?: string;
  private _query?: string;

  @Input() set text(text: string) {
    this._text = text;
    this.updateHighlighting();
  }

  @Input() set query(query: string) {
    this._query = query;
    this.updateHighlighting();
  }

  constructor(private readonly elementRef: ElementRef) {
  }

  private updateHighlighting(): void {
    if (this._text != null && this._query != null) {
      const matches = findTextMatches(this._text, this._query);
      applyHighlight(matches, this.elementRef.nativeElement);
    }
  }

}

export function findTextMatches(text: string, query: string, matches: Matches = []): Matches {
  if (query.length === 0) {
    return [{ type: 'Token', value: text }];
  }

  const normalizedText = text.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  const matchStart = normalizedText.indexOf(normalizedQuery);
  const matchEnd = matchStart + query.length;

  if (matchStart < 0) {
    matches.push({ type: 'Token', value: text });
    return matches;
  }

  const token = text.slice(0, matchStart);
  const match = text.slice(matchStart, matchEnd);
  const rest = text.slice(matchEnd, text.length);

  matches.push({ type: 'Token', value: token });

  if (match.length > 0) {
    matches.push({ type: 'Match', value: match });
  }

  if (rest.length > 0) {
    return findTextMatches(rest, query, matches);
  }

  return matches;
}

export function createHighlightedHtmlString(matches: Matches): string {
  return matches.reduce((htmlString, token) => {
    switch (token.type) {
      case 'Token':
        return `${htmlString}${token.value}`;
      case 'Match':
        return `${htmlString}<span class="query">${token.value}</span>`;
    }
  }, '');
}

function applyHighlight(matches: Matches, element: HTMLElement): void {
  element.innerHTML = createHighlightedHtmlString(matches);
}
