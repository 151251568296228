import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MenuItem} from './models/menu-item.model';

@Component({
  selector: 'nrg-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NrgMenuComponent {

  @Input()
  public username?: string;

  @Input()
  public menuItems: MenuItem[] = [];

  @Input()
  public version?: string;
}
