<section class="contract-section" id="active-product-section">
  <div fxLayout="row">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="park-name">
        {{parkName}}
      </div>
      <div class="park-info">
        Produktpaket: {{returnProductString(productType)}}
      </div>
      <div class="park-info">
        Vertragsversion: {{contractVersion}}
      </div>
      <div class="park-info">
        Unterschriftsdatum {{(signatureDate  | date) || '---'}}
      </div>
      <div class="park-info" [class.strike-out]="isInStateStorniert()">
        Vertragsstart: {{(contractStart  | date) || '---'}}
      </div>
      <div class="park-info" [class.strike-out]="isInStateStorniert()">
        Vertragsende: {{(contractEnd  | date) || '---'}}
        <p *ngIf="hasAmendment === true" class="asterisk">*</p>
      </div>
      <div class="park-info" *ngIf="hasCancellationDate()">
        Gekündigt zum: {{(canceledFor  | date) || '---'}}
      </div>
    </div>

    <div fxLayoutAlign="start center" class="contract-action-btns" fxLayout="column">
      <button mat-stroked-button [color]="shouldHighlightButton()"
              *ngIf="showOfferButton"
              [disabled]="disableOfferButton"
              (click)="onShowOffers()"
              id="choose-new-product-btn"> {{showOfferButtonName}}
      </button>

      <button mat-stroked-button class="contract-conditions-btn"
              *ngIf="showContractConditionsButton"
              (click)="onShowContractConditions()"> Vertragskonditionen anzeigen
      </button>
    </div>


  </div>
</section>
