import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartLegendComponent } from './chart-legend.component';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  declarations: [
    ChartLegendComponent
  ],
  exports: [
    ChartLegendComponent
  ],
  imports: [
    CommonModule,
    MatCheckboxModule
  ]
})
export class NrgChartLegendModule {
}
