import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartSeriesTypes } from '../chart/series/chart-series-types';
import { ReplaySubject } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'nrg-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartLegendComponent {
  public readonly chartSeries$$ = new ReplaySubject<ChartSeriesTypes[]>(1);

  @Input()
  public set series(series: ChartSeriesTypes[]) {
    if (series == null) {
      return;
    }

    this.chartSeries$$.next(series);
  }

  setVisibility(event: MatCheckboxChange, series: ChartSeriesTypes) {
    series.isVisible = event.checked;
  }
}
