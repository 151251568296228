  <div class="body">
  <mat-icon aria-hidden="true">{{icon}}</mat-icon>
  <span><ng-content></ng-content></span>
</div>
<div class="actions">
  <button *ngFor="let action of actions"
          (click)="action.execute()"
          mat-button
          color="primary">
    {{action.title}}
  </button>
</div>
