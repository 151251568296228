import { createFeatureSelector } from '@ngrx/store';
import { VkStatus } from './store/status/status.reducer';

export interface VkCommonState {
  status: VkStatus;
}

export const commonFeatureName = 'common';

export const selectCommonFeatureState = createFeatureSelector<VkCommonState>(commonFeatureName);
