import { FormSchema } from '@nrg/components';

export const techncialUnitRunPlanParameterSchema: FormSchema = {
  type: 'object',
  title: 'Fahrplanparameter',
  properties: {
    obereNennleistung: {
      // needed only for calculation of derived fields, therefore hidden
      title: 'Obere Nennleistung elektrisch (kW)',
      description: 'Auf welche maximale Leistung ist die Maschine ausgelegt?',
      type: 'number',
      hidden: true
    },
    maxThermLeistung: {
      title: 'Nennleistung thermisch (kW)',
      type: 'number',
      minimum: 0
    },
    stromkennzahl: {
      title: 'Stromkennzahl',
      description: 'Wie ist das Verhältnis von Strom- zu Wärmeerzeugung? (Strommkennzahl Herstellerangabe BHKW)',
      type: 'number',
      minimum: 0,
      readonly: true
    },
    wirkungsgradElektrisch: {
      title: 'Wirkungsgrad elektrisch (Prozent)',
      description: 'Wie ist das Verhältnis von aufgewandter Energieinput zu nutzbarem Strom? (Wirkungsgrad el. Herstellerangabe BHKW)',
      type: 'number',
      minimum: 25,
      maximum: 55
    },
    wirkungsgradThermisch: {
      title: 'Wirkungsgrad thermisch (Prozent)',
      description: 'Wie ist das Verhältnis von aufgewandter Energieinput zu nutzbarer Wärme? (Wirkungsgrad th. Herstellerangabe BHKW)',
      type: 'number',
      minimum: 0,
      maximum: 55
    },
    wirkungsgradGesamt: {
      title: 'Gesamtwirkungsgrad (Prozent)',
      type: 'number',
      minimum: 0,
      maximum: 100,
      readonly: true
    },
    rampUpTimeCold: {
      title: 'Anfahrzeit kalt (Zeitdauer in Min)',
      description: 'Die Anfahrzeit kalt beschreibt die Zeit während des Anschaltvorgangs, welche benötigt wird, um vom ausgeschalteten Zustand der Anlage in die Einspeisung ins Netz zu kommen (Netzsynchronisation). Kalt bedeutet hierbei, dass die Anlage länger als 48 Stunden ausgeschaltet war. Weitere Informationen finden Sie unter anderem in den Unterlagen Ihres Motorenherstellers.',
      type: 'number',
      minimum: 0,
      precision: 0,
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    },
    rampUpTimeWarm: {
      title: 'Anfahrzeit warm (Zeitdauer in Min)',
      description: 'Die Anfahrzeit warm beschreibt die Zeit während des Anschaltvorgangs, welche benötigt wird, um vom ausgeschalteten Zustand der Anlage in die Einspeisung ins Netz zu kommen (Netzsynchronisation). Warm bedeutet hierbei, dass die Anlage weniger als 48 Stunden ausgeschaltet war. Weitere Informationen finden Sie unter anderem in den Unterlagen Ihres Motorenherstellers.',
      type: 'number',
      minimum: 0,
      precision: 0,
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    },
    startUpTimeCold: {
      title: 'Hochfahrzeit kalt (Zeitdauer in Min)',
      description: 'Die Hochfahrzeit kalt beschreibt die Zeit während des Anschaltvorgangs, welche benötigt wird, um ab der Einspeisung ins Netz (Netzsynchronisation) bis zur Mindestleistung zu kommen. Kalt bedeutet hierbei, dass die Anlage länger als 48 Stunden ausgeschaltet war. Weitere Informationen finden Sie unter anderem in den Unterlagen Ihres Motorenherstellers.',
      type: 'number',
      minimum: 0,
      precision: 0,
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    },
    startUpTimeWarm: {
      title: 'Hochfahrzeit warm (Zeitdauer in Min)',
      description: 'Die Hochfahrzeit warm beschreibt die Zeit während des Anschaltvorgangs, welche benötigt wird, um ab der Einspeisung ins Netz (Netzsynchronisation) bis zur Mindestleistung zu kommen. Warm bedeutet hierbei, dass die Anlage weniger als 48 Stunden ausgeschaltet war. Weitere Informationen finden Sie unter anderem in den Unterlagen Ihres Motorenherstellers.',
      type: 'number',
      minimum: 0,
      precision: 0,
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    },
    shutDownTime: {
      title: 'Abfahrzeit (Zeitdauer in Min)',
      description: 'Die Abfahrzeit beschreibt die Zeit während des Abschaltvorgangs, welche benötigt wird, um von der Minimalleistung der Anlage in den ausgeschalteten Zustand zu kommen (Netztrennung). Weitere Informationen finden Sie unter anderem in den Unterlagen Ihres Motorenherstellers.',
      type: 'number',
      minimum: 0,
      precision: 0,
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    },
    loadGradientMaxPower: {
      title: 'Lastgradient Nennleistung (MW/min)',
      description: 'Die Geschwindigkeit, welche die Maschine benötigt, um eine höhere Leistungsstufe zu erreichen. Auch bekannt als positive Leistungsänderungsgeschwindigkeit. Weitere Informationen finden Sie unter anderem in den Unterlagen Ihres Motorenherstellers.',
      type: 'number',
      minimum: 0,
      precision: 3,
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    },
    loadGradientMinPower: {
      title: 'Lastgradient Mindestleistung (MW/min)',
      description: 'Die Geschwindigkeit, welche die Maschine benötigt, um eine niedrigere Leistungsstufe zu erreichen. Auch bekannt als negative Leistungsänderungsgeschwindigkeit. Weitere Informationen finden Sie unter anderem in den Unterlagen Ihres Motorenherstellers.',
      type: 'number',
      minimum: 0,
      precision: 3,
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    },
    totzeit: {
      title: 'Totzeit Anlage (Sekunden)',
      description: 'Gibt die Zeit an, zwischen der Sendung eines Signal bis zur Reaktion der Anlage',
      type: 'number',
      minimum: 0
    },
    feuerungsleistung: {
      title: 'Feuerungsleistung (kW)',
      description: 'Wie hoch ist der Wärmeinhalt des Brennstoffs, der dieser Anlage maximal zugeführt werden kann? (Feuerungsleistung Herstellerangabe BHKW)',
      type: 'number',
      minimum: 0,
      readonly: true
    },
    maxLeistung: {
      title: 'Maximale Fahrplanleistung (kW)',
      description: 'Welche Leistung soll die Maschine maximal fahren?',
      type: 'number',
      minimum: 0,
      precision: 0
    },
    minLeistung: {
      title: 'Minimale Fahrplanleistung (kW)',
      description: 'Welche Leistung soll die Maschine minimal fahren?',
      type: 'number',
      minimum: 1
    },
    anlageDarfStehen: {
      title: 'Abschaltung auf 0 kW erlaubt',
      description: 'Darf die Maschine bis auf Null herunter gefahren werden?',
      type: 'boolean'
    },
    startbarProTag: {
      title: 'Anzahl Starts pro Tag',
      description: 'Es werden maximal diese Anzahl an Starts pro Tag in der Fahrplanerstellung berücksichtigt.',
      type: 'number',
      minimum: 0,
      maximum: 24
    },
    zielmengenzeitraum: {
      title: 'Zeitraum für Zielmengenwert',
      enum: ['Tageszielmenge', 'Wochenzielmenge'],
      required: [
        'Tageszielmenge',
        'Wochenzielmenge',
      ],
    },
    zielmengenwert: {
      title: 'Zielmengenwert (kWh)',
      description: 'Wo liegt der Zielmengenwert?',
      type: 'number',
      minimum: 0
    },
    startkostenWarm: {
      title: 'Startkosten Warmstart (nach 8h Stillstand) (€/MW)',
      description: 'Wie hoch sind die Startkosten der Maschine, wenn die Maschine vorher 8h aus war?',
      type: 'number',
      minimum: 0,
      maximum: 100
    },
    startkostenKalt: {
      title: 'Startkosten Kaltstart (nach 24h Stillstand) (€/MW)',
      description: 'Wie hoch sind die Startkosten der Maschine, wenn die Maschine vorher 24h aus war?',
      type: 'number',
      minimum: 0,
      maximum: 200
    },
    betriebszeitProJahr: {
      title: 'Betriebsstunden pro Jahr (Stunden)',
      description: 'Wieviele Stunden im Jahr soll die Anlage laufen?',
      type: 'number',
      minimum: 0,
      maximum: 8784
    },
    minBetriebszeit: {
      title: 'Minimale Betriebszeit (Stunden)',
      description: 'Wieviel Stunden soll die Anlage mindestens laufen, wenn sie einmal gestartet worden ist? Zeigt weiterhin an, in welchem Zeitraum eine Anlage nach erfolgtem Start mindestens Leistung in das Netz einspeisen muss. Weitere Informationen finden Sie unter anderem in den Unterlagen Ihres Motorenherstellers.',
      type: 'number',
      minimum: 0,
      maximum: 10,
      precision: 0,
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    },
    minStillstandzeit: {
      title: 'Minimale Stillstandzeit (Stunden)',
      description: 'Wieviel Stunden soll die Anlage mindestens aus sein, wenn sie aus geschalten wird? Zeigt weiterhin an, in welchem Zeitraum eine Anlage nach erfolgter Netztrennung nicht zum Wiederanfahren zur Verfügung steht. Weitere Informationen finden Sie unter anderem in den Unterlagen Ihres Motorenherstellers.',
      type: 'number',
      minimum: 0,
      maximum: 10,
      precision: 0,
      warnIfEmpty: 'wird für RD2.0 benötigt.'
    },
    minBetriebProLeistungsstufe: {
      title: 'Minimale Betriebszeit pro Leistungsstufe (Stunden)',
      description: 'Wieviel Stunden soll die Leistung bei der Anlage mindestens gehalten werden?',
      type: 'number',
      minimum: 0,
      maximum: 10
    },
    maxJahresmengeStrom: {
      title: 'Maximale Jahresmenge Strom (kWh)',
      description: 'Wieviel Strom soll maximal pro Jahr erzeugt werden?',
      type: 'number',
      minimum: 0
    },
    minJahresmengeStrom: {
      title: 'Minimale Jahresmenge Strom (kWh)',
      description: 'Wieviel Strom muss mindestens pro Jahr erzeugt werden?',
      type: 'number',
      minimum: 0
    },
    maxJahresmengeWaerme: {
      title: 'Maximale Jahresmenge Wärme (kWh)',
      description: 'Wieviel Wärme soll maximal pro Jahr erzeugt werden?',
      type: 'number',
      minimum: 0
    },
    minJahresmengeWaerme: {
      title: 'Minimale Jahresmenge Wärme (kWh)',
      description: 'Wieviel Wärme muss mindestens pro Jahr erzeugt werden?',
      type: 'number',
      minimum: 0
    },
  },
  required: [
    'maxLeistung',
    'minLeistung',
    'wirkungsgradElektrisch',
    'wirkungsgradThermisch',
    'anlageDarfStehen'
  ],
  order: [
    'obereNennleistung',
    'maxThermLeistung',
    'stromkennzahl',
    'wirkungsgradElektrisch',
    'wirkungsgradThermisch',
    'wirkungsgradGesamt',
    'rampUpTimeCold',
    'rampUpTimeWarm',
    'shutDownTime',
    'startUpTimeCold',
    'startUpTimeWarm',
    'loadGradientMaxPower',
    'loadGradientMinPower',
    'totzeit',
    'feuerungsleistung',
    'leistungsstufen',
    'maxLeistung',
    'minLeistung',
    'anlageDarfStehen',
    'startbarProTag',
    'zielmengenzeitraum',
    'zielmengenwert',
    'startkostenWarm',
    'startkostenKalt',
    'betriebszeitProJahr',
    'minBetriebszeit',
    'minStillstandzeit',
    'minBetriebProLeistungsstufe',
    'maxJahresmengeStrom',
    'minJahresmengeStrom',
    'maxJahresmengeWaerme',
    'minJahresmengeWaerme',
  ],
};
