import { calculateStepSize, translateXToChartCoordinateSystem } from './coordinates';

const defaultTickWidth = 5;
const defaultLabelPositionOffsetX = { x: 3, y: 10 };
const defaultLabelPositionOffsetY = { x: 5, y: 3 };

export type AxisLabel<TLabel> = [label: TLabel, value: number];

export interface AxisRange {
  from: number;
  to: number;
}

export interface Tick {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  label: {
    text: string;
    x: number;
    y: number;
  };
}

export type Axis = Tick[];

export enum AxisSide {
  Left,
  Right
}

export interface AxisConfig {
  readonly id: string;
  readonly from: number;
  readonly to: number;
  readonly stepSize: number;
  readonly formatter: (value: number) => string;
}

export const percentAxisConfig: AxisConfig = {
  id: 'percent',
  from: 0,
  to: 100,
  stepSize: 25,
  formatter: value => `${value} %`
};

export function createAxisConfig(id: string, from: number, to: number, stepSize: number, formatter: (value: number) => string): AxisConfig {
  const fromRest = from % stepSize;
  if (fromRest !== 0) {
    from = from - fromRest - stepSize;
  }

  const toRest = to % stepSize;
  if (toRest !== 0) {
    to = to - toRest + stepSize;
  }

  return {
    id,
    from,
    to,
    stepSize,
    formatter
  };
}

export function generateAxisLabelsFromConfig(config: AxisConfig): string[] {
  const labels: string[] = [];

  for (let i = config.from; i <= config.to; i += config.stepSize) {
    labels.push(config.formatter(i));
  }

  return labels;
}

export function generateXAxis(labels: AxisLabel<string>[], axisWidth: number, tickWidth: number = defaultTickWidth, labelPositionOffset = defaultLabelPositionOffsetX): Axis {
  return labels.map(([label, x]) => {
    return {
      x1: x,
      y1: 0,
      x2: x,
      y2: tickWidth,
      label: {
        text: label,
        x: x + labelPositionOffset.x,
        y: tickWidth + labelPositionOffset.y
      }
    };
  });
}

export function generateYAxis(labels: string[], axisWidth: number, axisHeight: number, side: AxisSide, tickWidth: number = defaultTickWidth, labelPositionOffset = defaultLabelPositionOffsetY): Axis {
  const lastIndex = labels.length - 1;
  const stepSize = axisHeight / lastIndex;
  const isLeftAxis = side === AxisSide.Left;
  const x1 = isLeftAxis ? axisWidth - tickWidth : 0;
  const x2 = isLeftAxis ? axisWidth : tickWidth;
  const labelPositionOffsetX = isLeftAxis ? -labelPositionOffset.x : labelPositionOffset.x + tickWidth;

  const yAxis: Axis = [];

  let step = 0;
  for (let i = lastIndex; i >= 0; --i) {
    const label = labels[i];
    const y = stepSize * step;

    yAxis.push({
      x1,
      y1: y,
      x2,
      y2: y,
      label: {
        text: label,
        x: x1 + labelPositionOffsetX,
        y: y + labelPositionOffset.y
      }
    });

    ++step;
  }

  return yAxis;
}
