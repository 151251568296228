import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryHighlightDirective } from './query-highlight.directive';


@NgModule({
  declarations: [QueryHighlightDirective],
  exports: [QueryHighlightDirective],
  imports: [
    CommonModule
  ]
})
export class NrgQueryHighlightModule {
}
