<div class="content">
  <ng-content></ng-content>
</div>
<div class="nrg-sheet-handle"
     role="separator"
     (mousedown)="onMouseDown($event)">
</div>
<div [style.width.px]="sheetWidth$$ | async" class="nrg-sheet" @sheetVisibility *ngIf="isSheetOpen$ | async">
  <div class="header mat-title" *ngIf="isSheetHeaderVisible$ | async">
    <h3>{{title$ | async}}</h3>
    <button (click)="onClose($event)" mat-icon-button
            aria-label="Schließt das Bearbeitungsformular für einen Fahrplanblock">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <ng-content select="nrg-sheet"></ng-content>
</div>
