import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'vk-impersonate-link',
  templateUrl: './impersonate-link.component.html',
  styleUrls: ['./impersonate-link.component.css']
})
export class ImpersonateLinkComponent implements OnInit {

  public url = '';

  @Input() customerId = '';

  constructor() {
  }

  ngOnInit(): void {
    const hash = '#impersonate/' + this.customerId;
    const protocol = location.protocol;
    const host = location.host;
    this.url = getUrl(protocol, host as AllowedUrls, hash);
  }

}

export type AllowedUrls = 'localhost:4200' |
  'ops-staging.vk.n-ergie.de' |
  'ops-upshift.vk.n-ergie.de' |
  'ops-uidev.vk.n-ergie.de' |
  'ops-uat.vk.n-ergie.de' |
  'ops-review.vk.n-ergie.de' |
  'ops.vk.n-ergie.de' |
  'localhost:9876';

export function getUrl(protocol: string, host: AllowedUrls, hash: string): string {
  switch (host) {
    case 'localhost:4200':
      return protocol + '//localhost:4201/' + hash;
    case 'ops-staging.vk.n-ergie.de':
      return protocol + '//staging.vk.n-ergie.de/' + hash;
    case 'ops-upshift.vk.n-ergie.de':
      return protocol + '//upshift.vk.n-ergie.de/' + hash;
    case 'ops-uidev.vk.n-ergie.de':
      return protocol + '//uidev.vk.n-ergie.de/' + hash;
    case 'ops-uat.vk.n-ergie.de':
      return protocol + '//uat.vk.n-ergie.de/' + hash;
    case 'ops-review.vk.n-ergie.de':
      return protocol + '//review.vk.n-ergie.de/' + hash;
    case 'ops.vk.n-ergie.de':
      return protocol + '//vk.n-ergie.de/' + hash;
    case 'localhost:9876':
      return protocol + '//localhost:9876/' + hash;
  }
}
