import { Component, OnInit } from '@angular/core';
import { CustomerImportFormDataSource } from './customer-import-form.data.source';
import { CustomerImportExportService } from '../customer-import-export.service';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { CustomerWithAssets } from '../customer-import-export.schema';
import { AllCustomerIdsForCustomerImportFormGQL } from './customer-import-form.graphql.generated';
import { select, Store } from '@ngrx/store';
import { selectCustomerId } from '@vk/authentication';
import { VkCommonState } from '@vk/common';

@Component({
  selector: 'vk-template-customer-form',
  templateUrl: './customer-import-form.component.html',
  styleUrls: ['./customer-import-form.component.scss']
})
export class CustomerImportFormComponent implements OnInit {

  state$$ = new BehaviorSubject<'Input' | 'Loading' | 'Done'>('Input');

  lastCreatedCustomerId$$ = new ReplaySubject<string>(1);

  constructor(
    private readonly store: Store<VkCommonState>,
    private readonly customerImportExportService: CustomerImportExportService,
    private readonly allCustomerIdsGQL: AllCustomerIdsForCustomerImportFormGQL
  ) {
  }

  dataSource = new CustomerImportFormDataSource(
    this.store.pipe(
      select(selectCustomerId),
      distinctUntilChanged(),
      switchMap(tenantId => {
        return this.allCustomerIdsGQL.subscribe({ tenantId }).pipe(
          map(result => result.data!.assetTree.map(it => it.customerId))
        );
      })
    )
  );

  ngOnInit(): void {
    this.dataSource.patchValue({
      malo: [{
        accountingGrid: '11XMPM-NERGIE1-A'
      }]
    });
  }

  async handleSubmit(data: CustomerWithAssets) {

    if (prompt(`Wollen sie den Kunden '${data.customer.customerId}' wirklich wie angegeben importieren? Dann geben sie bitte JA in Großbuchstaben in das folgende Feld ein.`) !== 'JA') {
      return;
    }

    const customIdInUse = await this.dataSource.customerIdAlreadyInUse$.pipe(first()).toPromise();

    if (customIdInUse) {
      return;
    }

    const anyTeInvalid = await this.dataSource.anyTeNeedIsInvalid$$.pipe(first()).toPromise();

    if (anyTeInvalid) {
      return;
    }

    this.state$$.next('Loading');

    try {
      await this.customerImportExportService.importCustomer(data);
    } catch (e) {
      this.state$$.next('Loading');
    }

    if (this.state$$.value === 'Loading') {
      this.lastCreatedCustomerId$$.next(data.customer.customerId);
      this.state$$.next('Done');
    }
  }

  handleNewFile(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.item(0);
    if (file) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const content = evt.target?.result as string;
        if (content) {
          const data = JSON.parse(content);
          this.dataSource.loadImport(data);
        }
      };
      reader.readAsText(file, 'UTF-8');
    }
  }

  handleCreateNextCustomer() {
    this.state$$.next('Input');
  }
}
