import { NgModule } from '@angular/core';
import { ReportLinkComponent } from './report-link.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [ReportLinkComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        ReportLinkComponent
    ]
})
export class VkOptimizerReportLinkModule { }
