import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vk-contract-link',
  template: 'contract-link-mock',
  styleUrls: []
})
export class ContractLinkMockComponent implements OnInit {

  @Input() type: 'contract' | 'contract-preview' | 'termination-agreement' | 'termination-agreement-preview' = 'contract';
  @Input() contractId: string;
  @Input() customerId: string;
  @Input() technicalUnitId: string;
  @Input() priceCalculationId: string;

  constructor() {
  }

  ngOnInit() {
  }

}
