
<div class="content">

<vk-pool-selection *ngIf="withPoolFilter" (poolSelected)="onPoolSelected($event)" [withAllOption]="true"></vk-pool-selection>

<nrg-search-sheet
    [results]="results$ | async"
    [isLoading]="isLoading$ | async"
    (query)="onQueryChange($event)"
>
</nrg-search-sheet>

</div>
