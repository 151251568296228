import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { HttpToHttpsInterceptor } from './shared/http-to-https-interceptor/http-to-https-interceptor';
import { SharedModule } from './shared/shared.module';
import { NrgLayoutModule } from '@nrg/components';
import { initializeKeycloak, VkAuthenticationModule } from '@vk/authentication';
import { RemoteRegistryService } from './e2e-testing/remote-registry.service';
import { VkDocumentsModule } from '@vk/documents';
import { VkErrorNotificationModule } from '@vk/components';
import { MatDialogModule } from '@angular/material/dialog';
import { VkOptimizerModule } from '@vk/optimizer';
import { RetryInterceptor, VkCommonModule, VkGraphqlModule } from '@vk/common';
import { IS_PORTAL } from '@vk/components';

@NgModule({
  imports: [
    NrgLayoutModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    HttpClientModule,
    MatNativeDateModule,
    KeycloakAngularModule,
    SharedModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    VkAuthenticationModule,
    VkDocumentsModule.forRoot('/docs'),
    VkErrorNotificationModule,
    VkCommonModule,
    VkOptimizerModule.forRoot('/optimizer', true),
    MatDialogModule,
    VkGraphqlModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de'
    },
    // redhat keycloak service
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak(environment.keycloak),
      deps: [KeycloakService],
      multi: true
    },
    // TODO: make this interceptor activate optionally through configuration
    // this interceptor only works with the whole noumena system deployed and developed over https as currently
    // setup at n-ergie
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpToHttpsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true
    },
    RemoteRegistryService,
    {
      provide: IS_PORTAL,
      useValue: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
