import { Component, OnInit } from '@angular/core';
import { logoutAction, MenuItem, ToolbarItem, ToolbarService } from '@nrg/components';
import { selectCustomerId, selectImpersonatedCustomerId, selectUserName, VkAuthenticationService } from '@vk/authentication';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AdminLayoutRemote } from './admin-layout.remote';
import { ADMIN_LAYOUT_REMOTE } from './admin-layout.remote.interface';
import { environment } from '../../../environments/environment';
import { RemoteRegistryService } from '../../e2e-testing/remote-registry.service';
import { select, Store } from '@ngrx/store';
import { OptimizationStatesGQL } from './admin-layout.graphql.generated';
import { selectAnythingIsLoading } from '@vk/common';

function menuItems(notificationIcon?: string): MenuItem[] {
  return [
    {
      url: '/monitor',
      title: 'Monitoring',
      icon: 'network_check'
    },
    {
      url: '/vermarktungsmanager',
      title: 'Vermarktungsmanager',
      icon: 'assessment',
      notificationIcon
    },
    {
      url: '/products',
      title: 'Meine Produkte',
      icon: 'dashboard'
    },
    {
      url: '/masterdata',
      title: 'Stammdaten',
      icon: 'device_hub'
    },
    {
      url: '/allocations',
      title: 'Speicherzuordnung',
      icon: 'account_tree'
    },
    {
      url: '/verfuegbarkeit',
      title: 'Verfügbarkeit',
      icon: 'power_off'
    },
    {
      url: '/contact',
      title: 'Kontakt',
      icon: 'contact_support'
    },
    {
      url: '/imprint',
      title: 'Impressum',
      icon: 'info'
    },
    {
      url: '/privacyprotection',
      title: 'Datenschutz',
      icon: 'security'
    },
    {
      url: '/terms-of-use',
      title: 'Nutzungsbedingungen',
      icon: 'mode_comment'
    },
    {
      url: '/disclaimer',
      title: 'Haftungsausschluss',
      icon: 'announcement'
    },
    {
      url: '/documentation',
      title: 'Hilfe',
      icon: 'info'
    },
  ];
}

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent implements OnInit {

  public menuItems$: Observable<MenuItem[]>;
  public readonly actions$: Observable<ToolbarItem[]> = this.toolbarService.actions;

  public username: Observable<string>;

  public get version(): string {
    const suffix = environment.production ? '' : '-dev';
    return `${environment.version}${suffix}`;
  }

  constructor(
    private readonly authenticationService: VkAuthenticationService,
    private readonly remoteRegistry: RemoteRegistryService,
    private readonly store: Store<unknown>,
    private readonly states: OptimizationStatesGQL,
    private readonly toolbarService: ToolbarService
  ) {

    this.toolbarService.setActions([
        { ...logoutAction, execute: () => this.onLogout() }
      ]
    );

    this.menuItems$ = this.store.pipe(
      select(selectCustomerId)
    ).pipe(
      switchMap(customerId => {
        return this.states.subscribe({ customerId });
      }),
      map(result => {
        const statesForCustomer = result.data.optimizationJobStatesForCustomer;

        if (statesForCustomer.some(it => it.state !== 'Inactive')) {
          return 'autorenew';
        } else if (statesForCustomer.some(it => !['Success', 'Unknown'].includes(it.outcome))) {
          return 'error_outline';
        } else {
          return undefined;
        }
      }),
      map(notificationIcon => menuItems(notificationIcon)),
      catchError(err => {
        return of(menuItems('error_outline'));
      })
    );

    this.username = combineLatest([
      store.pipe(select(selectUserName)),
      store.pipe(select(selectImpersonatedCustomerId))
    ]).pipe(
      map(([userName, impersonatedId]) => {
        if (impersonatedId) {
          return `${userName} [${impersonatedId}]` as string;
        } else {
          return userName as string;
        }
      })
    );
  }

  public isLoading$ = this.store.pipe(select(selectAnythingIsLoading));

  ngOnInit() {
    this.remoteRegistry.registerRemote(this, ADMIN_LAYOUT_REMOTE, new AdminLayoutRemote(this));
  }

  onLogout() {
    this.authenticationService.logout()
      .subscribe();
  }
}
