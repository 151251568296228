import { createAction, props } from '@ngrx/store';
import { User } from '../user.model';

/**
 * Action to get the current logged in user
 */
export const getLoggedInUser = createAction(
  '[User] Get Logged In User'
);

/**
 * Action used to logout the current logged in user
 */
export const logoutCurrentUser = createAction(
  '[User] Logout User'
);

/**
 * Action used to set the current logged in user
 */
export const setCurrentUser = createAction(
  '[User] Set User',
  props<{ user: Omit<User, 'sessionId'> }>()
);

export const setImpersonatedUser = createAction(
  '[User] Set Impersonated User',
  props<{
    impersonatedUserId: string
  }>()
);

/**
 * Action used to delete the current logged in user
 */
export const deleteCurrentUser = createAction(
  '[User] Delete User'
);
