import { FormSchema } from '@nrg/components';

export const geographicCoordinatesSchema: FormSchema = {
  type: 'object',
  properties: {
    latitude: {
      title: 'Geogr. Breite (WGS84)',
      type: 'number',
      warnIfEmpty: 'wird für RD2.0 benötigt.',
      precision: 6
    },
    longitude: {
      title: 'Geogr. Länge (WGS84)',
      type: 'number',
      warnIfEmpty: 'wird für RD2.0 benötigt.',
      precision: 6
    }
  },
  title: 'Geografische Koordinaten',
  required: [
    'latitude',
    'longitude'
  ],
  order: [
    'latitude',
    'longitude'
  ]
};
