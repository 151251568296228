import { Component, OnInit } from '@angular/core';
import { NrgBreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'nrg-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  constructor(private breadcrumbService: NrgBreadcrumbService) { }

  breadcrumbs$ = this.breadcrumbService.breadcrumbs$;

  ngOnInit(): void {
  }

}
