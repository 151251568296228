import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportService } from './report.service';
import { JobType } from './report.graphql.generated';

@Component({
  selector: 'vk-optimization-report-link',
  templateUrl: './report-link.component.html',
  styleUrls: ['./report-link.component.css']
})
export class ReportLinkComponent implements OnInit {

  @Input() parkId: string;
  @Input() parkName: string;
  @Input() jobyType: JobType;

  private loadingFile$$ = new BehaviorSubject(false);

  private fileName = 'report.xlsx';

  public label$ = this.loadingFile$$.pipe(map(it => it ? 'Lädt...' : this.fileName));

  constructor(private readonly reportService: ReportService) {
  }

  ngOnInit() {
    if (this.parkName) {
      this.fileName = this.parkName + '.xlsx';
    }
  }

  handleClick() {
    if (this.loadingFile$$.getValue()) {
      return;
    }
    this.loadingFile$$.next(true);
    this.reportService.generateReport(this.parkId, this.jobyType).then(blob => {
      this.loadingFile$$.next(false);
      this.openFile(blob);
    });
  }

  private openFile(blob: Blob) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = this.fileName;
    link.click();
  }
}
