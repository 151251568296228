import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToolbarItem } from './models/toolbar-item';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  private readonly _actions = new BehaviorSubject<ToolbarItem[]>([]);

  public get actions(): Observable<ToolbarItem[]> {
    return this._actions.asObservable();
  }

  public setActions(actions: ToolbarItem[]) {
    this._actions.next(actions);
  }

  public clearActions() {
    this._actions.next([]);
  }
}
