<mat-progress-bar *ngIf="isLoadingResults | async" mode="query"></mat-progress-bar>
<section class="content-wrapper">
  <form class="search">
    <mat-form-field class="example-full-width">
      <input (input)="onQueryChange($event.target.value)" matInput
             aria-label="Geben Sie einen Suchbegriff ein"
             placeholder="Suchen"/>
    </mat-form-field>
  </form>
  <section class="search-tree">
    <ng-container *ngIf="showResults | async">
      <nrg-search-result-tree [result]="searchResult | async" [query]="searchQuery$ | async "></nrg-search-result-tree>
    </ng-container>
    <ng-container *ngIf="showNoResultsMessage | async">
      <div class="message">{{noResultsMessage | async}}</div>
    </ng-container>
  </section>
  <div class="error" *ngIf="showErrorMessage | async">
    <div class="error-message">{{errorMessage | async}}</div>
    <button mat-flat-button color="warn" (click)="onRetry()">Erneut laden</button>
  </div>
</section>

