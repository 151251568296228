import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  transform(value: string): string {

    switch (value) {
      case 'Direktvermarktung + Fahrplanmanagement':
      case 'DIREKTVERMARKTUNG_FAHRPLANMANAGEMENT': {
        return 'Direktvermarktung EEG und Fahrplanoptimierung (DVFP)';
      }
      case 'Direktvermarktung':
      case 'DIREKTVERMARKTUNG': {
        return 'Direktvermarktung EEG (DV)';
      }
      case 'Direktvermarktung + Fahrplanmanagement + Intraday':
      case 'FAHRPLANMANAGEMENT_INTRADAY': {
        return 'Direktvermarktung EEG und Fahrplanoptimierung und Intradayoptimierung (DVFPID) ';
      }
      case 'None': {
        return 'keins';
      }
      case 'Fahrplanmanagement': {
        return 'FP';
      }
      case 'DirektvermarktungGeplant': {
        return 'DV (geplant)';
      }
      case 'DirektvermarktungUngeplant': {
        return 'DV (ungeplant)';
      }
      default: {
        return value;
      }
    }
  }
}



