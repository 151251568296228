import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nrg-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class NrgButtonComponent {

  @Input()
  type = '';

  @Output()
  pressed: EventEmitter<void> = new EventEmitter<void>();

  onClick(): void {
    this.pressed.emit();
  }
}
