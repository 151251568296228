import { ApolloClientOptions, concat, DefaultOptions, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { VkAuthenticationService } from '@vk/authentication';
import { createMapDataLink } from './map-data-link';
import { createErrorLink } from './error-link';
import { createAuthLink } from './auth-link';
import { createSplitLink } from './http-and-ws-link';
import { VkCommonState } from '../vk-common.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [{
    provide: APOLLO_OPTIONS,
    useFactory: (httpLinkCreator: HttpLink, authenticationService: VkAuthenticationService, store: Store<VkCommonState>) => {

      const splitLink = createSplitLink(httpLinkCreator);
      const authMiddleware = createAuthLink(authenticationService);
      const mapData = createMapDataLink();
      const errorLink = createErrorLink(store);

      const defaultOptions: DefaultOptions = {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        }
      };

      return {
        cache: new InMemoryCache({
          addTypename: false,
        }),
        link: concat(errorLink, concat(mapData, concat(authMiddleware, splitLink))),
        defaultOptions,
      } as ApolloClientOptions<NormalizedCacheObject>;
    },
    deps: [HttpLink, VkAuthenticationService, Store],
  }]
})
export class VkGraphqlModule {
}
