import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { OptimizationResultChartDataSource } from './optimization-result-chart.data-source';
import { JobType, LoadResultGQL, ParkWithRelationShipsByIdGQL } from './optimization-result-chart.graphql.generated';

@Component({
  selector: 'vk-optimization-result-chart',
  templateUrl: './optimization-result-chart.component.html',
  styleUrls: ['./optimization-result-chart.component.css']
})
export class OptimizationResultChartComponent implements OnInit, OnDestroy {

  private readonly parkId$$ = new BehaviorSubject<string | undefined>(undefined);
  private readonly jobType$$ = new ReplaySubject<JobType>(1);

  private readonly componentWillBeDestroyed$$ = new Subject();

  @Input()
  public set parkId(value: string) {
    this.parkId$$.next(value);
  }

  @Input()
  public set jobType(value: JobType) {
    this.jobType$$.next(value);
  }

  constructor(
    private readonly store: Store<unknown>,
    private readonly load: LoadResultGQL,
    private readonly parkWithRelationships: ParkWithRelationShipsByIdGQL
  ) {
  }

  public dataSource = new OptimizationResultChartDataSource(
    this.store,
    this.parkId$$,
    this.jobType$$,
    this.load,
    this.parkWithRelationships
  );

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.componentWillBeDestroyed$$.next();
    this.componentWillBeDestroyed$$.complete();
  }

}
