/**
 * removes a specific element from the given array
 * @param element The element which should be removed from the given array
 * @param elements The array from which the element should be removed
 */
export function removeElement<TElement>(element: TElement, elements: TElement[]): TElement[] {
  return elements.reduce<TElement[]>((acc, currentElement) => {
    if (currentElement !== element) {
      acc.push(currentElement);
    }

    return acc;
  }, []);
}

export function fillArray<T>(array: T[], values: T[], index: number): T[] {
    const startArr = array.slice(0, index);
    const endArr = array.slice(index, array.length);

    return startArr.concat(values).concat(endArr);
}
