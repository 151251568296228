import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Pool } from '../products/products-overview.graphql.generated';

@Component({
  selector: 'vk-pool-selection',
  templateUrl: './pool-selection.component.html',
  styleUrls: ['./pool-selection.component.scss']
})
export class PoolSelectionComponent implements OnInit, OnDestroy {
  public poolIdControl = new FormControl('DV');
  private componentWillBeDestroyed = new Subject();
  public showAllOption = false;

  @Input() set withAllOption(enableAllOption: boolean) {
    this.showAllOption = enableAllOption;
    if (enableAllOption) {
      this.poolIdControl.setValue('ALL');
    } else {
      this.poolIdControl.setValue('DV');
    }
  }

  @Output() public poolSelected = new EventEmitter<Pool | undefined>();

  constructor() {
  }

  ngOnInit(): void {
    this.poolIdControl.valueChanges.pipe(
      map(poolString => mapToPool(poolString)),
      takeUntil(this.componentWillBeDestroyed)
    ).subscribe(
      pool => this.poolSelected.next(pool)
    );
  }

  ngOnDestroy() {
    this.componentWillBeDestroyed.next();
    this.componentWillBeDestroyed.complete();
  }
}

export function mapToPool(pool: string): Pool | undefined {
  switch (pool) {
    case ('DV'): {
      return Pool.Dv;
    }
    case('DVFP'): {
      return Pool.Dvfp;
    }
    case('Test-Pool'): {
      return Pool.Nopool;
    }
    default: {
      return undefined;
    }
  }
}
