import { Result } from '@nrg/components';
import { CustomerTree, ParkChildren, ParkUnit, ParkWithRelationShips } from '../master-data.graphql.generated';

export interface MasterDataUrlMappingStrategy {
  urlFromVK(): string;
  urlFromCustomer(customerId: string): string;
  urlFromPark(parkId: string, customerId: string): string;
  urlFromMalo(maloId: string): string;
  urlFromCustomerPremisesEquipment(customerPremisesEquipmentId: string, customerId?: string): string;
  urlFromTechnicalUnit(technicalUnitId: string, customerId: string): string;
  urlFromGasStorage(gasStorageId: string): string;
  urlFromHeatStorage(heatStorageId: string): string;
}

export interface ResultType {
  id: string;
  customerId?: string; // TODO: not optional
  parkId?: string;
  name: string;
}


export function buildAssetTree(customerTrees: Array<CustomerTree>, urlMappingStrategy: MasterDataUrlMappingStrategy, isPortal: boolean): Result[] {
  const customerNodes = buildCustomerTree(customerTrees, urlMappingStrategy);
  if (isPortal) {
    return customerNodes;
  }
  return [
      {
        title: 'Virtuelles Kraftwerk',
        label: 'V',
        url: urlMappingStrategy.urlFromVK(),
        children: customerNodes
      }
    ];
}

function buildCustomerTree(customerTrees: Array<CustomerTree>, urlMappingStrategy: MasterDataUrlMappingStrategy): Result[] {
  return customerTrees.map(customerAssetTree => {
      const customerId = customerAssetTree.customerId;
      const customerNode: Result = {
        title: customerAssetTree.customerId,
        label: 'K',
        url: urlMappingStrategy.urlFromCustomer(customerId),
        children: []
      };

      const parkTreesForCustomer = mapToParkTreesForCustomer(customerAssetTree.parksWithRelationShips, customerId, urlMappingStrategy);
      const unconnectedAssetTreeForCustomer = mapToUnconnectedAssetsTree(customerAssetTree.disconnectedAssets, customerId, urlMappingStrategy);

      if (unconnectedAssetTreeForCustomer != null) {
        return { ...customerNode, children: parkTreesForCustomer.concat(unconnectedAssetTreeForCustomer) };
      }
      return { ...customerNode, children: parkTreesForCustomer };
    }
  );
}

function mapToParkTreesForCustomer(parksWithRelationships: ParkWithRelationShips[], customerId: string, urlMappingStrategy: MasterDataUrlMappingStrategy): Result[] {
  return parksWithRelationships.map(parkWithRelationShip => {
    const park = convertPark(parkWithRelationShip, customerId, urlMappingStrategy);
    const {
      technicalUnits: technicalUnitsInPark,
      gasStorages: gasStoragesInPark,
      heatStorages: heatStoragesInPark,
      malos: malosInPark,
      customerPremisesEquipments: cpesInPark
    } = parkWithRelationShip;

    return addChildrenToParent(park, technicalUnitsInPark, gasStoragesInPark, heatStoragesInPark, malosInPark!!, cpesInPark ?? [], customerId, urlMappingStrategy);
  });
}

function mapToUnconnectedAssetsTree(parkChildren: ParkChildren, customerId: string, urlMappingStrategy: MasterDataUrlMappingStrategy): Result | undefined {
  if (hasUnconnectedAssets(parkChildren)) {
    const unconnectedAssets: Result = {
      title: 'Nicht verbunden',
      label: 'N',
      url: urlMappingStrategy.urlFromCustomer(customerId),
      children: []
    };

    const { technicalUnits, cpes, gasStorages, heatStorages, malos } = parkChildren;
    return addChildrenToParent(unconnectedAssets, technicalUnits, gasStorages, heatStorages, malos, cpes, customerId, urlMappingStrategy);
  }
  return undefined;
}

function hasUnconnectedAssets(parkChildren: ParkChildren): boolean {
  return (parkChildren.technicalUnits.length > 0) || (parkChildren.gasStorages.length > 0) || (parkChildren.heatStorages.length > 0) || (parkChildren.malos.length > 0) || (parkChildren.cpes.length > 0);
}

function addChildrenToParent(parent: Result, technicalUnits: ParkUnit[], gasStorages: ParkUnit[], heatStorages: ParkUnit[], malos: ParkUnit[], cpes: ParkUnit[], customerId: string, urlMappingStrategy: MasterDataUrlMappingStrategy): Result {
  const technicalUnitResults: Result[] = technicalUnits.map(technicalUnit => {
    return convertTechnicalUnit(technicalUnit, customerId, urlMappingStrategy);
  });

  const gasStorageResults: Result[] = gasStorages.map(gasStorage => {
      return convertGasStorage(gasStorage, customerId, urlMappingStrategy);
    }
  );

  const heatStorageResults: Result[] = heatStorages.map(heatStorage => {
      return convertHeatStorage(heatStorage, customerId, urlMappingStrategy);
    }
  );

  const maloResults: Result[] = malos!.map(malo => {
      return convertMalo(malo, customerId, urlMappingStrategy);
    }
  );

  const cpeResults: Result[] = cpes.map(cpe => {
      return convertCustomerPremisesEquipment(cpe, customerId, urlMappingStrategy);
    }
  );

  const allParkChildren = technicalUnitResults.concat(gasStorageResults).concat(heatStorageResults).concat(maloResults).concat(cpeResults);

  return {
    ...parent,
    children: parent.children.concat(allParkChildren)
  };
}


function convertPark(park: ParkWithRelationShips, customerId: string, urlMappingStrategy: MasterDataUrlMappingStrategy): Result {
  return {
    title: park.name,
    label: 'P',
    url: urlMappingStrategy.urlFromPark(park.id, customerId),
    children: []
  };
}

function convertTechnicalUnit(technicalUnit: ParkUnit, customerId: string, urlMappingStrategy: MasterDataUrlMappingStrategy): Result {
  return {
    title: technicalUnit.name,
    label: 'TE',
    url: urlMappingStrategy.urlFromTechnicalUnit(technicalUnit.id, customerId),
    children: []
  };
}

function convertCustomerPremisesEquipment(cpe: ParkUnit, customerId: string,
                                          urlMappingStrategy: MasterDataUrlMappingStrategy): Result {
  return {
    title: cpe.name,
    label: 'CPE',
    url: urlMappingStrategy.urlFromCustomerPremisesEquipment(cpe.id, customerId),
    children: []
  };
}

function convertGasStorage(gasStorage: ParkUnit, customerId: string, urlMappingStrategy: MasterDataUrlMappingStrategy): Result {
  return {
    title: gasStorage.name,
    label: 'GS',
    url: urlMappingStrategy.urlFromGasStorage(gasStorage.id),
    children: []
  };
}

function convertHeatStorage(heatStorage: ParkUnit, customerId: string, urlMappingStrategy: MasterDataUrlMappingStrategy): Result {
  return {
    title: heatStorage.name,
    label: 'WS',
    url: urlMappingStrategy.urlFromHeatStorage(heatStorage.id),
    children: []
  };
}

function convertMalo(malo: ParkUnit, customerId: string, urlMappingStrategy: MasterDataUrlMappingStrategy): Result {
  return {
    title: malo.name,
    label: 'M',
    url: urlMappingStrategy.urlFromMalo(malo.id),
    children: []
  };
}
