import { NgModule } from '@angular/core';
import { RouterTestingModule } from '@angular/router/testing';
import { KeycloakService } from 'keycloak-angular';
import { VkAuthenticationModule } from '../authentication.module';
import { KeycloakMockService } from './keycloak.mock.service';


@NgModule({
  declarations: [],
  imports: [
    VkAuthenticationModule,
    RouterTestingModule
  ],
  providers: [
    { provide: KeycloakService, useClass: KeycloakMockService }
  ]
})
export class VkAuthenticationTestingModule {
}
