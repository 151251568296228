import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'nrg-layout-content',
  templateUrl: './layout-content.component.html',
  styleUrls: ['./layout-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutContentComponent {

}
