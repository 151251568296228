<mat-progress-bar mode="indeterminate"></mat-progress-bar>
<ul *ngIf="showStepDescriptions">

  <div *ngFor="let loadingStep of loadingSteps | keyvalue: orderAsIs">

    <div fxLayout="row">
      <li [class.active]="loadingStep.key===currentLoadingStep">{{loadingStep.value}}</li>
      <div *ngIf="isStepDone(loadingStep.key)">
        <mat-icon class="loading-done">check</mat-icon>
      </div>
    </div>

  </div>
</ul>
<div *ngIf="!showStepDescriptions">Lade Daten...</div>

