import { ChartSeries, ChartSeriesType } from '../chart-series';
import { Block, isBlockEqual, translateBlocksToChartCoordinateSystem, TranslatedBlock } from './block';
import { Dimensions } from '../../models/dimensions';
import { AxisRange } from '../../models/axis';
import { DragAndDrop } from './drag-and-drop';

type DragAndDropBlock = Block & { selectionIndex: number; };

export class BlockChartSeries extends ChartSeries<Block, TranslatedBlock> {
  private readonly dragAndDrop = new DragAndDrop<DragAndDropBlock>();
  public readonly type: ChartSeriesType = 'Schedule';

  public render(layerDimensions: Dimensions, xAxisRange: AxisRange, yAxisRange: AxisRange, data: Block[], needsRedraw: boolean): TranslatedBlock[] {
    return translateBlocksToChartCoordinateSystem(
      data,
      xAxisRange.from,
      xAxisRange.to,
      yAxisRange.from,
      yAxisRange.to,
      layerDimensions.width,
      layerDimensions.height
    );
  }

  public isDataEqual(lhs: Block, rhs: Block): boolean {
    return isBlockEqual(lhs, rhs);
  }

  protected isSelected(lhs: Block, rhs: Block): boolean {
    return lhs.id === rhs.id;
  }

  public cloneData(block: Block): Block {
    return {
      id: block.id,
      value: block.value,
      from: block.from,
      to: block.to,
    };
  }

  public onDragStart(selectionIndex: number, fromPos: number, axisWidth: number, axisFrom: number, axisTo: number): void {
    const block = this.getData(selectionIndex);

    if (block != null) {
      this.dragAndDrop.start(fromPos, axisWidth, axisFrom, axisTo, { ...block, selectionIndex });
    }
  }

  public onDrag(toPos: number): Block | undefined {
    const block = this.dragAndDrop.drag(toPos);

    if (block == null) {
      return undefined;
    }

    const update = this.cloneData(block);

    this.updateData(block.selectionIndex, update);

    return update;
  }

  public onDragEnd(): void {
    this.dragAndDrop.stop();
  }
}
