<h1 mat-dialog-title>
  <ng-container [ngSwitch]="config.type">
    <mat-icon *ngSwitchCase="'success'" class="success">check_circle_outline</mat-icon>
    <mat-icon *ngSwitchCase="'fail'" class="fail">error_outline</mat-icon>
    <mat-icon *ngSwitchCase="'warning'" class="warn">warning</mat-icon>
  </ng-container>
  <span *ngIf="hasTitle" class="title">{{titleDisplay}}</span>
</h1>

<mat-dialog-content *ngIf="hasMessage" class="mat-typography">{{config.message}}</mat-dialog-content>

<mat-dialog-actions *ngIf="hasActions">
  <button *ngIf="config.cancelButtonLabel"
          (click)="onCancelClick()"
          mat-raised-button>
    {{config.cancelButtonLabel}}
  </button>
  <button *ngIf="config.acceptButtonLabel"
          (click)="onAcceptClick()"
          mat-raised-button
          color="warn">
    {{config.acceptButtonLabel}}
  </button>
</mat-dialog-actions>
