import { MonoEntityFormDataSource } from '../entity-form-data-source';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FormSchema } from '@nrg/components';
import { Permission } from '@vk/authentication';
import { createTechnicalUnitSchema } from '../../schema/technical-unit/create-technichal-unit.schema';
import { Injectable } from '@angular/core';
import { CreateTechnicalUnitGQL } from './technicalUnit.graphql.generated';
import { VkCommonState } from '@vk/common';

export interface TechnicalUnitCreateData {
  obereNennleistung: number;
  minLeistung: number;
  flexMotor: boolean;
  anzulegenderWert?: number;
}

const correlationId = 'CreateTechnicalUnitFormDataSource';

@Injectable({
  providedIn: 'any'
})
export class CreateTechnicalUnitFormDataSource extends MonoEntityFormDataSource<TechnicalUnitCreateData> {

  readonly writePermission = Permission.ASSETS_WRITE;
  readonly entityType = 'CreateTechnicalUnitFormDataSource' as any;
  readonly schema: FormSchema = createTechnicalUnitSchema;

  constructor(private readonly create: CreateTechnicalUnitGQL) {
    super(correlationId);
  }

  selectEntityById(id: string, store: Store<VkCommonState>): Observable<TechnicalUnitCreateData> {
    throw new Error('Not implemented');
  }

  loadEntity(id: string, store: Store<VkCommonState>): void {
    throw new Error('Not implemented');
  }

  saveEntity(technicalUnit: TechnicalUnitCreateData, store: Store<VkCommonState>, customerId: string): void {
    throw new Error('Not implemented');
  }

  createEntity(technicalUnitData: TechnicalUnitCreateData, customerId: string, store: Store<VkCommonState>): void {

    this.create.mutate({
      data: {
        obereNennleistung: technicalUnitData.obereNennleistung,
        minLeistung: technicalUnitData.minLeistung,
        anzulegenderWert: technicalUnitData.anzulegenderWert,
        flexMotor: technicalUnitData.flexMotor,
        kundenId: customerId
      }
    }).subscribe();
  }

  deleteEntity(id: string, store: Store<VkCommonState>, customerId: string): void {
    throw new Error('Not implemented');
  }

  makeTemplate(parentId: string): TechnicalUnitCreateData {
    return {
      flexMotor: true
    } as any;
  }
}
