import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EntityAllocationDataSource } from './data-source/entity-allocation-data-source';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AllocationEntity, EntityAllocations } from '@nrg/components';
import { VkAuthenticationState } from '@vk/authentication';

@Component({
  selector: 'vk-entity-allocation-grid',
  templateUrl: './entity-allocation-grid.component.html',
  styleUrls: ['./entity-allocation-grid.component.scss']
})
export class EntityAllocationGridComponent implements OnInit, OnDestroy {

  private readonly parentId$ = new ReplaySubject<string>(1);
  private readonly componentWillBeDestroyed$ = new Subject();
  public readonly$ = new BehaviorSubject(false);

  @Input() dataSource!: EntityAllocationDataSource;

  @Input() set parentId(value: string) {
    this.parentId$.next(value);
  }

  rowEntities$ = new ReplaySubject<AllocationEntity[]>(1);

  columnEntities$ = new ReplaySubject<AllocationEntity[]>(1);

  allocations$ = new ReplaySubject<EntityAllocations>(1);

  constructor(private store: Store<VkAuthenticationState>) {
  }

  ngOnInit() {

    this.dataSource.selectRowEntities().pipe(
      takeUntil(this.componentWillBeDestroyed$)
    ).subscribe(this.rowEntities$);

    this.dataSource.selectColumnEntities().pipe(
      takeUntil(this.componentWillBeDestroyed$)
    ).subscribe(this.columnEntities$);

    this.dataSource.selectEntityAllocations().pipe(
      takeUntil(this.componentWillBeDestroyed$)
    ).subscribe(this.allocations$);

    this.dataSource.selectIsReadonly().pipe(
      takeUntil(this.componentWillBeDestroyed$),
    ).subscribe(this.readonly$);

    this.parentId$.pipe(take(1)).subscribe(parentId => {
      this.dataSource.loadEntities(parentId);
    });
  }

  ngOnDestroy(): void {
    this.dataSource.unload();
    this.componentWillBeDestroyed$.next();
    this.componentWillBeDestroyed$.complete();
  }

  handleEntityAllocationsChanged(event: { checked: boolean, rowEntity: AllocationEntity, columnEntity: AllocationEntity }) {
    this.dataSource.updateEntityAllocation(event.checked, event.rowEntity, event.columnEntity);
  }
}
