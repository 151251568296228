import { MonoEntityFormDataSource } from './entity-form-data-source';
import { FormSchema } from '@nrg/components';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { MaloFormData, maloSchema } from '../schema/malo.schema';
import { Permission } from '@vk/authentication';
import { IS_PORTAL } from '../entity-form.injection-tokens';
import { Inject, Injectable } from '@angular/core';
import { CreateMaloGQL, MaloByIdGQL, MaloByIdSubscription, UpdateMaloGQL } from './malo.graphql.generated';
import { first, map } from 'rxjs/operators';
import { VkLoadingHandler } from '@vk/common';
import { ControlAreaEnum, MaloCreateInput, MaloInput, VoltageLevelEnum } from './park.graphql.generated';

const correlationId = 'MaloFormDataSource';

@Injectable({
  providedIn: 'any'
})
export class MaloFormDataSource extends MonoEntityFormDataSource<MaloFormData> {

  readonly writePermission = Permission.ASSETS_WRITE;
  readonly alternativeWritePermission = Permission.ASSETS_WRITE_CUSTOMER;

  readonly state = new ReplaySubject<MaloFormData>(1);

  constructor(
    @Inject(IS_PORTAL) private isCustomer: boolean,
    private readonly load: MaloByIdGQL,
    private readonly update: UpdateMaloGQL,
    private readonly create: CreateMaloGQL,
    private readonly loadingHandler: VkLoadingHandler,
  ) {
    super(correlationId);
  }

  readonly entityType = 'MaloFormDataSource' as any;
  readonly schema: FormSchema = maloSchema(this.isCustomer);

  loadEntity(id: string, store: Store<unknown>): void {
    this.state.next({} as any);
    this.load.subscribe({ id }).pipe(
      first(),
      this.loadingHandler.untilFirst('ParkGQL', this.correlationId),
      map(result => this.mapData(result.data!.maloById))
    ).subscribe(result => this.state.next(result));
  }

  selectEntityById(id: string, store: Store<unknown>): Observable<MaloFormData> {
    return this.state.asObservable();
  }

  saveEntity(entity: MaloFormData, store: Store<unknown>): void {
    const data = this.mapFormData(entity);
    this.update.mutate({ malo: data }).subscribe();
  }

  createEntity(entity: MaloFormData, customerId: string, store: Store<unknown>): void {
    const data = this.mapFormDataForCreate(entity, customerId);
    this.create.mutate({ malo: data }).subscribe();
  }

  deleteEntity(id: string, store: Store<unknown>): void {
  }

  private mapData(data: MaloByIdSubscription['maloById']): MaloFormData {
    return {
      protocolId: data.protocolId,
      anlagenNrEinspeisemenge: data.anlagenNrEinspeisemenge,
      anlagenNrGebuehren: data.anlagenNrGebuehren,
      bemessungsleistung: data.bemessungsleistung,
      accountingGrid: data.accountingGrid,
      datenzaehlpunkt: data.datenzaehlpunkt,
      maloId: data.maloId,
      controlArea: translateControlArea(data.controlArea),
      voltageLevel: this.translateVoltageLevel(data.voltageLevel),
      measurementLocationIds: data.measurementLocationIds ?? [],
      abschaltungExportEnabled: data.abschaltungExportEnabled
    };
  }

  private mapFormData(data: MaloFormData): MaloInput {
    return {
      abschaltungExportEnabled: data.abschaltungExportEnabled,
      parkId: null,
      customerId: 'ignored',
      protocolId: data.protocolId,
      anlagenNrEinspeisemenge: data.anlagenNrEinspeisemenge,
      anlagenNrGebuehren: data.anlagenNrGebuehren,
      bemessungsleistung: data.bemessungsleistung,
      accountingGrid: data.accountingGrid,
      datenzaehlpunkt: data.datenzaehlpunkt,
      maloId: data.maloId,
      controlArea: translateControlAreaBack(data.controlArea),
      measurementLocationIds: data.measurementLocationIds ?? [],
      voltageLevel: this.translateVoltageLevelBack(data.voltageLevel)
    };
  }

  private mapFormDataForCreate(data: MaloFormData, customerId: string): MaloCreateInput {
    return {
      customerId,
      abschaltungExportEnabled: data.abschaltungExportEnabled,
      anlagenNrEinspeisemenge: data.anlagenNrEinspeisemenge,
      anlagenNrGebuehren: data.anlagenNrGebuehren,
      bemessungsleistung: data.bemessungsleistung,
      accountingGrid: data.accountingGrid,
      datenzaehlpunkt: data.datenzaehlpunkt,
      maloId: data.maloId,
      controlArea: translateControlAreaBack(data.controlArea),
      measurementLocationIds: data.measurementLocationIds ?? [],
      voltageLevel: this.translateVoltageLevelBack(data.voltageLevel)
    };
  }

  private translateVoltageLevel(level?: VoltageLevelEnum | null): undefined | null | 'Hochspannung' | 'Höchstspannung' | 'Mittelspannung' | 'Niederspannung' {
    switch (level) {
      case VoltageLevelEnum.High:
        return 'Hochspannung';
      case VoltageLevelEnum.ExtraHigh:
        return 'Höchstspannung';
      case VoltageLevelEnum.Low:
        return 'Niederspannung';
      case VoltageLevelEnum.Medium:
        return 'Mittelspannung';
      default:
        return null;
    }
  }

  private translateVoltageLevelBack(level: undefined | null | 'Hochspannung' | 'Höchstspannung' | 'Mittelspannung' | 'Niederspannung'): VoltageLevelEnum | null {
    switch (level) {
      case 'Hochspannung':
        return VoltageLevelEnum.High;
      case 'Höchstspannung':
        return VoltageLevelEnum.ExtraHigh;
      case 'Niederspannung':
        return VoltageLevelEnum.Low;
      case 'Mittelspannung':
        return VoltageLevelEnum.Medium;
      default:
        return null;
    }
  }
}

export function translateControlAreaBack(level: undefined | null | 'TransnetBW' | 'Tennet' | 'Amprion' | '50Hertz' | 'Flensburg'): ControlAreaEnum | null {
  switch (level) {
    case 'TransnetBW':
      return ControlAreaEnum.Opt_10YdeEnbwN;
    case 'Tennet':
      return ControlAreaEnum.Opt_10YdeEon_1;
    case 'Amprion':
      return ControlAreaEnum.Opt_10YdeRwenetI;
    case '50Hertz':
      return ControlAreaEnum.Opt_10YdeVe_2;
    case 'Flensburg':
      return ControlAreaEnum.Opt_10Yflensburg_3;
    default:
      return null;
  }
}

export function translateControlArea(level: ControlAreaEnum | null | undefined): null | 'TransnetBW' | 'Tennet' | 'Amprion' | '50Hertz' | 'Flensburg' {
  switch (level) {
    case ControlAreaEnum.Opt_10YdeEnbwN:
      return 'TransnetBW';
    case ControlAreaEnum.Opt_10YdeEon_1:
      return 'Tennet';
    case ControlAreaEnum.Opt_10YdeRwenetI:
      return 'Amprion';
    case ControlAreaEnum.Opt_10YdeVe_2:
      return '50Hertz';
    case ControlAreaEnum.Opt_10Yflensburg_3:
      return 'Flensburg';
    default:
      return null;
  }
}
