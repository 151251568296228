import { DataPoint } from '../../models/data-point';
import { calculateStepSize, translateXToChartCoordinateSystem, translateYToChartCoordinateSystem } from '../../models/coordinates';

// translated x, translated y, original x, original y
export type TranslatedDataPoint = [number, number, number, number];

// TODO: find solution to use DataPoint[] instead of TranslatedDataPoint[]
export function createLinePath(currentPoints: TranslatedDataPoint[], previousPointCount: number = 0, svgPath: string = ''): string {
  for (let index = previousPointCount; index < currentPoints.length; ++index) {
    const [x, y] = currentPoints[index];

    if (index === 0) {
      svgPath = `M ${x} ${y}`;
    } else if (index === 1) {
      svgPath += ` L ${x} ${y}`;
    } else {
      svgPath += ` ${x} ${y}`;
    }
  }

  return svgPath;
}

export interface PointSelection {
  startIndex: number;
  points: TranslatedDataPoint[];
}

export function translatePointsToChartCoordinateSystem(points: DataPoint[], fromX: number, toX: number, fromY: number, toY: number, axisWidth: number, axisHeight: number): PointSelection {
  const stepSizeX = calculateStepSize(axisWidth, fromX, toX);
  const stepSizeY = calculateStepSize(axisHeight, fromY, toY);
  const translatedPoints: TranslatedDataPoint[] = [];
  let currentIndex = 0;
  let startIndex = 0;

  for (const point of points) {
    const [x, y] = point;

    // just draw points within range
    if (x >= fromX && x <= toX) {
      startIndex = startIndex > 0 ? startIndex : currentIndex;
      const x0 = translateXToChartCoordinateSystem(fromX, stepSizeX)(x);
      const y0 = translateYToChartCoordinateSystem(y, fromY, stepSizeY, axisHeight);

      translatedPoints.push([x0, y0, x, y]);

    } else if (x > toX) {
      break;
    }

    ++currentIndex;
  }

  return { startIndex, points: translatedPoints };
}
