import { FormObjectProperty } from '@nrg/components';

export const customerSchema: FormObjectProperty = {
  title: 'Kunde/Geschäftspartner',
  type: 'object',
  properties: {
    customerNumber: {
      type: 'number',
      title: 'Kundennummer'
    },
    companyName: {
      type: 'string',
      title: 'Firmierung'
    },
    additionalCompanyName1: {
      type: 'string',
      title: 'Firmierung Zusatz 1'
    },
    additionalCompanyName2: {
      type: 'string',
      title: 'Firmierung Zusatz 2'
    },
    emailAddress: {
      title: 'E-Mail',
      type: 'string'
    },
    phoneNumber1: {
      title: 'Telefon 1',
      type: 'string'
    },
    phoneNumber2: {
      title: 'Telefon 2',
      type: 'string'
    },
    faxNumber: {
      title: 'Fax',
      type: 'string'
    },
    street: {
      title: 'Straße',
      type: 'string'
    },
    houseNumber: {
      title: 'Hausnummer',
      type: 'string'
    },
    additionalAddress: {
      title: 'Zusatz',
      type: 'string'
    },
    postalCode: {
      title: 'Postleitzahl',
      type: 'string'
    },
    city: {
      title: 'Stadt',
      type: 'string'
    },
    country: {
      title: 'Land',
      type: 'string'
    },
    externalContractPerson: {
      title: 'Außendienstmitarbeiter',
      type: 'string'
    },
    internalContactPerson: {
      title: 'Innendienstmitarbeiter',
      type: 'string'
    },
  },
  required: ['companyName']
};
