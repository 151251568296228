const indices = new Map<string, number>();

// TODO: find another solution, see Window.Crypto or https://github.com/makeable/uuid-v4.js/blob/master/uuid-v4.js
export function generateUniqueId(prefix: string): string {
  const previousIndex = indices.get(prefix) ?? -1;
  const index = previousIndex + 1;

  indices.set(prefix, index);

  return `${prefix}-${index}`;
}
