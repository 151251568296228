import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NrgButtonComponent } from './button.component';

@NgModule({
  declarations: [NrgButtonComponent],
  imports: [
    CommonModule
  ],
  exports: [NrgButtonComponent]
})

export class NrgButtonModule { }
