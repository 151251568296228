import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllocationGridComponent } from './allocation-grid.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [AllocationGridComponent],
  exports: [AllocationGridComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule
  ]
})
export class NrgAllocationGridModule { }
