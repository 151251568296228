import * as moment_ from 'moment-timezone';
import { Moment } from 'moment-timezone';

// Hour-Deadline for Planningintervals
export const deadlineFpMorning = 10;

const moment = moment_;

export function getMomentFromDate(date: Date): Moment {
  moment.locale('de');
  return moment(date).tz('Europe/Amsterdam');
}

export function areDatesWithin(minutes: number, aDate: Date, anotherDate: Date): boolean {
  const timeDeltaPotentiallyNegative = (aDate.getTime() - anotherDate.getTime()) / 60000;
  const timeDelta = Math.abs(timeDeltaPotentiallyNegative);

  return timeDelta < minutes;

}

export function normalizeDate(date: Date): Date {
  return getMomentFromDate(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
}

export interface Weekday {
  readonly name: string;
  readonly value: number;
}

export function getPlanningDays(startingFromToday: boolean = false, currentDate: Date = new Date()): Array<Weekday> {

  const now = getMomentFromDate(currentDate);

  let days = startingFromToday ? new Set([0, 1]) : new Set([]);

  if (now.hour() < deadlineFpMorning) {
    days = new Set([...days, 1, 2, 3, 4, 5, 6, 7]);
  } else {
    days =  new Set([...days, 2, 3, 4, 5, 6, 7, 8]);
  }

  return [...days].map(day => {
    const date = moment(now).add(day, 'days');

    return {
      name: date.format('dddd'),
      value: date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().getTime()
    };
  });
}

export function getPlanningInterval(limit: 'min' | 'min-intraday' | 'max', currentDate = new Date()): number {
  const now = getMomentFromDate(currentDate);

  switch (limit) {
    case 'min': {
      if (now.hour() < deadlineFpMorning) {
        return moment(now).add(1, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf();
      } else {
        return moment(now).add(2, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf();
      }
    }
    case 'min-intraday': {
      return moment(now).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf();
    }
    case 'max': {
      if (now.hour() < deadlineFpMorning) {
        return moment(now).add(8, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf();
      } else {
        return moment(now).add(9, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf();
      }
    }
  }
}
