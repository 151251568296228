import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoolSelectionComponent } from './pool-selection.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [PoolSelectionComponent],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [PoolSelectionComponent]
})
export class PoolSelectionModule { }
