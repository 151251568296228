import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { VkAuthenticationService } from './authentication.service';
import { AuthenticationEffects } from './store/authentication.effects';
import { userReducer } from './store/authentication.reducer';
import { User } from './user.model';
import { authenticationStoreFeatureName } from './authentication.state';

export interface VkAuthenticationState {
  currentUser: User;
}

const FEATURE_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<VkAuthenticationState>>('authentication reducer');

export const authenticationFeatureReducers: ActionReducerMap<VkAuthenticationState> = {
  currentUser: userReducer
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(authenticationStoreFeatureName, FEATURE_REDUCER_TOKEN),
    EffectsModule.forFeature([AuthenticationEffects]),
  ],
  providers: [
    VkAuthenticationService,
    {
      provide: FEATURE_REDUCER_TOKEN,
      useValue: authenticationFeatureReducers,
    },
  ]
})
export class VkAuthenticationModule { }
