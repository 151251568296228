import { Injectable } from '@angular/core';
import {
  PriceCalculationDvfpGQL,
  PriceCalculationDvfpidGQL,
  PriceCalculationDvGQL,
  SavePriceCalculationGQL
} from './price-calculations-data-source.graphql.generated';
import { PriceCalculationDVFormDataSource } from './price-calculations-dv-data-source';
import { PriceCalculationDVFPFormDataSource } from './price-calculations-dvfp-data-source';
import { OfferFromDataGQL, SaveOfferFormDataGQL } from './offer-data-source.graphql.generated';
import { OfferFormDataSource } from './offer-data-source';
import { SaveSellingOpportunityFormDataGQL, SellingOpportunityFormDataGQL } from './selling-opportunity-data-source.graphql.generated';
import { SellingOpportunityFormDataSource } from './selling-opportunity-data-source';
import { PriceCalculationDVFPIDFormDataSource } from './price-calculations-dvfpid-data-source';

@Injectable({ providedIn: 'any' })
export class SalesHierarchyDataSourceFactory {
  constructor(
    private readonly priceCalculationDv: PriceCalculationDvGQL,
    private readonly savePriceCalculation: SavePriceCalculationGQL,
    private readonly priceCalculationDvfp: PriceCalculationDvfpGQL,
    private readonly priceCalculationDvfpid: PriceCalculationDvfpidGQL,
    private readonly offerFromData: OfferFromDataGQL,
    private readonly saveOfferFormData: SaveOfferFormDataGQL,
    private readonly sellingOpportunityFormData: SellingOpportunityFormDataGQL,
    private readonly saveSellingOpportunityFormData: SaveSellingOpportunityFormDataGQL
  ) {
  }

  createPriceCalculationDVFormDataSource(correlationId: string) {
    return new PriceCalculationDVFormDataSource(
      correlationId,
      this.priceCalculationDv,
      this.savePriceCalculation
    );
  }

  createPriceCalculationDVFPFormDataSource(correlationId: string) {
    return new PriceCalculationDVFPFormDataSource(
      correlationId,
      this.priceCalculationDvfp,
      this.savePriceCalculation
    );
  }

  createPriceCalculationDVFPIDFormDataSource(correlationId: string) {
    return new PriceCalculationDVFPIDFormDataSource(
      correlationId,
      this.priceCalculationDvfpid,
      this.savePriceCalculation
    );
  }

  createOfferFormDataSource(correlationId: string) {
    return new OfferFormDataSource(
      correlationId,
      this.offerFromData,
      this.saveOfferFormData,
    );
  }

  createSellingOpportunityFormDataSource(correlationId: string) {
    return new SellingOpportunityFormDataSource(
      correlationId,
      this.sellingOpportunityFormData,
      this.saveSellingOpportunityFormData
    );
  }
}
