import { FormSchema } from '@nrg/components';

export const heatStorageSchema: FormSchema = {
  type: 'object',
  title: 'Wärmespeicherdaten',
  properties: {
    name: {
      type: 'string',
      title: 'Name'
    },
    capacity: {
      type: 'number',
      title: 'Speicherkapazität (m³)',
      minimum: 0,
      maximum: 25000
    },
    minimalFillingLevel: {
      type: 'number',
      title: 'Untere Gasspeicherfüllstandsgrenze (Prozent)',
      minimum: 0,
      maximum: 40
    },
    maximalFillingLevel: {
      type: 'number',
      title: 'Obere Gasspeicherfüllstandsgrenze (Prozent)',
      minimum: 60,
      maximum: 100
    },
    flowTemperature: {
      type: 'number',
      title: 'Vorlauftemperatur (°C)',
      minimum: 0,
      maximum: 100
    },
    returnTemperature: {
      type: 'number',
      title: 'Rücklauftemperature (°C)',
      minimum: 0,
      maximum: 100
    }
  },
  required: [
    'name',
    'capacity'
  ]
};
