import { Store } from '@ngrx/store';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, filter, first, map, switchMap } from 'rxjs/operators';
import { FormSchema } from '@nrg/components';
import { FormGroup } from '@angular/forms';
import { MonoEntityFormDataSource } from '../entity-form-data-source';
import { techncialUnitRunPlanParameterSchema } from '../../schema/technical-unit/technical-unit-run-plan-parameter.schema';
import { Permission } from '@vk/authentication';
import {
  CalculateFeuerungsleistungGQL,
  CalculateStromkennzahlGQL,
  CalculateWirkungsGradGesamtGQL,
  RunPlanParametersGQL,
  SaveRunPlanParametersGQL,
  TechnicalUnitRunPlanParameters
} from './technicalUnit.graphql.generated';
import { Injectable } from '@angular/core';
import { EntityType, VkCommonState, VkLoadingHandler } from '@vk/common';

export type RunPlanParameter = TechnicalUnitRunPlanParameters & {
  technicalUnitId: string
};

@Injectable({
  providedIn: 'any'
})
export class TechnicalUnitRunPlanParameterFormDataSource extends MonoEntityFormDataSource<RunPlanParameter> {

  readonly writePermission = Permission.ASSETS_WRITE_CUSTOMER;
  readonly alternativeWritePermission = Permission.ASSETS_WRITE;
  readonly entityType = EntityType.TechnicalUnit;
  readonly schema: FormSchema = techncialUnitRunPlanParameterSchema;

  readonly state = new ReplaySubject<RunPlanParameter>(1);

  constructor(
    private readonly loadRunPlanParameters: RunPlanParametersGQL,
    private readonly calculateFeuerungsleistungGQL: CalculateFeuerungsleistungGQL,
    private readonly calculateStromkennzahlGQL: CalculateStromkennzahlGQL,
    private readonly calculateWirkungsGradGesamtGQL: CalculateWirkungsGradGesamtGQL,
    private readonly saveRunPlanParameters: SaveRunPlanParametersGQL,
    private readonly loadingHandler: VkLoadingHandler
  ) {
    super('TechnicalUnitRunPlanParameterFormDataSource');
  }

  selectEntityById(id: string, store: Store<VkCommonState>): Observable<RunPlanParameter> {
    return this.state.asObservable();
  }

  loadEntity(id: string, store: Store<VkCommonState>): void {
    this.state.next({} as any);
    this.loadRunPlanParameters.subscribe({ technicalUnitId: id }).pipe(
      first(),
      this.loadingHandler.untilFirst('RunPlanParametersGQL', this.correlationId)
    ).subscribe(result => {
      this.state.next(mapToRunPlanParameter(result.data!.technicalUnitById.runPlanParameters, id));
    });
  }

  saveEntity(runPlanParameter: RunPlanParameter, store: Store<VkCommonState>, customerId: string): void {
    const id = runPlanParameter.technicalUnitId;

    const data = {
      ...runPlanParameter,
      ...{ technicalUnitId: undefined }
    };

    this.saveRunPlanParameters.mutate({
      technicalUnitId: id,
      data
    }).pipe(
      this.loadingHandler.untilFirst('SaveRunPlanParametersGQL', this.correlationId)
    ).subscribe();
  }

  createEntity(runPlanParameter: RunPlanParameter, customerId: string, store: Store<VkCommonState>): void {
    // not implemented
  }

  deleteEntity(id: string, store: Store<VkCommonState>, customerId: string): void {
    // not implemented
  }

  makeTemplate(parentId: string): RunPlanParameter {
    return {} as any;
  }

  setUpDerivedFields(formGroup: FormGroup) {
    const stromkennzahl = formGroup.get('stromkennzahl')!;
    const maxThermLeistung = formGroup.get('maxThermLeistung')!;
    const obereNennleistung = formGroup.get('obereNennleistung')!;
    const wirkungsgradThermisch = formGroup.get('wirkungsgradThermisch')!;
    const wirkungsgradElektrisch = formGroup.get('wirkungsgradElektrisch')!;
    const wirkungsgradGesamt = formGroup.get('wirkungsgradGesamt')!;
    const feuerungsleistung = formGroup.get('feuerungsleistung')!;

    combineLatest([
      wirkungsgradElektrisch.valueChanges,
      wirkungsgradThermisch.valueChanges
    ]).pipe(
      debounceTime(300),
      filter(([wirkungsgradEl, wirkungsgradTh]) => wirkungsgradEl != null && wirkungsgradTh != null),
      switchMap(([wirkungsgradEl, wirkungsgradTh]) => {
        return this.calculateWirkungsGradGesamtGQL.subscribe({
          wirkungsgradThermisch: wirkungsgradTh,
          wirkungsgradElektrisch: wirkungsgradEl
        });
      }),
      map(result => {
        return result.data!.calculateWirkungsgradGesamt.result;
      })
    ).subscribe(value => {
      wirkungsgradGesamt.setValue(value);
    });

    combineLatest([
      obereNennleistung.valueChanges,
      maxThermLeistung.valueChanges,
      wirkungsgradGesamt.valueChanges
    ]).pipe(
      debounceTime(300),
      filter(([obereNennleistungValue, maxThermLeistungValue, wirkungsgradGesamtValue]) => obereNennleistungValue != null && maxThermLeistungValue != null && wirkungsgradGesamtValue != null),
      switchMap(([obereNennleistungValue, maxThermLeistungValue, wirkungsgradGesamtValue]) => {
        return this.calculateFeuerungsleistungGQL.subscribe({
          wirkungsgradGesamt: wirkungsgradGesamtValue,
          nennleistungElekkWh: obereNennleistungValue,
          nennleistungThermkWh: maxThermLeistungValue
        });
      }),
      map(result => {
        return result.data!.calculateFeuerungsleistung.result;
      })
    ).subscribe(value => {
      feuerungsleistung.setValue(value);
    });

    combineLatest([
      obereNennleistung.valueChanges,
      maxThermLeistung.valueChanges,
    ]).pipe(
      debounceTime(300),
      filter(([obereNennleistungValue, maxThermLeistungValue]) => obereNennleistungValue != null && maxThermLeistungValue != null),
      switchMap(([obereNennleistungValue, maxThermLeistungValue]) => {
        return this.calculateStromkennzahlGQL.subscribe({
          nennleistungElekkWh: obereNennleistungValue,
          nennleistungThermkWh: maxThermLeistungValue
        });
      }),
      map(result => {
        return result.data!.calculateStromkennzahl.result;
      })
    ).subscribe(value => {
      stromkennzahl.setValue(value);
    });

  }
}


export function mapToRunPlanParameter(parameters: TechnicalUnitRunPlanParameters, id: string): RunPlanParameter {

  return {
    ...parameters,
    technicalUnitId: id
  };
}
