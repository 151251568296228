<mat-progress-bar *ngIf="isLoading | async" mode="query"></mat-progress-bar>
<nrg-banner *ngIf="isErrorOccurred | async" [actions]="errorActions" icon="error">
  Die angeforderten Daten konnten nicht geladen werden, da ein unerwarteter Fehler aufgetreten ist.
</nrg-banner>
<nrg-toolbar [disableTitleClick]="true" [items]="displayedActions$ | async" [fullWidth]="true"
             [title]="title||schema.title" [inMatCard]="inMatCard">
</nrg-toolbar>
<ng-container>
  <nrg-schema-form [dataSource]="dataSource" [disabled]="isFormDisabled$ | async" [hideSubmitButton]="hideSubmitButton"
                   (valueSubmit)="onValueSubmit($event)" (hasValidationErrors)="onHasValidationErrors($event)">
  </nrg-schema-form>
</ng-container>
