import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormListProperty, FormProperty, FormSchema } from './form-schema';


export interface FormValue {
  value: any | undefined;
  disabled: boolean;
}

export type GroupElement = [FormValue, ValidatorFn[]];

@Injectable()
export class FormGroupBuilder {

  constructor(private formBuilder: FormBuilder) {
  }

  public makeGroup(formData: any, schema: FormSchema, isDisabled: boolean, isParentRequired: boolean = true): FormGroup {
    return this.formBuilder.group(mapFormData<GroupElement>((key, value, definition) => {
      const isRequired = (schema.required.indexOf(key) !== -1) && isParentRequired;


      if (definition.type === 'object') {
        return this.makeGroup(value || {}, definition, isDisabled, isRequired) as any;
      }

      if (definition.type === 'array' && definition.items.type === 'object') {
        const values = value.map((arrayValue: any) => {
          return this.makeGroup(arrayValue || {}, definition.items as FormSchema, isDisabled, isRequired) as any;
        });

        return this.formBuilder.array(values);
      }
      const validators = getValidatorsForProperty(key, schema, definition, isParentRequired);

      return [{ value, disabled: isDisabled }, validators];
    }, formData, schema));
  }

  public mapFormData(formData: any, schema: FormSchema, update = false): any {
    return mapFormData((key, value) => value, formData, schema, update);
  }

  public mapFormDataBack(formGroup: FormGroup, schema: FormSchema): any {
    return mapFormDataBack(formGroup, schema);
  }

}

function getValidatorsForProperty(key: string, schema: FormSchema, definition: FormProperty, parentRequired: boolean): ValidatorFn[] {
  const validators: ValidatorFn[] = [];

  if ((schema.required.indexOf(key) !== -1) && parentRequired) {
    validators.push(Validators.required);
  }
  if (definition.type === 'number' && definition.maximum != null) {
    validators.push(Validators.max(definition.maximum));
  }
  if (definition.type === 'number' && definition.minimum != null) {
    validators.push(Validators.min(definition.minimum));
  }
  if (definition.type === 'string' && definition.maxLength != null) {
    validators.push(Validators.maxLength(definition.maxLength));
  }
  if (definition.type === 'string' && definition.minLength != null) {
    validators.push(Validators.minLength(definition.minLength));
  }
  if (definition.type === 'string' && definition.pattern != null) {
    validators.push(Validators.pattern(definition.pattern));
  }
  if (definition.type === 'array' && definition.items.type === 'string' && definition.items.pattern != null) {
    validators.push(Validators.pattern(`((^|\\s)(${definition.items.pattern}))+`));
  }
  if (definition.warnIfEmpty) {
    validators.push(createWarningValidator(Validators.required));
  }

  return validators;
}

function getSchemaPropertyKeys(schema: FormSchema): string[] {
  return Object.getOwnPropertyNames(schema.properties);
}

export function mapFormData<T>(
  mapFn: (key: string, value: any, definition: FormProperty) => T,
  formData: any,
  schema: FormSchema,
  update = false
): { [key: string]: T } {
  return reduceFormData((group, schemaPropertyKey, propertyDefinition) => {

    if (update && formData[schemaPropertyKey] == null) {
      return group;
    }

    const value = mapValue(formData[schemaPropertyKey], propertyDefinition, update);

    group[schemaPropertyKey] = mapFn(schemaPropertyKey, value, propertyDefinition);

    return group;
  }, {} as { [key: string]: T }, schema);

}

export function mapFormDataBack(formGroup: FormGroup, schema: FormSchema): any {
  return reduceFormData((formData, schemaPropertyKey, propertyDefinition) => {
    const inputValue = formGroup.controls[schemaPropertyKey].value;
    formData[schemaPropertyKey] = mapValueBack(inputValue, propertyDefinition);
    return formData;
  }, {} as { [key: string]: string }, schema);
}

function mapDataBack(data: any, schema: FormSchema): any {
  return reduceFormData((formData, schemaPropertyKey, propertyDefinition) => {
    const inputValue = data[schemaPropertyKey];

    formData[schemaPropertyKey] = mapValueBack(inputValue, propertyDefinition);

    return formData;
  }, {} as { [key: string]: string }, schema);
}

type ReduceFn<T> = (previousValue: T, schemaPropertyKey: string, propertyDefinition: FormProperty) => T;

function reduceFormData<T>(
  reduceFn: ReduceFn<T>,
  previousValue: T,
  schema: FormSchema
): T {
  const schemaPropertyKeys = schema.order != null && schema.order.length > 0 ? schema.order : getSchemaPropertyKeys(schema);

  return schemaPropertyKeys.reduce((value, schemaPropertyKey) => {
    const propertyDefinition = schema.properties[schemaPropertyKey];

    if (!propertyDefinition) {
      return value;
    } else {
      return reduceFn(value, schemaPropertyKey, propertyDefinition);
    }
  }, previousValue);
}

export function mapValueBack(value: any, definition: FormProperty): any {
  if (value == null || value === '') {
    return undefined;
  }

  switch (definition.type) {
    case 'number':
      const temp =  value != null ? parseFloat(value) : definition.default;
      return parseNumberValue(temp, definition.precision);
    case 'array':
      if (definition.items.type === 'object') {
        return (value || [] as any[]).map((subvalue: any) => mapDataBack(subvalue, definition.items as FormSchema));
      }
      return value.split(' ').map((subvalue: any) => mapValueBack(subvalue, definition.items));
    case 'boolean':
      return !!value;
    case 'object':
      const mappedValue = mapDataBack(value, definition);
      if (isEmptyObject(value)) {
        return null;
      }
      return mappedValue;
    case 'string':
      return value;
    case 'dateString':
      return convertDateToUTCIsoString(value);
    case 'monthYearString':
      if (value != null) {
        const newDate = setDateToLastDayOfMonth(value!);
        return convertDateToUTCIsoString(newDate);
      } else {
        return;
      }
    default:
      return value;
  }
}

export function mapValue(value: any, definition: FormProperty, update?: boolean): any | undefined {
  switch (definition.type) {
    case 'number':
      return parseNumberValue(value, definition.precision);
    case 'array':
      if (definition.items.type === 'object') {
        return (value || [] as any[]).map((subvalue: any) => {
            return mapFormData((key, val) => val, subvalue, definition.items as FormSchema, update);
          }
        );
      }
      return parseArrayValue(value, definition);
    case 'object': {
      return mapFormData((key, val) => val, (value || {}), definition, update);
    }
    case 'string':
      return value;
    case 'dateString' || 'monthYearString':
      return value && new Date(value);
    case 'boolean':
    default:
      return value;
  }
}

export function parseNumberValue(value: any, precision: number = 2): number | undefined {
  let numberValue: number | undefined;

  if (typeof (value) === 'number') {
    numberValue = value;
  } else if (typeof (value) === 'string') {
    numberValue = parseFloat(value);
  }

  if (numberValue != null) {
    return roundToDecimalPlaces(numberValue, precision);
  }

  return;
}

export function parseArrayValue(values: any, property: FormListProperty): string | undefined {
  if (values instanceof Array) {
    return values.map(value => mapValue(value, property.items)).join(' ');
  }

  return;
}

function isEmptyObject(value: any) {
  if (typeof (value) !== 'object') {
    return false;
  }
  let result = true;

  for (const key of Object.getOwnPropertyNames(value)) {
    const subValue = value[key];
    if (typeof (subValue) !== 'undefined' && subValue !== null && !isEmptyObject(subValue)) {
      result = false;
    }
  }

  return result;
}

export function convertDateToUTCIsoString(date: Date | undefined): string | undefined {
  if (!date) {
    return;
  }

  // clone the date object
  const newDate = new Date(date);

  // check for invalid dates
  if (isNaN(newDate.getTime())) {
    return;
  }

  newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());

  const split = newDate.toISOString().split('T');

  return split[0] + 'T00:00:00Z';
}

export function setDateToLastDayOfMonth(date: Date): Date {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = newDate.getMonth();
  return new Date(year, month + 1, 0);
}

function createWarningValidator(validator: ValidatorFn): ValidatorFn {
  return (c: any): ValidationErrors | null => {
    c.warnings = validator(c);
    return null;
  };
}

function roundToDecimalPlaces(num: number, places: number): number {
  return +num.toFixed(places);
}
