import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map, tap } from 'rxjs/operators';
import { AuthenticationErrorType } from './session-timeout-error';
import { parsePermission, Permission } from './user.model';

export interface AuthenticatedUser {
  id: string;
  name: string;
  token: string;
  permissions: Permission[];
}

@Injectable({
  providedIn: 'root'
})
export class VkAuthenticationService {

  constructor(private keycloakService: KeycloakService,
              private readonly router: Router) {
  }

  public loadUser(): Observable<AuthenticatedUser> {
    return combineLatest([
      fromPromise(this.keycloakService.isLoggedIn()),
      fromPromise(this.keycloakService.getToken())
    ]).pipe(
      map(([isLoggedIn, token]) => {

        if (!isLoggedIn) {
          throw { type: AuthenticationErrorType.SessionTimeout };
        }

        const tokenData = JSON.parse(b64DecodeUnicode(token.split('.')[1]));

        const name = tokenData.preferred_username ?? tokenData.name;
        const kundenId = tokenData.party[0];

        const permissions = rolesToPermissions(this.keycloakService.getUserRoles());

        return { id: kundenId, name, token, permissions };
      })
    );
  }

  public logout(): Observable<void> {
    return fromPromise(this.keycloakService.logout())
      .pipe(
        tap(() => this.router.navigate(['']))
      );
  }
}

function rolesToPermissions(roles: string[]): Permission[] {
  return roles.map(parsePermission).filter(it => it) as Permission[];
}

function b64DecodeUnicode(str: string) {
  // From bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}
