import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AxisConfig, AxisRange, BlockChartSeries, ChartSeries, Color, colorWithAlpha, hslToRgb, LineChartSeries } from '@nrg/components';
import {
  JobType,
  LoadResultGQL,
  LoadResultQuery,
  ParkWithRelationShips,
  ParkWithRelationShipsByIdGQL, ParkWithRelationShipsByIdSubscription
} from './optimization-result-chart.graphql.generated';

const colors: Color[] = [
  colorWithAlpha(hslToRgb('hsl(211,75%,50%)'), 0.6),
  colorWithAlpha(hslToRgb('hsl(79,85%,35%)'), 0.6),
  colorWithAlpha(hslToRgb('hsl(336,100%,60%)'), 0.6),
  colorWithAlpha(hslToRgb('hsl(269,50%,35%)'), 0.6),
  colorWithAlpha(hslToRgb('hsl(211,75%,70%)'), 0.6),
  colorWithAlpha(hslToRgb('hsl(79,85%,65%)'), 0.6),
  colorWithAlpha(hslToRgb('hsl(336,100%,80%)'), 0.6),
  colorWithAlpha(hslToRgb('hsl(269,50%,65%)'), 0.6)
];

export interface DisplayedAsset {
  id: string;
  name: string;
  color: Color;
}

export class OptimizationResultChartDataSource {

  park$: Observable<ParkWithRelationShipsByIdSubscription['parkWithRelationShipsById']> = this.parkId$.pipe(
    switchMap(parkId => {
      return this.parkWithRelationships.subscribe({ parkId }).pipe(
        map(it => it.data.parkWithRelationShipsById)
      );
    })
  );

  result$ = combineLatest([
    this.parkId$,
    this.jobType$
  ]).pipe(
    switchMap(([parkId, jobType]) => {
      return this.load.fetch({
        jobType,
        assetId: parkId
      });
    }),
    map(it => it.data.optimizationResult)
  );

  displayedTechnicalUnits$ = combineLatest([
    this.park$,
    this.result$
  ]).pipe(
    map(([park, result]) => {
      let colorIndex = 0;
      return result.technicalUnits.map(unit => {
        const technicalUnit = park.technicalUnits.find(it => it.id === unit.technicalUnitId);
        const color = colors[colorIndex];
        colorIndex = (colorIndex + 1) % colors.length;
        return {
          id: technicalUnit.id,
          name: technicalUnit.name,
          color
        } as DisplayedAsset;
      });
    })
  );

  displayedGasStorages$ = combineLatest([
    this.park$,
    this.result$
  ]).pipe(
    map(([park, result]) => {
      return result.gasStorages.map(unit => {
        const gasStorage = park.gasStorages.find(it => it.id === unit.id);
        return {
          id: gasStorage.id,
          name: gasStorage.name,
          color: { type: 'rgb', r: 0, g: 0, b: 0 }
        } as DisplayedAsset;
      });
    })
  );

  loading$ = combineLatest([
    this.park$,
    this.result$
  ]).pipe(
    map(([park, result]) => !(park && result))
  );

  yAxisLeft$ = this.result$.pipe(
    map(result => {

      const maxPower = Math.max(
        100,
        ...(result?.technicalUnits ?? [])
          .map(it => it.blocks.map(block => block.power))
          .reduce((a, b) => a.concat(b), [])
      );

      const axis: AxisConfig = {
        formatter(value: number) {
          return `${value.toFixed(0)} kW`;
        },
        from: 0,
        id: 'power',
        stepSize: 50,
        to: maxPower
      };

      return axis;
    })
  );

  yAxisRight: AxisConfig = {
    from: 0,
    to: 100,
    stepSize: 10,
    id: 'percent',
    formatter(value: number) {
      return `${value.toFixed(0)} %`;
    }
  };

  dateRange$ = this.result$.pipe(
    map(result => {


      const period = result.period.stepDuration * 60 * 1000;
      const from = new Date(result.period.start).getTime();
      const to = from + period * result.period.stepCount;

      const axis: AxisRange = {
        from,
        to,
      };

      return axis;
    })
  );

  technicalUnitSeries$ = combineLatest([
    this.result$,
    this.displayedTechnicalUnits$
  ]).pipe(
    map(([result, technicalUnits]) => {

      return technicalUnits.map(technicalUnit => {
        const series = new BlockChartSeries(
          technicalUnit.id,
          technicalUnit.name,
          technicalUnit.color,
          'power'
        );
        const resultSeries = (result?.technicalUnits ?? []).find(it => it.technicalUnitId === technicalUnit.id);
        const data = resultSeries.blocks.map((block, index) => {
          return {
            id: `${index}`,
            from: new Date(block.start).getTime(),
            to: new Date(block.end).getTime(),
            value: block.power
          };
        });
        series.setData(data);
        return series;
      });

    })
  );

  gasStorageSeries$ = combineLatest([
    this.result$,
    this.displayedGasStorages$
  ]).pipe(
    map(([result, gasStorages]) => {

      return gasStorages.map(gasStorage => {
        const resultSeries = result!.gasStorages.find(it => it.id === gasStorage.id);
        const series = new LineChartSeries(
          gasStorage.id,
          gasStorage.name,
          gasStorage.color,
          'percent'
        );
        const data = resultSeries.series.map(item => {
          return [new Date(item.dateTime).getTime(), item.value];
        }) as Array<[number, number]>;
        series.setData(data);
        return series;
      });

    })
  );

  additionalTimeSeries$ = this.result$.pipe(
    map((result) => {

      return result!.additionalTimeSeries.map(resultSeries => {
        const series = new LineChartSeries(
          resultSeries.name,
          resultSeries.name,
          hslToRgb('hsl(32,80%,20%)'),
          'percent'
        );
        const data = resultSeries.series.map(item => {
          return [new Date(item.dateTime).getTime(), item.value];
        }) as Array<[number, number]>;
        series.setData(data);
        return series;
      });

    })
  );

  series$ = combineLatest([
    this.technicalUnitSeries$,
    this.gasStorageSeries$,
    this.additionalTimeSeries$
  ]).pipe(map(([technicalUnitSeries, gasStorageSeries, additionalTimeSeries]) => {
    return (technicalUnitSeries as ChartSeries<unknown, unknown>[])
      .concat(gasStorageSeries as ChartSeries<unknown, unknown>[])
      .concat(additionalTimeSeries as ChartSeries<unknown, unknown>[]);
  }));

  constructor(
    private readonly store: Store<unknown>,
    private readonly parkId$: Observable<string>,
    private readonly jobType$: Observable<JobType>,
    private readonly load: LoadResultGQL,
    private readonly parkWithRelationships: ParkWithRelationShipsByIdGQL
  ) {
  }

}
