<ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
  <nav>
    <ol class="wrapper">
      <li class="crumb" *ngFor="let item of breadcrumbs; let index=index;">
        <mat-icon *ngIf="index !== 0" class="chevron">chevron_right</mat-icon>
        <a [routerLink]="item.url">
          {{item.title}}
        </a>
      </li>
    </ol>
  </nav>
</ng-container>
