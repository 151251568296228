import { Injectable } from '@angular/core';
import { CustomerWithAssets } from './customer-import-export.schema';
import {
  ControlAreaEnum,
  CustomerImportAllocationInput,
  CustomerImportDataInput,
  ExportCustomerGQL,
  ImportCustomerGQL
} from './customer-import-export.graphql.generated';
import { select, Store } from '@ngrx/store';
import { translateEnergySourceBack } from '../entity-form/data-source/technical-unit/technical-unit-general-settings-form-data-source';
import { EnergySourceEnum } from '../entity-form/data-source/technical-unit/technicalUnit.graphql.generated';
import { selectCustomerId, VkAuthenticationState } from '@vk/authentication';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerImportExportService {

  correlationId = 'CustomerImportExportService';

  constructor(
    private readonly exportQuery: ExportCustomerGQL,
    private readonly importMutation: ImportCustomerGQL,
    private readonly store: Store<VkAuthenticationState>
  ) {
  }

  async exportCustomer(customerId: string) {

    const data = await this.exportQuery.fetch({
      customerId
    }).toPromise().then(x => x.data.exportCustomer);

    const park = data.parks[0];

    const malo = data.malos[0];

    const gasStorage = park?.gasStorages[0];
    const technicalUnits = park?.technicalUnits;
    const contracts = data.contracts;

    const result: CustomerWithAssets = {
      customer: {
        customerId,
        kundennummer: data.customerData?.customerNumber,
        firmierung: data.customerData?.companyName,
        firmierungZusatz1: data.customerData?.additionalCompanyName1,
        firmierungZusatz2: data.customerData?.additionalCompanyName2,
        email: data.userData.email,
        strasse: data.customerData?.street,
        hausnummer: data.customerData?.houseNumber,
        zusatz: data.customerData?.additionalAddress,
        plz: data.customerData?.postalCode,
        stadt: data.customerData?.city,
        land: data.customerData?.country,
        aussendienstMitarbeiter: data.customerData?.externalContractPerson,
        innendienstMitarbeiter: data.customerData?.internalContactPerson
      },
      gasStorage: gasStorage ? [{
        capacity: gasStorage.capacity,
        maximalFillingLevel: gasStorage.maximalFillingLevel,
        methanConcentration: gasStorage.methanConcentration,
        minimalFillingLevel: gasStorage.minimalFillingLevel,
        producedBiogas: gasStorage.producedBiogas,
      }] : [],
      malo: malo ? [{
        maloId: malo.maloId,
        controlArea: malo.controlArea as ControlAreaEnum,
        accountingGrid: malo.accountingGrid
      }] : [],
      technicalUnits: technicalUnits.map((technicalUnit) => {
        const contract = contracts.find(it => it.technicalUnitName === technicalUnit.name);
        return {
          datumDerNetzanbindung: technicalUnit.dateOfGridConnection?.toISOString() ?? null,
          inbetriebnahmeDatum: technicalUnit.dateOfCommissioning?.toISOString() ?? null,
          vertragsrelevant: contract?.isRelevantForContract === true,
          wirkungsgradElektrisch: technicalUnit.electricalEfficiency,
          wirkungsgradThermisch: technicalUnit.thermalEfficiency,
          anlageDarfStehen: technicalUnit.isShutdownAllowed,
          obereNennleistung: technicalUnit.upperPowerRating,
          maxLeistung: technicalUnit.maximalPower,
          minLeistung: technicalUnit.minimalPower,
          startbarProTag: technicalUnit.maximalNumberOfStartsPerDay,
          maxJahresmengeStrom: technicalUnit.maximalAnnualPowerAmount,
          strasse: technicalUnit?.street,
          hausnummer: technicalUnit?.houseNumber,
          zusatz: technicalUnit?.additionalAddress,
          plz: technicalUnit?.postalCode,
          stadt: technicalUnit?.city,
          land: technicalUnit?.country,
          energySource: translateEnergySource(technicalUnit.energySource as 'BIOMASS' | 'SOLAR'),
          curtailmentOffset: technicalUnit.curtailmentOffset ?? 0,
          anzulegenderWert: technicalUnit.anzulegenderWert,
          flexMotor: technicalUnit.flexMotor ?? false,
          eegAnlagenschluessel: technicalUnit.eegDeviceId
        };
      })
    };
    return result;
  }

  async importCustomer(data: CustomerWithAssets, skipTimeout = false) {
    const customerId = data.customer.customerId;

    const tenantId = await this.store.pipe(select(selectCustomerId), first()).toPromise();

    const payload: CustomerImportDataInput = {
      userData: {
        customerId,
        tenantId,
        email: data.customer.email,
        firstName: null,
        lastName: null,
        username: null,
        password: null,
      },
      customerData: {
        additionalAddress: data.customer.zusatz,
        additionalCompanyName1: data.customer.firmierungZusatz1,
        additionalCompanyName2: data.customer.firmierungZusatz2,
        city: data.customer.stadt,
        customerNumber: data.customer.kundennummer,
        country: data.customer.land,
        companyName: data.customer.firmierung,
        externalContractPerson: data.customer.aussendienstMitarbeiter,
        internalContactPerson: data.customer.innendienstMitarbeiter,
        houseNumber: data.customer.hausnummer,
        postalCode: data.customer.plz,
        street: data.customer.strasse
      },
      parks: [{
        name: `${customerId}_P1`,
        gasStorages: data.gasStorage.map((gs, index) => {
          return {
            name: `${customerId}_GS${index + 1}`,
            capacity: gs.capacity,
            maximalFillingLevel: gs.maximalFillingLevel,
            methanConcentration: gs.methanConcentration,
            minimalFillingLevel: gs.minimalFillingLevel,
            producedBiogas: gs.producedBiogas
          };
        }),
        technicalUnits: data.technicalUnits.map((te, index) => {
          return {
            name: `${customerId}_BG${index + 1}`,
            anzulegenderWert: te.anzulegenderWert,
            eegDeviceId: te.eegAnlagenschluessel,
            flexMotor: te.flexMotor,
            additionalAddress: te.zusatz,
            city: te.stadt,
            country: te.land,
            allowedProducts: null,
            controlMode: 'continuous',
            curtailmentOffset: te.curtailmentOffset,
            dateOfCommissioning: (te.inbetriebnahmeDatum != null) ? new Date(te.inbetriebnahmeDatum) : null,
            dateOfGridConnection: (te.datumDerNetzanbindung != null) ? new Date(te.datumDerNetzanbindung) : null,
            electricalEfficiency: te.wirkungsgradElektrisch || 40,
            houseNumber: te.hausnummer,
            postalCode: te.plz,
            street: te.strasse,
            isShutdownAllowed: te.anlageDarfStehen,
            lowerPowerRating: null,
            maximalAnnualPowerAmount: te.maxJahresmengeStrom,
            maximalNumberOfStartsPerDay: te.startbarProTag,
            maximalPower: te.maxLeistung,
            maximalThermalPower: te.obereNennleistung,
            minimalPower: te.minLeistung,
            minimalTechnicalPower: null,
            energySource: (te.energySource != null) ? translateEnergySourceBack(te.energySource) : EnergySourceEnum.Biomass,
            loadGradientMinPower: null,
            loadGradientMaxPower: null,
            thermalEfficiency: te.wirkungsgradThermisch || 40,
            upperPowerRating: te.obereNennleistung
          };
        })
      }],
      contracts: data.technicalUnits.map((te, index) => {
        return {
          technicalUnitName: `${customerId}_BG${index + 1}`,
          isRelevantForContract: te.vertragsrelevant ?? false,
          hasContract: false,
          commercializationType: null,
          dateOfCommercialization: null
        };
      }),
      malos: data.malo.map(malo => {
        return {
          maloId: malo.maloId,
          controlArea: malo.controlArea,
          accountingGrid: malo.accountingGrid
        };
      }),
      allocations: data.technicalUnits.map((te, index) => {
        const result: CustomerImportAllocationInput[] = [];
        if (data.gasStorage[0]) {
          result.push({
            type: 'te<->gs',
            technicalUnitName: `${customerId}_BG${index + 1}`,
            gasStorageName: `${customerId}_GS1`
          });
        }
        const malo = data.malo[0];
        if (malo) {
          result.push({
            type: 'te<->malo',
            technicalUnitName: `${customerId}_BG${index + 1}`,
            maloName: malo.maloId
          });
        }
        return result;
      }).reduce((a, b) => a.concat(b)),
      cpes: [],
      offers: []
    };

    await this.importMutation.mutate({ data: payload }).toPromise();

    if (!skipTimeout) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }

  }
}

function translateEnergySource(source: 'BIOMASS' | 'SOLAR'): 'Biomasse' | 'Photovoltaik' {
  switch (source) {
    case 'BIOMASS':
      return 'Biomasse';
    case 'SOLAR':
      return 'Photovoltaik';
  }
}
