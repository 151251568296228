import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatStoragePreferencesComponent } from './heat-storage-preferences.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { NrgToolbarModule } from '@nrg/components';

@NgModule({
  declarations: [HeatStoragePreferencesComponent],
  exports: [HeatStoragePreferencesComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTableModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    NrgToolbarModule
  ]
})
export class VkHeatStoragePreferencesModule { }
