import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { documentStoreBaseUrl } from './vk-documents.token';
import { VkAuthenticationModule } from '@vk/authentication';
import { ContractLinkComponent } from './contract-link/contract-link.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [ContractLinkComponent],
  imports: [
    HttpClientModule,
    VkAuthenticationModule,
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    ContractLinkComponent
  ]
})
export class VkDocumentsModule {
  static forRoot(baseUrl: string = ''): ModuleWithProviders<VkDocumentsModule> {
    return {
      ngModule: VkDocumentsModule,
      providers: [
        {
          provide: documentStoreBaseUrl,
          useValue: baseUrl
        }
      ]
    };
  }
}
