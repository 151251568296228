<nrg-toolbar [items]="actions$ | async" disableTitleClick fullWidth></nrg-toolbar>
<div class="top">
  <div class="top-item">
    <h3>Datei laden</h3>
    <p>
      <mat-form-field appearance="fill">
        <mat-label>Zeitzone für Import</mat-label>
        <select [disabled]="isReadonly$$ | async" matNativeControl [formControl]="offsetModeControl" #selectInput>
          <option value="Auto" [selected]="selectInput.value === 'Auto'">Automatisch (Normal- und Sommerzeit)</option>
          <option value="UTC" [selected]="selectInput.value === 'UTC'">Koordinierte Weltzeit (UTC)</option>
          <option value="CET" [selected]="selectInput.value === 'CET'">Normalzeit (CET)</option>
          <option value="CEST" [selected]="selectInput.value === 'CEST'">Sommerzeit (CEST)</option>
        </select>
      </mat-form-field>
    </p>
    <p>
      <input [disabled]="isReadonly$$ | async" type="file" (change)="handleNewFile($event)">
    </p>
    <p>
      <button [disabled]="isReadonly$$ | async" mat-raised-button (click)="deleteHistoricalHeatLoad()">Historische Wärmelast löschen</button>
    </p>
  </div>
  <div>
    <h3>Vereinfachte Vorhersage</h3>
    <p>
      <mat-form-field appearance="fill">
        <mat-label>Minmallast</mat-label>
        <input [disabled]="isReadonly$$ | async" type="number" matInput [formControl]="minimalHeat">
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
      <mat-label>Spitzenlast</mat-label>
      <input [disabled]="isReadonly$$ | async" type="number" matInput [formControl]="maximalHeat">
    </mat-form-field>
    </p>
  </div>
</div>

<ng-container *ngIf="heatStoragePreferences$ | async as preferences" [ngSwitch]="!!preferences.historicalHeatLoad">
  <ng-container *ngSwitchCase="true">
    <h3>Stand</h3>
    <p>
      <mat-form-field appearance="fill">
        <mat-label>Datum für Vorschau</mat-label>
        <input matInput [matDatepicker]="dp3" disabled [formControl]="dateControl">
        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
      </mat-form-field>
    </p>

    <table mat-table [dataSource]="tableData$ | async">

      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef> Uhrzeit </th>
        <td mat-cell *matCellDef="let element"> {{element.time}} </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef> Wärmelast (kW) </th>
        <td mat-cell *matCellDef="let element"> {{element.value}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </ng-container>
  <ng-container *ngSwitchCase="false">
    <p><i>Keine Daten vorhanden</i></p>
  </ng-container>
</ng-container>
