import { MonoEntityFormDataSource } from './entity-form-data-source';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { ParkFormData, parkSchema } from '../schema/park.schema';
import { FormSchema } from '@nrg/components';
import { Permission } from '@vk/authentication';
import {
  CreateParkGQL,
  ParkCreateInput,
  ParkGQL,
  ParkSubscription,
  ParkUpdateInput,
  PatchParkGQL,
  ProductEnum
} from './park.graphql.generated';
import { VkLoadingHandler } from '@vk/common';
import { first, map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { IS_CREATE, IS_PORTAL } from '../entity-form.injection-tokens';
import { AllowedProduct, getDerivedAllowedProduct, mapToAllowedProduct } from './allowedProducts';

const correlationId = 'ParkFormDataSource';

@Injectable({
  providedIn: 'any'
})
export class ParkFormDataSource extends MonoEntityFormDataSource<ParkFormData> {
  readonly writePermission = Permission.ASSETS_WRITE;
  readonly alternativeWritePermission = Permission.ASSETS_WRITE_CUSTOMER;
  readonly entityType = 'ParkFormDataSource' as any;
  schema: FormSchema = parkSchema(this.isCustomer, this.isCreate);
  readonly state = new ReplaySubject<ParkFormData>(1);

  constructor(
    private loadPark: ParkGQL,
    private patchPark: PatchParkGQL,
    private createPark: CreateParkGQL,
    private readonly loadingHandler: VkLoadingHandler,
    @Inject(IS_PORTAL) private readonly isCustomer: boolean,
    @Inject(IS_CREATE) private readonly isCreate: boolean,
  ) {
    super(correlationId);
  }

  loadEntity(id: string, store: Store<unknown>): void {
    this.state.next({} as any);
    this.loadPark.subscribe({ parkId: id }).pipe(
      first(),
      this.loadingHandler.untilFirst('ParkGQL', this.correlationId),
      map(result => mapToFormData(result.data!.park))
    ).subscribe(result => this.state.next(result));
  }

  selectEntityById(id: string, store: Store<unknown>): Observable<ParkFormData | undefined> {
    return this.state.asObservable();
  }

  saveEntity(park: ParkFormData, store: Store<unknown>): void {
    const parkUpdate = mapToParkUpdate(park);
    this.patchPark.mutate({ park: parkUpdate }).subscribe();
  }

  createEntity(entity: ParkFormData, customerId: string, store: Store<unknown>): void {
    const parkCreate = mapToParkCreate(entity, customerId);
    this.createPark.mutate({ parkCreate }).subscribe();
  }

  deleteEntity(id: string, store: Store<unknown>): void {
    // ToDo Implement in graphql
    // store.dispatch(deletePark({ parkId: id, correlationId: this.correlationId }));
  }

  makeTemplate(customerId: string): ParkFormData {
    return {
      activateOverride: false,
      produktfreigabeDV: false,
      produktfreigabeDVFP: false,
      produktfreigabeDVFPID: false,
      abschaltungEnabled: false,
      ...undefined as any
    };
  }
}

function mapToFormData(data: ParkSubscription['park']): ParkFormData {

  const allowedProducts = data.produktFreigabe.map(it => {
    switch (it) {
      case ProductEnum.Dv:
        return AllowedProduct.DV;
      case ProductEnum.Dvfp:
        return AllowedProduct.DVFP;
      case ProductEnum.Dvfpid:
        return AllowedProduct.DVFPID;
      default:
        return AllowedProduct.NONE;
    }
  });

  return {
    id: data.id,
    distributionSystemOperatorId: data.distributionSystemOperatorId,
    distributionSystemOperatorCoding: data.distributionSystemOperatorCoding,
    parkGroesse: data.parkGroesse,
    parkName: data.parkName,
    safetySchedulesEnabled: data.safetySchedulesEnabled,
    activateOverride: !!data.produktFreigabeOverride,
    produktfreigabeDerived: getDerivedAllowedProduct(allowedProducts),
    produktfreigabeDV: !!data.produktFreigabeOverride?.includes(ProductEnum.Dv),
    produktfreigabeDVFP: !!data.produktFreigabeOverride?.includes(ProductEnum.Dvfp),
    produktfreigabeDVFPID: !!data.produktFreigabeOverride?.includes(ProductEnum.Dvfpid),
    sortenreinerPark: data.sortenreinerPark,
    underPar51: data.underPar51,
    redispatchTransmissionOverride: data.redispatchTransmissionOverride,
    abschaltungEnabled: data.abschaltungEnabled
  };
}

export function mapToParkUpdate(park: ParkFormData): ParkUpdateInput {
  const override = park.activateOverride ? mapToAllowedProduct(park).map(it => {
    switch (it) {
      case AllowedProduct.DV: return ProductEnum.Dv;
      case AllowedProduct.DVFP: return ProductEnum.Dvfp;
      case AllowedProduct.DVFPID: return ProductEnum.Dvfpid;
      default: throw new Error(`Unkown product type: ${it}`);
    }
  }) : undefined;

  return {
    id: park.id,
    parkName: park.parkName,
    produktFreigabeOverride: override,
    safetySchedulesEnabled: park.safetySchedulesEnabled,
    distributionSystemOperatorId: park.distributionSystemOperatorId,
    distributionSystemOperatorCoding: park.distributionSystemOperatorCoding,
    redispatchTransmissionOverride: park.redispatchTransmissionOverride,
    abschaltungEnabled: park.abschaltungEnabled
  };
}

export function mapToParkCreate(park: ParkFormData, customerId: string): ParkCreateInput {
  return {
    customerId,
    parkName: park.parkName,
    safetySchedulesEnabled: park.safetySchedulesEnabled,
    distributionSystemOperatorId: park.distributionSystemOperatorId,
    distributionSystemOperatorCoding: park.distributionSystemOperatorCoding,
    redispatchTransmissionOverride: park.redispatchTransmissionOverride,
    abschaltungEnabled: park.abschaltungEnabled
  };
}

