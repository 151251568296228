import { createReducer, createSelector, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { acknowledgeAllErrors, addError, notifyDidEndLoading, notifyWillBeginLoading } from './status.actions';
import { EntityErrorStatus, EntityLoadingStatus, Status, StatusType } from '../../entities/status';
import { selectCommonFeatureState, VkCommonState } from '../../vk-common.state';
import { ErrorCode } from '../../entities/loading-error.entity';

// ------ State ------

export interface VkStatus extends EntityState<StatusType> {
}

const adapter = createEntityAdapter<StatusType>({
  selectId: status => makeStatusId(status)
});

let errorCounter = 0;

const { selectAll } = adapter.getSelectors();

function makeStatusId(status: StatusType): string {
  return `${status.entityType}_${status.entityId}`;
}

export const initialStatusState: VkStatus = adapter.getInitialState();

// ------ Reducer ------

// TODO: refactor
export const statusReducer = createReducer(
  initialStatusState,
  on(addError, (state, { error }) => {
    return adapter.upsertOne(error, state);
  }),
  on(notifyWillBeginLoading, (state, { ids, correlationId, entity }) => {
    let status: EntityLoadingStatus[];
    if (ids.length > 0) {
      status = ids.map(x => {
        return {
          status: Status.Loading,
          correlationId,
          entityId: x,
          entityType: entity,
        };
      });
    } else {
      status = [{
        status: Status.Loading,
        correlationId,
        entityType: entity,
      }];
    }

    return adapter.upsertMany(status, state);
  }),
  on(notifyDidEndLoading, (state, { ids, correlationId, entity, error }) => {
    if (error != null) {
      let errors: EntityErrorStatus[];

      if (ids.length > 0) {
        errors = ids.map(x => {
          const errorId = `error_${errorCounter++}`;
          return {
            status: Status.Error,
            correlationId,
            entityId: x,
            entityType: entity,
            error,
            acknowledged: false,
            errorId
          };
        });
      } else {
        const errorId = `error_${errorCounter++}`;
        errors = [{
          status: Status.Error,
          correlationId,
          entityType: entity,
          error,
          acknowledged: false,
          errorId
        }];
      }

      return adapter.upsertMany(errors, state);
    } else {
      let idsToRemove: string[];
      if (ids.length > 0) {
        idsToRemove = ids.map(x => {
          return makeStatusId({
            status: Status.Loading,
            correlationId,
            entityId: x,
            entityType: entity
          });
        });
      } else {
        idsToRemove = [makeStatusId({
          status: Status.Loading,
          correlationId,
          entityType: entity
        })];
      }

      return adapter.removeMany(idsToRemove, state);
    }
  }),
  on(acknowledgeAllErrors, (state) => {
    return adapter.map(loadingStatus => {
      if (loadingStatus.status === Status.Error) {
        return {
          ...loadingStatus,
          acknowledged: true,
        };
      }

      return loadingStatus;
    }, state);
  })
);

// ------ Selectors ------


const selectStatusState = createSelector(
  selectCommonFeatureState,
  ({ status }) => status
);

export type CounterSelector = (counter: StatusType) => boolean;
const defaultCounterSelector: CounterSelector = () => true;

export const filterByEntityId: (id: string) => CounterSelector = (id: string) => (counter: StatusType) => {
  return counter.entityId === id;
};

export const filterByEntityIdAndErrorCode: (id: string, code: ErrorCode) => CounterSelector = (id: string, code: ErrorCode) => (counter: StatusType) => {
  return counter.entityId === id && counter.status === Status.Error && counter.error.code === code;
};

export const filterByErrorCode: (code: ErrorCode) => CounterSelector = (code: ErrorCode) => (counter: StatusType) => {
  return counter.status === Status.Error && counter.error.code === code;
};

// tslint:disable-next-line:max-line-length
export const selectLoadingCount = (entitySelection: string[] = [], selector: CounterSelector = defaultCounterSelector) => createSelector(
  selectStatusState,
  ({ ids, entities }) => {
    return (ids as string[]).reduce((acc, id) => {
      const status = entities[id];

      if (status == null) {
        return acc;
      }

      if ((entitySelection.length > 0 &&
        entitySelection.find(x => x === status.entityType) == null) ||
        !selector(status)) {
        return acc;
      }

      switch (status.status) {
        case Status.Loading:
          acc.count += 1;
          break;
        case Status.Error:
          acc.errors += 1;
          break;
      }

      return acc;
    }, { count: 0, errors: 0 });
  }
);

/**
 * @deprecated use selectIsLoadingByContext instead
 */
export const selectIsLoading = (entitySelection: string[] = [], selector: CounterSelector = defaultCounterSelector) => createSelector(
  selectLoadingCount(entitySelection, selector),
  ({ count }) => count > 0
);

// TODO: selector with correlationId required
// tslint:disable-next-line:max-line-length
export const selectIsErrorOccurred = (entitySelection: string[] = [], selector: CounterSelector = defaultCounterSelector) => createSelector(
  selectLoadingCount(entitySelection, selector),
  ({ errors }) => errors > 0
);

export const selectAllNotAckownledgedErrors = createSelector(
  selectStatusState,
  (state) => {
    return selectAll(state).filter(loadingStatus => loadingStatus.status === Status.Error && !loadingStatus.acknowledged) as EntityErrorStatus[];
  }
);

export const selectLoadingContext = createSelector(
  selectStatusState,
  (state: VkStatus, props: { correlationId: string }) => {
    return selectAll(state).reduce<EntityLoadingStatus[]>((acc, loadingStatus) => {
      if (loadingStatus.status === Status.Loading && loadingStatus.correlationId === props.correlationId) {
        acc.push(loadingStatus);
      }

      return acc;
    }, []);
  }
);

export const selectErrorsByContext = createSelector(
  selectStatusState,
  (state: VkStatus, props: { correlationId: string }) => {
    return selectAll(state).reduce<EntityErrorStatus[]>((acc, loadingStatus) => {
      if (loadingStatus.status === Status.Error && loadingStatus.correlationId === props.correlationId) {
        acc.push(loadingStatus);
      }

      return acc;
    }, []);
  }
);

export const selectLoadingContextByEntityType = (entityType: string) => createSelector<VkCommonState, { correlationId: string }, EntityLoadingStatus[], EntityLoadingStatus[]>(
  selectLoadingContext,
  (context, props) => {
    return context.filter(loadingStatus => loadingStatus.entityType === entityType);
  }
);

export const selectIsLoadingByContext = createSelector(
  selectLoadingContext,
  context => {
    return context.length > 0;
  }
);

export const selectIsLoadingByContextAndEntityType = (entityType: string) => createSelector(
  selectLoadingContextByEntityType(entityType),
  context => {
    return context.length > 0;
  }
);

export const selectAnythingIsLoading = createSelector(
  selectStatusState,
  state => {
    return selectAll(state).some(it => it.status === Status.Loading);
  }
);
