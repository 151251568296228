import { KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';

export function initializeKeycloak(config: KeycloakConfig & { enabled: boolean }): (keycloak: KeycloakService) => () => Promise<boolean> {

  const tokens: {
    access_token?: string,
    id_token?: string,
    refresh_token?: string,
  } = {};

  if (window.location.hash.startsWith('#access_token')) {
    window.location.hash.slice('#'.length).split('&').forEach(item => {
      const [key, value] = item.split('=');
      // @ts-ignore
      tokens[key] = value;
    });
  }

  return (keycloak: KeycloakService) => (): Promise<boolean> => {
    if (config.enabled) {
      return keycloak.init({
        config,
        initOptions: tokens.access_token ? {
          token: tokens.access_token,
          refreshToken: tokens.refresh_token,
          idToken: tokens.id_token,
          checkLoginIframe: false
        } : {
          onLoad: 'login-required',
          checkLoginIframe: false,
        },
        enableBearerInterceptor: true
      });

    } else {
      return Promise.resolve(true);
    }
  };
}
