import { MonoEntityFormDataSource } from '../entity-form-data-source';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { FormSchema } from '@nrg/components';
import { sellingOpportunitySchema } from '../../schema/selling-opportunity.schema';
import { Permission } from '@vk/authentication';
import {
  SaveSellingOpportunityFormDataGQL,
  SellingOpportunity,
  SellingOpportunityFormDataGQL,
  SellingOpportunityFormDataSubscription
} from './selling-opportunity-data-source.graphql.generated';

type SellingOpportunityFormData = SellingOpportunityFormDataSubscription['sellingOpportunityById'];

export class SellingOpportunityFormDataSource extends MonoEntityFormDataSource<SellingOpportunityFormData> {
  readonly writePermission = Permission.CONTRACTS_WRITE_SALES;
  readonly entityType = 'SellingOpportunity' as any;
  readonly schema: FormSchema = sellingOpportunitySchema;

  readonly state = new ReplaySubject<SellingOpportunityFormData>(1);

  id: string | undefined;

  constructor(
    correlationId: string,
    private readonly sellingOpportunityFormData: SellingOpportunityFormDataGQL,
    private readonly saveSellingOpportunityFormData: SaveSellingOpportunityFormDataGQL
  ) {
    super(correlationId);
  }

  loadEntity(id: string, store: Store<unknown>): void {
    this.id = id;

    this.sellingOpportunityFormData.subscribe({ id })
      .subscribe(result => this.state.next(result.data!.sellingOpportunityById));
  }

  saveEntity(entity: SellingOpportunityFormData, store: Store<unknown>): void {
    this.saveSellingOpportunityFormData.mutate({
      id: this.id!,
      laufzeitBis: entity.laufzeitBis!,
      laufzeitVon: entity.laufzeitVon
    }).subscribe();
  }

  selectEntityById(id: string, store: Store<unknown>): Observable<SellingOpportunityFormData | undefined> {
    return this.state.asObservable();
  }

  createEntity(entity: SellingOpportunityFormData, customerId: string, store: Store<unknown>): void {
    throw new Error('Method not implemented.');
  }

  deleteEntity(id: string, store: Store<unknown>): void {
    throw new Error('Method not implemented.');
  }

  makeTemplate(parentId: string): SellingOpportunity {
    throw new Error('Method not implemented.');
  }

}
