import { Injectable } from '@angular/core';

const NRG_REMOTE_WINDOW_KEY = 'NRG_REMOTE_WINDOW_KEY';

@Injectable()
export class RemoteRegistryService {

  remoteRegistry: Map<string, any>;

  constructor() {
    (window as any)[NRG_REMOTE_WINDOW_KEY] = new Map();
    this.remoteRegistry = (window as any)[NRG_REMOTE_WINDOW_KEY];
  }

  registerRemote(injectable: any, symbol: string, remote: any) {
    this.remoteRegistry.set(symbol, remote);
    const ngOnDestroyOriginal = injectable.ngOnDestroy || (() => {
    });
    injectable.ngOnDestroy = () => {
      this.unregisterRemote(symbol);
      ngOnDestroyOriginal.call(injectable);
    };
  }

  private unregisterRemote(symbol: string) {
    this.remoteRegistry.delete(symbol);
  }
}
