import { Column, Workbook } from 'exceljs';
import { Injectable } from '@angular/core';
import { ExcelExportData, ExcelExportSeries } from './excel-export.model';

@Injectable({
  providedIn: 'root'
})
export class GenerateExcelService {

  async generateExcelReport(data: ExcelExportData): Promise<Blob> {

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Data');

    const colums: Array<Partial<Column>> = [
      { key: 'timeseries', width: 20, style: { numFmt: 'dd.mm.yyyy hh:MM' } }
    ];

    data.series.forEach((it: ExcelExportSeries<any>) => {
      colums.push({
        key: it.key,
      });
    });

    worksheet.columns = colums;

    const offset = new Date().getTimezoneOffset();

    worksheet.getColumn('timeseries').values = ['Datum/Uhrzeit', ...data.timeseries.map(it => new Date(it - offset * 60 * 1000))];

    data.series.forEach((it: ExcelExportSeries<any>) => {
      worksheet.getColumn(it.key).values = [it.name, ...this.columnValues(data.timeseries, it)];
    });

    const buffer = await workbook.xlsx.writeBuffer();

    return new Blob([buffer]);
  }

  columnValues<T>(timeseries: number[], series: ExcelExportSeries<T>): T[] {

    return timeseries.map(time => {
      return series.values[time];
    });
  }

}
