import {ApolloLink} from '@apollo/client/core';

export function createMapDataLink() {
  return new ApolloLink((operation, forward) => {
    const isoDateRegex = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/;
    if (operation.variables) {
      const mapValue = (key: string, value: any) => {
        if (key === '__typename') {
          return undefined;
        } else if (value instanceof Date) {
          return value.toISOString();
        } else {
          return value;
        }
      };
      operation.variables = JSON.parse(JSON.stringify(operation.variables), mapValue);
    }
    return (forward(operation)).map((data) => {
      const mapValue = (key: string, value: any) => {
        if (key === '__typename') {
          return undefined;
        } else if (typeof value === 'string' && isoDateRegex.test(value)) {
          return new Date(value);
        } else {
          return value;
        }
      };
      return JSON.parse(JSON.stringify(data), mapValue);
    });
  });
}
