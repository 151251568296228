<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="result-tree">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <nrg-simple-tree-item [node]="node" [query]="query"></nrg-simple-tree-item>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li>
      <nrg-simple-tree-item [icon]="treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'" [node]="node" [query]="query"></nrg-simple-tree-item>
      <ul class="children" [class.invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
