<nrg-layout
    [username]="username | async"
    [menuItems]="menuItems$ | async"
    (logout)="onLogout()"
    [version]="version"
    [actions]="actions$ | async"
    [isLoading]="isLoading$ | async">
  <div id="pageTop"></div>
  <router-outlet></router-outlet>
</nrg-layout>
