import { AxisLabel } from './axis';
import { format } from 'date-fns-tz';
import {
  addMonths,
  addYears,
  differenceInDays,
  eachMonthOfInterval,
  eachYearOfInterval,
  endOfDay,
  endOfHour,
  endOfMonth,
  startOfDay,
  startOfHour,
  startOfMonth
} from 'date-fns';
import { de } from 'date-fns/locale';

const locale: Locale = de;

export const secondInMs = 1000;
export const minuteInMs = 60000;
export const hourInMs = 3600000;
export const dayInMs = 86400000;
export const weekInMs = 604800000;
export const monthInMs = 28 * dayInMs;
export const yearInMs = 365 * dayInMs;

export type TimelineTick = [from: number, to: number, x: number];

type DateFormatter = (date: Date | number, timeZone: string) => string;
type DateFactory = (date: (Date | number)) => Date;
type TimelineTickGeneratorFactory = (fromOrigin: number, stepSizeInPx: number, minTickSpacingInPx: number) => TimelineTickGenerator;
export type TimelineTickGenerator = (from: number, to: number, widthInPx: number, stepSizeInMs: number, subdivisions: number[]) => Generator<TimelineTick>;
type TimelineSectionFactory = (stepSizeInPx: number, tickGenerator: TimelineTickGenerator) => (from: number, to: number) => TimelineSection;
type TimelineSectionType = 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds';
type TimelineMetaFactory = (stepSizeInMs: number,
                            getStart: DateFactory,
                            getEnd: DateFactory,
                            outerSection: TimelineSectionFactory,
                            innerSection: TimelineSectionFactory,
                            createOuterTickGenerator: TimelineTickGeneratorFactory,
                            createInnerTickGenerator: TimelineTickGeneratorFactory) => TimelineMeta;

export interface TimelineSection {
  readonly type: TimelineSectionType;
  readonly dateFormatter: DateFormatter;
  readonly tickGenerator: TimelineTickGenerator;
  readonly stepSizeInMs: number;
  readonly subdivisions: number[];
  readonly widthInPx: number;
}

export interface TimelineMeta {
  readonly outerSection: (from: number, to: number) => TimelineSection;
  readonly innerSection: (from: number, to: number) => TimelineSection;
  readonly stepSizeInPx: number;
  readonly axisWidthInPx: number;
  readonly fromOrigin: number;
  readonly toOrigin: number;
  readonly from: number;
  readonly to: number;
}

const normalizeDateRange = (start: number, end: number,
                            getStart: DateFactory,
                            getEnd: DateFactory): [from: number, to: number] => {
  const x0 = getStart(start).getTime();
  const x1 = end === getStart(end).getTime() ? end : getEnd(end).getTime() + 1;

  return [x0, x1];
};

function createTimelineMetaFactory(from: number, to: number, axisWidthInPx: number, stepSizeInPx: number, minTickSpacingInPx: number): TimelineMetaFactory {
  return (stepSizeInMs, getStart, getEnd, outerSection, innerSection, outerTickGeneratorFactory, innerTickGeneratorFactory) => {
    const [fromNormalized, toNormalized] = normalizeDateRange(from, to, getStart, getEnd);

    return {
      outerSection: outerSection(stepSizeInPx, outerTickGeneratorFactory(from, stepSizeInPx, minTickSpacingInPx)),
      innerSection: innerSection(stepSizeInPx, innerTickGeneratorFactory(from, stepSizeInPx, minTickSpacingInPx)),
      stepSizeInPx,
      axisWidthInPx,
      fromOrigin: from,
      toOrigin: to,
      from: fromNormalized,
      to: toNormalized,
    };
  };
}

export const createYearsSection: TimelineSectionFactory = (stepSizeInPx: number, tickGenerator: TimelineTickGenerator): (from: number, to: number) => TimelineSection => {
  return (from: number, to: number) => {
    const widthInPx = stepSizeInPx * (to - from);

    return {
      type: 'years',
      dateFormatter: yearDateFormatter,
      tickGenerator,
      stepSizeInMs: yearInMs,
      subdivisions: [1, 2, 3, 4, 6, 12],
      widthInPx,
    };
  };
};

export const createMonthsSection: TimelineSectionFactory = (stepSizeInPx: number, tickGenerator: TimelineTickGenerator): (from: number, to: number) => TimelineSection => {
  return (from: number, to: number) => {
    const widthInPx = stepSizeInPx * (to - from);

    return {
      type: 'months',
      dateFormatter: monthDateFormatter,
      tickGenerator,
      stepSizeInMs: monthInMs,
      subdivisions: [1, 2, 3, 4, 6, 12],
      widthInPx,
    };
  };
};

export const createDaysSection: TimelineSectionFactory = (stepSizeInPx: number, tickGenerator: TimelineTickGenerator): (from: number, to: number) => TimelineSection => {
  const stepSizeInMs = dayInMs;

  return (from: number, to: number) => {
    const days = differenceInDays(to, from);
    const widthInPx = stepSizeInPx * (days * stepSizeInMs);
    let subdivisions: number[];

    if (days === 30) {
      subdivisions = [1, 2, 3, 4, 5, 10, 15, 30];
    } else if (days === 31) {
      subdivisions = [1, 2, 3, 4, 5, 10, 15, 31];
    } else {
      // 28 days in month
      subdivisions = [1, 2, 4, 7, 14, 28];
    }

    return {
      type: 'days',
      dateFormatter: fullDateFormatter,
      tickGenerator,
      stepSizeInMs,
      subdivisions,
      widthInPx,
    };
  };
};

export const createHoursSection: TimelineSectionFactory = (stepSizeInPx: number, tickGenerator: TimelineTickGenerator): (from: number, to: number) => TimelineSection => {
  return (from: number, to: number) => {
    const widthInPx = stepSizeInPx * (to - from);

    return {
      type: 'hours',
      dateFormatter: shortTimeFormatter,
      tickGenerator,
      stepSizeInMs: hourInMs,
      subdivisions: [1, 2, 3, 4, 6, 8, 12, 24],
      widthInPx,
    };
  };
};

export const createMinutesSection: TimelineSectionFactory = (stepSizeInPx: number, tickGenerator: TimelineTickGenerator): (from: number, to: number) => TimelineSection => {
  return (from: number, to: number) => {
    const widthInPx = stepSizeInPx * (to - from);

    return {
      type: 'minutes',
      dateFormatter: fullTimeFormatter,
      tickGenerator,
      stepSizeInMs: minuteInMs,
      subdivisions: [1, 2, 4, 12, 30, 60],
      widthInPx,
    };
  };
};

export const createSecondsSection: TimelineSectionFactory = (stepSizeInPx: number, tickGenerator: TimelineTickGenerator): (from: number, to: number) => TimelineSection => {
  return (from: number, to: number) => {
    const widthInPx = stepSizeInPx * (to - from);

    return {
      type: 'seconds',
      dateFormatter: fullTimeFormatter,
      tickGenerator,
      stepSizeInMs: secondInMs,
      subdivisions: [1, 2, 4, 12, 30, 60],
      widthInPx,
    };
  };
};

// 01:00
const shortTimeFormatter: DateFormatter = (date: Date | number, timeZone: string) => format(date, 'HH:mm', { timeZone, locale });

// 01:00:00
const fullTimeFormatter: DateFormatter = (date: Date | number, timeZone: string) => format(date, 'HH:mm:ss', { timeZone, locale });

// Mo., 01.01.1970
const fullDateFormatter: DateFormatter = (date: Date | number, timeZone: string) => format(date, 'EEEEEE., dd.MM.yyyy', {
  timeZone,
  locale
});

// Feb., 1970
const monthDateFormatter: DateFormatter = (date: Date | number, timeZone: string) => format(date, 'MMM., yyyy', { timeZone, locale });

// 1970
const yearDateFormatter: DateFormatter = (date: Date | number, timeZone: string) => format(date, 'yyyy', { timeZone, locale });

export function getDateFormatter(timestamp: number): DateFormatter {
  if (timestamp % dayInMs === 0) {
    return fullDateFormatter;
  } else if (timestamp % minuteInMs !== 0) {
    return fullTimeFormatter;
  } else {
    return shortTimeFormatter;
  }
}

export function calculateStepSizeInPx(from: number, to: number, axisWidthInPx: number): number {
  const distanceInMs = to - from;

  if (distanceInMs <= 0) {
    return 0;
  }

  return axisWidthInPx / distanceInMs;
}

export function getTimelineMeta(from: number, to: number, axisWidthInPx: number, minTickSpacing: number): TimelineMeta {
  const stepSizeInPx = calculateStepSizeInPx(from, to, axisWidthInPx);
  const createTimelineMeta = createTimelineMetaFactory(from, to, axisWidthInPx, stepSizeInPx, minTickSpacing);
  const distanceInMs = to - from;

  if (distanceInMs >= yearInMs) {
    return createTimelineMeta(yearInMs, startOfMonth, endOfMonth, createYearsSection, createMonthsSection, generateYearTicks(axisWidthInPx, true), generateMonthTicks(axisWidthInPx, false));

  } else if (distanceInMs >= monthInMs) {
    return createTimelineMeta(monthInMs, startOfMonth, endOfMonth, createMonthsSection, createDaysSection, generateMonthTicks(axisWidthInPx, true), generateTimelineTicks(axisWidthInPx, false));

  } else if (distanceInMs >= dayInMs) {
    return createTimelineMeta(dayInMs, startOfDay, endOfDay, createDaysSection, createHoursSection, generateTimelineTicks(axisWidthInPx, true), generateTimelineTicks(axisWidthInPx, false));

  } else if (distanceInMs >= hourInMs) {
    return createTimelineMeta(hourInMs, startOfDay, endOfDay, createHoursSection, createMinutesSection, generateTimelineTicks(axisWidthInPx, true), generateTimelineTicks(axisWidthInPx, false));

  } else {
    return createTimelineMeta(minuteInMs, startOfHour, endOfHour, createMinutesSection, createSecondsSection, generateTimelineTicks(axisWidthInPx, true), generateTimelineTicks(axisWidthInPx, false));
  }

  throw new Error('unsupported date range');
}

export function calculateStep(from: number, to: number, widthInPx: number, minTickSpacingInPx: number, stepSizeInMs: number, subdivisions: number[]): number {
  const maxSubdivision = (to - from) / stepSizeInMs;
  const ticks = maxSubdivision + 1;
  const maxTicks = widthInPx / minTickSpacingInPx + 1;
  const maxStep = ticks / maxTicks;

  const step = subdivisions.find(division => division >= maxStep) ?? maxSubdivision;

  return Math.max(step, 1); // if step is less than 1, then we can draw all ticks
}

function checkBoundaries(from: number, to: number, fromOrigin: number, axisWidth: number, stepSizeInPx: number, minTickSpacingInPx: number): (x: number) => boolean {
  const toX = stepSizeInPx * (to - fromOrigin);
  const fromX = stepSizeInPx * (from - fromOrigin);

  // allow a smaller tick spacing at the edges, to avoid missing start and end ticks in some edge cases
  const minBoundaryTickSpacingInPx = minTickSpacingInPx - 5;

  return (x: number): boolean => {
    return x >= 0 && x <= axisWidth &&
      x - fromX >= minBoundaryTickSpacingInPx &&
      toX - x >= minBoundaryTickSpacingInPx;
  };
}

export function generateTimelineTicks(axisWidth: number, disableBoundaryCheck: boolean): (fromOrigin: number, stepSizeInPx: number, minTickSpacingInPx: number) => TimelineTickGenerator {
  return (fromOrigin: number, stepSizeInPx: number, minTickSpacingInPx: number) => {
    return function *(from: number, to: number, widthInPx: number, stepSizeInMs: number, subdivisions: number[]) {
      const step = calculateStep(from, to, widthInPx, minTickSpacingInPx, stepSizeInMs, subdivisions);
      const increaseByInMs = step * stepSizeInMs;
      const _checkBoundaries = checkBoundaries(from, to, fromOrigin, axisWidth, stepSizeInPx, minTickSpacingInPx);

      for (let timestamp = from; timestamp <= to; timestamp += increaseByInMs) {
        const x = stepSizeInPx * (timestamp - fromOrigin);

        if (disableBoundaryCheck || _checkBoundaries(x)) {
          yield [timestamp, timestamp + increaseByInMs, x];
        }
      }
    };
  };
}

type IntervalFn = (interval: Interval) => Date[];
type AddTimeFn = (current: number, amount: number) => Date;

function generateTimelineTicksUsingIntervalFn(axisWidth: number, disableBoundaryCheck: boolean, eachOfInterval: IntervalFn, addTime: AddTimeFn): (fromOrigin: number, stepSizeInPx: number, minTickSpacingInPx: number) => TimelineTickGenerator {
  return (fromOrigin: number, stepSizeInPx: number, minTickSpacingInPx: number) => {
    return function *(from: number, to: number, widthInPx: number, stepSizeInMs: number, subdivisions: number[]) {
      const step = calculateStep(from, to, widthInPx, minTickSpacingInPx, stepSizeInMs, subdivisions);
      const _checkBoundaries = checkBoundaries(from, to, fromOrigin, axisWidth, stepSizeInPx, minTickSpacingInPx);
      const dates = eachOfInterval({
        start: from, end: addTime(to, 1)
      });

      for (let i = 0; i < dates.length - 1; i += step) {
        const timestamp = dates[i].getTime();
        const nextTimestamp = dates[i + 1].getTime();
        const x = stepSizeInPx * (timestamp - fromOrigin);

        if (disableBoundaryCheck || _checkBoundaries(x)) {
          yield [timestamp, nextTimestamp, x];
        }
      }
    };
  };
}

export function generateMonthTicks(axisWidth: number, disableBoundaryCheck: boolean): (fromOrigin: number, stepSizeInPx: number, minTickSpacingInPx: number) => TimelineTickGenerator {
  return generateTimelineTicksUsingIntervalFn(axisWidth, disableBoundaryCheck, eachMonthOfInterval, addMonths);
}

export function generateYearTicks(axisWidth: number, disableBoundaryCheck: boolean): (fromOrigin: number, stepSizeInPx: number, minTickSpacingInPx: number) => TimelineTickGenerator {
  return generateTimelineTicksUsingIntervalFn(axisWidth, disableBoundaryCheck, eachYearOfInterval, addYears);
}

export function *createTimeline(axisWidth: number, from: number, to: number, timeZone: string = 'Europe/Berlin', minTickSpacingInPx: number = 20): IterableIterator<AxisLabel<string>> {
  const dimensions = getTimelineMeta(from, to, axisWidth, minTickSpacingInPx);

  const outerSection = dimensions.outerSection(dimensions.from, dimensions.to);
  const outerSectionTicks = outerSection.tickGenerator(dimensions.from, dimensions.to, outerSection.widthInPx, outerSection.stepSizeInMs, outerSection.subdivisions);
  const outerDateFormatter = outerSection.dateFormatter;

  for (const [currentOuter, nextOuter, xOuter] of outerSectionTicks) {
    if (xOuter >= 0 && xOuter <= axisWidth + 1) {
      yield [outerDateFormatter(currentOuter, timeZone), xOuter];
    }

    // avoid calculating inner ticks outside of the visible area
    if (axisWidth - xOuter <= minTickSpacingInPx) {
      break;
    }

    const innerSection = dimensions.innerSection(currentOuter, nextOuter);
    const innerDateFormatter = innerSection.dateFormatter;
    const innerSectionTicks = innerSection.tickGenerator(currentOuter, nextOuter, innerSection.widthInPx, innerSection.stepSizeInMs, innerSection.subdivisions);

    for (const [currentInner, _, xInner] of innerSectionTicks) {
      yield [innerDateFormatter(currentInner, timeZone), xInner];
    }
  }
}
