import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ToolbarItem, ToolBarItemType } from './models/toolbar-item';

@Component({
  selector: 'nrg-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NrgToolbarComponent {

  @Input() colorToolbar = false;

  @Input()
  public isWindowSmall = false;

  @Input() showLogo = false;

  @Input()
  public startCollapsed = false;

  @Input()
  public title?: string;

  @Input()
  public items: ToolbarItem[] = [];

  @Input()
  public inMatCard = false;

  @Input()
  public showMenuButton = false;

  @Input()
  public disableTitleClick = false;

  @Input()
  public showLogoutButton = false;

  @Input()
  public fullWidth = false;

  @Output()
  public toggleMenu = new EventEmitter<void>();

  @Output()
  public logout = new EventEmitter<void>();

  @Output()
  public titleClick = new EventEmitter<void>();

  onToggleMenu() {
    this.toggleMenu.emit();
  }

  onLogout() {
    this.logout.emit();
  }

  handleTitleClick() {
    if (!this.disableTitleClick) {
      this.titleClick.emit();
    }
  }

  isLabel(toolbarItemType: ToolBarItemType): boolean {
    return toolbarItemType === ToolBarItemType.LABEL;
  }
}
