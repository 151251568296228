import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { fieldDisplayName } from './field-display-name';
import {
  AssetType,
  JobProblem, JobProblemType,
  JobState,
  JobType,
  OptimizationProblemsGQL,
  OptimizationStateGQL
} from './optimization-problems.graphql.generated';

@Component({
  selector: 'vk-optimization-problem-list',
  templateUrl: './optimization-problem-list.component.html',
  styleUrls: ['./optimization-problem-list.component.css']
})
export class OptimizationProblemListComponent implements OnInit {

  private readonly assetId$$ = new BehaviorSubject<string | undefined>(undefined);
  private readonly jobType$$ = new ReplaySubject<JobType>(1);

  public problems$: Observable<JobProblem[]>;

  @Input()
  public showProgressIndicator: boolean;

  @Input()
  public showFatalMessageInClear: boolean;

  @Input()
  public set assetId(value: string) {
    this.assetId$$.next(value);
  }

  @Input()
  public assetType: AssetType;

  @Input()
  public set jobType(value: JobType) {
    this.jobType$$.next(value);
  }

  public showProgressIndicator$: Observable<boolean>;

  constructor(
    private readonly jobProblems: OptimizationProblemsGQL,
    private readonly state: OptimizationStateGQL,
  ) {
  }

  ngOnInit(): void {
    this.showProgressIndicator$ = !this.showProgressIndicator
      ? of(false)
      : combineLatest([
        this.assetId$$,
        this.jobType$$
      ]).pipe(
        switchMap(([assetId, jobType]) => {
          return this.state.subscribe({
            jobType,
            assetId,
            assetType: this.assetType
          });
        }),
        map(response => response.data.optimizationJobState),
        map( results =>  results.length !== 0 && results[0]?.state !== JobState.Inactive)
      );

    this.problems$ = combineLatest([
      this.assetId$$,
      this.jobType$$
    ]).pipe(
      switchMap(([assetId, jobType]) => {
        return this.jobProblems.subscribe({
          jobType,
          assetId,
          assetType: this.assetType
        });
      }),
      map(result => {
        return result.data.optimizationJobProblems;
      })
    );

  }

  public fieldDisplayName(fieldName: string) {
    return fieldDisplayName(fieldName);
  }

  public icon(type: JobProblemType): string {
    switch (type) {
      case JobProblemType.Fatal:
      case JobProblemType.Error:
      case JobProblemType.Violation:
        return 'error';
      case JobProblemType.Warning:
        return 'warning';
    }
  }

  public problemTypeDisplay(type: JobProblemType): string {
    switch (type) {
      case JobProblemType.Error:
      case JobProblemType.Fatal:
        return 'Fehler';
      case JobProblemType.Violation:
        return 'Nebenbedingungsverletzung';
      case JobProblemType.Warning:
        return 'Warnung';
    }
  }

  public problemMessageDisplay(problem: JobProblem): string {
    if (!this.showFatalMessageInClear && problem.type === JobProblemType.Fatal) {
      return 'Es ist ein unerwartetes Problem bei der Fahrplanoptimierung aufgetreten.';
    } else {
      return problem.message;
    }
  }

  classNameForProblem(problem: JobProblem) {
    switch (problem.type) {
      case JobProblemType.Error:
        return 'error';
      case JobProblemType.Warning:
        return 'warning';
      case JobProblemType.Violation:
        return 'error';
      case JobProblemType.Fatal:
        return 'fatal';
    }

  }
}
