export interface ToolbarItem {
  icon: string;
  text?: string;
  itemType: ToolBarItemType;
  disabled: boolean;
  execute: () => void;
}

export enum ToolBarItemType {
  ACTION,
  LABEL
}

export const handbookAction: ToolbarItem =  {
  itemType: ToolBarItemType.ACTION,
  icon: 'help',
  text: 'Handbuch',
  disabled: false,
  execute: () => {
    window.open('./assets/docs/handbook.pdf', '_blank');
  }
};

export const logoutAction: ToolbarItem =  {
  itemType: ToolBarItemType.ACTION,
  icon: 'power_settings_new',
  text: '',
  disabled: false,
  execute: () => {}
};

export const userLabel: ToolbarItem =  {
  itemType: ToolBarItemType.LABEL,
  icon: 'person',
  text: '',
  disabled: false,
  execute: () => {}
};

