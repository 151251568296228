import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DataPoint } from '../../products/products-overview.graphql.generated';

interface FormValues {
  unitType: number;
  unitNumber: number;
  dataPoint: string;
}

export interface DetachEvent {
  entityId: string;
  cpeId: string;
}

export interface UpdateEvent {
  entityId: string;
  cpeId: string;
  unitType: number;
  unitNumber: number;
  dataPoint: string;
}

@Component({
  selector: 'vk-entity-config-card[cpeId][entityId]',
  templateUrl: './entity-config-card.component.html',
  styleUrls: ['./entity-config-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityConfigCardComponent implements OnInit {

  public readonly form: FormGroup = new FormGroup({
    unitType: new FormControl(),
    unitNumber: new FormControl(),
    dataPoint: new FormControl()
  });

  public selectedDataPoint = '---';
  public dataPointOptions: Array<string> = [];

  public readonly readonly$ = new BehaviorSubject<boolean>(false);

  public readonly fieldErrorMessage = 'Der angegebene Wert ist ungültig.';

  public get unitTypeInput(): AbstractControl {
    return this.form.get('unitType')!;
  }

  public get unitNumberInput(): AbstractControl {
    return this.form.get('unitNumber')!;
  }

  @Input() public cpeId!: string;
  @Input() public entityId!: string;
  @Input() public tag?: string;
  @Input() public name?: string;
  @Input() public unitType?: number;
  @Input() public unitNumber?: number;

  @Input()
  public set dataPoint(dataPoint: DataPoint) {
    this.selectedDataPoint = dataPoint.dataPoint;
    this.dataPointOptions = dataPoint.options;
  }

  @Input()
  public set readonly(value: boolean) {
    this.readonly$.next(value);
  }

  @Output() public detach = new EventEmitter<DetachEvent>();
  @Output() public update = new EventEmitter<UpdateEvent>();

  constructor() {
  }

  ngOnInit(): void {
    this.form.patchValue({
      unitType: this.unitType,
      unitNumber: this.unitNumber,
      dataPoint: this.dataPoint?.dataPoint
    });
  }

  onUpdate(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    const values: FormValues = this.form.value;


    this.update.emit({
      cpeId: this.cpeId,
      entityId: this.entityId,
      ...{
        ...values,
        dataPoint: this.selectedDataPoint
      }
    });
  }

  onDetach(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    this.detach.emit({
      cpeId: this.cpeId,
      entityId: this.entityId
    });
  }
}
