import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { KeycloakService } from 'keycloak-angular';
import { catchError, flatMap, map } from 'rxjs/operators';
import { VkAuthenticationService } from '../authentication.service';
import * as AuthActions from './authentication.actions';
import { deleteCurrentUser } from './authentication.actions';
import { concat, EMPTY, of, throwError } from 'rxjs';
import { AuthenticationErrorType } from '../session-timeout-error';

@Injectable()
export class AuthenticationEffects {

  getLoggedInUser$ = createEffect(() => this.actions.pipe(
    ofType(AuthActions.getLoggedInUser),
    flatMap(() => {
      const impersonatedUserId = parseLocation();
      const impersonateActions = impersonatedUserId ? of(AuthActions.setImpersonatedUser({ impersonatedUserId })) : EMPTY;
      const setUserActions = this.authenticationService.loadUser().pipe(
        map(currentUser => {
          return AuthActions.setCurrentUser({
            user: {
              ...currentUser,
            }
          });
        }),
        catchError(error => {
          if (isSessionTimeoutError(error)) {
            return of(AuthActions.logoutCurrentUser());
          }

          return throwError(error);
        })
      );
      return concat(impersonateActions, setUserActions);
    }),
  ));

  logoutCurrentUser$ = createEffect(() => this.actions.pipe(
    ofType(AuthActions.logoutCurrentUser),
    flatMap(() => {
      return this.authenticationService.logout()
        .pipe(
          map(() => deleteCurrentUser())
        );
    }))
  );

  constructor(
    private readonly actions: Actions,
    private readonly router: Router,
    private readonly keycloakService: KeycloakService,
    private readonly authenticationService: VkAuthenticationService) {
  }
}

function isSessionTimeoutError(error: any): boolean {
  return error instanceof Object &&
    'type' in error &&
    error.type === AuthenticationErrorType.SessionTimeout;
}

function parseLocation(): string | null {
  const hash = location.hash;
  if (!hash.startsWith('#')) {
    return null;
  }

  let result: string | null = null;

  const split = hash.slice(1).split('/');

  if (split[0] === 'impersonate') {
    result = decodeURI(split[1]);
  }

  history.replaceState('', document.title, location.pathname + location.search);

  return result;
}

