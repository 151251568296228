<form #schemaForm="ngForm" *ngIf="formGroup != null" fxLayout="column" (ngSubmit)="handleSubmit()">
  <ng-container *ngTemplateOutlet="formGroupTemplate;context:{fg:formGroup,s:schema}">

  </ng-container>
  <button mat-flat-button id="submitButton" [class.hidden]="hideSubmitButton" color="accent" [disabled]="!canSubmit"
          type="submit">{{submitButtonTitle}}</button>
</form>
<ng-template #formGroupTemplate let-schema="s" let-formGroup="fg">

  <ng-container [formGroup]="formGroup" *ngFor="let propertyKey of propertyKeys(formGroup);"
                [ngSwitch]="schemaForProperty(schema, propertyKey).type">
    <div [class.hidden]="schemaForProperty(schema, propertyKey).hidden" class="form-input"
         [class.error]="hasError(formGroup, propertyKey) || hasWarnings(formGroup, propertyKey)">
      <mat-form-field *ngSwitchCase="'number'" floatLabel="always">
        <mat-label
            [class.readonly]="isReadonly(schema, propertyKey)">{{titleForProperty(schema, propertyKey)}}</mat-label>
        <input matInput type="number" lang="de-DE" [readonly]="isReadonly(schema, propertyKey)"
               [placeholder]="defaultValue(schema, propertyKey)"
               [formControlName]="propertyKey">
        <mat-error *ngIf="hasError(formGroup, propertyKey)">{{getError(formGroup, schema, propertyKey)}}</mat-error>
        <mat-hint class="warning"
                  *ngIf="hasWarnings(formGroup, propertyKey)">{{getWarning(formGroup, schema, propertyKey)}}</mat-hint>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'string'" floatLabel="always">
        <mat-label
            [class.readonly]="isReadonly(schema, propertyKey)">{{titleForProperty(schema, propertyKey)}}</mat-label>
        <input matInput [formControlName]="propertyKey" [readonly]="isReadonly(schema, propertyKey)"
               [placeholder]="defaultValue(schema, propertyKey)">
        <mat-error *ngIf="hasError(formGroup, propertyKey)">{{getError(formGroup, schema, propertyKey)}}</mat-error>
        <mat-hint class="warning"
                  *ngIf="hasWarnings(formGroup, propertyKey)">{{getWarning(formGroup, schema, propertyKey)}}</mat-hint>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'dateString'" floatLabel="always">
        <mat-label
            [class.readonly]="isReadonly(schema, propertyKey)">{{titleForProperty(schema, propertyKey)}}</mat-label>
        <input matInput autocomplete="off" [matDatepicker]="thisDatepicker" [formControlName]="propertyKey"
               readonly>
        <mat-datepicker-toggle matSuffix [for]="thisDatepicker"></mat-datepicker-toggle>
        <mat-datepicker [disabled]="isReadonly(schema, propertyKey)" #thisDatepicker></mat-datepicker>
        <mat-hint class="warning"
                  *ngIf="hasWarnings(formGroup, propertyKey)">{{getWarning(formGroup, schema, propertyKey)}}</mat-hint>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'monthYearString'" floatLabel="always">
        <mat-label
            [class.readonly]="isReadonly(schema, propertyKey)">{{titleForProperty(schema, propertyKey)}}</mat-label>
        <input matInput autocomplete="off" [matDatepicker]="thisDatepicker" [formControlName]="propertyKey"
               readonly>
        <mat-datepicker-toggle matSuffix [for]="thisDatepicker"></mat-datepicker-toggle>
        <mat-datepicker
            [disabled]="isReadonly(schema, propertyKey)"
            startView="multi-year"
            (monthSelected)="chosenMonthHandler($event, thisDatepicker, formGroup, propertyKey)"
            #thisDatepicker>
        </mat-datepicker>
        <mat-hint class="warning"
                  *ngIf="hasWarnings(formGroup, propertyKey)">{{getWarning(formGroup, schema, propertyKey)}}</mat-hint>
      </mat-form-field>
      <ng-container *ngSwitchCase="'array'">
        <ng-container *ngIf="isFormArray(schema, propertyKey); else noFormArray">
          <div class="form-group">
            <h3 class="nrg-form-group-header">{{titleForProperty(schema, propertyKey)}}</h3>
            <div [formArrayName]="propertyKey"
                 *ngFor="let arrayItem of getFormArray(formGroup, propertyKey).controls; let i=index">
              <h4>{{i + 1}}.
                <button *ngIf="(_disabled | async) === false" mat-icon-button
                        (click)="removeFormGroup(getFormArray(formGroup, propertyKey), i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </h4>
              <ng-container
                  *ngTemplateOutlet="formGroupTemplate;context:{fg:arrayItem,s:getArraySchema(schema, propertyKey)}">

              </ng-container>
            </div>
            <button *ngIf="(_disabled | async) === false && notAtMaxItems(schema, formGroup, propertyKey)"
                    mat-icon-button
                    (click)="addNewFormGroup(getFormArray(formGroup, propertyKey), getArraySchema(schema, propertyKey), $event)">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </ng-container>
        <ng-template #noFormArray>
          <mat-form-field floatLabel="always">
            <mat-label
                [class.readonly]="isReadonly(schema, propertyKey)">{{titleForProperty(schema, propertyKey)}}</mat-label>
            <input matInput [formControlName]="propertyKey" [readonly]="isReadonly(schema, propertyKey)">
            <mat-error *ngIf="hasError(formGroup, propertyKey)">{{getError(formGroup, schema, propertyKey)}}</mat-error>
            <mat-hint class="warning"
                      *ngIf="hasWarnings(formGroup, propertyKey)">{{getWarning(formGroup, schema, propertyKey)}}</mat-hint>
          </mat-form-field>
        </ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'boolean'">
        <mat-form-field [class.readonly]="isReadonly(schema, propertyKey)" floatLabel="always">
          <mat-label
              [class.readonly]="isReadonly(schema, propertyKey)">{{titleForProperty(schema, propertyKey)}}</mat-label>
          <mat-select [formControlName]="propertyKey"
                      [placeholder]="defaultValue(schema, propertyKey)">
            <mat-option *ngIf="!isRequired(schema, propertyKey)" [value]="null">{{'- -'}}</mat-option>
            <mat-option [value]="true">Ja</mat-option>
            <mat-option [value]="false">Nein</mat-option>
          </mat-select>
          <mat-error *ngIf="hasError(formGroup, propertyKey)">{{getError(formGroup, schema, propertyKey)}}</mat-error>
          <mat-hint class="warning"
                    *ngIf="hasWarnings(formGroup, propertyKey)">{{getWarning(formGroup, schema, propertyKey)}}</mat-hint>
        </mat-form-field>
      </ng-container>
      <ng-container *ngSwitchCase="'object'">
        <div class="form-group">
          <h3 class="nrg-form-group-header">{{titleForProperty(schema, propertyKey)}}</h3>
          <ng-container
              *ngTemplateOutlet="formGroupTemplate;context:{fg:formGroupForProperty(formGroup,propertyKey),s:schemaForProperty(schema, propertyKey)}">

          </ng-container>
        </div>
      </ng-container>
      <mat-form-field [class.readonly]="isReadonly(schema, propertyKey)" *ngIf="isEnum(schema, propertyKey)"
                      floatLabel="always">
        <mat-label
            [class.readonly]="isReadonly(schema, propertyKey)">{{titleForProperty(schema, propertyKey)}}</mat-label>
        <mat-select [formControlName]="propertyKey"
                    [placeholder]="defaultValue(schema, propertyKey)">
          <mat-option *ngIf="!isRequired(schema, propertyKey)" [value]="null">{{'- -'}}</mat-option>
          <mat-option *ngFor="let value of $any(schemaForProperty(schema, propertyKey)).enum" [value]="value">{{value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError(formGroup, propertyKey)">{{getError(formGroup, schema, propertyKey)}}</mat-error>
        <mat-hint class="warning"
                  *ngIf="hasWarnings(formGroup, propertyKey)">{{getWarning(formGroup, schema, propertyKey)}}</mat-hint>
      </mat-form-field>
      <ng-container *ngIf="schemaForProperty(schema, propertyKey).description">
        <mat-icon matSuffix [matTooltip]="schemaForProperty(schema, propertyKey).description">help</mat-icon>
      </ng-container>
    </div>
  </ng-container>

</ng-template>
