<mat-progress-bar *ngIf="showProgressIndicator$ | async" mode="indeterminate"></mat-progress-bar>
<ng-container *ngIf="problems$ | async as problems">
  <div *ngFor="let problem of problems" fxLayout="row" class="problem">
    <div>
      <div class="icon-circle">
        <span [className]="classNameForProblem(problem)"><mat-icon>{{icon(problem.type)}}</mat-icon></span>
      </div>
    </div>
    <div>
      <h3>
        <span [className]="classNameForProblem(problem)">
          {{problemTypeDisplay(problem.type)}} bei
          <span *ngIf="problem.field">'{{fieldDisplayName(problem.field)}}' von </span>
          {{problem.assetName}}
        </span>
      </h3>
      {{problemMessageDisplay(problem)}} [Fehlercode: {{problem.code || "000"}}]
    </div>
  </div>
</ng-container>
