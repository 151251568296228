import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityFormComponent } from './entity-form.component';
import { NrgBannerModule, NrgSchemaFormModule, NrgToolbarModule } from '@nrg/components';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    EntityFormComponent,
  ],
  exports: [
    EntityFormComponent
  ],
  imports: [
    CommonModule,
    NrgBannerModule,
    NrgSchemaFormModule,
    MatProgressBarModule,
    NrgToolbarModule,
  ],
})
export class VkEntityFormModule {

}
