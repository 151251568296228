export const enum Permission {
  SHARED_DATA_READ = 'SHARED_DATA_READ',
  SHARED_DATA_WRITE = 'SHARED_DATA_WRITE',
  ASSETS_READ = 'ASSETS_READ',
  ASSETS_WRITE = 'ASSETS_WRITE',
  ASSETS_WRITE_CUSTOMER = 'ASSETS_WRITE_CUSTOMER',
  CONTRACTS_READ = 'CONTRACTS_READ',
  CONTRACTS_WRITE_SALES = 'CONTRACTS_WRITE_SALES',
  CONTRACTS_WRITE_CUSTOMER = 'CONTRACTS_WRITE_CUSTOMER',
  BILLING_READ = 'BILLING_READ',
  BILLING_WRITE = 'BILLING_WRITE',
  OPTIMIZATION_READ = 'OPTIMIZATION_READ',
  SYSTEM_WRITE = 'SYSTEM_WRITE',
  CUSTOMERS_CREATE = 'CUSTOMERS_CREATE',
  REDISPATCH_READ = 'REDISPATCH_READ',
  REDISPATCH_WRITE = 'REDISPATCH_WRITE'
}

export function parsePermission(string: string): Permission | null {
  switch (string) {
    case 'SHARED_DATA_READ':
      return Permission.SHARED_DATA_READ;
    case 'SHARED_DATA_WRITE':
      return Permission.SHARED_DATA_WRITE;
    case 'ASSETS_READ':
      return Permission.ASSETS_READ;
    case 'ASSETS_WRITE':
      return Permission.ASSETS_WRITE;
    case 'ASSETS_WRITE_CUSTOMER':
      return Permission.ASSETS_WRITE_CUSTOMER;
    case 'CONTRACTS_READ':
      return Permission.CONTRACTS_READ;
    case 'CONTRACTS_WRITE_SALES':
      return Permission.CONTRACTS_WRITE_SALES;
    case 'CONTRACTS_WRITE_CUSTOMER':
      return Permission.CONTRACTS_WRITE_CUSTOMER;
    case 'BILLING_READ':
      return Permission.BILLING_READ;
    case 'BILLING_WRITE':
      return Permission.BILLING_WRITE;
    case 'OPTIMIZATION_READ':
      return Permission.OPTIMIZATION_READ;
    case 'SYSTEM_WRITE':
      return Permission.SYSTEM_WRITE;
    case 'CUSTOMERS_CREATE':
      return Permission.CUSTOMERS_CREATE;
    case 'REDISPATCH_READ':
      return Permission.REDISPATCH_READ;
    case 'REDISPATCH_WRITE':
      return Permission.REDISPATCH_WRITE;
    default:
      return null;
  }
}

export interface User {
  id: string;
  name: string;
  token?: string;
  impersonatedUserId?: string;
  permissions: Permission[];
  sessionId: string;
}
