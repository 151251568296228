export type Color = RgbColor | RgbaColor;

export interface RgbColor {
  type: 'rgb';
  r: number;
  g: number;
  b: number;
}

export interface RgbaColor {
  type: 'rgba';
  r: number;
  g: number;
  b: number;
  a: number;
}

export function colorToString(color: Color): string {
  switch (color.type) {
    case 'rgb':
      return `rgb(${color.r},${color.g},${color.b})`;
    case 'rgba':
      return `rgba(${color.r},${color.g},${color.b},${color.a})`;
  }
}

export function colorWithAlpha(color: Color, alpha: number): RgbaColor {
  switch (color.type) {
    case 'rgb':
      return { ...color, type: 'rgba', a: alpha };
    case 'rgba':
      return { ...color, a: alpha };
  }
}

/**
 * Gets all color components (h, s, l) from the given hsl color string
 * @param hsl a color string in form of 'hsl(360, 100%, 100%)'
 * @return the normalized components of the hsl color
 */
export function getHslColorComponents(hsl: string): [h: number, s: number, l: number] {
  const match = hsl.match(/hsl\(\s*(\d{1,3})\s*,\s*(\d{1,3})%\s*,\s*(\d{1,3})%\s*\)/) ?? [];

  if (match.length >= 4) {
    const h = parseInt(match[1], 10) / 360;
    const s = parseInt(match[2], 10) / 100;
    const l = parseInt(match[3], 10) / 100;

    return [h, s, l];
  }

  return [0, 0, 0];
}

/**
 * transforms a HSL color string to a RGB color.
 * This is a workaround for highcharts which does not support HSL colors :(
 * @param hsl a HSL color string 'hsl(0, 0%, 0%)'
 * @return a RGB color
 */
export function hslToRgb(hsl: string): Color {
  const [h, s, l] = getHslColorComponents(hsl);
  let r: number;
  let g: number;
  let b: number;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return {
    type: 'rgb',
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255)
  };
}

function hue2rgb(p: number, q: number, t: number): number {
  if (t < 0) {
    t += 1;
  }
  if (t > 1) {
    t -= 1;
  }
  if (t < 1 / 6) {
    return p + (q - p) * 6 * t;
  }
  if (t < 1 / 2) {
    return q;
  }
  if (t < 2 / 3) {
    return p + (q - p) * (2 / 3 - t) * 6;
  }
  return p;
}
