import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  CloseConnectionGQL,
  CpeConnectionState,
  CpesForTenantGQL,
  CustomerPremisesEquipment,
  OpenConnectionGQL
} from './cpe-status-list.graphql.generated';
import { VkLoadingHandler, selectIsLoadingByContext } from '@vk/common';
import { select, Store } from '@ngrx/store';
import { VkAuthenticationState, selectCustomerId } from '@vk/authentication';

const correlationId = 'VkCpeStatusListComponent';

@Component({
  selector: 'vk-cpe-status-list',
  templateUrl: './cpe-status-list.component.html',
  styleUrls: ['./cpe-status-list.component.scss']
})
export class VkCpeStatusListComponent {

  private searchTerms$ = new BehaviorSubject<string>('');

  constructor(
    private cpesGql: CpesForTenantGQL,
    private openConnectionGql: OpenConnectionGQL,
    private closeConnectionGql: CloseConnectionGQL,
    private loadingHandler: VkLoadingHandler,
    private store: Store<VkAuthenticationState>
  ) {
  }

  loading$ = this.store.pipe(select(selectIsLoadingByContext, { correlationId }));
  tenantId$ = this.store.pipe(select(selectCustomerId));

  allCpes$ = this.tenantId$.pipe(
    switchMap(tenantId => {
      return this.cpesGql.subscribe({ tenantId });
    }),
    map(it => it.data?.customerPremisesEquipmentsByTenantId ?? []),
    this.loadingHandler.untilFirst('CpesForTenantGQL', correlationId)
  ) as Observable<CustomerPremisesEquipment[]>;

  cpes$ = combineLatest([this.allCpes$, this.searchTerms$]).pipe(
    map(([cpes, term]) => {
      if (!term) {
        return cpes;
      }
      return cpes.filter(cpe => {
        return cpe.name.indexOf(term) !== -1 || (cpe.ipAddress != null && cpe.ipAddress.indexOf(term) !== -1);
      });
    }),
  );

  openConnection(id: string): void {
    this.openConnectionGql.mutate({ protocolId: id }).pipe(
      this.loadingHandler.untilComplete('OpenConnectionGQL', correlationId)
    ).subscribe();
  }

  closeConnection(id: string): void {
    this.closeConnectionGql.mutate({ protocolId: id }).pipe(
      this.loadingHandler.untilComplete('CloseConnectionGQL', correlationId)
    ).subscribe();
  }

  search(term: string): void {
    this.searchTerms$.next(term);
  }

  toggleConnection(cpeState: CpeConnectionState, id: string): void {
    switch (cpeState) {
      case CpeConnectionState.Active:
        this.closeConnection(id);
        break;
      case CpeConnectionState.Available:
        this.openConnection(id);
        break;
    }
  }

  toggleConnectionDisplayString(cpeState: CpeConnectionState): string {
    switch (cpeState) {
      case CpeConnectionState.Active:
        return 'Verbindung schließen';
      case CpeConnectionState.Available:
        return 'Verbindung aufbauen';
    }
  }

  connectionStateDisplayString(cpeState: CpeConnectionState): string {
    switch (cpeState) {
      case CpeConnectionState.Active:
        return 'Aktiv';
      case CpeConnectionState.Available:
        return 'Verfügbar';
    }
  }

  connectionStateClass(cpeState: CpeConnectionState): string {
    switch (cpeState) {
      case CpeConnectionState.Active:
        return 'active';
      case CpeConnectionState.Available:
        return 'available';
    }
  }
}
