import { select, Store } from '@ngrx/store';
import { SchemaFormDataSource } from '@nrg/components';
import { Observable, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Permission, selectAnyPermissionPresent } from '@vk/authentication';
import { map, takeUntil } from 'rxjs/operators';
import { EntityType, VkCommonState } from '@vk/common';

export abstract class EntityFormDataSource<TEntity, TValues> extends SchemaFormDataSource<TValues> {

  abstract readonly entityType: EntityType;
  abstract readonly writePermission: Permission;

  readonly alternativeWritePermission: Permission | null = null;

  readonly dataSourceWillBeDestroyed = new Subject();

  constructor(public readonly correlationId: string) {
    super();
  }

  selectIsReadonly(store: Store<VkCommonState>): Observable<boolean> {
    const permissions = this.alternativeWritePermission ? [this.writePermission, this.alternativeWritePermission] : [this.writePermission];
    return store.pipe(
      select(selectAnyPermissionPresent, { permissions }),
      takeUntil(this.dataSourceWillBeDestroyed),
      map(it => !it)
    );
  }

  abstract selectEntityById(id: string, store: Store<VkCommonState>): Observable<TEntity | undefined>;

  abstract selectFormValues(entity: TEntity): TValues | undefined;

  abstract loadEntity(id: string, store: Store<VkCommonState>): void;

  abstract updateEntity(entity: TEntity, values: TValues): TEntity;

  abstract saveEntity(entity: TEntity, store: Store<VkCommonState>, customerId?: string): void;

  abstract createEntity(entity: TEntity, customerId: string, store: Store<VkCommonState>): void;

  abstract deleteEntity(id: string, store: Store<VkCommonState>, customerId?: string): void;

  abstract makeTemplate(customerId: string): TEntity;

  public saveEntityIfNeeded(updatedEntity: TEntity, store: Store<VkCommonState>, customerId?: string): void {
    if (this.hasChanges) {
      this.saveEntity(updatedEntity, store, customerId);
    }
  }

  setUpDerivedFields(formGroup: FormGroup) {
  }

  onDestroy(): void {
    this.dataSourceWillBeDestroyed.next();
    this.dataSourceWillBeDestroyed.complete();
  }
}

export abstract class MonoEntityFormDataSource<TEntity> extends EntityFormDataSource<TEntity, Partial<TEntity>> {

  selectFormValues(entity: TEntity): TEntity | undefined {
    return entity;
  }

  updateEntity(entity: TEntity, values: TEntity): TEntity {
    return {
      ...entity,
      ...values,
    };
  }

  makeTemplate(customerId: string): TEntity {
    return {} as any;
  }
}
