import { JobState } from '../../optimization-state.graphql.generated';

export type OptimizationLoadingStateToMessageMap = Map<JobState, string>;

export const optimizationLoadingStateToMessageMap: OptimizationLoadingStateToMessageMap = new Map([
  [JobState.Queued, 'Warte auf Optimierung...'],
  [JobState.InProgress, 'Optimierter Fahrplan wird errechnet...'],
  [JobState.Postprocessing, 'Aktualisierter Fahrplan wird freigegeben...'],
]);



