import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import { HistoricalHeatLoad } from './heat-storage-preferences.graphql.generated';

export enum OffsetMode {
  UTC = 'UTC',
  CET = 'CET',
  CEST = 'CEST',
  Auto = 'Auto'// use the browser settings (switches between CET and CEST at correct date)
}

@Injectable({
  providedIn: 'root'
})
export class HeatLoadExcelImportService {
  async importExcelFile(arrayBuffer: ArrayBuffer, offset: OffsetMode = OffsetMode.Auto): Promise<HistoricalHeatLoad | null> {

    const workbook = new Workbook();

    await workbook.xlsx.load(arrayBuffer);

    return this.importWorkbook(workbook, offset);
  }

  importWorkbook(workbook: Workbook, offset: OffsetMode = OffsetMode.Auto): HistoricalHeatLoad | null {

    if (workbook.worksheets.length === 0) {
      throw alert('Verarbeitungsfehler: Kein Worksheet in Workbook gefunden');
    }
    const worksheet = workbook.worksheets[0];

    const dates: Date[] = worksheet.getColumn(1).values.slice(2).map(it => {
      return this.adjustOffset(it as Date, offset);
    });

    const values = worksheet.getColumn(2).values.slice(2) as Array<number | null>;

    if (values.length < 12 || !(dates[11] instanceof Date)) {
      return null;
    }

    const firstDayAroundNoon = dates[11]; // get roughly the middle of the first day

    const startTimestamp = this.dateTrunk(firstDayAroundNoon);

    const numberOfHoursInALeapYear = 8784; // python script expects always a full year of data (may have nulls though)

    const heatLoad = Array.from({ length: numberOfHoursInALeapYear }, (v, i) => null);

    for (let i = 0; i < dates.length; i++) {
      const timestamp = dates[i].getTime();
      const index = Math.floor((timestamp - startTimestamp) / (1000 * 60 * 60));
      if (index < 0 || index >= numberOfHoursInALeapYear) {
        continue;
      }
      heatLoad[index] = values[i];
    }

    return {
      startDate: new Date(startTimestamp),
      values: heatLoad
    };
  }

  private adjustOffset(date: Date, offset: OffsetMode) {
    if (offset === OffsetMode.Auto) {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
      );
    } else {
      let offsetHours = 0;
      switch (offset) {
        case OffsetMode.CEST:
          offsetHours = 2;
          break;
        case OffsetMode.CET:
          offsetHours = 1;
          break;
        default:
        case OffsetMode.UTC:
          offsetHours = 0;
          break;
      }
      const newDate = new Date(date);
      newDate.setHours(newDate.getHours() - offsetHours);
      return newDate;
    }
  }

  private dateTrunk(date: Date): number {
    const utcTrunk = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    return utcTrunk - (1 * 1000 * 60 * 60); // convert to CET
  }
}
