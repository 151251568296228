import { MonoEntityFormDataSource } from '../entity-form-data-source';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { FormSchema } from '@nrg/components';
import { Permission } from '@vk/authentication';
import {
  PriceCalculationDvfpidGQL,
  PriceCalculationDvfpidSubscription,
  SavePriceCalculationGQL
} from './price-calculations-data-source.graphql.generated';
import { map } from 'rxjs/operators';
import { priceCalculationDVFPIDSchema } from '../../schema/price-calculation-dvfpid.schema';

type PriceCalculationDvfpid = PriceCalculationDvfpidSubscription['priceCalculationById']['preiskalkulationeigenschaften'];

export class PriceCalculationDVFPIDFormDataSource extends MonoEntityFormDataSource<PriceCalculationDvfpid> {
  readonly writePermission = Permission.CONTRACTS_WRITE_SALES;
  readonly entityType = 'PriceCalculationDvfpid' as any;
  readonly schema: FormSchema = priceCalculationDVFPIDSchema;

  private readonly state = new ReplaySubject<PriceCalculationDvfpid>(1);
  private id: string | undefined;

  constructor(
    correlationId: string,
    private readonly priceCalculationDvfpid: PriceCalculationDvfpidGQL,
    private readonly savePriceCalculation: SavePriceCalculationGQL
  ) {
    super(correlationId);
  }

  loadEntity(id: string, store: Store<unknown>): void {
    this.id = id;
    this.priceCalculationDvfpid.subscribe({ id }).pipe(
      map(it => it.data!.priceCalculationById.preiskalkulationeigenschaften)
    ).subscribe(this.state);
  }

  saveEntity(entity: PriceCalculationDvfpid, store: Store<unknown>): void {

    let updatedConditions = entity;

    if (entity.grundgebuehrDiscount === 0) {
      updatedConditions = { ...entity, grundgebuehrDiscount: null, grundgebuehrDiscountEnde: null };
    }
    this.savePriceCalculation.mutate({
      id: this.id!,
      conditions: updatedConditions
    }).subscribe();
  }

  selectEntityById(id: string, store: Store<unknown>): Observable<PriceCalculationDvfpid | undefined> {
    return this.state.asObservable();
  }

  createEntity(entity: PriceCalculationDvfpid, customerId: string, store: Store<unknown>): void {
    throw new Error('Method not implemented.');
  }

  deleteEntity(id: string, store: Store<unknown>): void {
    throw new Error('Method not implemented.');
  }

  makeTemplate(parentId: string): PriceCalculationDvfpid {
    throw new Error('Method not implemented.');
  }

}
