export function fieldDisplayName(fieldName: string): string {
  switch (fieldName) {
    case 'obereNennleistung': return 'Obere Nennleistung elektrisch';
    case 'untereNennleistung': return 'Untere Nennleistung elektrisch';
    case 'technischeMinDauerleistung': return 'Technische Minimalleistung elektrisch';
    case 'leistungsstufen': return 'Leistungsstufen';
    case 'maxThermLeistung': return 'Nennleistung thermisch';
    case 'wirkungsgradElektrisch': return 'Wirkungsgrad elektrisch';
    case 'wirkungsgradThermisch': return 'Wirkungsgrad thermisch';
    case 'wirkungsgradGesamt': return 'Gesamtwirkungsgrad';
    case 'loadGradientMaxPower': return 'Leistungsänderungsgeschwindigkeit Positiv';
    case 'loadGradientMinPower': return 'Leistungsänderungsgeschwindigkeit Negativ';
    case 'totzeit': return 'Totzeit Anlage';
    case 'feuerungsleistung': return 'Feuerungsleistung';
    case 'maxLeistung': return 'Maximale Fahrplanleistung';
    case 'minLeistung': return 'Minimale Fahrplanleistung';
    case 'anlageDarfStehen': return 'Abschaltung auf 0 kW erlaubt';
    case 'startbarProTag': return 'Anzahl Starts pro Tag';
    case 'startkostenWarm': return 'Startkosten Warmstart';
    case 'startkostenKalt': return 'Startkosten Kaltstart';
    case 'betriebszeitProJahr': return 'Betriebsstunden pro Jahr';
    case 'minBetriebszeit': return 'Minimale Betriebszeit';
    case 'minStillstandzeit': return 'Minimale Stillstandzeit';
    case 'minBetriebProLeistungsstufe': return 'Minimale Betriebszeit pro Leistungsstufe';
    case 'maxJahresmengeStrom': return 'Maximale Jahresmenge Strom';
    case 'minJahresmengeStrom': return 'Minimale Jahresmenge Strom';
    case 'maxJahresmengeWaerme': return 'Maximale Jahresmenge Wärme';
    case 'minJahresmengeWaerme': return 'Minimale Jahresmenge Wärme';
    case 'erzeugtesBiogas': return 'Erzeugtes Biogas';
    case 'kapazitaet': return 'Speicherkapazität Biogas';
    case 'methangehaltBiogas': return 'Methangehalt Biogas';
    case 'energiegehaltBiogas': return 'Energiegehalt Biogas';
    case 'minFuellstand': return 'Untere Gasspeicherfüllstandsgrenze';
    case 'maxFuellstand': return 'Obere Gasspeicherfüllstandsgrenze';
    default: return fieldName;
  }
}
