import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Built-in java.math.BigDecimal */
  BigDecimal: number;
  /** Long type */
  Long: any;
  /** A type representing a formatted java.time.Period */
  Period: any;
  /** A type representing a formatted java.util.UUID */
  UUID: any;
  /** A type representing a formatted java.time.ZonedDateTime */
  ZonedDateTime: Date;
};



export type AdditionalTimeSeries = {
  name: Scalars['String'];
  series: Array<SeriesItem>;
};

export type Address = {
  hausnummer?: Maybe<Scalars['String']>;
  land?: Maybe<Scalars['String']>;
  plz: Scalars['String'];
  stadt: Scalars['String'];
  strasse: Scalars['String'];
  zusatz?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  hausnummer?: Maybe<Scalars['String']>;
  land?: Maybe<Scalars['String']>;
  plz: Scalars['String'];
  stadt: Scalars['String'];
  strasse: Scalars['String'];
  zusatz?: Maybe<Scalars['String']>;
};

export type AllocatableAsset = {
  name: Scalars['String'];
  protocolId: Scalars['UUID'];
  type: AllocatableAssetType;
};

export enum AllocatableAssetType {
  GasStorage = 'GasStorage',
  HeatStorage = 'HeatStorage',
  Malo = 'Malo',
  Park = 'Park',
  TechnicalUnit = 'TechnicalUnit'
}

export type Allocation = {
  allocationType: AssetAllocationType;
  columnEntityProtocolId: Scalars['UUID'];
  rowEntityProtocolId: Scalars['UUID'];
};

export type ApiTenant = {
  customerIds: Array<Scalars['String']>;
  defaultSalesHierarchyValuesId?: Maybe<Scalars['String']>;
  producer?: Maybe<Producer>;
  resourceProvider?: Maybe<ResourceProvider>;
  supplier?: Maybe<Supplier>;
  tenantId: Scalars['String'];
};

export enum AssetAllocationType {
  GasStorageToPark = 'GasStorageToPark',
  HeatStorageToPark = 'HeatStorageToPark',
  TechnicalUnitToGasStorage = 'TechnicalUnitToGasStorage',
  TechnicalUnitToHeatStorage = 'TechnicalUnitToHeatStorage',
  TechnicalUnitToMalo = 'TechnicalUnitToMalo',
  TechnicalUnitToPark = 'TechnicalUnitToPark'
}

export type AssetAllocations = {
  allocationType: AssetAllocationType;
  allocations: Array<Allocation>;
  columnEntities: Array<AllocatableAsset>;
  rowEntities: Array<AllocatableAsset>;
};

export enum AssetType {
  GasStorage = 'GasStorage',
  HeatStorage = 'HeatStorage',
  Park = 'Park',
  TechnicalUnit = 'TechnicalUnit'
}

export type AttachableDevice = {
  name: Scalars['String'];
  protocolId: Scalars['UUID'];
  type: AttachableDeviceType;
};

export enum AttachableDeviceType {
  GasStorage = 'GasStorage',
  HeatStorage = 'HeatStorage',
  TechnicalUnit = 'TechnicalUnit'
}

export type AttachedDevice = {
  dataPoints: DataPoint;
  name: Scalars['String'];
  protocolId: Scalars['UUID'];
  type: AttachableDeviceType;
  unitNumber: Scalars['BigDecimal'];
  unitType: Scalars['BigDecimal'];
};


export type BillingExport = {
  failures: Array<Scalars['String']>;
  successes: Array<Scalars['String']>;
};

export type BillingFailure = {
  cause: Scalars['String'];
  malo: Scalars['String'];
};

export type BillingGrossanlagenResult = {
  success: Scalars['Boolean'];
};

export type BillingImport = {
  lineItems?: Maybe<Array<Line>>;
  metadata?: Maybe<Metadata>;
};

export enum BillingMethodEnum {
  Pauschal = 'PAUSCHAL',
  Spitz = 'SPITZ',
  SpitzLight = 'SPITZ_LIGHT'
}

export type CalculationResult = {
  result?: Maybe<Scalars['BigDecimal']>;
};

export enum CalculationStates {
  DataMissing = 'DataMissing',
  Successful = 'Successful'
}

export enum CodingEnum {
  A10 = 'A10',
  Nde = 'NDE'
}

export enum CommercializationType {
  DirektvermarktungGeplant = 'DIREKTVERMARKTUNG_GEPLANT',
  DirektvermarktungUngeplant = 'DIREKTVERMARKTUNG_UNGEPLANT',
  Fahrplanmanagement = 'FAHRPLANMANAGEMENT',
  Intraday = 'INTRADAY',
  None = 'NONE'
}

export enum CompensationTypeEnum {
  Anzulengenderwert = 'ANZULENGENDERWERT'
}

export type ContractConditions = {
  contractId?: Maybe<Scalars['UUID']>;
  hasRedispatch: Scalars['Boolean'];
  name: Scalars['String'];
  productType?: Maybe<Scalars['String']>;
  technicalUnitId: Scalars['UUID'];
  unterschriftsdatum?: Maybe<Scalars['ZonedDateTime']>;
  vermarktungsbegin?: Maybe<Scalars['ZonedDateTime']>;
  vermarktungsende?: Maybe<Scalars['ZonedDateTime']>;
  vertragsbeginn?: Maybe<Scalars['ZonedDateTime']>;
  vertragsende?: Maybe<Scalars['ZonedDateTime']>;
};

export type ContractConditionsByMalo = {
  anzulegenderWertProzent?: Maybe<Scalars['BigDecimal']>;
  aufteilungsfaktorMehrerloes?: Maybe<Scalars['BigDecimal']>;
  aufteilungsfaktorMehrerloesIntraday?: Maybe<Scalars['BigDecimal']>;
  aufteilungsfaktorMindererloes?: Maybe<Scalars['BigDecimal']>;
  aufteilungsfaktorMindererloesIntraday?: Maybe<Scalars['BigDecimal']>;
  ausfallVerguetungFactor?: Maybe<Scalars['BigDecimal']>;
  ausfallverguetung: Scalars['String'];
  ausgleichszahlung: Scalars['String'];
  dienstleistungsentgelt?: Maybe<Scalars['BigDecimal']>;
  grundgebuehr?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscount?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscountEnde?: Maybe<Scalars['ZonedDateTime']>;
  hasRedispatch: Scalars['Boolean'];
  malo?: Maybe<Scalars['String']>;
  maloId: Scalars['UUID'];
  maloName: Scalars['String'];
  parkName: Scalars['String'];
  priceCalculationId: Scalars['UUID'];
  productType?: Maybe<Scalars['String']>;
  tes: Array<ContractConditions>;
  unterschriftsdatum?: Maybe<Scalars['ZonedDateTime']>;
  verfahrenAusfallarbeit?: Maybe<Scalars['String']>;
  vertragsbeginn?: Maybe<Scalars['ZonedDateTime']>;
  vertragsende?: Maybe<Scalars['ZonedDateTime']>;
};

export type ContractDates = {
  canceledFor?: Maybe<Scalars['ZonedDateTime']>;
  contractEnd?: Maybe<Scalars['ZonedDateTime']>;
  contractStart?: Maybe<Scalars['ZonedDateTime']>;
  signatureDate?: Maybe<Scalars['ZonedDateTime']>;
};

export type ContractFile = {
  bytes: Scalars['String'];
  name: Scalars['String'];
};

export type ContractPreviewDataForOffer = {
  hasRedispatch: Scalars['Boolean'];
  selectedProduct: ContractType;
  selectedTechnicalUnits: Array<TechnicalUnitToSignOrCancel>;
  tesToCancel: Array<TechnicalUnitToSignOrCancel>;
};

export enum ContractState {
  Aktiv = 'Aktiv',
  Gekuendigt = 'Gekuendigt',
  Gueltig = 'Gueltig',
  Neu = 'Neu',
  Storniert = 'Storniert',
  Unterschrieben = 'Unterschrieben',
  Vertragsbruch = 'Vertragsbruch'
}

export enum ContractType {
  Direktvermarktung = 'Direktvermarktung',
  DirektvermarktungFahrplanmanagement = 'DirektvermarktungFahrplanmanagement',
  FahrplanmanagementIntraday = 'FahrplanmanagementIntraday'
}

export enum ControlAreaEnum {
  Opt_10YdeEnbwN = 'OPT_10YDE_ENBW_____N',
  Opt_10YdeEon_1 = 'OPT_10YDE_EON______1',
  Opt_10YdeRwenetI = 'OPT_10YDE_RWENET___I',
  Opt_10YdeVe_2 = 'OPT_10YDE_VE_______2',
  Opt_10Yflensburg_3 = 'OPT_10YFLENSBURG___3'
}

export type CpeAllocations = {
  attachableDevices: Array<AttachableDevice>;
  attachedDevices: Array<AttachedDevice>;
  protocolId: Scalars['UUID'];
};

export enum CpeConnectionState {
  Active = 'Active',
  Available = 'Available'
}

export type CsvExportFile = {
  bytes: Scalars['String'];
  fileName: Scalars['String'];
};

export type CustomerData = {
  additionalAddress?: Maybe<Scalars['String']>;
  additionalCompanyName1?: Maybe<Scalars['String']>;
  additionalCompanyName2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  customerId: Scalars['String'];
  customerNumber?: Maybe<Scalars['Int']>;
  emailAddress?: Maybe<Scalars['String']>;
  externalContractPerson?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  internalContactPerson?: Maybe<Scalars['String']>;
  phoneNumber1?: Maybe<Scalars['String']>;
  phoneNumber2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type CustomerDataInput = {
  additionalAddress?: Maybe<Scalars['String']>;
  additionalCompanyName1?: Maybe<Scalars['String']>;
  additionalCompanyName2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  customerId: Scalars['String'];
  customerNumber?: Maybe<Scalars['Int']>;
  emailAddress?: Maybe<Scalars['String']>;
  externalContractPerson?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  internalContactPerson?: Maybe<Scalars['String']>;
  phoneNumber1?: Maybe<Scalars['String']>;
  phoneNumber2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type CustomerImportAllocation = {
  cpeName?: Maybe<Scalars['String']>;
  dataPoint?: Maybe<Scalars['BigDecimal']>;
  gasStorageName?: Maybe<Scalars['String']>;
  maloName?: Maybe<Scalars['String']>;
  technicalUnitName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  unitNumber?: Maybe<Scalars['BigDecimal']>;
  unitType?: Maybe<Scalars['BigDecimal']>;
};

export type CustomerImportAllocationInput = {
  cpeName?: Maybe<Scalars['String']>;
  dataPoint?: Maybe<Scalars['BigDecimal']>;
  gasStorageName?: Maybe<Scalars['String']>;
  maloName?: Maybe<Scalars['String']>;
  technicalUnitName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  unitNumber?: Maybe<Scalars['BigDecimal']>;
  unitType?: Maybe<Scalars['BigDecimal']>;
};

export enum CustomerImportCoding {
  A10 = 'A10',
  Nde = 'NDE'
}

export type CustomerImportContract = {
  commercializationType?: Maybe<Scalars['String']>;
  dateOfCommercialization?: Maybe<Scalars['ZonedDateTime']>;
  hasContract: Scalars['Boolean'];
  isRelevantForContract: Scalars['Boolean'];
  technicalUnitName: Scalars['String'];
};

export type CustomerImportContractInput = {
  commercializationType?: Maybe<Scalars['String']>;
  dateOfCommercialization?: Maybe<Scalars['ZonedDateTime']>;
  hasContract: Scalars['Boolean'];
  isRelevantForContract: Scalars['Boolean'];
  technicalUnitName: Scalars['String'];
};

export type CustomerImportCpe = {
  name: Scalars['String'];
};

export type CustomerImportCpeInput = {
  name: Scalars['String'];
};

export type CustomerImportCustomerData = {
  additionalAddress?: Maybe<Scalars['String']>;
  additionalCompanyName1?: Maybe<Scalars['String']>;
  additionalCompanyName2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['Int']>;
  externalContractPerson?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  internalContactPerson?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type CustomerImportCustomerDataInput = {
  additionalAddress?: Maybe<Scalars['String']>;
  additionalCompanyName1?: Maybe<Scalars['String']>;
  additionalCompanyName2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['Int']>;
  externalContractPerson?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  internalContactPerson?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type CustomerImportData = {
  allocations: Array<CustomerImportAllocation>;
  contracts: Array<CustomerImportContract>;
  cpes: Array<CustomerImportCpe>;
  customerData?: Maybe<CustomerImportCustomerData>;
  malos: Array<CustomerImportMalo>;
  offers: Array<CustomerImportOffer>;
  parks: Array<CustomerImportPark>;
  userData: CustomerImportLoginData;
};

export type CustomerImportDataInput = {
  allocations: Array<CustomerImportAllocationInput>;
  contracts: Array<CustomerImportContractInput>;
  cpes: Array<CustomerImportCpeInput>;
  customerData?: Maybe<CustomerImportCustomerDataInput>;
  malos: Array<CustomerImportMaloInput>;
  offers: Array<CustomerImportOfferInput>;
  parks: Array<CustomerImportParkInput>;
  userData: CustomerImportLoginDataInput;
};

export type CustomerImportEntityIndex = {
  anlagenmanagementId: Scalars['UUID'];
  contractIds: Array<CustomerImportEntityIndexEntry>;
  cpeIds: Array<CustomerImportEntityIndexEntry>;
  customerProtocolId: Scalars['UUID'];
  gasStorageIds: Array<CustomerImportEntityIndexEntry>;
  maloIds: Array<CustomerImportEntityIndexEntry>;
  parkIds: Array<CustomerImportEntityIndexEntry>;
  technicalUnitIds: Array<CustomerImportEntityIndexEntry>;
};

export type CustomerImportEntityIndexEntry = {
  key: Scalars['String'];
  value: Scalars['UUID'];
};

export type CustomerImportGasStorage = {
  capacity: Scalars['BigDecimal'];
  maximalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  methanConcentration?: Maybe<Scalars['BigDecimal']>;
  minimalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  producedBiogas: Scalars['BigDecimal'];
};

export type CustomerImportGasStorageInput = {
  capacity: Scalars['BigDecimal'];
  maximalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  methanConcentration?: Maybe<Scalars['BigDecimal']>;
  minimalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  producedBiogas: Scalars['BigDecimal'];
};

export type CustomerImportGeographicalCoordinates = {
  latitude: Scalars['BigDecimal'];
  longitude: Scalars['BigDecimal'];
};

export type CustomerImportGeographicalCoordinatesInput = {
  latitude: Scalars['BigDecimal'];
  longitude: Scalars['BigDecimal'];
};

export type CustomerImportLoginData = {
  customerId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  tenantId: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};

export type CustomerImportLoginDataInput = {
  customerId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  tenantId: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};

export type CustomerImportMalo = {
  accountingGrid?: Maybe<Scalars['String']>;
  controlArea?: Maybe<Scalars['String']>;
  maloId: Scalars['String'];
  measurementLocationIds?: Maybe<Array<Scalars['String']>>;
  voltageLevel?: Maybe<CustomerImportVoltageLevel>;
};

export type CustomerImportMaloInput = {
  accountingGrid?: Maybe<Scalars['String']>;
  controlArea?: Maybe<Scalars['String']>;
  maloId: Scalars['String'];
  measurementLocationIds?: Maybe<Array<Scalars['String']>>;
  voltageLevel?: Maybe<CustomerImportVoltageLevel>;
};

export type CustomerImportMarketPartner = {
  code: Scalars['String'];
  coding: CustomerImportCoding;
};

export type CustomerImportMarketPartnerInput = {
  code: Scalars['String'];
  coding: CustomerImportCoding;
};

export type CustomerImportOffer = {
  dateOfSigning: Scalars['ZonedDateTime'];
  parkName: Scalars['String'];
  product: OfferType;
};

export type CustomerImportOfferInput = {
  dateOfSigning: Scalars['ZonedDateTime'];
  parkName: Scalars['String'];
  product: OfferType;
};

export type CustomerImportPark = {
  distributionSystemOperator?: Maybe<CustomerImportMarketPartner>;
  gasStorages: Array<CustomerImportGasStorage>;
  name: Scalars['String'];
  redispatchTransmissionOverride?: Maybe<Scalars['Boolean']>;
  technicalUnits: Array<CustomerImportTechnicalUnit>;
};

export type CustomerImportParkInput = {
  distributionSystemOperator?: Maybe<CustomerImportMarketPartnerInput>;
  gasStorages: Array<CustomerImportGasStorageInput>;
  name: Scalars['String'];
  redispatchTransmissionOverride?: Maybe<Scalars['Boolean']>;
  technicalUnits: Array<CustomerImportTechnicalUnitInput>;
};

export type CustomerImportTechnicalUnit = {
  additionalAddress?: Maybe<Scalars['String']>;
  allowedProducts?: Maybe<Array<Scalars['String']>>;
  anzulegenderWert?: Maybe<Scalars['BigDecimal']>;
  city?: Maybe<Scalars['String']>;
  controlMode?: Maybe<Scalars['String']>;
  controllableResourceId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  curtailmentOffset: Scalars['BigDecimal'];
  dateOfCommissioning?: Maybe<Scalars['ZonedDateTime']>;
  dateOfGridConnection?: Maybe<Scalars['ZonedDateTime']>;
  eegDeviceId?: Maybe<Scalars['String']>;
  electricalEfficiency: Scalars['BigDecimal'];
  energySource: Scalars['String'];
  flexMotor: Scalars['Boolean'];
  geographicalCoordinates?: Maybe<CustomerImportGeographicalCoordinates>;
  houseNumber?: Maybe<Scalars['String']>;
  isShutdownAllowed: Scalars['Boolean'];
  loadGradientMaxPower?: Maybe<Scalars['BigDecimal']>;
  loadGradientMinPower?: Maybe<Scalars['BigDecimal']>;
  lowerPowerRating?: Maybe<Scalars['BigDecimal']>;
  maStRNumber?: Maybe<Scalars['String']>;
  maximalAnnualPowerAmount?: Maybe<Scalars['BigDecimal']>;
  maximalNumberOfStartsPerDay?: Maybe<Scalars['BigDecimal']>;
  maximalPower: Scalars['BigDecimal'];
  maximalThermalPower: Scalars['BigDecimal'];
  minDowntime?: Maybe<Scalars['BigDecimal']>;
  minOperatingTime?: Maybe<Scalars['BigDecimal']>;
  minimalPower: Scalars['BigDecimal'];
  minimalTechnicalPower?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  rampUpTimeCold?: Maybe<Scalars['BigDecimal']>;
  rampUpTimeWarm?: Maybe<Scalars['BigDecimal']>;
  shutDownTime?: Maybe<Scalars['BigDecimal']>;
  startUpTimeCold?: Maybe<Scalars['BigDecimal']>;
  startUpTimeWarm?: Maybe<Scalars['BigDecimal']>;
  street?: Maybe<Scalars['String']>;
  technicalResourceId?: Maybe<Scalars['String']>;
  thermalEfficiency: Scalars['BigDecimal'];
  upperPowerRating: Scalars['BigDecimal'];
};

export type CustomerImportTechnicalUnitInput = {
  additionalAddress?: Maybe<Scalars['String']>;
  allowedProducts?: Maybe<Array<Scalars['String']>>;
  anzulegenderWert?: Maybe<Scalars['BigDecimal']>;
  city?: Maybe<Scalars['String']>;
  controlMode?: Maybe<Scalars['String']>;
  controllableResourceId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  curtailmentOffset: Scalars['BigDecimal'];
  dateOfCommissioning?: Maybe<Scalars['ZonedDateTime']>;
  dateOfGridConnection?: Maybe<Scalars['ZonedDateTime']>;
  eegDeviceId?: Maybe<Scalars['String']>;
  electricalEfficiency: Scalars['BigDecimal'];
  energySource: Scalars['String'];
  flexMotor: Scalars['Boolean'];
  geographicalCoordinates?: Maybe<CustomerImportGeographicalCoordinatesInput>;
  houseNumber?: Maybe<Scalars['String']>;
  isShutdownAllowed: Scalars['Boolean'];
  loadGradientMaxPower?: Maybe<Scalars['BigDecimal']>;
  loadGradientMinPower?: Maybe<Scalars['BigDecimal']>;
  lowerPowerRating?: Maybe<Scalars['BigDecimal']>;
  maStRNumber?: Maybe<Scalars['String']>;
  maximalAnnualPowerAmount?: Maybe<Scalars['BigDecimal']>;
  maximalNumberOfStartsPerDay?: Maybe<Scalars['BigDecimal']>;
  maximalPower: Scalars['BigDecimal'];
  maximalThermalPower: Scalars['BigDecimal'];
  minDowntime?: Maybe<Scalars['BigDecimal']>;
  minOperatingTime?: Maybe<Scalars['BigDecimal']>;
  minimalPower: Scalars['BigDecimal'];
  minimalTechnicalPower?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  rampUpTimeCold?: Maybe<Scalars['BigDecimal']>;
  rampUpTimeWarm?: Maybe<Scalars['BigDecimal']>;
  shutDownTime?: Maybe<Scalars['BigDecimal']>;
  startUpTimeCold?: Maybe<Scalars['BigDecimal']>;
  startUpTimeWarm?: Maybe<Scalars['BigDecimal']>;
  street?: Maybe<Scalars['String']>;
  technicalResourceId?: Maybe<Scalars['String']>;
  thermalEfficiency: Scalars['BigDecimal'];
  upperPowerRating: Scalars['BigDecimal'];
};

export enum CustomerImportVoltageLevel {
  ExtraHighVoltage = 'EXTRA_HIGH_VOLTAGE',
  HighVoltage = 'HIGH_VOLTAGE',
  LowVoltage = 'LOW_VOLTAGE',
  MediumVoltage = 'MEDIUM_VOLTAGE'
}

export type CustomerLoginData = {
  email?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  party?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type CustomerLoginDataInput = {
  email?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  party?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type CustomerPremisesEquipment = {
  commonAddress?: Maybe<Scalars['BigDecimal']>;
  customerId: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parkIds: Array<Scalars['UUID']>;
  phoneNumber?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['BigDecimal']>;
  protocolId: Scalars['UUID'];
  simCardId?: Maybe<Scalars['String']>;
  state: CpeConnectionState;
  steuerbox?: Maybe<Scalars['String']>;
};

export type CustomerPremisesEquipmentCreateInput = {
  commonAddress?: Maybe<Scalars['BigDecimal']>;
  customerId: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['BigDecimal']>;
  simCardId?: Maybe<Scalars['String']>;
  steuerbox?: Maybe<Scalars['String']>;
};

export type CustomerPremisesEquipmentUpdateInput = {
  commonAddress?: Maybe<Scalars['BigDecimal']>;
  ipAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['BigDecimal']>;
  protocolId: Scalars['UUID'];
  simCardId?: Maybe<Scalars['String']>;
  steuerbox?: Maybe<Scalars['String']>;
};

export type CustomerTree = {
  customerId: Scalars['String'];
  disconnectedAssets: ParkChildren;
  parksWithRelationShips: Array<ParkWithRelationShips>;
  tenantId: Scalars['String'];
};

export type DataPoint = {
  dataPoint: Scalars['String'];
  options: Array<Scalars['String']>;
};

export type DataPointOptions = {
  gasStorage: Array<Scalars['String']>;
  heatStorage: Array<Scalars['String']>;
  technicalUnit: Array<Scalars['String']>;
};

export enum DecisionTypeEnum {
  Monatsmarktwert = 'MONATSMARKTWERT'
}

export type Discount = {
  discount?: Maybe<Scalars['BigDecimal']>;
  discountEndDate?: Maybe<Scalars['String']>;
  maloRef: Scalars['String'];
};

export type DvDefaultValues = {
  ausfallVerguetungFactor?: Maybe<Scalars['BigDecimal']>;
  ausfallVerguetungMalo: CompensationTypeEnum;
  dienstleistungsentgeltSatz: Scalars['BigDecimal'];
  grundgebuehrDiscount?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscountEnde?: Maybe<Scalars['ZonedDateTime']>;
  grundgebuehrenPerNummberOfTechnischeEinheiten: Array<GeneralFee>;
  kuendigungsfrist?: Maybe<Scalars['String']>;
  par51EntschaedigungFactor?: Maybe<Scalars['BigDecimal']>;
  par51EntschaedigungMalo: DecisionTypeEnum;
  verfahrenAusfallarbeit: ProductionTypeEnum;
  verlaengerungsdauer?: Maybe<Scalars['String']>;
  vertragsversion: Scalars['String'];
};

export type DvDefaultValuesInput = {
  ausfallVerguetungFactor?: Maybe<Scalars['BigDecimal']>;
  ausfallVerguetungMalo: CompensationTypeEnum;
  dienstleistungsentgeltSatz: Scalars['BigDecimal'];
  grundgebuehrDiscount?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscountEnde?: Maybe<Scalars['ZonedDateTime']>;
  grundgebuehrenPerNummberOfTechnischeEinheiten: Array<GeneralFeeInput>;
  kuendigungsfrist?: Maybe<Scalars['String']>;
  par51EntschaedigungFactor?: Maybe<Scalars['BigDecimal']>;
  par51EntschaedigungMalo: DecisionTypeEnum;
  verfahrenAusfallarbeit: ProductionTypeEnum;
  verlaengerungsdauer?: Maybe<Scalars['String']>;
  vertragsversion: Scalars['String'];
};

export type DvfpDefaultValues = {
  dienstleistungsentgeltSatz: Scalars['BigDecimal'];
  grundgebuehrDiscount?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscountEnde?: Maybe<Scalars['ZonedDateTime']>;
  grundgebuehrenPerNummberOfTechnischeEinheiten: Array<GeneralFee>;
  kuendigungsfrist?: Maybe<Scalars['String']>;
  verlaengerungsdauer?: Maybe<Scalars['String']>;
  vertragsversion: Scalars['String'];
  zuteilungsfaktorMehrerloes: Scalars['BigDecimal'];
  zuteilungsfaktorMindererloes: Scalars['BigDecimal'];
};

export type DvfpDefaultValuesInput = {
  dienstleistungsentgeltSatz: Scalars['BigDecimal'];
  grundgebuehrDiscount?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscountEnde?: Maybe<Scalars['ZonedDateTime']>;
  grundgebuehrenPerNummberOfTechnischeEinheiten: Array<GeneralFeeInput>;
  kuendigungsfrist?: Maybe<Scalars['String']>;
  verlaengerungsdauer?: Maybe<Scalars['String']>;
  vertragsversion: Scalars['String'];
  zuteilungsfaktorMehrerloes: Scalars['BigDecimal'];
  zuteilungsfaktorMindererloes: Scalars['BigDecimal'];
};

export type DvfpIdDefaultValues = {
  dienstleistungsentgeltSatz: Scalars['BigDecimal'];
  grundgebuehrDiscount?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscountEnde?: Maybe<Scalars['ZonedDateTime']>;
  grundgebuehrenPerNummberOfTechnischeEinheiten: Array<GeneralFee>;
  kuendigungsfrist?: Maybe<Scalars['String']>;
  verlaengerungsdauer?: Maybe<Scalars['String']>;
  vertragsversion: Scalars['String'];
  zuteilungsfaktorMehrerloes: Scalars['BigDecimal'];
  zuteilungsfaktorMehrerloesId: Scalars['BigDecimal'];
  zuteilungsfaktorMindererloes: Scalars['BigDecimal'];
  zuteilungsfaktorMindererloesId: Scalars['BigDecimal'];
};

export type DvfpIdDefaultValuesInput = {
  dienstleistungsentgeltSatz: Scalars['BigDecimal'];
  grundgebuehrDiscount?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscountEnde?: Maybe<Scalars['ZonedDateTime']>;
  grundgebuehrenPerNummberOfTechnischeEinheiten: Array<GeneralFeeInput>;
  kuendigungsfrist?: Maybe<Scalars['String']>;
  verlaengerungsdauer?: Maybe<Scalars['String']>;
  vertragsversion: Scalars['String'];
  zuteilungsfaktorMehrerloes: Scalars['BigDecimal'];
  zuteilungsfaktorMehrerloesId: Scalars['BigDecimal'];
  zuteilungsfaktorMindererloes: Scalars['BigDecimal'];
  zuteilungsfaktorMindererloesId: Scalars['BigDecimal'];
};

export enum EnergySourceEnum {
  Biomass = 'BIOMASS',
  Solar = 'SOLAR'
}

export type ExportPeriod = {
  start: Scalars['ZonedDateTime'];
  stepCount: Scalars['Int'];
  stepDuration: Scalars['Int'];
};

export type ExportResult = {
  additionalTimeSeries: Array<AdditionalTimeSeries>;
  gasStorages: Array<StorageResult>;
  heatStorages: Array<StorageResult>;
  period: ExportPeriod;
  technicalUnits: Array<TechnicalUnitResult>;
};

export type FileExport = {
  data: Scalars['String'];
  path: Scalars['String'];
};

export type FixedPriceAmendmentDataInput = {
  documentBytes: Scalars['String'];
  fixedPrice: Scalars['BigDecimal'];
  signatureDate: Scalars['ZonedDateTime'];
  validFrom: Scalars['ZonedDateTime'];
  validTo: Scalars['ZonedDateTime'];
};

export type GasStorage = {
  capacity: Scalars['BigDecimal'];
  customerId: Scalars['String'];
  energyConcentration?: Maybe<Scalars['BigDecimal']>;
  maximalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  methanConcentration?: Maybe<Scalars['BigDecimal']>;
  minimalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  parkId?: Maybe<Scalars['UUID']>;
  parkName?: Maybe<Scalars['String']>;
  producedBiogas: Scalars['BigDecimal'];
  protocolId: Scalars['UUID'];
};

export type GasStorageCalculationResult = {
  result?: Maybe<Scalars['BigDecimal']>;
};

export type GasStorageCreateInput = {
  capacity: Scalars['BigDecimal'];
  customerId: Scalars['String'];
  energyConcentration?: Maybe<Scalars['BigDecimal']>;
  maximalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  methanConcentration?: Maybe<Scalars['BigDecimal']>;
  minimalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  producedBiogas: Scalars['BigDecimal'];
};

export type GasStorageInput = {
  capacity: Scalars['BigDecimal'];
  customerId: Scalars['String'];
  energyConcentration?: Maybe<Scalars['BigDecimal']>;
  maximalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  methanConcentration?: Maybe<Scalars['BigDecimal']>;
  minimalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  parkId?: Maybe<Scalars['UUID']>;
  parkName?: Maybe<Scalars['String']>;
  producedBiogas: Scalars['BigDecimal'];
  protocolId: Scalars['UUID'];
};

export type GeneralFee = {
  grundgebuehr: Scalars['BigDecimal'];
  numberOfTechnischeEinheiten: Scalars['BigDecimal'];
};

export type GeneralFeeInput = {
  grundgebuehr: Scalars['BigDecimal'];
  numberOfTechnischeEinheiten: Scalars['BigDecimal'];
};

export type GeoCoordinates = {
  latitude: Scalars['BigDecimal'];
  longitude: Scalars['BigDecimal'];
};

export type GeoCoordinatesInput = {
  latitude: Scalars['BigDecimal'];
  longitude: Scalars['BigDecimal'];
};

export enum GlobalTimeSeriesType {
  IntraDayPricesBid = 'IntraDayPricesBid',
  IntraPricesDayAsk = 'IntraPricesDayAsk',
  PriceForwardCurve = 'PriceForwardCurve',
  TemperatureForwardCurve = 'TemperatureForwardCurve'
}

export type HeatStorage = {
  capacity?: Maybe<Scalars['BigDecimal']>;
  customerId: Scalars['String'];
  flowTemperature?: Maybe<Scalars['BigDecimal']>;
  maximalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  minimalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  parkId?: Maybe<Scalars['UUID']>;
  parkName?: Maybe<Scalars['String']>;
  protocolId: Scalars['UUID'];
  returnTemperature?: Maybe<Scalars['BigDecimal']>;
};

export type HeatStorageCreateInput = {
  capacity?: Maybe<Scalars['BigDecimal']>;
  customerId: Scalars['String'];
  flowTemperature?: Maybe<Scalars['BigDecimal']>;
  maximalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  minimalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  returnTemperature?: Maybe<Scalars['BigDecimal']>;
};

export type HeatStorageInput = {
  capacity?: Maybe<Scalars['BigDecimal']>;
  customerId: Scalars['String'];
  flowTemperature?: Maybe<Scalars['BigDecimal']>;
  maximalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  minimalFillingLevel?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  parkId?: Maybe<Scalars['UUID']>;
  parkName?: Maybe<Scalars['String']>;
  protocolId: Scalars['UUID'];
  returnTemperature?: Maybe<Scalars['BigDecimal']>;
};

export type HeatStoragePreferences = {
  historicalHeatLoad?: Maybe<HistoricalHeatLoad>;
  id: Scalars['UUID'];
  maximalHeatLoad?: Maybe<Scalars['BigDecimal']>;
  minimalHeatLoad?: Maybe<Scalars['BigDecimal']>;
};

export type HeatStoragePreferencesInput = {
  historicalHeatLoad?: Maybe<HistoricalHeatLoadInput>;
  id: Scalars['UUID'];
  maximalHeatLoad?: Maybe<Scalars['BigDecimal']>;
  minimalHeatLoad?: Maybe<Scalars['BigDecimal']>;
};

export type HistoricalHeatLoad = {
  startDate: Scalars['ZonedDateTime'];
  values: Array<Maybe<Scalars['BigDecimal']>>;
};

export type HistoricalHeatLoadInput = {
  startDate: Scalars['ZonedDateTime'];
  values: Array<Maybe<Scalars['BigDecimal']>>;
};

export enum JobOutcome {
  Error = 'Error',
  Fatal = 'Fatal',
  Success = 'Success',
  Unknown = 'Unknown',
  Warning = 'Warning'
}

export type JobProblem = {
  assetId: Scalars['UUID'];
  assetName: Scalars['String'];
  assetType: AssetType;
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
  type: JobProblemType;
};

export enum JobProblemType {
  Error = 'Error',
  Fatal = 'Fatal',
  Violation = 'Violation',
  Warning = 'Warning'
}

export enum JobState {
  InProgress = 'InProgress',
  Inactive = 'Inactive',
  Postprocessing = 'Postprocessing',
  Queued = 'Queued'
}

export enum JobType {
  DayAhead = 'DayAhead',
  DryRun = 'DryRun',
  HeatLoadForecast = 'HeatLoadForecast',
  IntraDay = 'IntraDay',
  ProductionForecast = 'ProductionForecast',
  RevenueHistory = 'RevenueHistory',
  RevenuePrediction = 'RevenuePrediction'
}

export type Line = {
  datum: Scalars['ZonedDateTime'];
  profitwert?: Maybe<Scalars['BigDecimal']>;
};

export enum LogBookContextType {
  Asset = 'Asset',
  Customer = 'Customer',
  Park = 'Park',
  System = 'System',
  Tenant = 'Tenant'
}

export type LogBookEntryMetaData = {
  key: LogBookEntryMetaDataKey;
  value: Scalars['String'];
};

export enum LogBookEntryMetaDataKey {
  ContractState = 'ContractState',
  ContractType = 'ContractType',
  CurveUpdateFrom = 'CurveUpdateFrom',
  CurveUpdateMethod = 'CurveUpdateMethod',
  CurveUpdateTo = 'CurveUpdateTo',
  CustomerData = 'CustomerData',
  CustomerPremisesEquipment = 'CustomerPremisesEquipment',
  Fault = 'Fault',
  GasStorage = 'GasStorage',
  HeatStorage = 'HeatStorage',
  Malo = 'Malo',
  Offer = 'Offer',
  OptimizationOutcome = 'OptimizationOutcome',
  OptimizationType = 'OptimizationType',
  Park = 'Park',
  SafetySchedulePower = 'SafetySchedulePower',
  SafetyScheduleStartTime = 'SafetyScheduleStartTime',
  TechnicalUnit = 'TechnicalUnit'
}

export enum LogBookEntryType {
  AddFixedPriceAmendment = 'ADD_FIXED_PRICE_AMENDMENT',
  AllocateAsset = 'ALLOCATE_ASSET',
  AttachDeviceToCpe = 'ATTACH_DEVICE_TO_CPE',
  BackdateOfferSignature = 'BACKDATE_OFFER_SIGNATURE',
  CancelContract = 'CANCEL_CONTRACT',
  ContractPdfCreated = 'CONTRACT_PDF_CREATED',
  ContractStateChanged = 'CONTRACT_STATE_CHANGED',
  CpeConnected = 'CPE_CONNECTED',
  CpeDisconnected = 'CPE_DISCONNECTED',
  CpeFault = 'CPE_FAULT',
  CpeStateActive = 'CPE_STATE_ACTIVE',
  CpeStateAvailable = 'CPE_STATE_AVAILABLE',
  CreateCpe = 'CREATE_CPE',
  CreateGasStorage = 'CREATE_GAS_STORAGE',
  CreateHeatStorage = 'CREATE_HEAT_STORAGE',
  CreateLogin = 'CREATE_LOGIN',
  CreateMalo = 'CREATE_MALO',
  CreatePaperDummyForContract = 'CREATE_PAPER_DUMMY_FOR_CONTRACT',
  CreatePark = 'CREATE_PARK',
  CreateTechnicalUnit = 'CREATE_TECHNICAL_UNIT',
  CreateTenant = 'CREATE_TENANT',
  DeallocateAsset = 'DEALLOCATE_ASSET',
  DecommissionTechnicalUnit = 'DECOMMISSION_TECHNICAL_UNIT',
  DeleteLogin = 'DELETE_LOGIN',
  DetachDeviceFromCpe = 'DETACH_DEVICE_FROM_CPE',
  GsConnected = 'GS_CONNECTED',
  GsDisconnected = 'GS_DISCONNECTED',
  GsFault = 'GS_FAULT',
  HeatLoadForecastCompleted = 'HEAT_LOAD_FORECAST_COMPLETED',
  ImportCustomer = 'IMPORT_CUSTOMER',
  MakeTechnicalUnitContractRelevant = 'MAKE_TECHNICAL_UNIT_CONTRACT_RELEVANT',
  NewContractSigned = 'NEW_CONTRACT_SIGNED',
  NewSellingOpportunityCreated = 'NEW_SELLING_OPPORTUNITY_CREATED',
  OptimizationCompleted = 'OPTIMIZATION_COMPLETED',
  ProductionForecastCompleted = 'PRODUCTION_FORECAST_COMPLETED',
  RemoveFixedPriceAmendment = 'REMOVE_FIXED_PRICE_AMENDMENT',
  ResetOperationsSchedule = 'RESET_OPERATIONS_SCHEDULE',
  ResetPrescriptionsSchedule = 'RESET_PRESCRIPTIONS_SCHEDULE',
  ResetSellingOpportunity = 'RESET_SELLING_OPPORTUNITY',
  SetCommercializationBegin = 'SET_COMMERCIALIZATION_BEGIN',
  SignOffer = 'SIGN_OFFER',
  TeConnected = 'TE_CONNECTED',
  TeDisconnected = 'TE_DISCONNECTED',
  TeFault = 'TE_FAULT',
  TeSafetyScheduleUpdated = 'TE_SAFETY_SCHEDULE_UPDATED',
  TeScheduleUploaded = 'TE_SCHEDULE_UPLOADED',
  TriggerDryRunOptimization = 'TRIGGER_DRY_RUN_OPTIMIZATION',
  UpdateActiveSchedule = 'UPDATE_ACTIVE_SCHEDULE',
  UpdateCommercializationType = 'UPDATE_COMMERCIALIZATION_TYPE',
  UpdateCustomerData = 'UPDATE_CUSTOMER_DATA',
  UpdateGasStorage = 'UPDATE_GAS_STORAGE',
  UpdateGasStorageFillingLevel = 'UPDATE_GAS_STORAGE_FILLING_LEVEL',
  UpdateHeatStorage = 'UPDATE_HEAT_STORAGE',
  UpdateHeatStorageFillingLevel = 'UPDATE_HEAT_STORAGE_FILLING_LEVEL',
  UpdateHeatStorageOptimizationPreferences = 'UPDATE_HEAT_STORAGE_OPTIMIZATION_PREFERENCES',
  UpdateIntradayPricesAsk = 'UPDATE_INTRADAY_PRICES_ASK',
  UpdateIntradayPricesBid = 'UPDATE_INTRADAY_PRICES_BID',
  UpdateLogin = 'UPDATE_LOGIN',
  UpdateMalo = 'UPDATE_MALO',
  UpdateNonavailabilities = 'UPDATE_NONAVAILABILITIES',
  UpdateOffer = 'UPDATE_OFFER',
  UpdateOperationsSchedule = 'UPDATE_OPERATIONS_SCHEDULE',
  UpdatePark = 'UPDATE_PARK',
  UpdateParkOptimizationPreferences = 'UPDATE_PARK_OPTIMIZATION_PREFERENCES',
  UpdatePassword = 'UPDATE_PASSWORD',
  UpdatePrescriptionsSchedule = 'UPDATE_PRESCRIPTIONS_SCHEDULE',
  UpdatePriceCalculationWithinOffer = 'UPDATE_PRICE_CALCULATION_WITHIN_OFFER',
  UpdatePriceForwardCurve = 'UPDATE_PRICE_FORWARD_CURVE',
  UpdateSalesHierarchyDefaultValues = 'UPDATE_SALES_HIERARCHY_DEFAULT_VALUES',
  UpdateSellingOpportunity = 'UPDATE_SELLING_OPPORTUNITY',
  UpdateTechnicalGeneralSettings = 'UPDATE_TECHNICAL_GENERAL_SETTINGS',
  UpdateTechnicalUnitConnectionSettings = 'UPDATE_TECHNICAL_UNIT_CONNECTION_SETTINGS',
  UpdateTechnicalUnitRunPlanParameters = 'UPDATE_TECHNICAL_UNIT_RUN_PLAN_PARAMETERS',
  UpdateTemperatureForwardCurve = 'UPDATE_TEMPERATURE_FORWARD_CURVE',
  WsConnected = 'WS_CONNECTED',
  WsDisconnected = 'WS_DISCONNECTED',
  WsFault = 'WS_FAULT'
}

export type LogBookEntryWithMetaData = {
  customerId?: Maybe<Scalars['String']>;
  metaData: Array<LogBookEntryMetaData>;
  tenantId?: Maybe<Scalars['String']>;
  time: Scalars['ZonedDateTime'];
  type: LogBookEntryType;
  username?: Maybe<Scalars['String']>;
};


export type Malo = {
  abschaltungExportEnabled: Scalars['Boolean'];
  accountingGrid?: Maybe<Scalars['String']>;
  anlagenNrEinspeisemenge?: Maybe<Scalars['BigDecimal']>;
  anlagenNrGebuehren?: Maybe<Scalars['BigDecimal']>;
  bemessungsleistung?: Maybe<Scalars['BigDecimal']>;
  controlArea?: Maybe<ControlAreaEnum>;
  customerId: Scalars['String'];
  datenzaehlpunkt?: Maybe<Scalars['String']>;
  maloId: Scalars['String'];
  measurementLocationIds: Array<Scalars['String']>;
  parkId?: Maybe<Scalars['UUID']>;
  parkName?: Maybe<Scalars['String']>;
  protocolId: Scalars['UUID'];
  voltageLevel?: Maybe<VoltageLevelEnum>;
};

export type MaloBillingResponse = {
  data: Array<FileExport>;
  errors: Array<BillingFailure>;
  maloIds: Array<Scalars['String']>;
};

export type MaloCreateInput = {
  abschaltungExportEnabled: Scalars['Boolean'];
  accountingGrid?: Maybe<Scalars['String']>;
  anlagenNrEinspeisemenge?: Maybe<Scalars['BigDecimal']>;
  anlagenNrGebuehren?: Maybe<Scalars['BigDecimal']>;
  bemessungsleistung?: Maybe<Scalars['BigDecimal']>;
  controlArea?: Maybe<ControlAreaEnum>;
  customerId: Scalars['String'];
  datenzaehlpunkt?: Maybe<Scalars['String']>;
  maloId: Scalars['String'];
  measurementLocationIds: Array<Scalars['String']>;
  voltageLevel?: Maybe<VoltageLevelEnum>;
};

export type MaloInput = {
  abschaltungExportEnabled: Scalars['Boolean'];
  accountingGrid?: Maybe<Scalars['String']>;
  anlagenNrEinspeisemenge?: Maybe<Scalars['BigDecimal']>;
  anlagenNrGebuehren?: Maybe<Scalars['BigDecimal']>;
  bemessungsleistung?: Maybe<Scalars['BigDecimal']>;
  controlArea?: Maybe<ControlAreaEnum>;
  customerId: Scalars['String'];
  datenzaehlpunkt?: Maybe<Scalars['String']>;
  maloId: Scalars['String'];
  measurementLocationIds: Array<Scalars['String']>;
  parkId?: Maybe<Scalars['UUID']>;
  parkName?: Maybe<Scalars['String']>;
  protocolId: Scalars['UUID'];
  voltageLevel?: Maybe<VoltageLevelEnum>;
};

export type ManualImportFileInput = {
  data: Scalars['String'];
  filename: Scalars['String'];
};

export enum MeldungsStatus {
  A14 = 'A14',
  A15 = 'A15'
}

export enum MessageBoxType {
  Inbox = 'INBOX',
  Outbox = 'OUTBOX'
}

export type Metadata = {
  anlagenNrEinspeisemenge: Scalars['BigDecimal'];
  anlagenNrGebuehren: Scalars['BigDecimal'];
  importTimestamp: Scalars['ZonedDateTime'];
  maloId: Scalars['String'];
  preis: Scalars['BigDecimal'];
  profilwertGultig: Scalars['String'];
};

export type Mutation = {
  addFixedPriceAmendment: Scalars['UUID'];
  allocateAsset: Scalars['String'];
  attachDeviceToCpe: Scalars['String'];
  backdateOfferSignature: Scalars['String'];
  cancelContract: Scalars['UUID'];
  close104Connection: Scalars['String'];
  createContractPaperDummy: Scalars['UUID'];
  createCustomerPremisesEquipment: Scalars['UUID'];
  createGasStorage: Scalars['String'];
  createHeatStorage: Scalars['String'];
  createLoginForCustomer: Scalars['String'];
  createMalo: Scalars['UUID'];
  createPark: Scalars['UUID'];
  createTechnicalUnit: Scalars['UUID'];
  createTenant: Scalars['String'];
  deallocateAsset: Scalars['String'];
  decommissionTechnicalUnit: Scalars['UUID'];
  deleteCustomerLogin: Scalars['String'];
  detachDeviceFromCpe: Scalars['String'];
  exportBilling: MaloBillingResponse;
  importAauez: BillingExport;
  importAazFiles: BillingExport;
  importCustomer: CustomerImportEntityIndex;
  importDayaheadPrices: Scalars['String'];
  importLastgang: BillingExport;
  importLastgangFiles: BillingExport;
  importPrices: Scalars['String'];
  logError: Scalars['String'];
  logNavigationEvent: Scalars['String'];
  logSessionStart: Scalars['String'];
  makeTechnicalUnitContractRelevant: Scalars['UUID'];
  open104Connection: Scalars['String'];
  queueTransmissionForResending?: Maybe<TransmissionStatus>;
  reattachDeviceToCpe: Scalars['String'];
  removeFixedPriceAmendment: Scalars['UUID'];
  resetSchedule: Scalars['UUID'];
  resetSellingOpportunity: Scalars['String'];
  saveConnectionSettingsForTechnicalUnit: Scalars['UUID'];
  saveGeneralSettingsForTechnicalUnit: Scalars['UUID'];
  saveHeatStorageOptimizationPreferences: HeatStoragePreferences;
  saveNonAvailability: Scalars['UUID'];
  saveParkOptimizationPreferences: ParkPreferences;
  savePriceCalculation: Scalars['String'];
  saveRunPlanParametersForTechnicalUnit: Scalars['UUID'];
  saveSchedule: Scalars['UUID'];
  setCommercializationBegin: Scalars['UUID'];
  setCommercializationType: Scalars['UUID'];
  setOverridableOnTransmission?: Maybe<TransmissionStatus>;
  setSalesHierarchyDefaults: Scalars['String'];
  signOffer: Scalars['String'];
  startDryRunOptimization: Scalars['UUID'];
  triggerBillingForAllGrossanlagen: BillingGrossanlagenResult;
  unfreezeIntradayOptimizationForPark: Scalars['String'];
  updateCustomerData: Scalars['String'];
  updateCustomerLogin: Scalars['String'];
  updateCustomerPremisesEquipment: Scalars['String'];
  updateGasStorage: Scalars['String'];
  updateGasStorageFillingLevel: Scalars['String'];
  updateHeatStorage: Scalars['String'];
  updateHeatStorageFillingLevel: Scalars['String'];
  updateHoursBlockedForIntradayOptimization: Scalars['String'];
  updateMalo: Scalars['String'];
  updateOffer: Scalars['String'];
  updatePark: Scalars['UUID'];
  updatePasswordForCustomerLogin: Scalars['String'];
  updateSellingOpportunity: Scalars['String'];
};


export type MutationAddFixedPriceAmendmentArgs = {
  amendmentData: FixedPriceAmendmentDataInput;
  protocolId: Scalars['UUID'];
};


export type MutationAllocateAssetArgs = {
  allocationType: AssetAllocationType;
  columnProtocolId: Scalars['UUID'];
  rowProtocolId: Scalars['UUID'];
};


export type MutationAttachDeviceToCpeArgs = {
  cpeProtocolId: Scalars['UUID'];
  dataPoint: Scalars['String'];
  protocolId: Scalars['UUID'];
  type: AttachableDeviceType;
  unitNumber: Scalars['BigDecimal'];
  unitType: Scalars['BigDecimal'];
};


export type MutationBackdateOfferSignatureArgs = {
  offerId: Scalars['UUID'];
  signatureDate: Scalars['ZonedDateTime'];
};


export type MutationCancelContractArgs = {
  cancelingDate: Scalars['ZonedDateTime'];
  contractId: Scalars['UUID'];
};


export type MutationClose104ConnectionArgs = {
  cpeProtocolId: Scalars['UUID'];
};


export type MutationCreateContractPaperDummyArgs = {
  contractId: Scalars['UUID'];
  customerId: Scalars['String'];
};


export type MutationCreateCustomerPremisesEquipmentArgs = {
  data: CustomerPremisesEquipmentCreateInput;
};


export type MutationCreateGasStorageArgs = {
  gasStorage: GasStorageCreateInput;
};


export type MutationCreateHeatStorageArgs = {
  heatStorage: HeatStorageCreateInput;
};


export type MutationCreateLoginForCustomerArgs = {
  customerId: Scalars['String'];
  data: CustomerLoginDataInput;
};


export type MutationCreateMaloArgs = {
  malo: MaloCreateInput;
};


export type MutationCreateParkArgs = {
  parkCreate: ParkCreateInput;
};


export type MutationCreateTechnicalUnitArgs = {
  data: TechnicalUnitCreateInput;
};


export type MutationCreateTenantArgs = {
  tenantId: Scalars['String'];
};


export type MutationDeallocateAssetArgs = {
  allocationType: AssetAllocationType;
  columnProtocolId: Scalars['UUID'];
  rowProtocolId: Scalars['UUID'];
};


export type MutationDecommissionTechnicalUnitArgs = {
  technicalUnitId: Scalars['UUID'];
};


export type MutationDeleteCustomerLoginArgs = {
  customerId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationDetachDeviceFromCpeArgs = {
  cpeProtocolId: Scalars['UUID'];
  protocolId: Scalars['UUID'];
  type: AttachableDeviceType;
};


export type MutationExportBillingArgs = {
  month: Scalars['String'];
};


export type MutationImportAauezArgs = {
  month: Scalars['String'];
};


export type MutationImportAazFilesArgs = {
  files: Array<ManualImportFileInput>;
  yearMonth: Scalars['String'];
};


export type MutationImportCustomerArgs = {
  data: CustomerImportDataInput;
};


export type MutationImportDayaheadPricesArgs = {
  files: Array<ManualImportFileInput>;
  yearMonth: Scalars['String'];
};


export type MutationImportLastgangArgs = {
  month: Scalars['String'];
};


export type MutationImportLastgangFilesArgs = {
  files: Array<ManualImportFileInput>;
  yearMonth: Scalars['String'];
};


export type MutationImportPricesArgs = {
  month: Scalars['String'];
};


export type MutationLogErrorArgs = {
  app: Scalars['String'];
  errorMessage: Scalars['String'];
  impersonatedUser?: Maybe<Scalars['String']>;
  sessionId: Scalars['String'];
  user: Scalars['String'];
};


export type MutationLogNavigationEventArgs = {
  app: Scalars['String'];
  impersonatedUser?: Maybe<Scalars['String']>;
  sessionId: Scalars['String'];
  url: Scalars['String'];
  user: Scalars['String'];
};


export type MutationLogSessionStartArgs = {
  app: Scalars['String'];
  impersonatedUser?: Maybe<Scalars['String']>;
  screenHeight: Scalars['Int'];
  screenWidth: Scalars['Int'];
  sessionId: Scalars['String'];
  user: Scalars['String'];
  userAgent: Scalars['String'];
};


export type MutationMakeTechnicalUnitContractRelevantArgs = {
  technicalUnitId: Scalars['UUID'];
};


export type MutationOpen104ConnectionArgs = {
  cpeProtocolId: Scalars['UUID'];
};


export type MutationQueueTransmissionForResendingArgs = {
  documentId: Scalars['String'];
};


export type MutationReattachDeviceToCpeArgs = {
  cpeProtocolId: Scalars['UUID'];
  dataPoint: Scalars['String'];
  protocolId: Scalars['UUID'];
  type: AttachableDeviceType;
  unitNumber: Scalars['BigDecimal'];
  unitType: Scalars['BigDecimal'];
};


export type MutationRemoveFixedPriceAmendmentArgs = {
  amendmentProtocolId: Scalars['UUID'];
  protocolId: Scalars['UUID'];
};


export type MutationResetScheduleArgs = {
  scheduleType: ScheduleType;
  technicalUnitId: Scalars['UUID'];
};


export type MutationResetSellingOpportunityArgs = {
  sellingOpportunityId: Scalars['UUID'];
};


export type MutationSaveConnectionSettingsForTechnicalUnitArgs = {
  connectionSettings: TechnicalUnitConnectionSettingsInput;
  technicalUnitId: Scalars['UUID'];
};


export type MutationSaveGeneralSettingsForTechnicalUnitArgs = {
  generalSettings: TechnicalUnitGeneralSettingsInput;
  technicalUnitId: Scalars['UUID'];
};


export type MutationSaveHeatStorageOptimizationPreferencesArgs = {
  preferences: HeatStoragePreferencesInput;
};


export type MutationSaveNonAvailabilityArgs = {
  items: Array<NonAvailabilityInput>;
  technicalUnitId: Scalars['UUID'];
};


export type MutationSaveParkOptimizationPreferencesArgs = {
  preferences: ParkPreferencesInput;
};


export type MutationSavePriceCalculationArgs = {
  conditions: PriceCalculationConditionsInput;
  priceCalculationId: Scalars['UUID'];
};


export type MutationSaveRunPlanParametersForTechnicalUnitArgs = {
  runPlanParameters: TechnicalUnitRunPlanParametersInput;
  technicalUnitId: Scalars['UUID'];
};


export type MutationSaveScheduleArgs = {
  blocks: Array<ScheduleBlockInput>;
  scheduleType: ScheduleType;
  technicalUnitId: Scalars['UUID'];
};


export type MutationSetCommercializationBeginArgs = {
  commercializationBegin: Scalars['ZonedDateTime'];
  contractId: Scalars['UUID'];
};


export type MutationSetCommercializationTypeArgs = {
  commercializationType: CommercializationType;
  contractId: Scalars['UUID'];
};


export type MutationSetOverridableOnTransmissionArgs = {
  documentId: Scalars['String'];
  gueltigAb: Scalars['ZonedDateTime'];
  meldungsStatus: MeldungsStatus;
  version: Scalars['Long'];
};


export type MutationSetSalesHierarchyDefaultsArgs = {
  dvDefaultValues: DvDefaultValuesInput;
  dvfpDefaultValues: DvfpDefaultValuesInput;
  dvfpIdDefaultValues: DvfpIdDefaultValuesInput;
  tenantId: Scalars['String'];
};


export type MutationSignOfferArgs = {
  offerId: Scalars['UUID'];
};


export type MutationStartDryRunOptimizationArgs = {
  parkId: Scalars['UUID'];
};


export type MutationTriggerBillingForAllGrossanlagenArgs = {
  month: Scalars['String'];
};


export type MutationUnfreezeIntradayOptimizationForParkArgs = {
  parkId: Scalars['UUID'];
};


export type MutationUpdateCustomerDataArgs = {
  data: CustomerDataInput;
};


export type MutationUpdateCustomerLoginArgs = {
  data: CustomerLoginDataInput;
};


export type MutationUpdateCustomerPremisesEquipmentArgs = {
  data: CustomerPremisesEquipmentUpdateInput;
};


export type MutationUpdateGasStorageArgs = {
  gasStorage: GasStorageInput;
};


export type MutationUpdateGasStorageFillingLevelArgs = {
  fillingLevel: Scalars['BigDecimal'];
  gasStorageId: Scalars['UUID'];
};


export type MutationUpdateHeatStorageArgs = {
  heatStorage: HeatStorageInput;
};


export type MutationUpdateHeatStorageFillingLevelArgs = {
  fillingLevel: Scalars['BigDecimal'];
  heatStorageId: Scalars['UUID'];
};


export type MutationUpdateHoursBlockedForIntradayOptimizationArgs = {
  hours: Array<Scalars['BigDecimal']>;
  parkId: Scalars['UUID'];
};


export type MutationUpdateMaloArgs = {
  malo: MaloInput;
};


export type MutationUpdateOfferArgs = {
  offerId: Scalars['UUID'];
  values: OfferInput;
};


export type MutationUpdateParkArgs = {
  parkUpdate: ParkUpdateInput;
};


export type MutationUpdatePasswordForCustomerLoginArgs = {
  customerId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationUpdateSellingOpportunityArgs = {
  laufzeitBis: Scalars['ZonedDateTime'];
  laufzeitVon?: Maybe<Scalars['ZonedDateTime']>;
  sellingOpportunityId: Scalars['UUID'];
};

export type NonAvailability = {
  end: Scalars['ZonedDateTime'];
  isLocked?: Maybe<Scalars['Boolean']>;
  start: Scalars['ZonedDateTime'];
};

export type NonAvailabilityInput = {
  end: Scalars['ZonedDateTime'];
  isLocked?: Maybe<Scalars['Boolean']>;
  start: Scalars['ZonedDateTime'];
};

export type Offer = {
  kuendigungsfrist?: Maybe<Scalars['Period']>;
  verlaengerungsdauer?: Maybe<Scalars['Period']>;
  vertragsversion: Scalars['String'];
};

export type OfferByPriceCalculationId = {
  isOpen: Scalars['Boolean'];
  offerId: Scalars['UUID'];
  productType?: Maybe<Scalars['String']>;
};

export type OfferConditions = {
  maloConditions: Array<OfferConditionsForMalo>;
  productType?: Maybe<Scalars['String']>;
  vertragsbeginn?: Maybe<Scalars['ZonedDateTime']>;
  vertragsende?: Maybe<Scalars['ZonedDateTime']>;
};

export type OfferConditionsForMalo = {
  aufteilungsfaktorMehrerloes?: Maybe<Scalars['BigDecimal']>;
  aufteilungsfaktorMehrerloesIntraday?: Maybe<Scalars['BigDecimal']>;
  aufteilungsfaktorMindererloes?: Maybe<Scalars['BigDecimal']>;
  aufteilungsfaktorMindererloesIntraday?: Maybe<Scalars['BigDecimal']>;
  ausfallverguetung: Scalars['String'];
  ausgleichszahlung: Scalars['String'];
  dienstleistungsentgelt?: Maybe<Scalars['BigDecimal']>;
  grundgebuehr?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscount?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscountEnde?: Maybe<Scalars['ZonedDateTime']>;
  hasRedispatch: Scalars['Boolean'];
  maloId: Scalars['UUID'];
  maloName: Scalars['String'];
  parkName: Scalars['String'];
  priceCalculationId: Scalars['UUID'];
  technicalUnits: Array<TechnicalUnitIdentifier>;
  verfahrenAusfallarbeit?: Maybe<Scalars['String']>;
};

export type OfferInput = {
  kuendigungsfrist?: Maybe<Scalars['Period']>;
  verlaengerungsdauer?: Maybe<Scalars['Period']>;
  vertragsversion: Scalars['String'];
};

export enum OfferType {
  Dv = 'DV',
  Dvfp = 'DVFP',
  Dvfpid = 'DVFPID',
  Unkown = 'Unkown'
}

export type OfferWithRevenuePrediction = {
  additionalRevenueFromDVFP?: Maybe<Scalars['BigDecimal']>;
  calculationState: CalculationStates;
  discounts: Array<Discount>;
  generalFee?: Maybe<Scalars['BigDecimal']>;
  hasRedispatch: Scalars['Boolean'];
  offerId: Scalars['UUID'];
  productType: Produkt;
  revenue?: Maybe<Scalars['BigDecimal']>;
  serviceFee?: Maybe<Scalars['BigDecimal']>;
  totalRevenue?: Maybe<Scalars['BigDecimal']>;
};

export type OpenSellingOpportunityForOffer = {
  customerId: Scalars['String'];
  offerId: Scalars['UUID'];
  sellingOpportunityId: Scalars['UUID'];
};

export type OpenSellingOpportunityState = {
  customerId?: Maybe<Scalars['String']>;
  parkProtocolId: Scalars['UUID'];
  processingError?: Maybe<Scalars['String']>;
  processingOutcome: SalesOpportunityProcessingOutcome;
  processingState: SalesOpportunityProcessingState;
  sellingOpportunityId?: Maybe<Scalars['UUID']>;
  state?: Maybe<SellingOpportunityState>;
};

export type OptimizationError = {
  code: Scalars['Int'];
  gsIds?: Maybe<Array<Scalars['UUID']>>;
  message: Scalars['String'];
  teIds?: Maybe<Array<Scalars['UUID']>>;
  wsIds?: Maybe<Array<Scalars['UUID']>>;
};

export enum OptimizationTimeFrame {
  Daily = 'Daily',
  Weekly = 'Weekly'
}

export type Park = {
  abschaltungEnabled: Scalars['Boolean'];
  addingTEProcess: Scalars['Boolean'];
  decommissioningTEProcess: Scalars['Boolean'];
  distributionSystemOperatorCoding?: Maybe<CodingEnum>;
  distributionSystemOperatorId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  kundenId: Scalars['String'];
  parkGroesse: Scalars['BigDecimal'];
  parkName: Scalars['String'];
  produktFreigabe: Array<ProductEnum>;
  produktFreigabeOverride?: Maybe<Array<ProductEnum>>;
  redispatchTransmissionOverride?: Maybe<Scalars['Boolean']>;
  safetySchedulesEnabled: Scalars['Boolean'];
  sortenreinerPark: Scalars['Boolean'];
  underPar51: Scalars['Boolean'];
};

export type ParkChildren = {
  cpes: Array<ParkUnit>;
  gasStorages: Array<ParkUnit>;
  heatStorages: Array<ParkUnit>;
  malos: Array<ParkUnit>;
  technicalUnits: Array<ParkUnit>;
};

export type ParkCreateInput = {
  abschaltungEnabled: Scalars['Boolean'];
  customerId: Scalars['String'];
  distributionSystemOperatorCoding?: Maybe<CodingEnum>;
  distributionSystemOperatorId?: Maybe<Scalars['String']>;
  parkName: Scalars['String'];
  redispatchTransmissionOverride?: Maybe<Scalars['Boolean']>;
  safetySchedulesEnabled: Scalars['Boolean'];
};

export type ParkOptimizationState = {
  assetId: Scalars['UUID'];
  errors: Array<OptimizationError>;
  hasInput: Scalars['Boolean'];
  hasOutput: Scalars['Boolean'];
  jobType: JobType;
  kundenId: Scalars['String'];
  outcome: JobOutcome;
  parkId: Scalars['UUID'];
  requestedAt?: Maybe<Scalars['ZonedDateTime']>;
  revenueDV?: Maybe<Scalars['BigDecimal']>;
  revenueDVFP?: Maybe<Scalars['BigDecimal']>;
  state: JobState;
  warnings: Array<OptimizationError>;
};

export type ParkPreferences = {
  accuracy?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
  optimizationTimeFrame?: Maybe<OptimizationTimeFrame>;
  targetMarginPercentage?: Maybe<Scalars['BigDecimal']>;
};

export type ParkPreferencesInput = {
  accuracy?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
  optimizationTimeFrame?: Maybe<OptimizationTimeFrame>;
  targetMarginPercentage?: Maybe<Scalars['BigDecimal']>;
};

export type ParkUnit = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  pool?: Maybe<Pool>;
  tags: Array<Scalars['String']>;
};

export type ParkUpdateInput = {
  abschaltungEnabled: Scalars['Boolean'];
  distributionSystemOperatorCoding?: Maybe<CodingEnum>;
  distributionSystemOperatorId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  parkName: Scalars['String'];
  produktFreigabeOverride?: Maybe<Array<ProductEnum>>;
  redispatchTransmissionOverride?: Maybe<Scalars['Boolean']>;
  safetySchedulesEnabled: Scalars['Boolean'];
};

export type ParkWithRelationShips = {
  customerPremisesEquipments: Array<ParkUnit>;
  gasStorages: Array<ParkUnit>;
  heatStorages: Array<ParkUnit>;
  id: Scalars['UUID'];
  malos: Array<ParkUnit>;
  name: Scalars['String'];
  pools: Array<Pool>;
  tags: Array<Scalars['String']>;
  technicalUnits: Array<ParkUnit>;
};


export type PlanningHint = {
  technicalUnitName: Scalars['String'];
  technicalUnitProtocolId: Scalars['UUID'];
  type: PlanningHintType;
};

export enum PlanningHintType {
  NewTe = 'NewTe'
}

export enum Pool {
  Dv = 'DV',
  Dvfp = 'DVFP',
  Nopool = 'NOPOOL'
}

export type PriceCalculation = {
  angebotId: Scalars['UUID'];
  customerId: Scalars['String'];
  id: Scalars['UUID'];
  maloId: Scalars['UUID'];
  preiskalkulationeigenschaften: PriceCalculationConditions;
  state?: Maybe<Scalars['String']>;
};

export type PriceCalculationConditions = {
  ausfallVerguetungFactor?: Maybe<Scalars['BigDecimal']>;
  ausfallVerguetungMalo?: Maybe<CompensationTypeEnum>;
  dienstleistungsentgeltSatz?: Maybe<Scalars['BigDecimal']>;
  eccGebuehrIDGA?: Maybe<Scalars['BigDecimal']>;
  epexGebuehrIDGA?: Maybe<Scalars['BigDecimal']>;
  grundgebuehr?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscount?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscountEnde?: Maybe<Scalars['ZonedDateTime']>;
  par51EntschaedigungFactor?: Maybe<Scalars['BigDecimal']>;
  par51EntschaedigungMalo?: Maybe<DecisionTypeEnum>;
  poenaleFaktorIDGA?: Maybe<Scalars['BigDecimal']>;
  preisfindung?: Maybe<Scalars['String']>;
  verfahrenAusfallarbeit?: Maybe<ProductionTypeEnum>;
  zuteilungsfaktorFpMehrerloes?: Maybe<Scalars['BigDecimal']>;
  zuteilungsfaktorFpMehrerloesId?: Maybe<Scalars['BigDecimal']>;
  zuteilungsfaktorFpMindererloes?: Maybe<Scalars['BigDecimal']>;
  zuteilungsfaktorFpMindererloesId?: Maybe<Scalars['BigDecimal']>;
};

export type PriceCalculationConditionsInput = {
  ausfallVerguetungFactor?: Maybe<Scalars['BigDecimal']>;
  ausfallVerguetungMalo?: Maybe<CompensationTypeEnum>;
  dienstleistungsentgeltSatz?: Maybe<Scalars['BigDecimal']>;
  eccGebuehrIDGA?: Maybe<Scalars['BigDecimal']>;
  epexGebuehrIDGA?: Maybe<Scalars['BigDecimal']>;
  grundgebuehr?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscount?: Maybe<Scalars['BigDecimal']>;
  grundgebuehrDiscountEnde?: Maybe<Scalars['ZonedDateTime']>;
  par51EntschaedigungFactor?: Maybe<Scalars['BigDecimal']>;
  par51EntschaedigungMalo?: Maybe<DecisionTypeEnum>;
  poenaleFaktorIDGA?: Maybe<Scalars['BigDecimal']>;
  preisfindung?: Maybe<Scalars['String']>;
  verfahrenAusfallarbeit?: Maybe<ProductionTypeEnum>;
  zuteilungsfaktorFpMehrerloes?: Maybe<Scalars['BigDecimal']>;
  zuteilungsfaktorFpMehrerloesId?: Maybe<Scalars['BigDecimal']>;
  zuteilungsfaktorFpMindererloes?: Maybe<Scalars['BigDecimal']>;
  zuteilungsfaktorFpMindererloesId?: Maybe<Scalars['BigDecimal']>;
};

export type PricePoint = {
  datum: Scalars['ZonedDateTime'];
  preis: Scalars['BigDecimal'];
};

export type Producer = {
  code: Scalars['String'];
  coding: Scalars['String'];
};

export enum ProductEnum {
  Dv = 'DV',
  Dvfp = 'DVFP',
  Dvfpid = 'DVFPID'
}

export type ProductObligationState = {
  obligations: ProductObligations;
  parkId: Scalars['UUID'];
};

export type ProductObligations = {
  addingTeProcess: Scalars['Boolean'];
  decommissionTeProcess: Scalars['Boolean'];
  fulfillsProductRequirement: Scalars['Boolean'];
  hasAllowedProducts: Scalars['Boolean'];
  inTechnicalReview: Scalars['Boolean'];
  needsPlanning: Scalars['Boolean'];
  needsUpdateBecauseTeIsDefect: Scalars['Boolean'];
  needsUpdateForNewTe: Scalars['Boolean'];
  newContractVersionAvailable: Scalars['Boolean'];
};

export type ProductSet = {
  contractDates: ContractDates;
  contractVersion?: Maybe<Scalars['String']>;
  parkId: Scalars['UUID'];
  parkName: Scalars['String'];
  priceCalcId?: Maybe<Scalars['UUID']>;
  productType: ProductType;
  technicalUnitProducts: Array<TechnicalUnitProduct>;
};

export enum ProductType {
  Dv = 'DV',
  Dvfp = 'DVFP',
  Id = 'ID',
  None = 'NONE'
}

export enum ProductTypeEnum {
  Dv = 'DV',
  Dvfp = 'DVFP',
  Dvfpid = 'DVFPID',
  None = 'NONE'
}

export enum ProductionTypeEnum {
  Pauschal = 'PAUSCHAL'
}

export enum Produkt {
  Direktvermarktung = 'DIREKTVERMARKTUNG',
  DirektvermarktungFahrplanmanagement = 'DIREKTVERMARKTUNG_FAHRPLANMANAGEMENT',
  FahrplanmanagementIntraday = 'FAHRPLANMANAGEMENT_INTRADAY'
}

export type Query = {
  aazForMaloAndMonth: BillingImport;
  activeProductType: ProductTypeEnum;
  comtest: RaidaResponse;
  contractAmendmentFile: ContractFile;
  contractFile: ContractFile;
  contractFilePreview: ContractFile;
  csvExportForTenant: CsvExportFile;
  dayaheadPricesForMonth: Array<PricePoint>;
  exportCustomer: CustomerImportData;
  getPlannedResourceSchedulesXmls: RedispatchFile;
  getRaidaXmlCommunication: RaidaCommnicationXml;
  getRaidaXmlCommunicationsOverview: Array<RaidaCommunicationXmlInfos>;
  getReports: Array<XmlDataReport>;
  getTransmissionLogsFor: Array<TransmissionStatusLog>;
  getTransmissionStatus: Array<TransmissionStatus>;
  getXmls: RedispatchFile;
  lastgangForMaloAndMonth: BillingImport;
  listAllTenants: Array<ApiTenant>;
  loginsByCustomerId: Array<CustomerLoginData>;
  offersWithPredictionsForPark: Array<OfferWithRevenuePrediction>;
  optimizationInput: Scalars['String'];
  optimizationOutput: Scalars['String'];
  optimizationResult: ExportResult;
  runBillingForMalos: MaloBillingResponse;
  sendData: Array<RaidaResponse>;
  sendUnavailabilities: Array<RaidaResponse>;
  terminationAgreementFile: ContractFile;
  terminationAgreementFileExists: Scalars['Boolean'];
  terminationAgreementFilePreview: ContractFile;
  unavailabilitesXmls: RedispatchFile;
  waitForSalesOpportunityForPark: Scalars['String'];
};


export type QueryAazForMaloAndMonthArgs = {
  maloId: Scalars['String'];
  yearMonth: Scalars['String'];
};


export type QueryActiveProductTypeArgs = {
  parkId: Scalars['UUID'];
};


export type QueryContractAmendmentFileArgs = {
  contractAmendmentId: Scalars['UUID'];
  customerId: Scalars['String'];
};


export type QueryContractFileArgs = {
  contractId: Scalars['UUID'];
  customerId: Scalars['String'];
};


export type QueryContractFilePreviewArgs = {
  customerId: Scalars['String'];
  priceCalculationId: Scalars['UUID'];
  technicalUnitId: Scalars['UUID'];
};


export type QueryCsvExportForTenantArgs = {
  tenantId: Scalars['String'];
};


export type QueryDayaheadPricesForMonthArgs = {
  yearMonth: Scalars['String'];
};


export type QueryExportCustomerArgs = {
  customerId: Scalars['String'];
};


export type QueryGetRaidaXmlCommunicationArgs = {
  documentId: Scalars['String'];
  messageBoxType: MessageBoxType;
};


export type QueryGetReportsArgs = {
  forAllCustomers: Scalars['Boolean'];
};


export type QueryGetTransmissionLogsForArgs = {
  documentId: Scalars['String'];
};


export type QueryLastgangForMaloAndMonthArgs = {
  maloId: Scalars['String'];
  yearMonth: Scalars['String'];
};


export type QueryLoginsByCustomerIdArgs = {
  customerId: Scalars['String'];
};


export type QueryOffersWithPredictionsForParkArgs = {
  parkId: Scalars['UUID'];
};


export type QueryOptimizationInputArgs = {
  assetId: Scalars['UUID'];
  jobType: JobType;
};


export type QueryOptimizationOutputArgs = {
  assetId: Scalars['UUID'];
  jobType: JobType;
};


export type QueryOptimizationResultArgs = {
  assetId: Scalars['UUID'];
  jobType: JobType;
};


export type QueryRunBillingForMalosArgs = {
  maloIds: Array<Scalars['String']>;
  yearMonth: Scalars['String'];
};


export type QueryTerminationAgreementFileArgs = {
  customerId: Scalars['String'];
  technicalUnitId: Scalars['UUID'];
};


export type QueryTerminationAgreementFileExistsArgs = {
  customerId: Scalars['String'];
  technicalUnitId: Scalars['UUID'];
};


export type QueryTerminationAgreementFilePreviewArgs = {
  customerId: Scalars['String'];
  technicalUnitId: Scalars['UUID'];
};


export type QueryWaitForSalesOpportunityForParkArgs = {
  parkId: Scalars['UUID'];
};

export type RaidaCommnicationXml = {
  filename: Scalars['String'];
  subject: Scalars['String'];
  xmlStrings: Array<Scalars['String']>;
};

export type RaidaCommunicationXmlInfos = {
  createdAt: Scalars['ZonedDateTime'];
  documentId: Scalars['String'];
  fileName: Scalars['String'];
  type: MessageBoxType;
};

export type RaidaResponse = {
  isFailed: Scalars['Boolean'];
  responseBody?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
};

export type RedispatchFile = {
  bytes: Scalars['String'];
  name: Scalars['String'];
};

export type ResourceProvider = {
  code: Scalars['String'];
  coding: Scalars['String'];
};

export type SalesHierarchyDefaults = {
  dvDefaults: DvDefaultValues;
  dvfpDefaults: DvfpDefaultValues;
  dvfpidDefaults: DvfpIdDefaultValues;
};

export enum SalesOpportunityProcessingOutcome {
  Error = 'Error',
  Success = 'Success',
  Unknown = 'Unknown'
}

export enum SalesOpportunityProcessingState {
  Busy = 'Busy',
  Inactive = 'Inactive',
  Overridden = 'Overridden',
  ParkIsInconsistent = 'ParkIsInconsistent',
  Unknown = 'Unknown'
}

export type ScheduleBlock = {
  end: Scalars['ZonedDateTime'];
  power: Scalars['BigDecimal'];
  start: Scalars['ZonedDateTime'];
};

export type ScheduleBlockInput = {
  end: Scalars['ZonedDateTime'];
  power: Scalars['BigDecimal'];
  start: Scalars['ZonedDateTime'];
};

export enum ScheduleType {
  ActiveSchedule = 'ActiveSchedule',
  CurtailmentSchedule = 'CurtailmentSchedule',
  OperationsSchedule = 'OperationsSchedule',
  Prescriptions = 'Prescriptions',
  ProductionForecast = 'ProductionForecast',
  Proposal = 'Proposal',
  SafetySchedule = 'SafetySchedule'
}

export type SellingOpportunity = {
  laufzeitBis?: Maybe<Scalars['ZonedDateTime']>;
  laufzeitVon?: Maybe<Scalars['ZonedDateTime']>;
};

export type SellingOpportunityChildren = {
  customerId: Scalars['String'];
  offers: Array<SellingOpportunityOffer>;
  protocolId: Scalars['UUID'];
  state: SellingOpportunityState;
};

export type SellingOpportunityOffer = {
  offerProtocolId: Scalars['UUID'];
  priceCalculations: Array<SellingOpportunityPriceCalculation>;
  product: Scalars['String'];
  state: SellingOpportunityState;
};

export type SellingOpportunityPriceCalculation = {
  maloId: Scalars['String'];
  priceCalculationProtocolId: Scalars['UUID'];
};

export enum SellingOpportunityState {
  Canceled = 'Canceled',
  Open = 'Open',
  Won = 'Won'
}

export type SeriesItem = {
  dateTime: Scalars['ZonedDateTime'];
  value: Scalars['BigDecimal'];
};

export type SignedProducts = {
  activeProductsGroupedBySignatureDate: Array<ProductSet>;
  customerId: Scalars['String'];
  finishedProductsGroupedBySignatureDate: Array<ProductSet>;
  futureProductsGroupedBySignatureDate: Array<ProductSet>;
  hasOffers: Scalars['Boolean'];
};

export type StorageResult = {
  id: Scalars['UUID'];
  series: Array<SeriesItem>;
};

export enum StorageType {
  GasStorage = 'GasStorage',
  HeatStorage = 'HeatStorage'
}

export type StorageWithFillingLevel = {
  fillingLevel?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  protocolId: Scalars['UUID'];
  type: StorageType;
};

export type Subscription = {
  allCustomerPremisesEquipmentsForAllCustomers: Array<CustomerPremisesEquipment>;
  allDataPointOptions: DataPointOptions;
  allParksWithRelationShipsForCustomer: Array<ParkWithRelationShips>;
  allStoragesForCustomerWithFillingLevel: Array<StorageWithFillingLevel>;
  assetAllocationsByType: AssetAllocations;
  assetTree: Array<CustomerTree>;
  calculateEnergyConcentration: GasStorageCalculationResult;
  calculateFeuerungsleistung: CalculationResult;
  calculateStromkennzahl: CalculationResult;
  calculateWirkungsgradGesamt: CalculationResult;
  contractConditions: Array<ContractConditionsByMalo>;
  contractPreviewDataForOffer: ContractPreviewDataForOffer;
  cpeAllocationsByType: CpeAllocations;
  customerDataById: CustomerData;
  customerPremisesEquipmentById: CustomerPremisesEquipment;
  customerPremisesEquipmentsByCustomerId: Array<CustomerPremisesEquipment>;
  customerPremisesEquipmentsByTenantId: Array<CustomerPremisesEquipment>;
  dayOfContractEndForOffers: Scalars['ZonedDateTime'];
  dayOfContractStartForOffers: Scalars['ZonedDateTime'];
  gasStorageById: GasStorage;
  globalTimeSeries: Array<SeriesItem>;
  heatStorageById: HeatStorage;
  heatStorageOptimizationPreferences: HeatStoragePreferences;
  hoursBlockedForIntradayOptimization: Array<Scalars['BigDecimal']>;
  isTechnicalUnitLastTechnicalUnitInPark: Scalars['Boolean'];
  logBookEntries: Array<LogBookEntryWithMetaData>;
  maloById: Malo;
  nonAvailabilitiesForTechnicalUnit: Array<NonAvailability>;
  offerById: Offer;
  offerConditions: OfferConditions;
  offerForPriceCalculation: OfferByPriceCalculationId;
  openSellingOpportunityStateForPark: OpenSellingOpportunityState;
  optimizationJobProblems: Array<JobProblem>;
  optimizationJobState: Array<ParkOptimizationState>;
  optimizationJobStatesForCustomer: Array<ParkOptimizationState>;
  park: Park;
  parkOptimizationPreferences: ParkPreferences;
  parkWithRelationShipsById: ParkWithRelationShips;
  priceCalculationById: PriceCalculation;
  priceCalculationsForOffer: Array<PriceCalculation>;
  productObligationsForCustomer: Array<ProductObligationState>;
  productObligationsForPark: ProductObligationState;
  productsOverviewForCustomer: Array<SignedProducts>;
  productsOverviewForPark: SignedProducts;
  salesHierarchyDefaults: SalesHierarchyDefaults;
  scheduleForPark: Array<ScheduleBlock>;
  scheduleForPool: Array<ScheduleBlock>;
  scheduleForTechnicalUnit: Array<ScheduleBlock>;
  schedulePlanningHintsForPark: Array<PlanningHint>;
  sellingOpportunityById: SellingOpportunity;
  sellingOpportunityChildrenById: SellingOpportunityChildren;
  sellingOpportunityForOffer: OpenSellingOpportunityForOffer;
  technicalUnitById: TechnicalUnit;
  terminatedTechnicalUnitsForCustomer: Array<Scalars['UUID']>;
  timeSeriesForAsset: Array<SeriesItem>;
};


export type SubscriptionAllParksWithRelationShipsForCustomerArgs = {
  customerId: Scalars['String'];
};


export type SubscriptionAllStoragesForCustomerWithFillingLevelArgs = {
  customerId: Scalars['String'];
};


export type SubscriptionAssetAllocationsByTypeArgs = {
  allocationType: AssetAllocationType;
  customerId?: Maybe<Scalars['String']>;
  parkId?: Maybe<Scalars['UUID']>;
};


export type SubscriptionAssetTreeArgs = {
  customerId?: Maybe<Scalars['String']>;
  pool?: Maybe<Pool>;
  query?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
};


export type SubscriptionCalculateEnergyConcentrationArgs = {
  methanConcentration?: Maybe<Scalars['BigDecimal']>;
};


export type SubscriptionCalculateFeuerungsleistungArgs = {
  nennleistungElekkWh: Scalars['BigDecimal'];
  nennleistungThermkWh: Scalars['BigDecimal'];
  wirkungsgradGesamt: Scalars['BigDecimal'];
};


export type SubscriptionCalculateStromkennzahlArgs = {
  nennleistungElekkWh: Scalars['BigDecimal'];
  nennleistungThermkWh: Scalars['BigDecimal'];
};


export type SubscriptionCalculateWirkungsgradGesamtArgs = {
  wirkungsgradElektrisch: Scalars['BigDecimal'];
  wirkungsgradThermisch: Scalars['BigDecimal'];
};


export type SubscriptionContractConditionsArgs = {
  offerId: Scalars['UUID'];
};


export type SubscriptionContractPreviewDataForOfferArgs = {
  offerId: Scalars['UUID'];
};


export type SubscriptionCpeAllocationsByTypeArgs = {
  protocolId: Scalars['UUID'];
  type: AttachableDeviceType;
};


export type SubscriptionCustomerDataByIdArgs = {
  customerId: Scalars['String'];
};


export type SubscriptionCustomerPremisesEquipmentByIdArgs = {
  protocolId: Scalars['UUID'];
};


export type SubscriptionCustomerPremisesEquipmentsByCustomerIdArgs = {
  customerId: Scalars['String'];
};


export type SubscriptionCustomerPremisesEquipmentsByTenantIdArgs = {
  tenantId: Scalars['String'];
};


export type SubscriptionDayOfContractEndForOffersArgs = {
  offerId: Scalars['UUID'];
};


export type SubscriptionGasStorageByIdArgs = {
  gasStorageId: Scalars['UUID'];
};


export type SubscriptionGlobalTimeSeriesArgs = {
  from: Scalars['ZonedDateTime'];
  to: Scalars['ZonedDateTime'];
  type: GlobalTimeSeriesType;
};


export type SubscriptionHeatStorageByIdArgs = {
  heatStorageId: Scalars['UUID'];
};


export type SubscriptionHeatStorageOptimizationPreferencesArgs = {
  id: Scalars['UUID'];
};


export type SubscriptionHoursBlockedForIntradayOptimizationArgs = {
  parkId: Scalars['UUID'];
};


export type SubscriptionIsTechnicalUnitLastTechnicalUnitInParkArgs = {
  technicalUnitId: Scalars['UUID'];
};


export type SubscriptionLogBookEntriesArgs = {
  contextId: Scalars['String'];
  contextType: LogBookContextType;
  filter: Array<LogBookEntryType>;
  from?: Maybe<Scalars['ZonedDateTime']>;
  limit: Scalars['Int'];
  to?: Maybe<Scalars['ZonedDateTime']>;
};


export type SubscriptionMaloByIdArgs = {
  protocolId: Scalars['UUID'];
};


export type SubscriptionNonAvailabilitiesForTechnicalUnitArgs = {
  technicalUnitId: Scalars['UUID'];
};


export type SubscriptionOfferByIdArgs = {
  offerId: Scalars['UUID'];
};


export type SubscriptionOfferConditionsArgs = {
  offerId: Scalars['UUID'];
};


export type SubscriptionOfferForPriceCalculationArgs = {
  priceCalculation: Scalars['UUID'];
};


export type SubscriptionOpenSellingOpportunityStateForParkArgs = {
  parkId: Scalars['UUID'];
};


export type SubscriptionOptimizationJobProblemsArgs = {
  assetId: Scalars['UUID'];
  assetType: AssetType;
  jobType: JobType;
};


export type SubscriptionOptimizationJobStateArgs = {
  assetId: Scalars['UUID'];
  assetType: AssetType;
  jobType: JobType;
};


export type SubscriptionOptimizationJobStatesForCustomerArgs = {
  customerId: Scalars['String'];
  jobType: JobType;
};


export type SubscriptionParkArgs = {
  parkId: Scalars['UUID'];
};


export type SubscriptionParkOptimizationPreferencesArgs = {
  id: Scalars['UUID'];
};


export type SubscriptionParkWithRelationShipsByIdArgs = {
  parkId: Scalars['UUID'];
};


export type SubscriptionPriceCalculationByIdArgs = {
  priceCalculationId: Scalars['UUID'];
};


export type SubscriptionPriceCalculationsForOfferArgs = {
  offerId: Scalars['UUID'];
};


export type SubscriptionProductObligationsForCustomerArgs = {
  customerId: Scalars['String'];
};


export type SubscriptionProductObligationsForParkArgs = {
  parkId: Scalars['UUID'];
};


export type SubscriptionProductsOverviewForCustomerArgs = {
  customerId: Scalars['String'];
};


export type SubscriptionProductsOverviewForParkArgs = {
  parkId: Scalars['UUID'];
};


export type SubscriptionSalesHierarchyDefaultsArgs = {
  tenantId: Scalars['String'];
};


export type SubscriptionScheduleForParkArgs = {
  from?: Maybe<Scalars['ZonedDateTime']>;
  parkId: Scalars['UUID'];
  scheduleType: ScheduleType;
  to?: Maybe<Scalars['ZonedDateTime']>;
};


export type SubscriptionScheduleForPoolArgs = {
  from?: Maybe<Scalars['ZonedDateTime']>;
  pool: Pool;
  to?: Maybe<Scalars['ZonedDateTime']>;
};


export type SubscriptionScheduleForTechnicalUnitArgs = {
  from?: Maybe<Scalars['ZonedDateTime']>;
  scheduleType: ScheduleType;
  technicalUnitId: Scalars['UUID'];
  to?: Maybe<Scalars['ZonedDateTime']>;
};


export type SubscriptionSchedulePlanningHintsForParkArgs = {
  parkId: Scalars['UUID'];
};


export type SubscriptionSellingOpportunityByIdArgs = {
  sellingOpportunityId: Scalars['UUID'];
};


export type SubscriptionSellingOpportunityChildrenByIdArgs = {
  sellingOpportunityId: Scalars['UUID'];
};


export type SubscriptionSellingOpportunityForOfferArgs = {
  offerId: Scalars['UUID'];
};


export type SubscriptionTechnicalUnitByIdArgs = {
  id: Scalars['UUID'];
};


export type SubscriptionTerminatedTechnicalUnitsForCustomerArgs = {
  customerId: Scalars['String'];
};


export type SubscriptionTimeSeriesForAssetArgs = {
  assetId: Scalars['UUID'];
  from?: Maybe<Scalars['ZonedDateTime']>;
  to?: Maybe<Scalars['ZonedDateTime']>;
  type: TimeSeriesType;
};

export type Supplier = {
  code: Scalars['String'];
  coding: Scalars['String'];
};

export type TechnicalUnit = {
  connectionSettings: TechnicalUnitConnectionSettings;
  contractRelevant: Scalars['Boolean'];
  customerId?: Maybe<Scalars['String']>;
  generalSettings: TechnicalUnitGeneralSettings;
  id: Scalars['UUID'];
  isDefective: Scalars['Boolean'];
  parkId?: Maybe<Scalars['UUID']>;
  parkName?: Maybe<Scalars['String']>;
  relevantForPlaning: Scalars['Boolean'];
  runPlanParameters: TechnicalUnitRunPlanParameters;
};

export type TechnicalUnitConnectionSettings = {
  anbindungTE?: Maybe<Scalars['String']>;
  anbindungVK?: Maybe<Scalars['String']>;
  datumDerInbetriebnahme?: Maybe<Scalars['ZonedDateTime']>;
  datumDerNetzanbindung?: Maybe<Scalars['ZonedDateTime']>;
  hundertProzentWert: Scalars['BigDecimal'];
  leistungsstufen?: Maybe<Array<Scalars['BigDecimal']>>;
  motorhersteller?: Maybe<Scalars['String']>;
  obereNennleistung: Scalars['BigDecimal'];
  planungsrelevant: Scalars['Boolean'];
  planungsrelevantOverride?: Maybe<Scalars['Boolean']>;
  steuerbarkeit?: Maybe<Scalars['String']>;
  steuerungsfreigabe?: Maybe<Array<ProductEnum>>;
  steuerungsmodus?: Maybe<Scalars['String']>;
  steuerungstyp?: Maybe<Scalars['String']>;
  technicalUnitId: Scalars['UUID'];
  technischeMinDauerleistung?: Maybe<Scalars['BigDecimal']>;
  untereNennleistung?: Maybe<Scalars['BigDecimal']>;
};

export type TechnicalUnitConnectionSettingsInput = {
  anbindungTE?: Maybe<Scalars['String']>;
  anbindungVK?: Maybe<Scalars['String']>;
  datumDerInbetriebnahme?: Maybe<Scalars['ZonedDateTime']>;
  datumDerNetzanbindung?: Maybe<Scalars['ZonedDateTime']>;
  hundertProzentWert: Scalars['BigDecimal'];
  leistungsstufen?: Maybe<Array<Scalars['BigDecimal']>>;
  motorhersteller?: Maybe<Scalars['String']>;
  obereNennleistung: Scalars['BigDecimal'];
  planungsrelevant: Scalars['Boolean'];
  planungsrelevantOverride?: Maybe<Scalars['Boolean']>;
  steuerbarkeit?: Maybe<Scalars['String']>;
  steuerungsfreigabe?: Maybe<Array<ProductEnum>>;
  steuerungsmodus?: Maybe<Scalars['String']>;
  steuerungstyp?: Maybe<Scalars['String']>;
  technicalUnitId: Scalars['UUID'];
  technischeMinDauerleistung?: Maybe<Scalars['BigDecimal']>;
  untereNennleistung?: Maybe<Scalars['BigDecimal']>;
};

export type TechnicalUnitCreateInput = {
  anzulegenderWert?: Maybe<Scalars['BigDecimal']>;
  flexMotor: Scalars['Boolean'];
  kundenId: Scalars['String'];
  minLeistung: Scalars['BigDecimal'];
  obereNennleistung: Scalars['BigDecimal'];
};

export type TechnicalUnitGeneralSettings = {
  adresse?: Maybe<Address>;
  anzulegenderWert?: Maybe<Scalars['BigDecimal']>;
  bemerkung?: Maybe<Scalars['String']>;
  billingMethod: BillingMethodEnum;
  controllableResourceId?: Maybe<Scalars['String']>;
  curtailmentOffset: Scalars['BigDecimal'];
  eegAnlagenschluessel?: Maybe<Scalars['String']>;
  energySource: EnergySourceEnum;
  flexMotor: Scalars['Boolean'];
  koordinatenWgs84?: Maybe<GeoCoordinates>;
  mastrNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  technicalResourceId?: Maybe<Scalars['String']>;
  technicalUnitId: Scalars['UUID'];
};

export type TechnicalUnitGeneralSettingsInput = {
  adresse?: Maybe<AddressInput>;
  anzulegenderWert?: Maybe<Scalars['BigDecimal']>;
  bemerkung?: Maybe<Scalars['String']>;
  billingMethod: BillingMethodEnum;
  controllableResourceId?: Maybe<Scalars['String']>;
  curtailmentOffset: Scalars['BigDecimal'];
  eegAnlagenschluessel?: Maybe<Scalars['String']>;
  energySource: EnergySourceEnum;
  flexMotor: Scalars['Boolean'];
  koordinatenWgs84?: Maybe<GeoCoordinatesInput>;
  mastrNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  technicalResourceId?: Maybe<Scalars['String']>;
  technicalUnitId: Scalars['UUID'];
};

export type TechnicalUnitIdentifier = {
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type TechnicalUnitProduct = {
  canceldFor?: Maybe<Scalars['ZonedDateTime']>;
  commercializationEnd?: Maybe<Scalars['ZonedDateTime']>;
  commercializationStart?: Maybe<Scalars['ZonedDateTime']>;
  contractAmendmentId?: Maybe<Scalars['UUID']>;
  contractEndDate?: Maybe<Scalars['ZonedDateTime']>;
  contractId?: Maybe<Scalars['UUID']>;
  contractStartDate?: Maybe<Scalars['ZonedDateTime']>;
  contractVersion?: Maybe<Scalars['String']>;
  currentCommercializationType?: Maybe<CommercializationType>;
  fixedPrice?: Maybe<Scalars['BigDecimal']>;
  maloName: Scalars['String'];
  offerId?: Maybe<Scalars['UUID']>;
  possibleCommercializationTypes?: Maybe<Array<CommercializationType>>;
  priceCalculation?: Maybe<Scalars['UUID']>;
  productType: ProductType;
  signatureDate?: Maybe<Scalars['ZonedDateTime']>;
  state: ContractState;
  technicalUnitHasDefect?: Maybe<Scalars['Boolean']>;
  technicalUnitId?: Maybe<Scalars['UUID']>;
  technicalUnitName: Scalars['String'];
  type: TechnicalUnitProductType;
};

export enum TechnicalUnitProductType {
  Contract = 'Contract',
  Dummy = 'Dummy',
  FixedPriceAmendment = 'FixedPriceAmendment'
}

export type TechnicalUnitResult = {
  blocks: Array<ScheduleBlock>;
  technicalUnitId: Scalars['UUID'];
};

export type TechnicalUnitRunPlanParameters = {
  anlageDarfStehen: Scalars['Boolean'];
  betriebszeitProJahr?: Maybe<Scalars['BigDecimal']>;
  feuerungsleistung?: Maybe<Scalars['BigDecimal']>;
  loadGradientMaxPower?: Maybe<Scalars['BigDecimal']>;
  loadGradientMinPower?: Maybe<Scalars['BigDecimal']>;
  maxJahresmengeStrom?: Maybe<Scalars['BigDecimal']>;
  maxJahresmengeWaerme?: Maybe<Scalars['BigDecimal']>;
  maxLeistung: Scalars['BigDecimal'];
  maxThermLeistung: Scalars['BigDecimal'];
  minBetriebProLeistungsstufe?: Maybe<Scalars['BigDecimal']>;
  minBetriebszeit?: Maybe<Scalars['BigDecimal']>;
  minJahresmengeStrom?: Maybe<Scalars['BigDecimal']>;
  minJahresmengeWaerme?: Maybe<Scalars['BigDecimal']>;
  minLeistung: Scalars['BigDecimal'];
  minStillstandzeit?: Maybe<Scalars['BigDecimal']>;
  obereNennleistung: Scalars['BigDecimal'];
  rampUpTimeCold?: Maybe<Scalars['BigDecimal']>;
  rampUpTimeWarm?: Maybe<Scalars['BigDecimal']>;
  shutDownTime?: Maybe<Scalars['BigDecimal']>;
  startUpTimeCold?: Maybe<Scalars['BigDecimal']>;
  startUpTimeWarm?: Maybe<Scalars['BigDecimal']>;
  startbarProTag?: Maybe<Scalars['BigDecimal']>;
  startkostenKalt?: Maybe<Scalars['BigDecimal']>;
  startkostenWarm?: Maybe<Scalars['BigDecimal']>;
  stromkennzahl?: Maybe<Scalars['BigDecimal']>;
  totzeit?: Maybe<Scalars['BigDecimal']>;
  wirkungsgradElektrisch: Scalars['BigDecimal'];
  wirkungsgradGesamt?: Maybe<Scalars['BigDecimal']>;
  wirkungsgradThermisch: Scalars['BigDecimal'];
  zielmengenwert?: Maybe<Scalars['BigDecimal']>;
  zielmengenzeitraum?: Maybe<ZielmengeZeitraum>;
};

export type TechnicalUnitRunPlanParametersInput = {
  anlageDarfStehen: Scalars['Boolean'];
  betriebszeitProJahr?: Maybe<Scalars['BigDecimal']>;
  feuerungsleistung?: Maybe<Scalars['BigDecimal']>;
  loadGradientMaxPower?: Maybe<Scalars['BigDecimal']>;
  loadGradientMinPower?: Maybe<Scalars['BigDecimal']>;
  maxJahresmengeStrom?: Maybe<Scalars['BigDecimal']>;
  maxJahresmengeWaerme?: Maybe<Scalars['BigDecimal']>;
  maxLeistung: Scalars['BigDecimal'];
  maxThermLeistung: Scalars['BigDecimal'];
  minBetriebProLeistungsstufe?: Maybe<Scalars['BigDecimal']>;
  minBetriebszeit?: Maybe<Scalars['BigDecimal']>;
  minJahresmengeStrom?: Maybe<Scalars['BigDecimal']>;
  minJahresmengeWaerme?: Maybe<Scalars['BigDecimal']>;
  minLeistung: Scalars['BigDecimal'];
  minStillstandzeit?: Maybe<Scalars['BigDecimal']>;
  obereNennleistung: Scalars['BigDecimal'];
  rampUpTimeCold?: Maybe<Scalars['BigDecimal']>;
  rampUpTimeWarm?: Maybe<Scalars['BigDecimal']>;
  shutDownTime?: Maybe<Scalars['BigDecimal']>;
  startUpTimeCold?: Maybe<Scalars['BigDecimal']>;
  startUpTimeWarm?: Maybe<Scalars['BigDecimal']>;
  startbarProTag?: Maybe<Scalars['BigDecimal']>;
  startkostenKalt?: Maybe<Scalars['BigDecimal']>;
  startkostenWarm?: Maybe<Scalars['BigDecimal']>;
  stromkennzahl?: Maybe<Scalars['BigDecimal']>;
  totzeit?: Maybe<Scalars['BigDecimal']>;
  wirkungsgradElektrisch: Scalars['BigDecimal'];
  wirkungsgradGesamt?: Maybe<Scalars['BigDecimal']>;
  wirkungsgradThermisch: Scalars['BigDecimal'];
  zielmengenwert?: Maybe<Scalars['BigDecimal']>;
  zielmengenzeitraum?: Maybe<ZielmengeZeitraum>;
};

export type TechnicalUnitToSignOrCancel = {
  name: Scalars['String'];
  priceCalculationId?: Maybe<Scalars['UUID']>;
  technicalUnitId: Scalars['UUID'];
};

export enum TimeSeriesType {
  GasFillingLevelForecast = 'GasFillingLevelForecast',
  HeatFillingLevelForecast = 'HeatFillingLevelForecast',
  HeatLoadForecast = 'HeatLoadForecast'
}

export type TransmissionReason = {
  code: Scalars['String'];
  text: Scalars['String'];
};

export type TransmissionStatus = {
  documentId: Scalars['String'];
  logs: Array<TransmissionStatusLog>;
  meldungsStatus?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  validFrom?: Maybe<Scalars['ZonedDateTime']>;
  version: Scalars['Long'];
};

export type TransmissionStatusLog = {
  createdAt: Scalars['ZonedDateTime'];
  reasons: Array<TransmissionReason>;
  state: Scalars['String'];
};


export enum VoltageLevelEnum {
  ExtraHigh = 'ExtraHigh',
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

export type XmlDataError = {
  name: Scalars['String'];
  type: XmlDataErrorType;
};

export enum XmlDataErrorType {
  InvalidFormat = 'INVALID_FORMAT',
  MissingOptionalField = 'MISSING_OPTIONAL_FIELD',
  MissingRequiredField = 'MISSING_REQUIRED_FIELD'
}

export type XmlDataReport = {
  children: Array<XmlDataReport>;
  hasErrors: Scalars['Boolean'];
  hasWarnings: Scalars['Boolean'];
  isValid: Scalars['Boolean'];
  referenceId: Scalars['String'];
  report: Array<XmlDataError>;
  type: Scalars['String'];
};

export enum ZielmengeZeitraum {
  Tageszielmenge = 'Tageszielmenge',
  Wochenzielmenge = 'Wochenzielmenge'
}


export type GasStorageByIdSubscriptionVariables = Exact<{
  gasStorageId: Scalars['UUID'];
}>;


export type GasStorageByIdSubscription = { gasStorageById: Pick<GasStorage, 'parkId' | 'customerId' | 'capacity' | 'energyConcentration' | 'maximalFillingLevel' | 'methanConcentration' | 'minimalFillingLevel' | 'name' | 'producedBiogas' | 'protocolId'> };

export type CalculateEnergyContentSubscriptionVariables = Exact<{
  methanConcentration?: Maybe<Scalars['BigDecimal']>;
}>;


export type CalculateEnergyContentSubscription = { calculateEnergyConcentration: Pick<GasStorageCalculationResult, 'result'> };

export type UpdateGasStorageMutationVariables = Exact<{
  data: GasStorageInput;
}>;


export type UpdateGasStorageMutation = Pick<Mutation, 'updateGasStorage'>;

export type CreateGasStorageMutationVariables = Exact<{
  data: GasStorageCreateInput;
}>;


export type CreateGasStorageMutation = Pick<Mutation, 'createGasStorage'>;

export const GasStorageByIdDocument = gql`
    subscription GasStorageById($gasStorageId: UUID!) {
  gasStorageById(gasStorageId: $gasStorageId) {
    parkId
    customerId
    capacity
    energyConcentration
    maximalFillingLevel
    methanConcentration
    minimalFillingLevel
    name
    producedBiogas
    protocolId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GasStorageByIdGQL extends Apollo.Subscription<GasStorageByIdSubscription, GasStorageByIdSubscriptionVariables> {
    document = GasStorageByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CalculateEnergyContentDocument = gql`
    subscription CalculateEnergyContent($methanConcentration: BigDecimal) {
  calculateEnergyConcentration(methanConcentration: $methanConcentration) {
    result
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CalculateEnergyContentGQL extends Apollo.Subscription<CalculateEnergyContentSubscription, CalculateEnergyContentSubscriptionVariables> {
    document = CalculateEnergyContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGasStorageDocument = gql`
    mutation UpdateGasStorage($data: GasStorageInput!) {
  updateGasStorage(gasStorage: $data)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGasStorageGQL extends Apollo.Mutation<UpdateGasStorageMutation, UpdateGasStorageMutationVariables> {
    document = UpdateGasStorageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGasStorageDocument = gql`
    mutation CreateGasStorage($data: GasStorageCreateInput!) {
  createGasStorage(gasStorage: $data)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGasStorageGQL extends Apollo.Mutation<CreateGasStorageMutation, CreateGasStorageMutationVariables> {
    document = CreateGasStorageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    