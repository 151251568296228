import { Injectable } from '@angular/core';
import { ExcelExportData, GenerateExcelService } from '@nrg/components';
import { first, map } from 'rxjs/operators';
import {
  JobType,
  LoadResultGQL,
  ParkForOptimizationReportGQL,
  ScheduleBlock,
  SeriesItem
} from './report.graphql.generated';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private readonly excelService: GenerateExcelService,
    private readonly load: LoadResultGQL,
    private readonly parkGQL: ParkForOptimizationReportGQL
  ) {
  }

  public async generateReport(parkId: string, jobType: JobType) {
    const result = await this.load.fetch({
      jobType: jobType as any,
      assetId: parkId,
    }).pipe(
      map(it => it.data.optimizationResult),
      first()
    ).toPromise();

    const park = await this.parkGQL.subscribe({ parkId }).pipe(
      map(r => r.data.parkWithRelationShipsById),
      first()
    ).toPromise();

    const period = result.period.stepDuration * 60 * 1000;
    const from = new Date(result.period.start).getTime();
    const to = from + period * result.period.stepCount;

    const timeseries = this.makeTimeseries(period, from, to);

    const data: ExcelExportData = {
      timeseries,
      series: [
        ...result.technicalUnits.map(technicalUnit => {
          const te = park.technicalUnits.find(it => it.id === technicalUnit.technicalUnitId);
          return {
            values: this.scheduleToSeriesValues(technicalUnit.blocks ?? [], period, from, to),
            key: 'technicalUnits_' + technicalUnit.technicalUnitId,
            name: te?.name ?? 'Technische Einheit'
          };
        }),
        ...result.gasStorages.map(gasStorage => {
          const te = park.gasStorages.find(it => it.id === gasStorage.id);
          return {
            values: this.seriesToSeriesValues(gasStorage.series ?? [], period, from, to),
            key: 'gasStorages_' + gasStorage.id,
            name: te?.name ?? 'Gasspeicher'
          };
        }),
        ...result.heatStorages.map(heatStorage => {
          const te = park.heatStorages.find(it => it.id === heatStorage.id);
          return {
            values: this.seriesToSeriesValues(heatStorage.series ?? [], period, from, to),
            key: 'heatStorages_' + heatStorage.id,
            name: te?.name ?? 'Wärmespeicher'
          };
        }),
      ]
    };

    return await this.excelService.generateExcelReport(data);
  }

  private makeTimeseries(period: number, from: number, to: number) {

    let datetime = from;

    const result = [];

    do {
      result.push(datetime);
      datetime += period;
    } while (datetime < to);

    return result;
  }

  private scheduleToSeriesValues(schedule: ScheduleBlock[], period: number, from: number, to: number): { [time: number]: number } {

    const values: { [time: number]: number } = {};

    schedule.forEach(block => {
      let datetime = new Date(block.start).getTime();
      const blockend = new Date(block.end).getTime();
      do {
        const rounded = this.roundDatetime(datetime, period, from);
        values[rounded] = block.power;
        datetime += period;
      } while (datetime < blockend);
    });

    return values;

  }

  private seriesToSeriesValues(items: SeriesItem[], period: number, from: number, to: number): { [time: number]: number } {

    const values: { [time: number]: number } = {};

    items.forEach(({ dateTime, value }) => {
      const rounded = this.roundDatetime(new Date(dateTime).getTime(), period, from);
      values[rounded] = value;
    });

    return values;

  }

  private roundDatetime(datetime: number, period: number, from: number) {
    const index = Math.floor((datetime - from) / period);
    return from + index * period;
  }
}
