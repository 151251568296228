import { MonoEntityFormDataSource } from './entity-form-data-source';
import { select, Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { heatStorageSchema } from '../schema/heat-storage-form.schema';
import { FormSchema } from '@nrg/components';
import { FormGroup } from '@angular/forms';
import { Permission } from '@vk/authentication';
import { Injectable } from '@angular/core';
import { VkLoadingHandler, EntityType } from '@vk/common';
import {
  CreateHeatStorageGQL,
  HeatStorageByIdGQL,
  UpdateHeatStorageGQL,
  HeatStorageCreateInput,
  HeatStorageInput
} from './heat-storage.graphql.generated';
import { first, map } from 'rxjs/operators';

const correlationId = 'HeatStorageFormDataSource';

@Injectable({
  providedIn: 'any'
})
export class HeatStorageFormDataSource extends MonoEntityFormDataSource<HeatStorageInput> {
  readonly writePermission = Permission.ASSETS_WRITE_CUSTOMER;
  readonly alternativeWritePermission = Permission.ASSETS_WRITE;
  readonly entityType = EntityType.HeatStorage;
  readonly schema: FormSchema = heatStorageSchema;

  readonly state = new ReplaySubject<HeatStorageInput>(1);

  constructor(
    private readonly load: HeatStorageByIdGQL,
    private readonly update: UpdateHeatStorageGQL,
    private readonly create: CreateHeatStorageGQL,
    private readonly loadingHandler: VkLoadingHandler
  ) {
    super(correlationId);
  }

  onInit() {
  }

  setUpDerivedFields(formGroup: FormGroup) {
  }

  loadEntity(id: string, store: Store<unknown>): void {
    this.state.next({} as any);
    this.load.subscribe({ heatStorageId: id }).pipe(
      first(),
      this.loadingHandler.untilFirst('ParkGQL', this.correlationId),
      map(result => result.data!.heatStorageById)
    ).subscribe(result => this.state.next(result));
  }

  selectEntityById(id: string, store: Store<unknown>): Observable<HeatStorageInput | undefined> {
    return this.state.asObservable();
  }

  saveEntity(entity: HeatStorageInput, store: Store<unknown>): void {
    this.update.mutate({ data: entity }).subscribe();

  }

  createEntity(entity: HeatStorageInput, customerId: string, store: Store<unknown>): void {
    const createInput: HeatStorageCreateInput = {
      customerId,
      capacity: entity.capacity,
      maximalFillingLevel: entity.maximalFillingLevel,
      name: entity.name,
      minimalFillingLevel: entity.minimalFillingLevel,
      flowTemperature: entity.flowTemperature,
      returnTemperature: entity.returnTemperature
    };

    this.create.mutate({ data: createInput }).subscribe();
  }

  deleteEntity(id: string, store: Store<unknown>): void {
    throw new Error('Not implemented!');
  }

}


