<h2>{{title}}</h2>
<mat-progress-bar mode="indeterminate" *ngIf="dataSource.isLoading$ | async"></mat-progress-bar>
<form [formGroup]="form" (ngSubmit)="onEntityAttach($event)">
  <mat-form-field testId="entityId">
    <mat-label>{{entityType}}</mat-label>
    <mat-select required formControlName="entityId">
      <mat-option *ngFor="let entity of dataSource.attachableEntities$ | async" [value]="entity.protocolId">
        {{entity.name}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="isEntityIdInputInvalid">{{fieldErrorMessage}}</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Unit Type</mat-label>
    <input required formControlName="unitType" matInput type="number">
    <mat-error *ngIf="isUnitTypeInputInvalid">{{fieldErrorMessage}}</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Unit Number</mat-label>
    <input required formControlName="unitNumber" matInput type="number">
    <mat-error *ngIf="isUnitNumberInputInvalid">{{fieldErrorMessage}}</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Data Point</mat-label>
    <mat-select formControlName="dataPoint">
      <mat-option value={{option}} *ngFor="let option of allDataPointOptionsFor(tag)">{{option}}</mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-stroked-button type="submit" [disabled]="form.invalid || (isFormDisabled$ | async) || (isReadonly$ | async)">Zuweisen</button>
</form>
<div class="grid">
  <div class="cell" *ngFor="let entity of dataSource.attachedEntities$ | async">
    <vk-entity-config-card
        [tag]="tag"
        [cpeId]="dataSource.cpeId$$ | async"
        [entityId]="entity.protocolId"
        [name]="entity.name"
        [unitType]="entity.unitType"
        [unitNumber]="entity.unitNumber"
        [dataPoint]="entity.dataPoints"
        (detach)="onEntityDetach($event)"
        (update)="onEntityUpdate($event)">
    </vk-entity-config-card>
  </div>
</div>
