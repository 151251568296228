import { MonoEntityFormDataSource } from './entity-form-data-source';

import { CustomerPremisesEquipmentFormData, customerPremisesEquipmentSchema } from '../schema/customer-premises-equipment.schema';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { FormSchema } from '@nrg/components';
import { Permission } from '@vk/authentication';
import { Injectable } from '@angular/core';
import { first, map, tap } from 'rxjs/operators';
import { VkLoadingHandler } from '@vk/common';
import {
  CpeByIdGQL,
  CreateCpeGQL,
  UpdateCpeGQL,
  CustomerPremisesEquipmentCreateInput,
  CustomerPremisesEquipmentUpdateInput
} from './customer-premises-equipment.graphql.generated';

const correlationId = 'CustomerPremisesEquipmentFormDataSource';

@Injectable({
  providedIn: 'any'
})
export class CustomerPremisesEquipmentFormDataSource extends MonoEntityFormDataSource<CustomerPremisesEquipmentFormData> {

  readonly writePermission = Permission.ASSETS_WRITE;
  readonly entityType = 'CustomerPremisesEquipmentFormDataSource' as any;
  readonly schema: FormSchema = customerPremisesEquipmentSchema;

  readonly state = new ReplaySubject<CustomerPremisesEquipmentFormData>(1);

  constructor(
    private readonly load: CpeByIdGQL,
    private readonly update: UpdateCpeGQL,
    private readonly create: CreateCpeGQL,
    private readonly loadingHandler: VkLoadingHandler
  ) {
    super(correlationId);
  }

  loadEntity(id: string, store: Store<unknown>): void {
    this.state.next({} as any);
    this.load.subscribe({ protocolId: id }).pipe(
      first(),
      this.loadingHandler.untilFirst('CpeByIdGQL', this.correlationId),
      map(result => result.data!.customerPremisesEquipmentById)
    ).subscribe(result => this.state.next(result));
  }

  selectEntityById(id: string, store: Store<unknown>): Observable<CustomerPremisesEquipmentFormData> {
    return this.state.asObservable();
  }

  saveEntity(entity: CustomerPremisesEquipmentFormData, store: Store<unknown>): void {
    const data: CustomerPremisesEquipmentUpdateInput = {
      name: entity.name,
      commonAddress: entity.commonAddress,
      ipAddress: entity.ipAddress,
      port: entity.port,
      protocolId: entity.protocolId,
      phoneNumber: entity.phoneNumber,
      simCardId: entity.simCardId,
      steuerbox: entity.steuerbox
    };
    this.update.mutate({ data }).subscribe();
  }

  createEntity(entity: CustomerPremisesEquipmentFormData, customerId: string, store: Store<unknown>): void {
    const createInput: CustomerPremisesEquipmentCreateInput = {
      customerId,
      name: entity.name,
      commonAddress: entity.commonAddress,
      ipAddress: entity.ipAddress,
      port: entity.port,
      phoneNumber: entity.phoneNumber,
      simCardId: entity.simCardId,
      steuerbox: entity.steuerbox
    };
    this.create.mutate({ data: createInput }).subscribe();
  }

  deleteEntity(id: string, store: Store<unknown>): void {
  }
}
