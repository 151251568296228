import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Result } from './models/result.model';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'nrg-search-sheet',
  templateUrl: './search-sheet.component.html',
  styleUrls: ['./search-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchSheetComponent {

  private readonly searchQuery = new BehaviorSubject<string>('');
  public readonly searchResult = new BehaviorSubject<Result[]>([]);
  public readonly isLoadingResults = new BehaviorSubject<boolean>(false);
  public readonly errorMessage = new BehaviorSubject<string>('');

  public readonly searchQuery$ = this.searchQuery.pipe(
    debounceTime(500)
  );

  public readonly hasResults = this.searchResult.pipe(
    map(results => results?.length > 0 ?? false)
  );

  public readonly showErrorMessage = combineLatest([
    this.errorMessage,
    this.isLoadingResults
  ]).pipe(
    map(([message, isLoading]) => !isLoading && message.length > 0)
  );

  public readonly showNoResultsMessage = combineLatest([
    this.hasResults,
    this.isLoadingResults,
    this.showErrorMessage
  ]).pipe(
    map(([hasResults, isLoading, showErrorMessage]) => !isLoading && !hasResults && !showErrorMessage)
  );

  public readonly showResults = combineLatest([
    this.hasResults,
    this.isLoadingResults,
    this.showErrorMessage
  ]).pipe(
    map(([hasResults, isLoadingResults, showErrorMessage]) => hasResults && !isLoadingResults && !showErrorMessage)
  );

  public readonly noResultsMessage = this.searchQuery.pipe(
    map(query => `Ihre Suche "${query}" ergab keine Ergebnisse.`)
  );

  @Input()
  public set isLoading(isLoading: boolean) {
    this.isLoadingResults.next(isLoading);
  }

  @Input()
  public set results(results: Result[]) {
    this.searchResult.next(results);
  }

  @Input()
  public set error(error: string | null) {
    this.errorMessage.next(error != null ? error : '');
  }

  @Output() public readonly query = new EventEmitter<string>();
  @Output() public readonly retry = new EventEmitter<void>();

  onQueryChange(query: string) {
    this.searchQuery.next(query);
    this.query.emit(query);
  }

  onRetry() {
    this.retry.emit();
  }

}
