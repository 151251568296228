import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorNotificationComponent } from './error-notification.component';



@NgModule({
  declarations: [ErrorNotificationComponent],
  exports: [ErrorNotificationComponent],
  imports: [
    CommonModule
  ]
})
export class VkErrorNotificationModule { }
