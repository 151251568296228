export const minSheetWidth = 200;
export const maxSheetWidth = 500;

export type Handle = (x2: number) => number;

export function makeHandle(sheetWidth: number, x: number,
                           minWidth: number = minSheetWidth,
                           maxWidth: number = maxSheetWidth): Handle {
  return x2 => {
    const width = sheetWidth + x - x2;

    return Math.max(Math.min(width, maxWidth), minWidth);
  };
}
