export enum AllowedProduct {
  NONE = 'Keine Produktfreigabe',
  DV = 'DV',
  DVFP = 'DVFP',
  DVFPID = 'DVFPID'
}

export type AllowedProducts = Array<AllowedProduct>;

export type AllowedProductFlags = {
  produktfreigabeDV: boolean;
  produktfreigabeDVFP: boolean;
  produktfreigabeDVFPID: boolean;
  activateOverride: boolean;
  produktfreigabeDerived: string;
};

export interface AllowedProductsForPark {
  parkId: string;
  allowedProductsOverrideFlags: AllowedProductFlags;
}

export function getDerivedAllowedProduct(allowedProducts: AllowedProducts): string {
  const allowedProductString = allowedProducts.reduce((allowedProductsDescription, allowedProduct, index) => {
    const prefix = (index === 0) ? '' : ' + ';
    switch (allowedProduct) {
      case AllowedProduct.DV: {
        return allowedProductsDescription + prefix + 'DV';
      }
      case AllowedProduct.DVFP: {
        return allowedProductsDescription + prefix + 'DVFP';
      }
      case AllowedProduct.DVFPID: {
        return allowedProductsDescription + prefix + 'DVFPID';
      }
    }


    return allowedProductsDescription;
  }, '');

  return allowedProductString.length > 0 ? allowedProductString : AllowedProduct.NONE;
}


export function mapToAllowedProduct(allowedProductFlags: AllowedProductFlags): AllowedProducts {
  const allowedProducts: AllowedProducts = [];

  if (allowedProductFlags.produktfreigabeDV) {
    allowedProducts.push(AllowedProduct.DV);
  }

  if (allowedProductFlags.produktfreigabeDVFP) {
    allowedProducts.push(AllowedProduct.DVFP);
  }

  if (allowedProductFlags.produktfreigabeDVFPID) {
    allowedProducts.push(AllowedProduct.DVFPID);
  }

  return allowedProducts;
}

export function mapToAllowedProductFlags(allowedProducts: AllowedProducts, produktfreigabeDerived: string): AllowedProductFlags {
  const manualOverrideIsActive = allowedProducts.length > 0;
  return {
    produktfreigabeDV: allowedProducts.includes(AllowedProduct.DV),
    produktfreigabeDVFP: allowedProducts.includes(AllowedProduct.DVFP),
    produktfreigabeDVFPID: allowedProducts.includes(AllowedProduct.DVFPID),
    activateOverride: manualOverrideIsActive,
    produktfreigabeDerived
  };
}
