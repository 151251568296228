import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartComponent } from './chart.component';
import { TransformDirective } from './transform.directive';


@NgModule({
  declarations: [
    ChartComponent,
    TransformDirective
  ],
  exports: [
    ChartComponent
  ],
  imports: [
    CommonModule
  ]
})
export class NrgChartModule {
}
