import { calculateStepSize, translateXToChartCoordinateSystem } from '../../models/coordinates';

export interface Rectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Block {
  id: string;
  from: number; // TODO: rename to start
  to: number; // TODO: rename to end
  value: number;
}

export type TranslatedBlock = Rectangle & Block;

export function translateBlocksToChartCoordinateSystem(blocks: Block[], fromX: number, toX: number, fromY: number, toY: number, axisWidth: number, axisHeight: number): TranslatedBlock[] {
  const stepSizeX = calculateStepSize(axisWidth, fromX, toX);
  const stepSizeY = calculateStepSize(axisHeight, fromY, toY);

  return blocks.reduce<TranslatedBlock[]>((translatedBlocks, block) => {
    if (block.to <= fromX || block.from >= toX) {
      return translatedBlocks;
    }

    const xFrom = block.from < fromX ? 0 : translateXToChartCoordinateSystem(fromX, stepSizeX)(block.from);
    const xTo = translateXToChartCoordinateSystem(fromX, stepSizeX)(block.to > toX ? toX : block.to);
    const height = translateXToChartCoordinateSystem(fromY, stepSizeY)(block.value);

    translatedBlocks.push({
      ...block,
      x: xFrom,
      y: axisHeight - height,
      width: xTo - xFrom,
      height
    });

    return translatedBlocks;
  }, []);
}

export function isBlockEqual(lhs: Block, rhs: Block): boolean {
  return lhs.id === rhs.id &&
    lhs.value === rhs.value &&
    lhs.from === rhs.from &&
    lhs.to === rhs.to;
}
