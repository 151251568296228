import { FormSchema } from '@nrg/components';

export const addressSchema: FormSchema = {
  type: 'object',
  properties: {
    strasse: {
      title: 'Straße',
      type: 'string'
    },
    hausnummer: {
      title: 'Hausnummer',
      type: 'string'
    },
    zusatz: {
      title: 'Zusatz',
      type: 'string'
    },
    plz: {
      title: 'Postleitzahl',
      type: 'string'
    },
    stadt: {
      title: 'Stadt',
      type: 'string'
    },
    land: {
      title: 'Land',
      type: 'string'
    }
  },
  title: 'Adresse',
  required: [
    'plz',
    'stadt',
    'strasse'
  ],
  order: [
    'strasse',
    'hausnummer',
    'zusatz',
    'plz',
    'stadt',
    'land',
  ]
};
