import { createReducer, createSelector, on } from '@ngrx/store';
import { produce } from 'immer';
import { Permission, User } from '../user.model';
import * as AuthActions from './authentication.actions';
import { selectAuthenticationFeatureState } from '../authentication.state';

function makeId(length: number) {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// ------ State ------

export const initialUserState: User = {
  name: '',
  id: '',
  permissions: [],
  sessionId: makeId(6).toUpperCase()
};

// ------ Reducer ------

export const userReducer = createReducer<User>(
  initialUserState,
  on(AuthActions.deleteCurrentUser, (state) => {
    return initialUserState;
  }),
  on(AuthActions.setCurrentUser, (state, { user }) => {
    return produce(state, draftState => {
      const { id, name, permissions } = user;

      draftState.id = id;
      draftState.name = name;
      draftState.permissions = permissions;
    });
  }),
  on(AuthActions.setImpersonatedUser, (state, { impersonatedUserId }) => {
    return produce(state, draftState => {
      draftState.impersonatedUserId = impersonatedUserId;
    });
  }),
);

// ------ Selectors ------

const selectUser = createSelector(
  selectAuthenticationFeatureState,
  (state) => state?.currentUser ?? initialUserState
);

export const selectCustomerId = createSelector(
  selectUser,
  (user) => user.impersonatedUserId || user.id
);

export const selectImpersonatedCustomerId = createSelector(
  selectUser,
  (user) => user.impersonatedUserId
);

export const selectUserName = createSelector(
  selectUser,
  (user) => user?.name
);

export const selectSessionId = createSelector(
  selectUser,
  (user) => user?.sessionId
);

export const selectAllPermissions = createSelector(
  selectUser,
  (user: User) => {
    return user?.permissions ?? [];
  }
);

export const selectPermissionPresent = createSelector(
  selectUser,
  (user: User, props: { permission: Permission }) => {
    const userPermissions = user?.permissions || [];
    return userPermissions.includes(props.permission);
  }
);

export const selectAnyPermissionPresent = createSelector(
  selectUser,
  (user: User, props: { permissions: Permission[] }) => {
    const userPermissions = user?.permissions || [];
    return props.permissions.some(it => userPermissions.includes(it));
  }
);
