import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerImportFormComponent } from './customer-import-form/customer-import-form.component';
import { NrgSchemaFormModule } from '@nrg/components';
import { CustomerExportButtonComponent } from './customer-export-button/customer-export-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [CustomerImportFormComponent, CustomerExportButtonComponent],
  exports: [CustomerImportFormComponent, CustomerExportButtonComponent],
  imports: [
    CommonModule,
    NrgSchemaFormModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule
  ]
})
export class VkCustomerImportExportModule { }
