import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { FilteredResult } from '../search-sheet/models/result.model';

@Component({
  selector: 'nrg-search-result-tree',
  templateUrl: './search-result-tree.component.html',
  styleUrls: ['./search-result-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultTreeComponent {
  treeControl = new NestedTreeControl<FilteredResult>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FilteredResult>();

  @Input() query?: string;

  @Input() set result(data: FilteredResult[]) {
    if (data == null) {
      return;
    }
    this.dataSource.data = data;
    this.treeControl.dataNodes = data;
    this.treeControl.expandAll();
  }

  hasChild = (_: number, node: FilteredResult) => node.children.length > 0;
}
