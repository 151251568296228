import { FormSchema } from '@nrg/components';

export const priceCalculationDvSchema: FormSchema = {
  type: 'object',
  required: ['par51EntschaedigungFactor'],
  properties: {
    dienstleistungsentgeltSatz: {
      type: 'number',
      title: 'Dienstleistungsentgelt Satz'
    },
    grundgebuehr: {
      type: 'number',
      title: 'Grundgebühr'
    },
    grundgebuehrDiscount: {
      type: 'number',
      title: 'Rabatt auf Grundgebühr (in Prozent)',
      minimum: 0,
      maximum: 100
    },
    grundgebuehrDiscountEnde: {
      type: 'monthYearString',
      title: 'Rabatt begrenzt bis einschließlich...',
    },
    par51EntschaedigungFactor: {
      type: 'number',
      title: 'Faktor §51 (in Prozent)'
    },
    ausfallVerguetungFactor: {
      type: 'number',
      title: 'Faktor Ausfallvergütung (in Prozent)',
      readonly: true
    },
    ausfallVerguetungMalo: {
      enum: ['ANZULENGENDERWERT'],
      title: 'Art der Ausfallvergütung bei marktbedingter Steuerung',
      readonly: true
    },
    verfahrenAusfallarbeit: {
      enum: ['PAUSCHAL'],
      title: 'Berechnungsverfahren der Ausfallarbeit',
      readonly: true
    },
    par51EntschaedigungMalo: {
      enum: ['MONATSMARKTWERT'],
      title: 'Grundlage Entschädigung bei Steuerung aufgrund §51',
      readonly: true
    },
  },
  title: 'Preiskalkulation'
};
