import { MonoEntityFormDataSource } from '../entity-form-data-source';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { FormSchema } from '@nrg/components';
import { priceCalculationDvSchema } from '../../schema/price-calculation-dv.schema';
import { Permission } from '@vk/authentication';
import {
  PriceCalculationDvGQL,
  PriceCalculationDvSubscription,
  SavePriceCalculationGQL
} from './price-calculations-data-source.graphql.generated';
import { map } from 'rxjs/operators';

type PriceCalculationDv = PriceCalculationDvSubscription['priceCalculationById']['preiskalkulationeigenschaften'];

export class PriceCalculationDVFormDataSource extends MonoEntityFormDataSource<PriceCalculationDv> {
  readonly writePermission = Permission.CONTRACTS_WRITE_SALES;
  readonly entityType = 'PriceCalculationDv' as any;
  readonly schema: FormSchema = priceCalculationDvSchema;

  private readonly state = new ReplaySubject<PriceCalculationDv>(1);
  private id: string | undefined;

  constructor(
    correlationId: string,
    private readonly priceCalculationDv: PriceCalculationDvGQL,
    private readonly savePriceCalculation: SavePriceCalculationGQL
  ) {
    super(correlationId);
  }

  loadEntity(id: string, store: Store<unknown>): void {
    this.id = id;
    this.priceCalculationDv.subscribe({ id }).pipe(
      map(it => it.data!.priceCalculationById.preiskalkulationeigenschaften)
    ).subscribe(this.state);
  }

  saveEntity(entity: PriceCalculationDv, store: Store<unknown>): void {
    let updatedConditions = entity;

    if (entity.grundgebuehrDiscount === 0) {
      updatedConditions = { ...entity, grundgebuehrDiscount: null, grundgebuehrDiscountEnde: null };
    }

    this.savePriceCalculation.mutate({
      id: this.id!,
      conditions: updatedConditions
    }).subscribe();
  }

  selectEntityById(id: string, store: Store<unknown>): Observable<PriceCalculationDv | undefined> {
    return this.state.asObservable();
  }

  createEntity(entity: PriceCalculationDv, customerId: string, store: Store<unknown>): void {
    throw new Error('Method not implemented.');
  }

  deleteEntity(id: string, store: Store<unknown>): void {
    throw new Error('Method not implemented.');
  }

  makeTemplate(parentId: string): PriceCalculationDv {
    throw new Error('Method not implemented.');
  }

}
