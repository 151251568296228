import { Observable } from 'rxjs';

class ResizeObserverProxy {
  private resizeObserver?: ResizeObserver;

  constructor(onError: (e: Error) => void, onResize: () => void) {
    // check if browser supports ResizeObserver to avoid crashes in old browsers
    if ('ResizeObserver' in window) {
      const resizeObserver = new ResizeObserver(onResize);

      if (hasFunction('observe', resizeObserver) &&
        hasFunction('disconnect', resizeObserver)) {
        this.resizeObserver = resizeObserver;
      }
    }

    // propagate error if initialization was not successful
    if (this.resizeObserver == null) {
      onError(new Error('ResizeObserver not supported'));
    }
  }

  public observe(element: Element): void {
    this.resizeObserver?.observe(element);
  }

  public disconnect(): void {
    this.resizeObserver?.disconnect();
  }
}

function hasFunction(name: string, obj: any): boolean {
  return name in obj &&
    typeof obj[name] === 'function';
}

export function createResizeObserver(element: Element): Observable<void> {
  return new Observable<void>(subscriber => {
    const resizeObserver: ResizeObserverProxy = new ResizeObserverProxy(
      e => subscriber.error(e),
      () => subscriber.next()
    );

    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  });
}
