import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VkContractsTableComponent } from './vk-contracts-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { VkDocumentsModule } from '@vk/documents';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [VkContractsTableComponent],
  exports: [
    VkContractsTableComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatFormFieldModule,
    MatButtonModule,
    FormsModule,
    MatSelectModule,
    MatCheckboxModule,
    VkDocumentsModule
  ]
})
export class VkContractsTableModule {
}
