import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { selectCustomerId } from '@vk/authentication';
import {
  ContractAmendmentFileGQL,
  ContractFile,
  ContractFileGQL,
  ContractFilePreviewGQL,
  TerminatedTechnicalUnitsForCustomerGQL,
  TerminationAgreementFileGQL,
  TerminationAgreementFilePreviewGQL
} from './contract-link.graphql.generated';
import { VkCommonState } from '@vk/common';

@Component({
  selector: 'vk-contract-link',
  templateUrl: './contract-link.component.html',
  styleUrls: ['./contract-link.component.css']
})
export class ContractLinkComponent implements OnInit, OnDestroy {

  componentWillBeDestroyed = new Subject<boolean>();

  @Input() type: 'contract' | 'contract-amendment' | 'contract-preview' | 'termination-agreement' | 'termination-agreement-preview' = 'contract';
  @Input() contractId: string;

  private customerIdHasBeenSet = false;

  @Input()
  public set customerId(customerId: string) {
    if (customerId) {
      this.customerIdHasBeenSet = true;
      this.customerId$$.next(customerId);
    }
  }

  @Input() technicalUnitId: string;
  @Input() contractAmendmentId: string;
  @Input() priceCalculationId: string;
  @Input() buttonLabel: string;

  private hasCancellation = false;
  public readonly customerId$$ = new ReplaySubject<string>(1);

  public fileIsPresent$ = new ReplaySubject<boolean>(1);

  constructor(
    private readonly store: Store<VkCommonState>,
    private readonly contract: ContractFileGQL,
    private readonly contractAmendment: ContractAmendmentFileGQL,
    private readonly contractPreview: ContractFilePreviewGQL,
    private readonly terminationAgreement: TerminationAgreementFileGQL,
    private readonly terminationAgreementPreview: TerminationAgreementFilePreviewGQL,
    private terminatedTechnicalUnitsForCustomer: TerminatedTechnicalUnitsForCustomerGQL
  ) {
  }

  ngOnInit() {
    if (!this.customerIdHasBeenSet) {
      this.store.pipe(
        select(selectCustomerId),
        first()
      ).subscribe(customerId => {
          this.customerId$$.next(customerId);
        }
      );
    }

    this.customerId$$.pipe(
      switchMap(customerId => this.terminatedTechnicalUnitsForCustomer.subscribe({ customerId })),
      map(response => response.data.terminatedTechnicalUnitsForCustomer),
      takeUntil(this.componentWillBeDestroyed)
    ).subscribe(canceledTechnicalUnits => {
      this.hasCancellation = canceledTechnicalUnits.includes(this.technicalUnitId);
    });
  }

  async onDownloadContract() {

    const customerId = await this.customerId$$.pipe(first()).toPromise();

    switch (this.type) {
      case 'contract':
        await this.contract.fetch({
          customerId,
          contractId: this.contractId
        }).toPromise().then(async (result) => {
          this.openFile(result.data.contractFile);
        });
        return;
      case 'contract-amendment':
        await this.contractAmendment.fetch({
          customerId,
          contractAmendmentId: this.contractAmendmentId
        }).toPromise().then(async (result) => {
          this.openFile(result.data.contractAmendmentFile);
        });
        return;
      case 'contract-preview':
        await this.contractPreview.fetch({
          customerId,
          technicalUnitId: this.technicalUnitId,
          priceCalculationId: this.priceCalculationId
        }).toPromise().then(async (result) => {
          this.openFile(result.data.contractFilePreview);
        });
        return;
      case 'termination-agreement':
        await this.terminationAgreement.fetch({
          customerId,
          technicalUnitId: this.technicalUnitId
        }).toPromise().then(async (result) => {
          this.openFile(result.data.terminationAgreementFile);
        });
        return;
      case 'termination-agreement-preview':
        await this.terminationAgreementPreview.fetch({
          customerId,
          technicalUnitId: this.technicalUnitId
        }).toPromise().then(async (result) => {
          this.openFile(result.data.terminationAgreementFilePreview);
        });
        return;
    }
  }

  private async openFile(file: ContractFile) {
    const blob = await fetch(`data:application/octet-stream;base64,${file.bytes}`).then(res => res.blob());
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = file.name;
    link.click();
  }

  shouldDisplayButton() {
    if (this.type === 'termination-agreement') {
      return this.hasCancellation;
    }
    return true;
  }

  ngOnDestroy() {
    this.componentWillBeDestroyed.next(true);
    this.componentWillBeDestroyed.complete();
  }
}

