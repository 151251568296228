import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { EntityFormDataSource } from './data-source/entity-form-data-source';
import { filter, first, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { BannerAction, FormSchema, ToolbarItem, ToolBarItemType } from '@nrg/components';
import {
  EntityType,
  ErrorCode,
  filterByEntityId,
  filterByEntityIdAndErrorCode,
  filterByErrorCode,
  selectIsErrorOccurred,
  selectIsLoading,
  VkCommonState,
} from '@vk/common';

export type EntityFormMode = 'create' | 'edit';

@Component({
  selector: 'vk-entity-form[mode]',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./entity-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityFormComponent implements OnInit, OnDestroy {
  private readonly componentWillBeDestroyed = new Subject<void>();

  private readonly _id = new ReplaySubject<string>(1);
  private readonly _parentId = new BehaviorSubject<string>('');
  private readonly _mode$ = new ReplaySubject<EntityFormMode>(1);
  private readonly _valueSubmit = new Subject<any>();
  public readonly _actions = new BehaviorSubject<ToolbarItem[]>([]);
  public readonly _readonly$$ = new BehaviorSubject<boolean>(false);
  public readonly _disabled$$ = new BehaviorSubject<boolean>(false);
  public readonly isInPencilMode$$ = new BehaviorSubject(false);
  public readonly hasValidationErrors$$ = new BehaviorSubject(false);

  public readonly isFormDisabled$: Observable<boolean> = combineLatest([
    this._mode$,
    this.isInPencilMode$$,
  ]).pipe(
    map(([mode, isInPencilMode]) => {
      if (mode === 'create') {
        return false;
      } else {
        return !isInPencilMode;
      }
    })
  );

  public readonly errorActions: BannerAction[] = [{
    title: 'Erneut versuchen',
    execute: () => this.onRetry()
  }];

  private get entityType(): EntityType {
    return this.dataSource.entityType;
  }

  public get schema(): FormSchema {
    return this.dataSource.schema;
  }

  private readonly entity: Observable<any> = this._mode$.pipe(
    switchMap(mode => {
      switch (mode) {
        case 'edit':
          return this._id.pipe(
            switchMap(id => this.dataSource.selectEntityById(id, this.store)),
            filter(entity => entity != null),
          );
        case 'create':
          return this._parentId.pipe(
            map(parentId => this.dataSource.makeTemplate(parentId)),
          );
      }
    })
  );

  public readonly values: Observable<any> = this.entity.pipe(
    map(entity => this.dataSource.selectFormValues(entity)),
    filter(entity => entity != null),
  );

  public readonly hasValues = this.values.pipe(
    map(values => values != null)
  );

  public readonly isLoading = this._id.pipe(
    switchMap(id => {
      return this.store.pipe(select(selectIsLoading([this.entityType], filterByEntityId(id))));
    }),
  );

  public readonly isErrorOccurred = this._id.pipe(
    switchMap(id => {
      return this.store.pipe(select(selectIsErrorOccurred([this.entityType], filterByEntityId(id))));
    }),
  );

  @Input() public dataSource!: EntityFormDataSource<any, any>;

  @Input() public dataSourceFactory: (() => EntityFormDataSource<any, any>) | undefined = undefined;

  @Input() public hideSubmitButton = true;

  @Input() public hideDeleteAction = false;

  @Input() public hideEditAction = false;

  @Input() public inMatCard = false;

  @Input()
  public set mode(value: EntityFormMode) {
    this._mode$.next(value);
  }

  @Input()
  public set disabled(value: boolean) {
    this._disabled$$.next(value);
  }

  @Input()
  public set id(id: string) {
    this._id.next(id);
  }

  @Input()
  public set parentId(id: string) {
    this._parentId.next(id);
  }

  @Input() public title = '';

  @Output() public readonly valueSubmit = new EventEmitter<any>();

  @Output() public readonly createSuccess = new EventEmitter<void>();
  @Output() public readonly deleteSuccess = new EventEmitter<void>();
  @Output() public readonly createCanceled = new EventEmitter<void>();

  constructor(
    private readonly store: Store<VkCommonState>) {
  }

  public displayedActions$ = combineLatest([
    this._readonly$$,
    this._disabled$$,
    this._actions,
  ]).pipe(
    map(([readonly, disabled, actions]) => {
      return (readonly || disabled) ? [] : actions;
    })
  );

  ngOnInit() {

    if (this.dataSourceFactory) {
      this.dataSource = this.dataSourceFactory();
    }

    this.dataSource.selectIsReadonly(this.store).subscribe(this._readonly$$);

    combineLatest([
      this.isFormDisabled$,
      this.hasValidationErrors$$
    ]).subscribe(([isFormDisabled, hasValidationErrors]) => {
      this.updateToolbar(!isFormDisabled, hasValidationErrors);
    });

    this._id.pipe(
      filter(id => id != null),
      takeUntil(this.componentWillBeDestroyed),
    ).subscribe(id => {
      this.dataSource.loadEntity(id, this.store);
      this.isInPencilMode$$.next(false);
    });

    this.values.pipe(
      takeUntil(this.componentWillBeDestroyed)
    ).subscribe(value => {
      this.dataSource.patchValue(value);
    });

    this._valueSubmit.pipe(
      withLatestFrom(this.entity),
      map(([values, entity]) => {
        return this.dataSource.updateEntity(entity, values);
      }),
      withLatestFrom(this._mode$, this._parentId),
      takeUntil(this.componentWillBeDestroyed)
    ).subscribe(([entity, mode, customerId]) => {
      switch (mode) {
        case 'edit':
          this.dataSource.saveEntityIfNeeded(entity, this.store, customerId);
          break;
        case 'create':
          this.createEntity(entity);
          break;
      }
      this.valueSubmit.next(entity);
    });

    this._mode$.pipe(take(1)).subscribe(mode => {

      if (mode === 'create') {

        this._valueSubmit.pipe(
          switchMap(_ => {
            return this.store.pipe(
              select(selectIsLoading([this.entityType])), filter(x => !x),
              takeUntil(this.store.pipe(
                select(selectIsErrorOccurred([this.entityType], filterByErrorCode(ErrorCode.CreatingEntityFailed))),
                filter(x => x),
              )));
          }),
          map(_ => void 0)
        ).subscribe(this.createSuccess);

      }
    });
  }

  ngOnDestroy(): void {

    this.componentWillBeDestroyed.next();
    this.componentWillBeDestroyed.complete();
  }

  onRetry(): void {
    this._id.pipe(
      take(1)
    ).subscribe(id => {
      this.dataSource.loadEntity(id, this.store);
    });
  }

  onValueSubmit(values: any): void {
    this.isInPencilMode$$.next(false);
    this._valueSubmit.next(values);
  }

  onHasValidationErrors(hasValidationErrors: boolean) {
    this.hasValidationErrors$$.next(hasValidationErrors);
  }

  private updateToolbar(isDisabled: boolean, hasErrors: boolean): void {
    let actions: ToolbarItem[] = [];

    if (isDisabled) {
      actions = [{
        icon: 'check',
        itemType: ToolBarItemType.ACTION,
        disabled: hasErrors,
        execute: () => {
          this.applyChanges();
        }
      }, {
        icon: 'cancel',
        itemType: ToolBarItemType.ACTION,
        disabled: false,
        execute: () => {
          this.cancel();
        }
      }];
    } else {

      if (!this.hideEditAction) {
        actions = [{
          icon: 'create',
          itemType: ToolBarItemType.ACTION,
          disabled: false,
          execute: () => {
            this.isInPencilMode$$.next(true);
          }
        }];
      }

      if (!this.hideDeleteAction) {
        actions.push({
          icon: 'delete',
          itemType: ToolBarItemType.ACTION,
          disabled: false,
          execute: () => {
            this.deleteEntity();
          }
        });
      }
    }

    this._actions.next(actions);
  }

  private createEntity(entity: any): void {
    this._parentId.pipe(take(1))
      .subscribe(customerId => this.dataSource.createEntity(entity, customerId, this.store));
  }

  private deleteEntity(): void {
    const answer = window.prompt('Wollen sie das Element wirklich vollständig und unwiderruflich löschen? Dann tippen sie bitte JA in das folgende Feld.');

    if (answer === 'JA') {
      combineLatest([this._id, this._parentId]).pipe(
        take(1)
      ).subscribe(([id, customerId]) => {
          this.dataSource.deleteEntity(id, this.store, customerId);
          this.store.pipe(
            select(selectIsLoading([this.entityType], filterByEntityId(id))), filter(x => !x),
            takeUntil(this.componentWillBeDestroyed),
            takeUntil(this.store.pipe(
              select(selectIsErrorOccurred([this.entityType], filterByEntityIdAndErrorCode(id, ErrorCode.DeletingEntityFailed))),
              filter(x => x),
            )),
            map(_ => void 0),
          ).subscribe(this.deleteSuccess);
        }
      );
    }
  }

  private applyChanges(): void {
    this.isInPencilMode$$.next(false);
    this.dataSource.submit();
  }

  private cancel() {
    this._mode$.pipe(first()).subscribe(mode => {
      if (mode === 'create') {
        this.createCanceled.next();
      } else {
        this.isInPencilMode$$.next(false);
        this.dataSource.reset();
      }
    });
  }
}
