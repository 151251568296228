import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BannerAction } from './models/banner-action';

@Component({
  selector: 'nrg-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerComponent {

  @Input() public icon?: string;

  @Input() public actions: BannerAction[] = [];

}
