import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { FormSchema } from '@nrg/components';
import { MonoEntityFormDataSource } from '../entity-form-data-source';
import { technicalUnitConnectionSettingsSchema } from '../../schema/technical-unit/technical-unit-connection-settings.schema';
import { Permission } from '@vk/authentication';
import {
  ConnectionParametersGQL,
  ProductEnum,
  SaveConnectionParametersGQL,
  TechnicalUnitConnectionSettings
} from './technicalUnit.graphql.generated';
import { VkCommonState, VkLoadingHandler } from '@vk/common';
import { Inject, Injectable } from '@angular/core';
import { IS_PORTAL } from '../../entity-form.injection-tokens';
import { EntityType } from '@vk/common';

export interface ConnectionSettings {
  technicalUnitId: string;
  hundertProzentWert: number;
  anbindungVK?: string | null;
  anbindungTE?: string | null;
  steuerungsfreigabeDV?: boolean | null;
  steuerungsfreigabeDVFP?: boolean | null;
  steuerungsfreigabeDVFPID?: boolean | null;
  steuerungstyp?: string | null;
  steuerungsmodus?: string | null;
  steuerbarkeit?: string | null;
  motorhersteller?: string | null;
  obereNennleistung: number;
  untereNennleistung?: number | null;
  technischeMinDauerleistung?: number | null;
  leistungsstufen?: number[] | null;
  dateOfNetworkConnection?: Date | null;
  dateOfCommission?: Date | null;
  planungsrelevant: boolean;
  planungsrelevantOverride?: boolean | null;
}

const correlationId = 'TechnicalUnitConnectionSettingsFormDataSource';

@Injectable({
  providedIn: 'any'
})
export class TechnicalUnitConnectionSettingsFormDataSource extends MonoEntityFormDataSource<ConnectionSettings> {

  readonly state = new ReplaySubject<ConnectionSettings>(1);

  constructor(
    private readonly saveConnectionParameters: SaveConnectionParametersGQL,
    private readonly connectionParameters: ConnectionParametersGQL,
    private readonly loadingHandler: VkLoadingHandler,
    @Inject(IS_PORTAL) private readonly isCustomer: boolean,
  ) {
    super(correlationId);
  }

  readonly writePermission = Permission.ASSETS_WRITE;
  readonly entityType = EntityType.TechnicalUnit;
  readonly schema: FormSchema = technicalUnitConnectionSettingsSchema(this.isCustomer);

  selectEntityById(id: string, store: Store<VkCommonState>): Observable<ConnectionSettings> {
    return this.state.asObservable();
  }

  loadEntity(id: string, store: Store<VkCommonState>): void {
    this.state.next({} as any);
    this.connectionParameters.subscribe({ technicalUnitId: id }).pipe(
      first(),
      this.loadingHandler.untilFirst('RunPlanParametersGQL', this.correlationId)
    ).subscribe(result => {
      this.state.next(mapToFormData(result.data!.technicalUnitById.connectionSettings, id));
    });
  }

  saveEntity(connectionSettings: ConnectionSettings, store: Store<VkCommonState>, customerId: string): void {
    const id = connectionSettings.technicalUnitId;

    const data = mapFormDataBack(connectionSettings, id);

    this.saveConnectionParameters.mutate({
      technicalUnitId: id,
      data
    }).pipe(
      this.loadingHandler.untilFirst('SaveRunPlanParametersGQL', this.correlationId)
    ).subscribe();
  }

  createEntity(physicalParameter: ConnectionSettings, customerId: string, store: Store<VkCommonState>): void {
    // not implemented
    throw new Error('Method not implemented.');
  }

  deleteEntity(id: string, store: Store<VkCommonState>, customerId: string): void {
    // not implemented
    throw new Error('Method not implemented.');
  }

  makeTemplate(parentId: string): ConnectionSettings | any {
    return {} as any;
  }
}

function mapToFormData(parameters: TechnicalUnitConnectionSettings, id: string): ConnectionSettings {

  return {
    anbindungTE: parameters.anbindungTE,
    anbindungVK: parameters.anbindungVK,
    hundertProzentWert: parameters.hundertProzentWert,
    leistungsstufen: parameters.leistungsstufen,
    motorhersteller: parameters.motorhersteller,
    obereNennleistung: parameters.obereNennleistung,
    steuerbarkeit: parameters.steuerbarkeit,
    steuerungsmodus: parameters.steuerungsmodus,
    steuerungstyp: parameters.steuerungstyp,
    technischeMinDauerleistung: parameters.technischeMinDauerleistung,
    untereNennleistung: parameters.untereNennleistung,
    dateOfCommission: parameters.datumDerInbetriebnahme,
    dateOfNetworkConnection: parameters.datumDerNetzanbindung,
    steuerungsfreigabeDV: parameters.steuerungsfreigabe?.some(it => it === ProductEnum.Dv),
    steuerungsfreigabeDVFP: parameters.steuerungsfreigabe?.some(it => it === ProductEnum.Dvfp),
    steuerungsfreigabeDVFPID: parameters.steuerungsfreigabe?.some(it => it === ProductEnum.Dvfpid),
    planungsrelevantOverride: parameters.planungsrelevantOverride,
    planungsrelevant: parameters.planungsrelevant,
    technicalUnitId: id
  };
}

function mapFormDataBack(parameters: ConnectionSettings, id: string): TechnicalUnitConnectionSettings {

  let steuerungsfreigabe: Array<ProductEnum> | null;

  if (
    parameters.steuerungsfreigabeDV == null
    && parameters.steuerungsfreigabeDVFP == null
    && parameters.steuerungsfreigabeDVFPID == null
  ) {
    steuerungsfreigabe = null;
  } else {
    steuerungsfreigabe = [];

    if (parameters.steuerungsfreigabeDV) {
      steuerungsfreigabe.push(ProductEnum.Dv);
    }
    if (parameters.steuerungsfreigabeDVFP) {
      steuerungsfreigabe.push(ProductEnum.Dvfp);
    }
    if (parameters.steuerungsfreigabeDVFPID) {
      steuerungsfreigabe.push(ProductEnum.Dvfpid);
    }
  }

  return {
    anbindungTE: parameters.anbindungTE,
    anbindungVK: parameters.anbindungVK,
    hundertProzentWert: parameters.hundertProzentWert,
    leistungsstufen: parameters.leistungsstufen,
    motorhersteller: parameters.motorhersteller,
    obereNennleistung: parameters.obereNennleistung,
    steuerbarkeit: parameters.steuerbarkeit,
    steuerungsmodus: parameters.steuerungsmodus,
    steuerungstyp: parameters.steuerungstyp,
    technischeMinDauerleistung: parameters.technischeMinDauerleistung,
    untereNennleistung: parameters.untereNennleistung,
    datumDerInbetriebnahme: parameters.dateOfCommission,
    datumDerNetzanbindung: parameters.dateOfNetworkConnection,
    steuerungsfreigabe,
    planungsrelevantOverride: parameters.planungsrelevantOverride,
    planungsrelevant: parameters.planungsrelevant,
    technicalUnitId: id
  };
}


