import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SheetContext {
  readonly type: string;
  readonly title?: string;
  readonly isClosable: boolean;

  onOpen(): void;

  onClose(): void;
}

const sheetClosedContextType = 'nrg-sheet/closed';
const sheetOpenedContextType = 'nrg-sheet/opened';

class SheetClosedContext implements SheetContext {
  public readonly type = sheetClosedContextType;
  public readonly isClosable = true;

  onClose(): void {
  }

  onOpen(): void {
  }
}

class SheetOpenedContext implements SheetContext {
  public readonly type = sheetOpenedContextType;
  public readonly isClosable = false;

  onClose(): void {
  }

  onOpen(): void {
  }
}

@Injectable({
  providedIn: 'root'
})
export class NrgSheetContainerService {
  private readonly currentContext$$ = new BehaviorSubject<SheetContext>(new SheetClosedContext());

  public readonly title$: Observable<string | undefined> = this.currentContext$$.pipe(
    map(context => context.title)
  );

  public readonly isHeaderVisible$: Observable<boolean> = this.currentContext$$.pipe(
    map(context => context.title != null || context.isClosable)
  );

  public readonly isOpen$: Observable<boolean> = this.currentContext$$.pipe(
    map(context => context.type !== sheetClosedContextType)
  );

  public readonly contextType$: Observable<string> = this.currentContext$$.pipe(
    map(context => context.type)
  );

  public open(context?: SheetContext): void {
    context = context ?? new SheetOpenedContext();

    this.currentContext$$.next(context);
    context.onOpen();
  }

  public close(): void {
    this.currentContext$$.value.onClose();
    this.currentContext$$.next(new SheetClosedContext());
  }

}
