import { VkError } from './loading-error.entity';

export type StatusType = EntityLoadingStatus | EntityErrorStatus;

export enum Status {
  Loading,
  Error
}

export interface EntityLoadingStatus {
  status: Status.Loading;
  entityType: string;
  entityId?: string;
  correlationId: string;
}

export interface EntityErrorStatus {
  status: Status.Error;
  entityType: string;
  entityId?: string;
  correlationId: string;
  error: VkError;
  acknowledged: boolean;
  errorId: string;
}
