import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NrgLayoutComponent } from './layout.component';
import { NrgMenuModule } from '../menu/menu.module';
import { NrgToolbarModule } from '../toolbar/toolbar.module';
import { LayoutContentComponent } from './layout-content/layout-content.component';
import { MatButtonModule } from '@angular/material/button';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  imports: [
    CommonModule,
    NrgMenuModule,
    MatSidenavModule,
    NrgToolbarModule,
    MatIconModule,
    FlexLayoutModule,
    BreadcrumbModule,
    MatProgressBarModule
  ],
  declarations: [NrgLayoutComponent, LayoutContentComponent],
  exports: [NrgLayoutComponent, LayoutContentComponent]
})
export class NrgLayoutModule {
}
