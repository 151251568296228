<div class="flex-container">
    <table mat-table
           *ngIf="!isLoading"
           [dataSource]="dataSource$ | async"
           class="mat-elevation-z8">
      <ng-container matColumnDef="rowName">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>
  
      <ng-container *ngFor="let column of columnEntities$$ | async" matColumnDef="{{ column.id }}">
        <th mat-header-cell *matHeaderCellDef>{{ column.name }}</th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            [checked]="row[column.id]"
            (change)="handleAllocationChange($event, row, column)"
            [disabled]="readonly || (singleSelectionPerRow && row[column.id])"
          >
          </mat-checkbox>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="columns | async"></tr>
      <tr mat-row *matRowDef="let row; columns: columns | async"></tr>
    </table>
  </div>
  