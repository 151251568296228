import { OperatorFunction } from 'rxjs';
import { EntityState } from '@ngrx/entity/src/models';
import { filter, map } from 'rxjs/operators';

interface Entity {
  materialized: boolean;
  id: string;
}

interface LoadingOneAction {
  id: string;
  correlationId: string;
}

interface LoadingManyAction {
  ids: string[];
  correlationId: string;
}

interface LoadingOneResult {
  entity: Entity;
  correlationId: string;
}

interface LoadingManyResult {
  ids: string[];
  correlationId: string;
}

type LoadingOneParams<TAction extends LoadingOneAction> = [TAction, EntityState<Entity>];
type LoadingManyParams<TAction extends LoadingManyAction> = [TAction, EntityState<Entity>];

/**
 * Rx operator which let pass all entities which are not materialized
 */
export function filterOneIfNotMaterialized<TAction extends LoadingOneAction>(): OperatorFunction<LoadingOneParams<TAction>, LoadingOneResult> {
  return source => source.pipe(
    map(([{ id, correlationId }, state]) => {
      const entity = state.entities[id] ?? { id, materialized: false };

      return {
        entity,
        correlationId,
      };
    }),
    filter(({ entity }) => !entity.materialized),
  );
}

export function filterManyIfNotMaterialized<TAction extends LoadingManyAction>(): OperatorFunction<LoadingManyParams<TAction>, LoadingManyResult> {
  return source => source.pipe(
    map(([{ ids, correlationId }, state]) => {
      const result: LoadingManyResult = { correlationId, ids: [] };

      return ids.reduce((acc, id) => {
        const entity = state.entities[id];

        if (entity == null || !entity.materialized) {
          acc.ids.push(id);
        }

        return acc;
      }, result);
    }),
    filter(result => result.ids.length > 0),
  );
}
