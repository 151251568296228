import { Injectable } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';

@Injectable()
export class KeycloakMockService {

  getToken(): Promise<string> {
    return Promise.resolve('test_token');
  }
  getUsername(): string {
    return 'test_user';
  }

  logout(redirectUri?: string): Promise<void> {
    return Promise.resolve();
  }

  loadUserProfile(forceReload?: boolean): Promise<KeycloakProfile> {
    const profile: KeycloakProfile & {attributes: any} = {
      attributes: {
        kundenId: ['test_kunden_id']
      }
    };

    return Promise.resolve(profile);
  }
}
