import { Injectable } from '@angular/core';
import { VkCommonState } from './vk-common.state';
import { Action, Store } from '@ngrx/store';
import { ErrorCode, VkError } from './entities/loading-error.entity';
import { notifyDidEndLoading, notifyWillBeginLoading } from './store/status/status.actions';
import { findErrorMessage } from './store/store-helper/handle-loading.operator';
import { defer, EMPTY, MonoTypeOperatorFunction, of, pipe } from 'rxjs';
import { catchError, finalize, tap, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VkLoadingHandler {

  constructor(private readonly store: Store<VkCommonState>) {
  }

  untilComplete = <T>(entity: string, correlationId: string, ids?: string[]) => this.handleLoadingUntilCompleteInternal<T>(entity, correlationId, ids);
  untilFirst = <T>(entity: string, correlationId: string, ids?: string[]) => this.handleLoadingUntilFirstInternal<T>(entity, correlationId, ids);

  private handleLoadingUntilCompleteInternal<T>(entity: string, correlationId: string, ids?: string[]): MonoTypeOperatorFunction<T> {
    const context: { ids: string[], correlationId: string, entity: string, error?: VkError } = {
      ids: ids ?? [],
      entity,
      correlationId
    };

    return source =>
      defer(() => {
        let hasError = false;
        this.store.dispatch(notifyWillBeginLoading(context));
        return source.pipe(
          timeout(30000),
          catchError(error => {
            hasError = true;
            const errorMessage = findErrorMessage(error);
            this.store.dispatch(notifyDidEndLoading(context));
            return EMPTY;
          }),
          finalize(() => {
            if (!hasError) {
              this.store.dispatch(notifyDidEndLoading(context));
            }
          })
        );
      });
  }

  private handleLoadingUntilFirstInternal<T>(entity: string, correlationId: string, ids?: string[]): MonoTypeOperatorFunction<T> {
    const context: { ids: string[], correlationId: string, entity: string, error?: VkError } = {
      ids: ids ?? [],
      entity,
      correlationId
    };


    return source =>
      defer(() => {
        let isFirst = true;
        this.store.dispatch(notifyWillBeginLoading(context));
        return source.pipe(
          tap(v => {
            if (!isFirst) {
              return;
            }
            this.store.dispatch(notifyDidEndLoading(context));
            isFirst = false;
          }),
          catchError(error => {
            isFirst = false;
            const errorMessage = findErrorMessage(error);
            this.store.dispatch(notifyDidEndLoading(context));
            return EMPTY;
          }),
          finalize(() => {
            if (isFirst) {
              this.store.dispatch(notifyDidEndLoading(context));
            }
          })
        );
      });


  }
}

export class VkLoadingHandlerMock extends VkLoadingHandler {

  constructor() {
    super(undefined as any);
  }

  untilComplete = <T>(...args: any[]) => this.noop<T>();
  untilFirst = <T>(...args: any[]) => this.noop<T>();

  private noop<T>(): MonoTypeOperatorFunction<T> {
    return source => source;
  }
}
