import { FormSchema } from '@nrg/components';

export const sellingOpportunitySchema: FormSchema = {
    type: 'object',
    required: ['laufzeitBis'],
    properties: {
        laufzeitVon: {
            type: 'dateString',
            title: 'Laufzeit Von'
        },
        laufzeitBis: {
            type: 'dateString',
            title: 'Laufzeit Bis'
        },
    },
    title: 'Verkaufschance'
};
