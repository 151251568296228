import { MonoTypeOperatorFunction, noop, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { catchError, endWith, startWith, tap, timeout } from 'rxjs/operators';
import { notifyDidEndLoading, notifyWillBeginLoading } from '../status/status.actions';

import {
  createError,
  deleteError,
  ErrorCode,
  isCreateError,
  isDeleteError,
  isReadingError,
  isTimeOutError,
  isUpdateError,
  VkError,
  readError,
  timeOutError,
  updateError
} from '../../entities/loading-error.entity';

export function handleLoading(entity: string, errorCode: ErrorCode, correlationId: string, ids?: string[]): MonoTypeOperatorFunction<Action> {
  const context: { ids: string[], correlationId: string, entity: string, error?: VkError } = {
    ids: ids ?? [],
    entity,
    correlationId
  };

  return source =>
    source.pipe(
      endWith(notifyDidEndLoading(context)),
      catchError(error => {
        const errorMessage = findErrorMessage(error);
        return of(notifyDidEndLoading({ ...context, error: { code: errorCode, error: errorMessage } }));
      }),
      startWith(notifyWillBeginLoading(context)),
    );
}


export function findErrorMessage(error: any): string {

  if (isReadingError(error)) {
    return readError.error;
  }

  if (isUpdateError(error)) {
    return updateError.error;
  }

  if (isCreateError(error)) {
    return createError.error;
  }

  if (isDeleteError(error)) {
    return deleteError.error;
  }

  if (isTimeOutError(error)) {
    return timeOutError.error;
  }

  if (typeof (error) !== 'string' && 'message' in error) {
    const nestedErrorMessage = error?.error?.message;
    const errorMessage = error.message;
    return nestedErrorMessage ? errorMessage + '\n\n' + nestedErrorMessage : errorMessage;
  }

  if (typeof (error) === 'string') {
    return error;
  }


  return error + '';
}
