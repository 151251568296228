import { FormSchema } from '@nrg/components';

export const gasStorageSchema: FormSchema = {
  type: 'object',
  title: 'Gasspeicherdaten',
  properties: {
    name: {
      type: 'string',
      title: 'Name'
    },
    producedBiogas: {
      type: 'number',
      title: 'Erzeugtes Biogas (m³N/h)',
      minimum: 0,
      maximum: 1000
    },
    capacity: {
      type: 'number',
      title: 'Speicherkapazität Biogas (m³)',
      minimum: 0,
      maximum: 25000
    },
    methanConcentration: {
      type: 'number',
      title: 'Methangehalt Biogas (Prozent)',
      minimum: 45,
      maximum: 80
    },
    energyConcentration: {
      type: 'number',
      title: 'Energiegehalt Biogas (kWh)',
      readonly: true
    },
    minimalFillingLevel: {
      type: 'number',
      title: 'Untere Gasspeicherfüllstandsgrenze (Prozent)',
      minimum: 0,
      maximum: 40
    },
    maximalFillingLevel: {
      type: 'number',
      title: 'Obere Gasspeicherfüllstandsgrenze (Prozent)',
      minimum: 60,
      maximum: 100
    }
  },
  required: [
    'name',
    'producedBiogas',
    'capacity'
  ]
};
