import { Directive, ElementRef, Input } from '@angular/core';

export interface Translation {
  x: number;
  y: number;
}

@Directive({
  selector: '[nrgTransform]'
})
export class TransformDirective {
  private translation?: Translation;
  private rotation?: number;

  @Input()
  set translateBy(translation: Translation) {
    this.translation = translation;
    this.applyTransformation(translation, this.rotation);
  }

  @Input()
  set rotateBy(degree: number) {
    this.rotation = degree;
    this.applyTransformation(this.translation, degree);
  }

  constructor(private hostElementRef: ElementRef) {
  }

  private applyTransformation(translation?: Translation, rotation?: number): void {
    const svgElement: SVGElement = this.hostElementRef.nativeElement;
    const transformation: string[] = [];

    if (translation != null) {
      transformation.push(`translate(${translation.x} ${translation.y})`);
    }

    if (rotation != null) {
      transformation.push(`rotate(${rotation})`);
    }

    if (transformation.length > 0) {
      svgElement.setAttribute('transform', transformation.join(' '));
    }
  }

}
